unit uTools;

{$mode objfpc}

interface
uses
sysutils,classes, WEBLib.Forms, WEBLib.Graphics, WEBLib.Controls,
WEBLib.StdCtrls, WEBLib.WebCtrls, WEBLib.Dialogs, WebLib.Storage,
WEBLib.ExtCtrls, WEBLib.Buttons, web, StrUtils;

Type

  { TIBEWebPanel }

  TIBEWebPanel=class(TWebPanel)
  private
    procedure btnAddClick(Sender: TObject);
    public
      pk:String;
      pkuerzel, pkuerzelErfass:String;
      edtMinuten:TWebEdit;
      procedure LabelFormatieren(var lbl: TWebLabel; const ASize: boolean; const Align: TAlignment; const HT, T, HL, L, HW, W, HH, H: Integer; const FontC: TColor; const FontN: String; HFontS, FontS: Integer; const Bold: boolean);
      procedure initPanel(const SBParent: TScrollBox; const Index: Integer; const Kuerzel, Name, Detail, Kunde, PriKey, area: String);
      procedure initAdrDetails(const SBParent: TScrollBox; const Name, Anschrift1, Anschrift2, Tel, Mobil, EMail: String; PriKey: String; gefunden: Integer);
      procedure initAdrAPs(const SBParent: TScrollBox; const Index: Integer; const Name, Tel, Mobil, EMail: String);
      procedure initDokuUeberschrift(const SBParent: TScrollBox;const name :string);
      procedure initDokuInhalt(const SBParent: TScrollBox;const name, endung, datei_id, info, status: string);
      procedure initPrjDetails(const SBParent: TScrollBox; const Name, Nummer, Kuerzel, Datum, Status, PL, SB, Wert, rechte: String; PriKey: String; gefunden: Integer);
      procedure initPrjPosUebertschrift(const SBParent: TScrollBox;const name :string);
      procedure initPrjPosInhalt(const SBParent: TScrollBox;const posId, posNr, art, kurztext, vkPreis, menge, einheit, gesamt, langtext, rechte:string);
      procedure initPrjPosCam(const SBParent: TScrollBox);
  end;

Type
  TIBEWebSpeedButton=class(TWebSpeedButton)
    public
      pk:String;
  end;

  TIBEWebButton=class(TWebButton)
    public
      pk:String;
  end;

  TIBEDataRow=class(TStringList)
    private
      fdata:TStringlist;
    public
      constructor create;
      destructor destroy;override;
  end;

  { TIBEData }

  TIBEData=class
     private
       frows:TStringList;
       frow0:TStringList;
       frow1:TStringList;
       frow2:TStringList;
       frow3:TStringList;
       frow4:TStringList;
       frow5:TStringList;
       frow6:TStringList;
       frow7:TStringList;
       frow8:TStringList;
       frow9:TStringList;
       RawDataSL:TStringlist;
     public
       fieldnames:TStringList;
       RawData:String;
       procedure ExecSQL(sql:String);
       procedure AfterExec;
       function count:Integer;
       function row(pk:String):TIBEDataRow;
       function pk(i:Integer):String;      
       function Value(i:Integer; Feldname: String):String;
       constructor create;
       destructor destroy;override;
       function onLoad(Event: TEventListenerEvent): boolean;
  end;

function ScreenWidth: Integer;
function ScreenHeight: Integer;
function ItemHeight: Integer;
function mxScreenWidth: Integer;
function HandySize: Boolean;
function DateTimeDiff(date1, date2: TDateTime): Real;
function ExecGeladen(Event: TEventListenerEvent): boolean;
function FillStrLeft(const Text: String; const Laenge: Integer; const Fillchar: Char = ' '; const IgnoreUeberlaenge: boolean = TRUE): string;
function FillStrRight(const Text: String; const Laenge: Integer; const Fillchar: Char = ' '; const IgnoreUeberlaenge: boolean = TRUE): string;
function ReplaceSpecialChar(const Search: String): string;

var
  XHR,XHREXEC : TJSXMLHttpRequest;
  ExecStringList: TStringList;

  FScreenWidth: Integer;
  FScreenHeight: Integer;
  area:String;           
  Execarea:String;

implementation

uses fMain;

function ScreenWidth: Integer;
begin
  result:=FScreenWidth-18;
end;

function ScreenHeight: Integer;
begin
  result:=FScreenHeight;
end;

function ItemHeight: Integer;
begin
{ result:=ScreenHeight-40;
  result:=result div 10;
  if (result < 85) then
     result := 85;}
  if HandySize then
    result := 60
  else
    result := 85;
end;

function mxScreenWidth: Integer;
begin
  result:=ScreenWidth;
  if result>1000 then result:=1000;
end;                               

function HandySize: Boolean;
begin
  result:= (ScreenWidth < 400) or frmMain.Iphone or frmMain.Android;
end;

function DateTimeDiff(date1, date2: TDateTime): Real;
var difference: real;
begin
  difference := date2 - date1;
  difference := Frac(difference) * 24;
  Result := Round(difference * 4) / 4;
end; 

function ExecGeladen(Event: TEventListenerEvent): boolean;
  procedure Auslesen;
  var s:String;
      i:integer;
      RohDaten: String;
      RohDatenSL: TStringList;
  begin
    RohDatenSL := TStringList.Create;
    RohDaten:=XHREXEC.responseText;
    RohDatenSL.text:=RohDaten;
    ExecStringList.Clear;
    if copy(RohDaten,1,5)='ERROR' then begin
      frmMain.lblError.Caption:=copy(RohDaten,6,3000);
      showmessage(copy('__'+RohDaten,6,3000));
    end
    else
    for i:=0 to RohDatenSL.Count -1 do begin
      s := RohDatenSL.Strings[i];
      while pos('|',s)>0 do begin
        ExecStringList.Add(copy(s,1,pos('|',s)-1));
        s := copy(s,pos('|',s)+1);
      end;
      ExecStringList.Add(s);
    end;
    RohDatenSL.Free;
  end;
begin
  if (XHREXEC.status = 200) then begin
    result:=true;
    Auslesen;
    if Execarea='AnzahlDatensaetze' then frmMain.ShowNextButton else
    if Execarea='ShowKurztext' then ShowMessage(ExecStringList.Text) else
    if Execarea='ShowLangtext' then ShowMessage(ExecStringList.Text) else
    if Execarea='Count' then frmMain.CountErfassung else
    if Execarea='xxxxx' then ShowMessage('xxxx');
  end;
end;

function FillStrLeft(const Text: String; const Laenge: Integer; const Fillchar: Char = ' '; const IgnoreUeberlaenge: boolean = TRUE): string;
begin
  Result := Text;
  while (length(Result) < Laenge) do
    Result := Fillchar + Result;
  if not IgnoreUeberlaenge then
    Result := copy(Result, 1, Laenge);
end;                   

function FillStrRight(const Text: String; const Laenge: Integer; const Fillchar: Char = ' '; const IgnoreUeberlaenge: boolean = TRUE): string;
begin
  Result := Text;
  while (length(Result) < Laenge) do
    Result := Result + Fillchar;
  if not IgnoreUeberlaenge then
    Result := copy(Result, 1, Laenge);
end;

procedure TIBEWebPanel.btnAddClick(Sender: TObject);
var m:integer;
    sqlexec:STring;
begin
  m:=StrToIntDef(edtMinuten.text,0);
  if TWebButton(sender).caption='+15' then m:=m+15 else
  if TWebButton(sender).caption='+60' then m:=m+60 else
  if TWebButton(sender).caption='!' then
  begin

   showmessage('speichern');
   sqlexec:=frmmain.Token+frmmain.ident+'update test set id='+edtMinuten.Text;
   xhrexec.open('GET', 'https://greenware.ibexpert.com/sqlgw/data.php?CMD='+sqlexec, false);
   xhrexec.send;
   showmessage('gespeichert');

  end;
  edtMinuten.text:=inttostr(m);
end;

procedure TIBEWebPanel.LabelFormatieren(var lbl: TWebLabel; const ASize: boolean; const Align: TAlignment; const HT, T, HL, L, HW, W, HH, H: Integer; const FontC: TColor; const FontN: String; HFontS, FontS: Integer; const Bold: boolean);
begin
  lbl.Autosize := ASize;
  lbl.Alignment := Align;
  lbl.Font.Color := FontC;
  lbl.Font.Name := FontN;

  if Bold then
    lbl.Font.Style := lbl.Font.Style + [fsBold];
  if HandySize then begin
    lbl.Top := HT;
    lbl.Left := HL;
    lbl.Width := HW;
    lbl.Height := HH;
    lbl.Font.Size := HFontS;
  end
  else begin
    lbl.Top := T;
    lbl.Left := L;
    lbl.Width := W;
    lbl.Height := H;
    lbl.Font.Size := FontS;
  end;
end;

procedure TIBEWebPanel.initPanel(const SBParent: TScrollBox; const Index: Integer;
                                 const Kuerzel, Name, Detail, Kunde, PriKey, area: String);
var iLabel: TWebImageControl;
    lblLabel, lblName1, lblAnschrift, lblKunde, lblInfo: TWebLabel;
    dots: string;
    copyint: integer;
    pnlLinie: TWebPanel;
begin
  parent := SBParent;
  Height := ItemHeight;
  BorderStyle := bsNone;
  //if (Index = 0) then
    //top := (Index * ItemHeight) + 10
  //else
  top := (Index * ItemHeight) - Index;
  Left := 10;
  Height := ItemHeight;
  Width := frmMain.edtSearch.width + frmMain.pnlsearch.width;
  //Anchors := [akTop,akLeft,akRight];
  caption := '';
  Color := clwhite;
  //if index mod 2 = 0 then
    //Color:=clwhite
  //else
    //Color:=clSilver;

  //Caption:='&nbsp;'+adrData.row(adrData.pk(index)).Values['NAME1'];
  //Caption:='&nbsp;'+Name1;
  //Caption:='&nbsp;'+IntTostr(Index);
  if (Kuerzel <> '') then begin
    iLabel := TWebImageControl.create(self);
    //graue Box
    iLabel.Parent := self;
    iLabel.Top := 9;
    if HandySize then begin
      iLabel.Width := 80;
      iLabel.Height := 16;
      iLabel.Left := 5;
    end
    else begin             
      iLabel.Width := 150;
      iLabel.Height := 28;
      iLabel.Left := 10;
    end;
    iLabel.Autosize := FALSE;
    iLabel.Picture.LoadFromFile('.\labelbackground_grey.jpg');
    iLabel.OnClick := self.OnClick;
    lblLabel := TWebLabel.Create(self);
    lblLabel.Parent := self;
    LabelFormatieren(lblLabel, FALSE, taCenter, 9, 11, 8, 10, 75, 150, 12, 25, clWhite, 'Helvetica', 9, 12, TRUE);
    //Kürzel graue Box

    dots := '';
    copyint:= 20;
    if (Length(Kuerzel) > 9) and (Handysize) and ((area = 'MainMenu') or (area = 'MainMenuNext'))then begin
     dots := '...';
     copyint := 9;
    end
    else if (Length(Kuerzel) > 13) and not (Handysize) and ((area = 'MainMenu') or (area = 'MainMenu'))then begin
     dots := '...';
     copyint := 13;
    end
    else if (Length(Kuerzel) > 12) and (Handysize) and ((area = 'Projekte') or (area = 'ProjekteNext')) then begin
     dots := '...';
     copyint := 12;
    end
    else if (Length(Kuerzel) > 16) and not (Handysize) and ((area = 'Projekte') or (area = 'ProjekteNext'))then begin
      dots := '...';
      copyint := 16;
    end;

    lblLabel.Caption := copy(Kuerzel, 1, copyint)+dots;
    lblLabel.OnClick := self.OnClick;
  end;
  lblName1 := TWebLabel.Create(self);
  lblName1.Parent := self;
  LabelFormatieren(lblName1, TRUE, taLeftJustify, 7, 9, 90, 180, 0, 0, 0, 0, $00606060, 'Helvetica', 14, 18, FALSE);
  //Name rechts neben grauer Box
  lblName1.OnClick := self.OnClick;
  lblName1.Caption := Name;

  lblAnschrift := TWebLabel.Create(self);
  lblAnschrift.Parent := self;
  LabelFormatieren(lblAnschrift, TRUE, taLeftJustify, 29, 42, 90, 180, 0, 0, 0, 0, $00606060, 'Helvetica', 11, 14, FALSE);
  //Anschrift/ Projektdatum und Name
  lblAnschrift.Caption := Detail;
  lblAnschrift.OnClick := self.OnClick;
  lblKunde := TWebLabel.Create(self);
  lblKunde.Parent := self;
  LabelFormatieren(lblKunde, TRUE, taLeftJustify, 29, 42, 8, 10, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 11, TRUE);
  //Kunden Kürzel (Projektseite)
  if (HandySize) and (Length(Kunde) > 8) then
    lblKunde.Caption := copy(Kunde, 1, 8)+'...'
  else if (HandySize) then
    lblKunde.Caption := copy(Kunde, 1, 8)
  else if (Length(Kunde) > 16) then
    lblKunde.Caption := copy(Kunde, 1, 15)+'...'
  else
    lblKunde.Caption := copy(Kunde, 1, 15);
  lblKunde.OnClick := self.OnClick;

{ lblInfo := TWebLabel.Create(self);
  lblInfo.Parent := self;
  LabelFormatieren(lblInfo, TRUE, taLeftJustify, 35, 50, 10, 10, 0, 0, 0, 0, $00606060, 'Helvetica', 7, 7, FALSE);
  lblInfo.Caption := 'S-W: ' + IntToStr(ScreenWidth) + '; I-H: ' + IntToStr(ItemHeight);
  lblInfo.OnClick := self.OnClick;}

  pk := PriKey;
  if (Kunde = '') then
    pkuerzel := Kuerzel
  else
    pkuerzel := copy(Detail, 0, pos(' - ', Detail) - 1);

  pnlLinie:= TWebPanel.create(self);
  pnlLinie.Parent := self;
  pnlLinie.Left := 10;
  if HandySize then pnlLinie.Top:=57 else pnlLinie.Top:=77;

  pnlLinie.Height:=1;
  pnlLinie.Borderstyle:= bsSingle;
  pnlLinie.Width:= frmMain.edtSearch.width + frmMain.pnlsearch.width;

end;

procedure TIBEWebPanel.initAdrDetails(const SBParent: TScrollBox; const Name, Anschrift1, Anschrift2, Tel, Mobil, EMail: String; PriKey: String; gefunden: Integer);
var ivcard, iAdresszurueckBG, iProjektBG, igmaps, iDokumenteBG: TWebImageControl;
    lblAdresse, lblProjekt, lblgmaps, lblName, lblAnschriftInfo, lblAnschrift1, lblAnschrift2,
    lblKontaktInfo, lblTelInfo, lblTel, lblMobilInfo, lblMobil, lblEMailInfo, lblEMail, lblAPInfo,
    lblDokumente : TWebLabel;
    btnAdd: TWebButton;
    pnlLinie: TWebPanel;
begin
  parent := SBParent;
  Height := ItemHeight;

  BorderStyle := bsNone;
  top := 0;
  Left := 0;
  if handysize then Height := 260 else Height := 315;

  if (Trim(Tel) <> '') or (Trim(Mobil) <> '') or (Trim(EMail) <> '') then
    Height := Height + 40;
  if (Trim(Tel) <> '') then                                               
    Height := Height + 20;
  if (Trim(Mobil) <> '') then
    Height := Height + 20;
  if (Trim(EMail) <> '') then
    Height := Height + 20;

  Width := ScreenWidth;
  Anchors := [akTop,akLeft,akRight];
  caption := '';
  Color := clwhite;
  //if index mod 2 = 0 then
    //Color:=clwhite
  //else
    //Color:=clSilver;

  //Caption:='&nbsp;'+adrData.row(adrData.pk(index)).Values['NAME1'];
  //Caption:='&nbsp;'+Name1;
  //Caption:='&nbsp;'+IntTostr(Index);
  ivcard := TWebImageControl.create(self);
  ivcard.Parent := self;
  ivcard.Left := 24;
  ivcard.Top := 15;
  if HandySize then begin
    ivcard.Width := 40;
    ivcard.Height := 30;
  end
  else begin
    ivcard.Width := 80;
    ivcard.Height := 61;
  end;
  ivcard.Autosize := FALSE;
  ivcard.Picture.LoadFromFile('.\vcard_image.jpg');

  igmaps := TWebImageControl.create(self);
  igmaps.Parent := self;
  igmaps.Left := 250;
  igmaps.Top := 180;
  if HandySize then begin
    igmaps.Width := 20;
    igmaps.Height := 20;
    igmaps.Top := 135;
  end
  else begin
    igmaps.Width := 30;
    igmaps.Height := 30;
  end;
  igmaps.Autosize := FALSE;
  igmaps.Picture.LoadFromFile('.\google_maps.jpg');    
  //igmaps.OnClick := @frmMain.GMapsAufrufen;
  lblgmaps := TWebLabel.create(self);
  lblgmaps.Parent := self;
  LabelFormatieren(lblgmaps, FALSE, taCenter, 135, 180, 250, 250, 0, 0, 0, 0, clWhite, 'Helvetica', 19, 23, FALSE);
  //Google maps icon
  lblgmaps.Caption := '<a href=https://www.google.de/maps/place/'+StringReplace(Anschrift1 + '+' + Anschrift2, ' ', '+', [rfReplaceAll])+' target="_blank" style="text-decoration: none">&&nbsp;&&nbsp;&&nbsp;</a>';

  iAdresszurueckBG := TWebImageControl.create(self);
  iAdresszurueckBG.Parent := self;
  iAdresszurueckBG.Left := 25;
  if HandySize then begin
    iAdresszurueckBG.Top := 60;
    iAdresszurueckBG.Width := 130;
    iAdresszurueckBG.Height := 25;
  end
  else begin
    iAdresszurueckBG.Top := 100;
    iAdresszurueckBG.Width := 180;
    iAdresszurueckBG.Height := 30;
  end;
  iAdresszurueckBG.Autosize := FALSE;
  iAdresszurueckBG.Picture.LoadFromFile('.\labelbackground_grey.jpg');
  lblAdresse := TWebLabel.Create(self);
  lblAdresse.Parent := self;
  LabelFormatieren(lblAdresse, FALSE, taCenter, 65, 105, 25, 25, 130, 180, 25, 30, clWhite, 'Helvetica', 10, 13, FALSE);
  //zu Adresse zurück Button
  lblAdresse.Caption := 'zurück zu Adressen';
  lblAdresse.OnClick := @frmMain.pnlSearchClick;

  iProjektBG := TWebImageControl.create(self);
  iProjektBG.Parent := self;

  if HandySize then begin 
    iProjektBG.Top := 60;
    iProjektBG.Width := 120;
    iProjektBG.Height := 25;
    iProjektBG.Left := 175;
  end
  else begin         
    iProjektBG.Top := 100;
    iProjektBG.Width := 160;
    iProjektBG.Height := 30;
    iProjektBG.Left := 220;
  end;
  iProjektBG.Autosize := FALSE;
  iProjektBG.Picture.LoadFromFile('.\labelbackground_grey.jpg');
  lblProjekt := TWebLabel.Create(self);
  lblProjekt.Parent := self;     
  LabelFormatieren(lblProjekt, FALSE, taCenter, 65, 105, 175, 220, 120, 160, 25, 30, clWhite, 'Helvetica', 10, 13, FALSE);
  //Projekte anzeigen Button
  lblProjekt.Caption := 'Projekte anzeigen';
  lblProjekt.OnClick := @frmMain.ProjekteAnzeigen;

  iDokumenteBG := TWebImageControl.create(self);
    iDokumenteBG.Parent := self;
    iDokumenteBG.Left := 25;
    if HandySize then begin
      iDokumenteBG.Top := 92;
      iDokumenteBG.Width := 130;
      iDokumenteBG.Height := 25;
    end
    else begin
      iDokumenteBG.Top := 137;
      iDokumenteBG.Width := 180;
      iDokumenteBG.Height := 30;
    end;
  iDokumenteBG.Autosize := FALSE;
  lblDokumente := TWebLabel.Create(self);
  lblDokumente.Parent := self;
  LabelFormatieren(lblDokumente, FALSE, taCenter, 97, 142, 25, 25, 130, 180, 25, 30, clWhite, 'Helvetica', 10, 13, FALSE);
  //zu Adresse zurück Button
  lblDokumente.Caption := 'Dokumente anzeigen';
  lblDokumente.OnClick := @frmMain.DokumenteAnzeigen;

  if not(gefunden = 0) and not (gefunden = -1)then begin
    iDokumenteBG.Picture.LoadFromFile('.\labelbackground_grey.jpg');
    frmMain.buttonDokTrue := True;
  end
  else begin
    iDokumenteBG.Picture.LoadFromFile('.\labelbackground_bright_grey.jpg');
    frmMain.buttonDokTrue := False;
  end;

  lblName := TWebLabel.Create(self);
  lblName.Parent := self;    
  LabelFormatieren(lblName, TRUE, taLeftJustify, 15, 15, 75, 120, 0, 0, 0, 0, $00606060, 'Helvetica', 13, 18, FALSE);
  //Überschrift/ Unternehmen
  lblName.Caption := Name;

  lblAnschriftInfo := TWebLabel.Create(self);
  lblAnschriftInfo.Parent := self;
  LabelFormatieren(lblAnschriftInfo, TRUE, taLeftJustify, 135, 180, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 13, 16, FALSE);
  //Anschrift Überschrift
  lblAnschriftInfo.Caption := 'Anschrift';
  lblAnschrift1 := TWebLabel.Create(self);
  lblAnschrift1.Parent := self;
  LabelFormatieren(lblAnschrift1, TRUE, taLeftJustify, 160, 210, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 11, 13, FALSE);
  //Anschrift Straße
  lblAnschrift1.Caption := Anschrift1;
  lblAnschrift2 := TWebLabel.Create(self);
  lblAnschrift2.Parent := self;
  LabelFormatieren(lblAnschrift2, TRUE, taLeftJustify, 180, 230, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 11, 13, FALSE);
  //Anschrift Ort
  lblAnschrift2.Caption := Anschrift2;

  pnlLinie:= TWebPanel.create(self);
  pnlLinie.Parent := self;
  pnlLinie.Left := 25;
  if HandySize then pnlLinie.Top:=210 else pnlLinie.Top:=255;

  pnlLinie.Height:=1;
  pnlLinie.Borderstyle:= bsSingle;
  pnlLinie.Width:= frmMain.edtSearch.width + frmMain.pnlsearch.width;

  if (Trim(Tel) <> '') or (Trim(Mobil) <> '') or (Trim(EMail) <> '') then begin
    lblKontaktInfo := TWebLabel.Create(self);
    lblKontaktInfo.Parent := self;     
    LabelFormatieren(lblKontaktInfo, TRUE, taLeftJustify, 215, 270, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 13, 16, FALSE);
    //Kontakt Überschrift
    lblKontaktInfo.Caption := 'Kontakt';

    pnlLinie:= TWebPanel.create(self);
    pnlLinie.Parent := self;
    pnlLinie.Height:=1;
    pnlLinie.Left := 25;
    pnlLinie.Borderstyle:= bsSingle;
    pnlLinie.Width:= frmMain.edtSearch.width + frmMain.pnlsearch.width;

    if (Trim(Tel) <> '') then begin
      lblTelInfo := TWebLabel.Create(self);
      lblTelInfo.Parent := self;  
      LabelFormatieren(lblTelInfo, TRUE, taLeftJustify, 245, 300, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 11, 13, FALSE);
      //Telefon Überschrift
      lblTelInfo.Caption := 'Telefon:';
      lblTel := TWebLabel.Create(self);
      lblTel.Parent := self;       
      LabelFormatieren(lblTel, TRUE, taLeftJustify, 245, 300, 85, 85, 0, 0, 0, 0, $00606060, 'Helvetica', 11, 13, FALSE);
      //Telefonnummer
      lblTel.Caption := '<a href=tel:'+StringReplace(Tel, ' ', '', [rfReplaceAll])+'>'+Tel+'</a>';

      if HandySize then pnlLinie.Top:=274 else pnlLinie.Top:=319;
    end;

    if (Trim(Mobil) <> '') then begin
      lblMobilInfo := TWebLabel.Create(self);
      lblMobilInfo.Parent := self;
      LabelFormatieren(lblMobilInfo, TRUE, taLeftJustify, 245, 300, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 11, 13, FALSE);
      //Überschrift Mobile Nummer
      if (Trim(Tel) <> '') then
        lblMobilInfo.Top := lblMobilInfo.Top + 20;
      lblMobilInfo.Caption := 'Mobil:';
      lblMobil := TWebLabel.Create(self);
      lblMobil.Parent := self;        
      LabelFormatieren(lblMobil, TRUE, taLeftJustify, 245, 300, 85, 85, 0, 0, 0, 0, $00606060, 'Helvetica', 11, 13, FALSE);
      //Mobile Telefonnummer
      if (Trim(Tel) <> '') then
        lblMobil.Top := lblMobil.Top + 20;
      lblMobil.Caption := '<a href=tel:'+StringReplace(Mobil, ' ', '', [rfReplaceAll])+'>'+Mobil+'</a>';

      if HandySize then pnlLinie.Top:=285 else pnlLinie.Top:=335;
    end;

    if (Trim(EMail) <> '') then begin
      lblEMailInfo := TWebLabel.Create(self);
      lblEMailInfo.Parent := self; 
      LabelFormatieren(lblEMailInfo, TRUE, taLeftJustify, 245, 300, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 11, 13, FALSE);
      //Email Überschrift
      if (Trim(Tel) <> '') then
        lblEMailInfo.Top := lblEMailInfo.Top + 20;
      if (Trim(Mobil) <> '') then
        lblEMailInfo.Top := lblEMailInfo.Top + 20;
      lblEMailInfo.Caption := 'E-Mail:';
      lblEMail := TWebLabel.Create(self);
      lblEMail.Parent := self;            
      LabelFormatieren(lblEMail, TRUE, taLeftJustify, 245, 300, 85, 85, 0, 0, 0, 0, $00606060, 'Helvetica', 11, 13, FALSE);
      //Email
      if (Trim(Tel) <> '') then
        lblEMail.Top := lblEMail.Top + 20;
      if (Trim(Mobil) <> '') then
        lblEMail.Top := lblEMail.Top + 20;
      lblEMail.Caption := '<a href=mailto:'+EMail+'>'+EMail+'</a>';

      if HandySize then pnlLinie.Top:=315 else pnlLinie.Top:=365;
    end;
  end;

  lblAPInfo := TWebLabel.Create(self);
  lblAPInfo.Parent := self;  
  LabelFormatieren(lblAPInfo, TRUE, taLeftJustify, 235, 290, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 13, 16, FALSE);
  //Ansprechpartner Überschrift
  if (Trim(Tel) <> '') or (Trim(Mobil) <> '') or (Trim(EMail) <> '') then
    lblAPInfo.Top := lblAPInfo.Top + 40;
  if (Trim(Tel) <> '') then
    lblAPInfo.Top := lblAPInfo.Top + 20;
  if (Trim(Mobil) <> '') then
    lblAPInfo.Top := lblAPInfo.Top + 20;
  if (Trim(EMail) <> '') then
    lblAPInfo.Top := lblAPInfo.Top + 20;
  lblAPInfo.Caption := 'Ansprechpartner';

  pk := PriKey;
end;

procedure TIBEWebPanel.initAdrAPs(const SBParent: TScrollBox; const Index: Integer; const Name, Tel, Mobil, EMail: String);
var lblName, lblTelInfo, lblTel, lblMobilInfo, lblMobil, lblEMailInfo, lblEMail, lblAPInfo: TWebLabel;
begin
  parent := SBParent;
  Height := 40;
  BorderStyle := bsNone;
  top := frmMain.letzteHeight;
  Left := 25;
  Height := 30;
  if (Trim(Tel) <> '') then
    Height := Height + 20;
  if (Trim(Mobil) <> '') then
    Height := Height + 20;
  if (Trim(Email) <> '') then
    Height := Height + 20;
  Width := ScreenWidth - 45;
  Anchors := [akTop,akLeft,akRight];
  caption := '';
  Color := clwhite;

  lblName := TWebLabel.Create(self);
  lblName.Parent := self;        
  LabelFormatieren(lblName, TRUE, taLeftJustify, 5, 5, 0, 0, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
  //Name
  lblName.Caption := Name;

  if (Trim(Tel) <> '') then begin
    lblTelInfo := TWebLabel.Create(self);
    lblTelInfo.Parent := self;  
    LabelFormatieren(lblTelInfo, TRUE, taLeftJustify, 25, 25, 0, 0, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
    //Telefon Überschrift
    lblTelInfo.Caption := 'Telefon:';
    lblTel := TWebLabel.Create(self);
    lblTel.Parent := self;             
    LabelFormatieren(lblTel, TRUE, taLeftJustify, 25, 25, 60, 60, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
    //Telefonnummer
    lblTel.Caption := '<a href=tel:'+StringReplace(Tel, ' ', '', [rfReplaceAll])+'>'+Tel+'</a>';
  end;

  if (Trim(Mobil) <> '') then begin
    lblMobilInfo := TWebLabel.Create(self);
    lblMobilInfo.Parent := self;  
    LabelFormatieren(lblMobilInfo, TRUE, taLeftJustify, 25, 25, 0, 0, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
    //Mobilnummer Überschrift
    if (Trim(Tel) <> '') then
      lblMobilInfo.Top := lblMobilInfo.Top + 20;
    lblMobilInfo.Caption := 'Mobil:';
    lblMobil := TWebLabel.Create(self);
    lblMobil.Parent := self;    
    LabelFormatieren(lblMobil, TRUE, taLeftJustify, 25, 25, 60, 60, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
    //Mobile Telefonnummer
    if (Trim(Tel) <> '') then
      lblMobil.Top := lblMobil.Top + 20;
    lblMobil.Caption := '<a href=tel:'+StringReplace(Mobil, ' ', '', [rfReplaceAll])+'>'+Mobil+'</a>';
  end;

  if (Trim(EMail) <> '') then begin
    lblEMailInfo := TWebLabel.Create(self);
    lblEMailInfo.Parent := self;      
    LabelFormatieren(lblEMailInfo, TRUE, taLeftJustify, 25, 25, 0, 0, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
    //Email Überschrift
    if (Trim(Tel) <> '') then
      lblEMailInfo.Top := lblEMailInfo.Top + 20;
    if (Trim(Mobil) <> '') then
      lblEMailInfo.Top := lblEMailInfo.Top + 20;
    lblEMailInfo.Caption := 'E-Mail:';
    lblEMail := TWebLabel.Create(self);
    lblEMail.Parent := self;             
    LabelFormatieren(lblEMail, TRUE, taLeftJustify, 25, 25, 60, 60, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
    //Email
    if (Trim(Tel) <> '') then
      lblEMail.Top := lblEMail.Top + 20;
    if (Trim(Mobil) <> '') then
      lblEMail.Top := lblEMail.Top + 20;
    lblEMail.Caption := '<a href=mailto:'+EMail+'>'+EMail+'</a>';
  end;

end;

procedure TIBEWebPanel.initDokuUeberschrift(const SBParent: TScrollBox;const name :string);
var ibox, iAdresseBG: TWebImageControl;
    lblAdresse, lblName: TWebLabel;
begin
  parent := SBParent;
  Height := ItemHeight;

  BorderStyle := bsNone;
  top := 0;
  Left := 0;
  Height := 460;
  Width := frmMain.edtSearch.width + frmMain.pnlsearch.width;
  Anchors := [akTop,akLeft,akRight];
  Color := clwhite;

  ibox := TWebImageControl.create(self);
  ibox.Parent := self;
  ibox.Left := 24;
  ibox.Top := 15;
  if HandySize then begin
    ibox.Width := 40;
    ibox.Height := 30;
  end
  else begin
    ibox.Width := 80;
    ibox.Height := 61;
  end;
  ibox.Autosize := FALSE;
  ibox.Picture.LoadFromFile('.\box_image.jpg');

  iAdresseBG := TWebImageControl.create(self);
  iAdresseBG.Parent := self;
  iAdresseBG.Left := 25;
  if HandySize then begin
    iAdresseBG.Top := 60;
    iAdresseBG.Width := 110;
    iAdresseBG.Height := 25;
  end
  else begin
    iAdresseBG.Top := 100;
    iAdresseBG.Width := 160;
    iAdresseBG.Height := 30;
  end;
  iAdresseBG.Autosize := FALSE;
  iAdresseBG.Picture.LoadFromFile('.\labelbackground_grey.jpg');
  iAdresseBG.OnClick := @frmMain.AdresseAnzeigen;
  lblAdresse := TWebLabel.Create(self);
  lblAdresse.Parent := self;
  LabelFormatieren(lblAdresse, FALSE, taCenter, 65, 105, 25, 25, 110, 160, 25, 30, clWhite, 'Helvetica', 10, 13, FALSE);
  //Adresse anzeigen Button
  lblAdresse.Caption := 'Adresse anzeigen';
  lblAdresse.OnClick := @frmMain.AdresseAnzeigen;

  lblName := TWebLabel.Create(self);
  lblName.Parent := self;
  LabelFormatieren(lblName, TRUE, taLeftJustify, 20, 30, 75, 120, 0, 0, 0, 0, $00606060, 'Helvetica', 11, 16, FALSE);
  //Überschrift Projekt
  lblName.Caption := 'Dokumente von:' + Name;

end;

procedure TIBEWebPanel.initDokuInhalt(const SBParent: TScrollBox;const name, endung, datei_id, info, status: string);
var lblDoku, lblDokumentButton, lbllinkDoku, lblmessage: TWebLabel;
    pnlLinie: TWebPanel;
    iDokumentButtonBG: TWebImageControl;
begin
  parent := SBParent;
  Height := ItemHeight;

  BorderStyle := bsNone;
  if handysize then
     top := frmMain.letzteHeight-350
  else
     top := frmMain.letzteHeight-300;

  Left := 0;

  if handysize then
     Height := 72
  else
     Height := 90;
  Width := frmMain.edtSearch.width + frmMain.pnlsearch.width;
  Anchors := [akTop,akLeft,akRight];
  caption := '';
  Color := clwhite;

  //Dokumentinfos anzeigen
  lblDoku := TWebLabel.Create(self);
  lblDoku.Parent := self;

  lblDoku.Caption := '<b>'+datei_id+'</b>' +' '+ name
                      +'<br>'+ info;

  LabelFormatieren(lblDoku, TRUE, taLeftJustify, 0, 0, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);

  iDokumentButtonBG := TWebImageControl.Create(self);
  iDokumentButtonBG.Parent := self;
  iDokumentButtonBG.Left := 25;
  if HandySize then begin
    iDokumentButtonBG.Top := 44;
    iDokumentButtonBG.Width := 100;
    iDokumentButtonBG.Height := 25;
  end
  else begin
    iDokumentButtonBG.Top := 54;
    iDokumentButtonBG.Width := 120;
    iDokumentButtonBG.Height := 30;
  end;
  iDokumentButtonBG.Autosize := FALSE;
  iDokumentButtonBG.Picture.LoadFromFile('.\labelbackground_grey.jpg');

  lblDokumentButton := TWebLabel.Create(self);
  lblDokumentButton.Parent := self;
  LabelFormatieren(lblDokumentButton, FALSE, taCenter, 45, 55, 30, 30, 0, 0, 0, 0, clWhite, 'Helvetica', 10, 13, FALSE);

  //Dokument befindet sich noch nicht in der Datenbank und wurde angefragt
  if (status = '2') then begin
    lblDokumentButton.Caption:= 'wird angefragt';

    lblmessage := TWebLabel.Create(self);
    lblmessage.Parent := self;
    lblmessage.Caption := 'Dokument ist bald abrufbar'
                         +'<br>Bitte warten';
    LabelFormatieren(lblmessage, TRUE, taLeftJustify, 38, 51, 141, 148, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
    frmMain.WtDokumentNeuladen.enabled := true;
  end
  //Dokument befindet sich in der Datenbank und kann heruntergeladen werden
  else if (status = '3') or (status = '4') then begin
    LabelFormatieren(lblDokumentButton, FALSE, taCenter, 45, 55, 31, 31, 0, 0, 0, 0, clWhite, 'Helvetica', 10, 13, FALSE);
    lblDokumentButton.Caption:= 'Herunterladen';

    lblDokumentButton.Caption:='<a href="https://greenwarecloud.de/sqlgw/img.php?IDX='+frmmain.Token+frmmain.ident+datei_id+'" download="'+info+endung+'">Herunterladen</img>';
  end
  //Dokument befindet sich nicht in der Datenbank, kann aber angefragt werden
  else begin
    LabelFormatieren(lblDokumentButton, FALSE, taCenter, 45, 55, 40, 40, 0, 0, 0, 0, clWhite, 'Helvetica', 10, 13, FALSE);
    lblDokumentButton.Name := 'd' + datei_id;
    lblDokumentButton.caption := 'Anfragen';
    //damit das onclick nur einmal funktioniert, wird hierbei die Caption abgefragt
    if lblDokumentButton.caption = 'Anfragen' then begin
       lblDokumentButton.OnClick:= @frmMain.UpdateStatus; //setzt status auf 2
    end;
  end;
end;

procedure TIBEWebPanel.initPrjDetails(const SBParent: TScrollBox; const Name, Nummer, Kuerzel, Datum, Status, PL, SB, Wert, rechte: String; PriKey: String; gefunden: Integer);
var ibox, iAdresseBG, iProjektzurueckBG, iErfassungBG, iStatusBG,
    iPositionen, iDokumenteBG: TWebImageControl;
    lblAdresse, lblProjekt, lblErfassung, lblName, lblProjektInfo, lblNummerInfo,
    lblNummer, lblKuerzelInfo, lblKuerzel, lblDatumInfo, lblDatum, lblStatusInfo,
    lblStatus, lblProjektleitung, lblPLInfo, lblPL, lblSBInfo, lblSB,
    lblFinanzielles, lblWertInfo, lblWert, lblProjektpositionen, lblPositionen,
    lblDokumente: TWebLabel;
    i, widthPlus: integer;
    pnlLinie: TWebPanel;
begin
  parent := SBParent;
  Height := ItemHeight;

  BorderStyle := bsNone;
  top := 0;
  Left := 0;
  Height := 500;
  Width := ScreenWidth;
  Anchors := [akTop,akLeft,akRight];
  caption := '';
  Color := clwhite;

  ibox := TWebImageControl.create(self);
  ibox.Parent := self;
  ibox.Left := 24;
  ibox.Top := 15;
  if HandySize then begin
    ibox.Width := 40;
    ibox.Height := 30;
  end
  else begin
    ibox.Width := 80;
    ibox.Height := 61;
  end;
  ibox.Autosize := FALSE;
  ibox.Picture.LoadFromFile('.\box_image.jpg');

  iAdresseBG := TWebImageControl.create(self);
  iAdresseBG.Parent := self;
  iAdresseBG.Left := 25;
  if HandySize then begin
    iAdresseBG.Top := 60;
    iAdresseBG.Width := 115;
    iAdresseBG.Height := 25;
  end
  else begin
    iAdresseBG.Top := 100;
    iAdresseBG.Width := 160;
    iAdresseBG.Height := 30;
  end;
  iAdresseBG.Autosize := FALSE;
  iAdresseBG.Picture.LoadFromFile('.\labelbackground_grey.jpg');
  iAdresseBG.OnClick := @frmMain.AdresseAnzeigen;
  lblAdresse := TWebLabel.Create(self);
  lblAdresse.Parent := self;
  LabelFormatieren(lblAdresse, FALSE, taCenter, 65, 105, 25, 25, 110, 160, 25, 30, clWhite, 'Helvetica', 10, 13, FALSE);
  //Adresse anzeigen Button
  lblAdresse.Caption := 'Adresse anzeigen';
  lblAdresse.OnClick := @frmMain.AdresseAnzeigen;

  iProjektzurueckBG := TWebImageControl.create(self);
  iProjektzurueckBG.Parent := self;
  if HandySize then begin
    iProjektzurueckBG.Top := 60;
    iProjektzurueckBG.Width := 115;
    iProjektzurueckBG.Height := 25;
    iProjektzurueckBG.Left := 145;
  end
  else begin
    iProjektzurueckBG.Top := 100;
    iProjektzurueckBG.Width := 160;
    iProjektzurueckBG.Height := 30;
    iProjektzurueckBG.Left := 200;
  end;
  iProjektzurueckBG.Autosize := FALSE;
  iProjektzurueckBG.Picture.LoadFromFile('.\labelbackground_grey.jpg');
  lblProjekt := TWebLabel.Create(self);
  lblProjekt.Parent := self;
  LabelFormatieren(lblProjekt, FALSE, taCenter, 65, 105, 145, 200, 110, 160, 25, 30, clWhite, 'Helvetica', 10, 13, FALSE);
  //zu Projekten zurück Button (mit Suchfilter auf Projekte des enstsprechenden Kürzels)
  lblProjekt.Caption := 'Projekte anzeigen';
  lblProjekt.OnClick := @frmMain.ProjekteAnzeigen;

  iErfassungBG := TWebImageControl.create(self);
  iErfassungBG.Parent := self;
  if HandySize then begin
    iErfassungBG.Top := 92;
    iErfassungBG.Left := 160;
    iErfassungBG.Width := 125;
    iErfassungBG.Height := 25;
  end
  else begin
    iErfassungBG.Top := 137;
    iErfassungBG.Left := 215;
    iErfassungBG.Width := 160;
    iErfassungBG.Height := 30;
  end;

  iErfassungBG.Autosize := FALSE;
  iErfassungBG.OnClick := @frmMain.AdresseAnzeigen;
  lblErfassung := TWebLabel.Create(self);
  lblErfassung.Parent := self;
  LabelFormatieren(lblErfassung, FALSE, taCenter, 97, 142, 160, 215, 110, 160, 25, 30, clWhite, 'Helvetica', 10, 13, FALSE);
  //Erfassung beginnen Button
  lblErfassung.Caption := 'Erfassung beginnen';
  lblErfassung.OnClick := @frmMain.ErfassungClick;

  if (Status = 'Auftrag erteilt') or (Status = 'Auftrag in Ausführung') then begin
    iErfassungBG.Picture.LoadFromFile('.\labelbackground_grey.jpg');
    frmMain.buttonErfTrue := True;
  end
  else begin
    iErfassungBG.Picture.LoadFromFile('.\labelbackground_bright_grey.jpg');
    frmMain.buttonErfTrue := False;
  end;

  iDokumenteBG := TWebImageControl.create(self);
    iDokumenteBG.Parent := self;
    iDokumenteBG.Left := 25;
    if HandySize then begin
      iDokumenteBG.Top := 92;
      iDokumenteBG.Width := 130;
      iDokumenteBG.Height := 25;
    end
    else begin
      iDokumenteBG.Top := 137;
      iDokumenteBG.Width := 180;
      iDokumenteBG.Height := 30;
    end;
  iDokumenteBG.Autosize := FALSE;
  lblDokumente := TWebLabel.Create(self);
  lblDokumente.Parent := self;
  LabelFormatieren(lblDokumente, FALSE, taCenter, 97, 142, 25, 25, 130, 180, 25, 30, clWhite, 'Helvetica', 10, 13, FALSE);
  //zu Adresse zurück Button
  lblDokumente.Caption := 'Dokumente anzeigen';
  lblDokumente.OnClick := @frmMain.DokumenteAnzeigen;

  if not(gefunden = 0) and not (gefunden = -1)then begin
    iDokumenteBG.Picture.LoadFromFile('.\labelbackground_grey.jpg');
    frmMain.buttonDokTrue := True;
  end
  else begin
    iDokumenteBG.Picture.LoadFromFile('.\labelbackground_bright_grey.jpg');
    frmMain.ButtonDoktrue := False;
  end;

  //Überschrift Projekt
  lblName := TWebLabel.Create(self);
  lblName.Parent := self;
  LabelFormatieren(lblName, TRUE, taLeftJustify, 15, 15, 75, 120, 0, 0, 0, 0, $00606060, 'Helvetica', 13, 18, FALSE);
  lblName.Caption := Name;
  //Projektinformationen Überschrift
  lblProjektInfo := TWebLabel.Create(self);
  lblProjektInfo.Parent := self;
  LabelFormatieren(lblProjektInfo, TRUE, taLeftJustify, 120, 170, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 11, 16, FALSE);
  lblProjektInfo.Caption := 'Projektinformationen';
  //Projektnummer Überschrift
  lblNummerInfo := TWebLabel.Create(self);
  lblNummerInfo.Parent := self;
  LabelFormatieren(lblNummerInfo, TRUE, taLeftJustify, 150, 200, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
  lblNummerInfo.Caption := 'Projektnummer:';
  //Projektnummer
  lblNummer := TWebLabel.Create(self);
  lblNummer.Parent := self;
  LabelFormatieren(lblNummer, TRUE, taLeftJustify, 150, 200, 150, 150, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
  lblNummer.Caption := Nummer;
  //Kürzel Überschrift
  lblKuerzelInfo := TWebLabel.Create(self);
  lblKuerzelInfo.Parent := self;
  lblKuerzelInfo.Caption := 'Kürzel:';
  LabelFormatieren(lblKuerzelInfo, TRUE, taLeftJustify, 170, 220, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
  //Kürzel
  lblKuerzel := TWebLabel.Create(self);
  lblKuerzel.Parent := self;
  LabelFormatieren(lblKuerzel, TRUE, taLeftJustify, 170, 220, 150, 150, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
  lblKuerzel.Caption := Kuerzel;
  //Datum Überschrift
  lblDatumInfo := TWebLabel.Create(self);
  lblDatumInfo.Parent := self;
  LabelFormatieren(lblDatumInfo, TRUE, taLeftJustify, 190, 240, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
  lblDatumInfo.Caption := 'Datum:';
  //Datum
  lblDatum := TWebLabel.Create(self);
  lblDatum.Parent := self;
  LabelFormatieren(lblDatum, TRUE, taLeftJustify, 190, 240, 150, 150, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
  lblDatum.Caption := Datum;
  //Projektstatus Überschrift
  lblStatusInfo := TWebLabel.Create(self);
  lblStatusInfo.Parent := self;
  LabelFormatieren(lblStatusInfo, TRUE, taLeftJustify, 210, 260, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
  lblStatusInfo.Caption := 'Projektstatus:';
  iStatusBG := TWebImageControl.create(self);
  iStatusBG.Parent := self;
  iStatusBG.Left := 144;

  widthPlus:= 0;
  if Length(Status) > 20 then widthPlus := 80
  else if Length(Status) > 16 then widthPlus := 23;  //Width für Projektstatus-Box für längeren Projektstatus verlängern

  if HandySize then begin
    iStatusBG.Top := 207;
    iStatusBG.Width := 120 + widthPlus;
    iStatusBG.Height := 21;
  end
  else begin
    iStatusBG.Top := 260;
    iStatusBG.Width := 140 + widthPlus;
    iStatusBG.Height := 23;
  end;
  iStatusBG.Autosize := FALSE;
  iStatusBG.Picture.LoadFromFile('.\labelbackground_blue.jpg');
  //Projektstatus
  lblStatus := TWebLabel.Create(self);
  lblStatus.Parent := self;
  LabelFormatieren(lblStatus, FALSE, taCenter, 210, 260, 150, 150, 0, 0, 0, 0, clWhite, 'Helvetica', 10, 13, FALSE);
  lblStatus.Caption := Status;

  pnlLinie:= TWebPanel.create(self);
  pnlLinie.Parent := self;
  pnlLinie.Left := 25;
  if HandySize then pnlLinie.Top:=235 else pnlLinie.Top:=290;

  pnlLinie.Height:=1;
  pnlLinie.Borderstyle:= bsSingle;
  pnlLinie.Width:= frmMain.edtSearch.width + frmMain.pnlsearch.width;

  //Projektleitung Überschrift
  lblProjektleitung := TWebLabel.Create(self);
  lblProjektleitung.Parent := self;
  LabelFormatieren(lblProjektleitung, TRUE, taLeftJustify, 250, 300, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 11, 16, FALSE);
  lblProjektleitung.Caption := 'Projektleitung';
  //Projektleiter Überschrift
  lblPLInfo := TWebLabel.Create(self);
  lblPLInfo.Parent := self;
  LabelFormatieren(lblPLInfo, TRUE, taLeftJustify, 280, 330, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
  lblPLInfo.Caption := 'Projektleiter:';
  //Projektleiter
  lblPL := TWebLabel.Create(self);
  lblPL.Parent := self;
  LabelFormatieren(lblPL, TRUE, taLeftJustify, 280, 330, 150, 150, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
  lblPL.Caption := PL;
  //Sachbearbeiter Überschrift
  lblSBInfo := TWebLabel.Create(self);
  lblSBInfo.Parent := self;
  LabelFormatieren(lblSBInfo, TRUE, taLeftJustify, 300, 350, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
  lblSBInfo.Caption := 'Sachbearbeiter:';
  //Sachbearbeiter
  lblSB := TWebLabel.Create(self);
  lblSB.Parent := self;
  LabelFormatieren(lblSB, TRUE, taLeftJustify, 300, 350, 150, 150, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
  lblSB.Caption := SB;
  i := 70;

  pnlLinie:= TWebPanel.create(self);
  pnlLinie.Parent := self;
  pnlLinie.Left := 25;
  if HandySize then pnlLinie.Top:=325 else pnlLinie.Top:=385;

  pnlLinie.Height:=1;
  pnlLinie.Borderstyle:= bsSingle;
  pnlLinie.Width:= frmMain.edtSearch.width + frmMain.pnlsearch.width;

  if (rechte = 'J') then begin
  lblFinanzielles := TWebLabel.Create(self);
  lblFinanzielles.Parent := self;
  LabelFormatieren(lblFinanzielles, TRUE, taLeftJustify, 340, 390, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 11, 16, FALSE);
  //Finanzielles Überschrift
  lblFinanzielles.Caption := 'Finanzielles';
  lblWertInfo := TWebLabel.Create(self);
  lblWertInfo.Parent := self;
  LabelFormatieren(lblWertInfo, TRUE, taLeftJustify, 370, 420, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
  //Auftragswert Überschrift
  lblWertInfo.Caption := 'Auftragswert:';
  lblWert := TWebLabel.Create(self);
  lblWert.Parent := self;
  LabelFormatieren(lblWert, TRUE, taLeftJustify, 370, 420, 135, 135, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);
  //Auftragswert
  lblWert.Caption := Wert;
  i := 0;

  pnlLinie:= TWebPanel.create(self);
  pnlLinie.Parent := self;
  pnlLinie.Left := 25;
  if HandySize then pnlLinie.Top:=390 else pnlLinie.Top:=445;

  pnlLinie.Height:=1;
  pnlLinie.Borderstyle:= bsSingle;
  pnlLinie.Width:= frmMain.edtSearch.width + frmMain.pnlsearch.width;
  end;

  lblProjektpositionen := TWebLabel.Create(self);
  lblProjektpositionen.Parent := self;
  LabelFormatieren(lblProjektpositionen, TRUE, taLeftJustify, 410 - i, 460 - i, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 11, 16, FALSE);
  //Projektpositionen Überschrift
  lblProjektpositionen.Caption := 'Projektpositionen';

  iPositionen := TWebImageControl.create(self);
  iPositionen.Parent := self;
  if HandySize then begin
    iPositionen.Top := 410 - i;
    iPositionen.Left := 155;
    iPositionen.Width := 125;
    iPositionen.Height := 25;
  end
  else begin
    iPositionen.Top := 460 - i;
    iPositionen.Left := 200;
    iPositionen.Width := 160;
    iPositionen.Height := 30;
  end;

  iPositionen.Autosize := FALSE;
  iPositionen.Picture.LoadFromFile('.\labelbackground_grey.jpg');
  iPositionen.OnClick := @frmMain.AdresseAnzeigen;
  lblPositionen := TWebLabel.Create(self);
  lblPositionen.Parent := self;
  LabelFormatieren(lblPositionen, FALSE, taCenter, 415 - i, 465 - i, 155, 200, 110, 160, 25, 30, clWhite, 'Helvetica', 10, 13, FALSE);
  //Positionen anzeigen Button
  lblPositionen.Caption := 'Positionen anzeigen';
  lblPositionen.OnClick := @frmMain.wsbPositionenClick;

  pkuerzel := Kuerzel;
  pk := PriKey;
  //Falls Erfassung möglich: pkuerzel = kürzel des Projektes zur weiterleitung zur Erfassung/ zum befüllen der ersten editBox
  pkuerzelErfass := Kuerzel + ' - ' + Nummer + ' - ' + Name;
  frmMain.edNKLohnProjekt.Text := pkuerzelErfass;
  frmMain.edNKMaschProjekt.Text := pkuerzelErfass;
  frmMain.edNKMatProjekt.Text := pkuerzelErfass;
end;

procedure TIBEWebPanel.initPrjPosUebertschrift(const SBParent: TScrollBox;const name :string);
var ibox, iAdresseBG, iProjektBG: TWebImageControl;
    lblAdresse, lblName, lblProjekt, lblPositionenInfo: TWebLabel;
begin
  parent := SBParent;
  Height := ItemHeight;

  BorderStyle := bsNone;
  top := 0;
  Left := 0;
  Height := 450;
  Width := frmMain.edtSearch.width + frmMain.pnlsearch.width;
  Anchors := [akTop,akLeft,akRight];
  Color := clwhite;

  ibox := TWebImageControl.create(self);
  ibox.Parent := self;
  ibox.Left := 24;
  ibox.Top := 15;
  if HandySize then begin
    ibox.Width := 40;
    ibox.Height := 30;
  end
  else begin
    ibox.Width := 80;
    ibox.Height := 61;
  end;
  ibox.Autosize := FALSE;
  ibox.Picture.LoadFromFile('.\box_image.jpg');

  iAdresseBG := TWebImageControl.create(self);
  iAdresseBG.Parent := self;
  iAdresseBG.Left := 25;
  if HandySize then begin
    iAdresseBG.Top := 60;
    iAdresseBG.Width := 110;
    iAdresseBG.Height := 25;
  end
  else begin
    iAdresseBG.Top := 100;
    iAdresseBG.Width := 160;
    iAdresseBG.Height := 30;
  end;
  iAdresseBG.Autosize := FALSE;
  iAdresseBG.Picture.LoadFromFile('.\labelbackground_grey.jpg');
  iAdresseBG.OnClick := @frmMain.AdresseAnzeigen;
  lblAdresse := TWebLabel.Create(self);
  lblAdresse.Parent := self;
  LabelFormatieren(lblAdresse, FALSE, taCenter, 65, 105, 25, 25, 110, 160, 25, 30, clWhite, 'Helvetica', 10, 13, FALSE);
  //Adresse anzeigen Button
  lblAdresse.Caption := 'Adresse anzeigen';
  lblAdresse.OnClick := @frmMain.AdresseAnzeigen;

  iProjektBG := TWebImageControl.create(self);
  iProjektBG.Parent := self;
  if HandySize then begin
    iProjektBG.Top := 60;
    iProjektBG.Left := 155;
    iProjektBG.Width := 110;
    iProjektBG.Height := 25;
  end
  else begin
    iProjektBG.Top := 100;
    iProjektBG.Left := 200;
    iProjektBG.Width := 160;
    iProjektBG.Height := 30;
  end;

  iProjektBG.Autosize := FALSE;
  iProjektBG.Picture.LoadFromFile('.\labelbackground_grey.jpg');
  iProjektBG.OnClick := @frmMain.pnlNavClick;
  lblProjekt := TWebLabel.Create(self);
  lblProjekt.Parent := self;
  LabelFormatieren(lblProjekt, FALSE, taCenter, 65, 105, 155, 200, 110, 160, 25, 30, clWhite, 'Helvetica', 10, 13, FALSE);
  //Projekt anzeigen Button
  lblProjekt.Caption := 'zurück zu Projekt';
  lblProjekt.OnClick := @frmMain.pnlNavClick;

  lblName := TWebLabel.Create(self);
  lblName.Parent := self;
  LabelFormatieren(lblName, TRUE, taLeftJustify, 20, 30, 75, 120, 0, 0, 0, 0, $00606060, 'Helvetica', 11, 16, FALSE);
  //Überschriff Projekt
  lblName.Caption := Name;

end;

procedure TIBEWebPanel.initPrjPosInhalt(const SBParent: TScrollBox;const posId, posNr, art, kurztext, vkPreis, menge, einheit, gesamt, langtext, rechte: string);
var lblPos, lblDetailPos: TWebLabel;
    index1, index2, index3: integer;
    vkpreis2, menge2, gesamt2: String;
    pnlLinie: TWebPanel;
    iDetailPosBG, icam: TWebImageControl;
begin
  parent := SBParent;
  Height := ItemHeight;
  BorderStyle := bsNone;

  if handysize then begin
     top := frmMain.letzteHeight-350;
     Left := 0;
  end
  else begin
     top := frmMain.letzteHeight-300;
     Left := 25;
  end;

  if handysize then
     Height := 100
  else
     Height := 150;
  Width := frmMain.edtSearch.width + frmMain.pnlsearch.width - 45;
  Anchors := [akTop,akLeft,akRight];
  caption := '';
  Color := clwhite;

  lblPos := TWebLabel.Create(self);
  lblPos.Parent:=self;

  index1 := Pos('.', vkPreis);
  index2 := Pos('.', menge);
  index3 := Pos('.', gesamt);

  vkpreis2 := StringReplace(vkPreis,'.',',',[rfReplaceAll]);
  menge2 := StringReplace(menge,'.',',',[rfReplaceAll]);
  gesamt2 := StringReplace(gesamt,'.',',',[rfReplaceAll]);

  pnlLinie:= TWebPanel.create(self);
  pnlLinie.Parent:= self;
  pnlLinie.Left:= 25;

  pnlLinie.Height:=1;
  pnlLinie.Borderstyle:= bsSingle;
  pnlLinie.Width:= frmMain.edtSearch.width + frmMain.pnlSearch.width - 45;
  if (rechte = 'J')then begin
     lblPos.Caption :=posNr
                    +'<br>'+kurztext
                    +'<br><b>Art: </b>'+art
                    +'<br><b>Menge: </b>'+copy(menge2,1,index2+2)+' '+einheit
                    +'<br><b>VK-Preis: </b>'+copy(vkpreis2,1,index1+2)+'€'+'<b> Gesamt: </b>'+copy(gesamt2,1,index3+2)+'€'
                    +'<br>'+langtext;
     if Handysize then pnlLinie.Top:=96 else pnlLinie.Top:= 124;
  end
  else if (rechte = 'N') then begin
     lblPos.Caption :='<br>'+posNr
                    +'<br>'+kurztext
                    +'<br><b>Art: </b>'+art
                    +'<br><b>Menge: </b>'+copy(menge2,1,index2+2)+' '+einheit
                    +'<br>'+langtext;
     if Handysize then pnlLinie.Top:=96 else pnlLinie.Top:= 124;
  end;
  LabelFormatieren(lblPos, TRUE, taLeftJustify, 0, 0, 25, 25, 0, 0, 0, 0, $00606060, 'Helvetica', 10, 13, FALSE);

  {icam := TWebImageControl.create(self);
  icam.Parent := self;
  icam.Left := frmMain.edtSearch.width + frmMain.pnlsearch.width - 90;
  icam.Top := lblPos.Top + 5;
  if HandySize then begin
    icam.Width := 25;
    icam.Height := 15;
  end
  else begin
    icam.Width := 45;
    icam.Height := 30;
  end;
  icam.Autosize := FALSE;
  icam.Picture.LoadFromFile('.\camera_pic.jpg');
  icam.onclick := @frmMain.ShowCamera; }

  if not(langtext = '')then begin    //wenn langtext vorhanden, Button anzeigen für showmessage mit langtext
    iDetailPosBG := TWebImageControl.create(self);
    iDetailPosBG.Parent := self;

    if HandySize then begin
      iDetailPosBG.Top := 102;
      iDetailPosBG.Width := 205;
      iDetailPosBG.Height := 25;
      iDetailPosBG.Left := 25;
    end
    else begin
      iDetailPosBG.Top := 130;
      iDetailPosBG.Width := 255;
      iDetailPosBG.Height := 30;
      iDetailPosBG.Left := 25;
    end;
    iDetailPosBG.Autosize := FALSE;
    iDetailPosBG.Picture.LoadFromFile('.\labelbackground_grey.jpg');
    lblDetailPos := TWebLabel.Create(self);
    lblDetailPos.Parent := self;
    LabelFormatieren(lblDetailPos, FALSE, taCenter, 105, 132, 27, 27, 160, 200, 25, 30, clWhite, 'Helvetica', 10, 13, FALSE);
    //Langtext anzeigen Button
    lblDetailPos.Caption := 'Vollständigen Langtext anzeigen';
    lblDetailPos.Name := 'P'+posId;
    lblDetailPos.OnClick := @frmMain.btnLangtextClick;

    if Handysize then pnlLinie.Top:=132 else pnlLinie.Top:= 165;

    if Handysize then Height := Height+35 else Height := Height+30;
  end;

end;

procedure TIBEWebPanel.initPrjPosCam(const SBParent: TScrollBox);
var
   icam: TWebImageControl;
begin
  parent := SBParent;
  Height := ItemHeight;
  BorderStyle := bsNone;

  if handysize then begin
     top := frmMain.letzteHeight-350;
     Left := 0;
  end
  else begin
     top := frmMain.letzteHeight-300;
     Left := 25;
  end;

  if handysize then
     Height := 300
  else
     Height := 300;
  Width := frmMain.edtSearch.width + frmMain.pnlsearch.width - 45;
  Anchors := [akTop,akLeft,akRight];
  caption := '';
  Color := clwhite;
end;

procedure TIBEData.ExecSQL(sql:String);
var sqlx:String;
    s,pk,fn,fnx,fv:String;
    i,ix:integer;
    dr:TIBEDataRow;
begin
  while frows.Count>0 do begin
    dr:=TIBEDataRow(frows.Objects[0]);
    dr.free;
    frows.Delete(0);
  end;
{ frow0.Clear;
  frow1.Clear;
  frow2.Clear;
  frow3.Clear;
  frow4.Clear;
  frow5.Clear;
  frow6.Clear;
  frow7.Clear;
  frow8.Clear;
  frow9.Clear;}
  if copy(uppercase(sql),13,6)='SELECT' then begin
   fieldnames.Clear;
   fnx:=copy(uppercase(sql),pos(' ID,',sql)+1,8000);
   fnx:=copy(fnx,1,pos(' FROM ',fnx));
   while (pos(',',fnx)>0) do begin
     fn:=trim(copy(fnx,1,pos(',',fnx)-1));
     fnx:=trim(copy(fnx,pos(',',fnx)+1,8000));
     fieldnames.Add(fn);
   end;
   fieldnames.Add(fnx);
  end;
  sqlx:=stringreplace(sql,'''','''''',[rfReplaceAll]);
  xhr.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlx, true);
  xhr.send;
end;

procedure TIBEData.AfterExec;
var sqlx:String;
    s,pk,fn,fnx,fv:String;
    i,ix:integer;
    dr:TIBEDataRow;
begin
  RawData:=xhr.responseText;
  RawDataSL.text:=RawData;
  if copy(rawdata,1,5)='IDENT' then begin
    frmMain.ProcessToken;
  end
  else if copy(rawdata,1,5)='ERROR' then begin
    frmMain.lblError.Caption:=copy(rawdata,6,300);
    showmessage(copy('after exec__'+rawdata,6,3000));
  end
  else begin
    for i:=0 to RawDataSL.Count -1 do begin
      s:=RawDataSL.Strings[i];
      ix:=0;
{     if fieldnames.Count >= 1 then frow0.add('');
      if fieldnames.Count >= 2 then frow1.add('');
      if fieldnames.Count >= 3 then frow2.add('');
      if fieldnames.Count >= 4 then frow3.add('');
      if fieldnames.Count >= 5 then frow4.add('');
      if fieldnames.Count >= 6 then frow5.add('');
      if fieldnames.Count >= 7 then frow6.add('');
      if fieldnames.Count >= 8 then frow7.add('');
      if fieldnames.Count >= 9 then frow8.add('');
      if fieldnames.Count >= 10 then frow9.add('');      }
      while pos('|',s)>0 do begin
        fv:=copy(s,1,pos('|',s)-1);
        //s:=copy(s,pos('|',s)+1,8000);
        s:=copy(s,pos('|',s)+1);
        if ix=0 then begin
          pk:=fv;
          row(pk).Values['ID']:=fv;
        end
        else begin
          fn:=fieldnames.Strings[ix];
          row(pk).Values[fn]:=fv;
        end;
{        case ix of
          0: frow0[i] := fv;
          1: frow1[i] := fv;
          2: frow2[i] := fv;
          3: frow3[i] := fv;
          4: frow4[i] := fv;
          5: frow5[i] := fv;
          6: frow6[i] := fv;
          7: frow7[i] := fv;
          8: frow8[i] := fv;
          9: frow9[i] := fv;
        end;   }
        ix:=ix+1;
      end;
      row(pk).Values[fieldnames.Strings[ix]]:=s;
{      case ix of
        0: frow0[i] := s;
        1: frow1[i] := s;
        2: frow2[i] := s;
        3: frow3[i] := s;
        4: frow4[i] := s;
        5: frow5[i] := s;
        6: frow6[i] := s;
        7: frow7[i] := s;
        8: frow8[i] := s;
        9: frow9[i] := s;
      end;  }
    end;
  end;
end;

{ TIBEDataRow }

constructor TIBEDataRow.create;
begin
  inherited;
  fdata:=TStringList.create;
end;

destructor TIBEDataRow.destroy;
begin
  fdata.free;
end;

{ TIBEData }

function TIBEData.count: Integer;
begin
  result:=frows.Count;
end;

function TIBEData.row(pk: String): TIBEDataRow;
var wdr:TIBEDataRow;
begin
  if frows.IndexOf(pk)<0 then begin
    wdr:=TIBEDataRow.create;
    frows.AddObject(pk,wdr);
  end;
  result:=TIBEDataRow(frows.Objects[frows.IndexOf(pk)]);
end;

function TIBEData.pk(i: Integer): String;
begin
  result:=frows.Strings[i];
end;

function TIBEData.Value(i:Integer; Feldname: String):String;
begin
  case fieldnames.IndexOf(FeldName) of
    0: Result := frow0[i];
    1: Result := frow1[i];
    2: Result := frow2[i];
    3: Result := frow3[i];
    4: Result := frow4[i];
    5: Result := frow5[i];
    6: Result := frow6[i];
    7: Result := frow7[i];
    8: Result := frow8[i];
    9: Result := frow9[i];
  end;

end;

constructor TIBEData.create;
begin
  inherited;
  frows:=TStringList.create;
  frow0:=TStringList.create;
  frow1:=TStringList.create;
  frow2:=TStringList.create;
  frow3:=TStringList.create;
  frow4:=TStringList.create;
  frow5:=TStringList.create;
  frow6:=TStringList.create;
  frow7:=TStringList.create;
  frow8:=TStringList.create;
  frow9:=TStringList.create;
  fieldnames:=TStringList.create;
  RawDataSL:=TStringlist.create;
end;

destructor TIBEData.destroy;
begin
  RawDataSL.free;
  fieldnames.free;
  frows.free;     
  frow0.free;
  frow1.free;
  frow2.free;
  frow3.free;
  frow4.free;
  frow5.free;
  frow6.free;
  frow7.free;
  frow8.free;
  frow9.free;
end;

function TIBEData.onLoad(Event: TEventListenerEvent): boolean;
begin
    if (xhr.status = 200) then
    begin
      result:=true;
      AfterExec;
      if area='MainMenu' then frmMain.ShowMainMenu else
      if area='MainMenuNext' then frmMain.ShowMainMenuNext else
      if area='DetailMenu' then frmMain.ShowDetailMenu else
      if area='DetailAPs' then frmMain.ShowDetailAPs else  
      if area='DetailAPsNext' then frmMain.ShowDetailAPsNext else
      if area='ShowDokumente' then frmMain.ShowDokumente else
      if area='Projekte' then frmMain.ShowProjekte else
      if area='ProjekteNext' then frmMain.ShowProjekteNext else
      if area='ShowProjektDetails' then frmMain.ShowProjektDetails else
      if area='ShowPositionen' then frmMain.ShowPositionen else
      if area='ShowPositionenNext' then frmMain.ShowPositionenNext else
      if area='Erfasssung-Personal' then frmMain.ShowErfassungPersonal else
      if area='Erfasssung-Lohnarten' then frmMain.ShowErfassungLohnarten else
      if area='Erfasssung-Maschinen' then frmMain.ShowErfassungMaschinen else   
      if area='Erfasssung-Material' then frmMain.ShowErfassungMaterial else
      if area='NKZeilen' then frmMain.ShowNKZeilen else
      if area='PrepareForBenutzer' then frmMain.AskForBenutzer else
      if area='AskForBenutzer' then frmMain.AskForBenutzer else
      if area='SetBenutzerInfo' then frmMain.SetBenutzerInfo else
      if area='AskForToken' then frmMain.ProcessToken else
      if area='ReleaseToken' then frmMain.ReleaseToken;
    end;
end;

function ReplaceSpecialChar(const Search :String):string;
begin
  if (ContainsText(Search, '&'))then begin
     result := stringreplace(Search, '&', '%26', [rfreplaceall]);
  end
  else if (ContainsText(Search, '#'))then begin
     result := stringreplace(Search, '#', '%23', [rfreplaceall]);
  end
  else if (ContainsText(Search, ''''))then begin
     result := stringreplace(Search, '''', '''''', [rfreplaceall]);
  end
  else if (ContainsText(Search, '+'))then begin
     result := stringreplace(Search, '+', '%2B', [rfreplaceall]);
  end
  else begin
     result := Search;
  end;
end;

end.

