{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Cookies;

interface

uses
  SysUtils, Classes;

type
  TCookie = class(TCollectionItem)
  private
    FPath: string;
    FName: string;
    FExpiry: TDateTime;
    FValue: string;
    FChanged: boolean;
    procedure SetExpiry(const Value: TDateTime);
    procedure SetName(const Value: string);
    procedure SetPath(const Value: string);
    procedure SetValue(const Value: string);
  protected
    property Changed: boolean read FChanged write FChanged;
  public
    function CookieAsString: string;
  published
    property Name: string read FName write SetName;
    property Value: string read FValue write SetValue;
    property Expiry: TDateTime read FExpiry write SetExpiry;
    property Path: string read FPath write SetPath;
  end;

  TCookies = class(TCollection)
  private
    function GetItem(Index: integer): TCookie;
    procedure SetItem(Index: integer; const Value: TCookie);
  public
    constructor Create;

    procedure GetCookies;
    procedure SetCookies;

    procedure Delete(ACookie: TCookie); overload;
    procedure Delete(const AName: string); overload;

    function Add(const AName, AValue: string; Expiry: TDateTime): TCookie; overload;
    function Add(const AName, AValue: string): TCookie; overload;
    function Add(const AName, AValue, APath: string): TCookie; overload;
    function Add(const AName, AValue, APath: string; Expiry: TDateTime): TCookie; overload;
    property Items[Index: integer]: TCookie read GetItem write SetItem;
    function Find(const AName: string): TCookie;
  end;


implementation

uses
  Web, JS;

{ TCookies }

function TCookies.Add(const AName, AValue: string; Expiry: TDateTime): TCookie;
begin
  Result := Add(AName, AValue, '/', Expiry);
end;

function TCookies.Add(const AName, AValue: string): TCookie;
begin
  Result := Add(AName, AValue, '/', 0);
end;

function TCookies.Add(const AName, AValue, APath: string): TCookie;
begin
  Result := Add(AName, AValue, APath, 0);
end;

function TCookies.Add(const AName, AValue, APath: string;
  Expiry: TDateTime): TCookie;
begin
  Result := nil;

  if AName = '' then
    Exit;

  Result := TCookie(inherited Add);
  Result.Name := AName;
  Result.Value := AValue;
  Result.Expiry := Expiry;
  Result.Path := APath;
  Result.Changed := true;
end;

constructor TCookies.Create;
begin
  inherited Create(TCookie);
end;

procedure TCookies.Delete(const AName: string);
var
  cookie: TCookie;
begin
  cookie := Find(AName);
  if Assigned(cookie) then
  begin
    Delete(cookie);
  end;
end;

procedure TCookies.Delete(ACookie: TCookie);
begin
  ACookie.Expiry := EncodeDate(1970, 1, 1) + EncodeTime(0,0,0,0);
end;

function TCookies.Find(const AName: string): TCookie;
var
  i: integer;
begin
  Result := nil;

  for i := 0 to Count - 1 do
  begin
    if (Items[i].Name = AName) then
    begin
      Result := Items[i];
      break;
    end;
  end;
end;

procedure TCookies.GetCookies;
var
  s: string;
  sl: TStringList;
  i: integer;
  cookie: TCookie;
begin
  asm
    s = document.cookie;
  end;

  sl := TStringList.Create;
  sl.Delimiter := ';';
  sl.StrictDelimiter := true;
  sl.DelimitedText := s;

  Clear;

  try
    for i := 0 to sl.Count - 1 do
    begin
      cookie := TCookie(inherited Add);
      cookie.Name := Trim(sl.Names[i]);
      cookie.Value := sl.Values[sl.Names[i]];
      cookie.Changed := false;
    end;
  finally
    sl.Free;
  end;
end;

function TCookies.GetItem(Index: integer): TCookie;
begin
  Result := TCookie(inherited Items[Index]);
end;

{$HINTS OFF}
procedure TCookies.SetCookies;
var
  s: string;
  i: integer;
begin
  s := '';
  for i := 0 to Count - 1 do
  begin
    if Items[i].Changed then
    begin
      Items[i].Changed := false;

      s := Items[i].CookieAsString;

      asm
        document.cookie = s;
      end;
    end;
  end;
end;
{$HINTS ON}

procedure TCookies.SetItem(Index: integer; const Value: TCookie);
begin
  inherited Items[index] := Value;
end;

{ TCookie }

function TCookie.CookieAsString: string;
var
  d: TJSDate;
  ye,mo,da,ho,mi,se,ms: word;
  s: string;
begin
  Result := Name + '=' + encodeURIComponent(Value) + ';';

  if Expiry <> 0 then
  begin
    DecodeDate(Expiry, ye, mo, da);
    DecodeTime(Expiry, ho, mi, se, ms);

    asm
      d = new Date(ye,mo-1,da,ho,mi,se,ms);
    end;

    if (ye = 1970) and (mo = 1) and (da = 1) then
      s := 'Thu, 01 Jan 1970 00:00:00 UTC'
    else
      s := d.toUTCString;

    Result := Result + 'expires=' + s + ';';
  end;

  if (Path <> '') then
    Result := Result + 'path=' + path + ';'
  else
    Result := Result + 'path=/;';
end;

{ TCookie }

procedure TCookie.SetExpiry(const Value: TDateTime);
begin
  if (FExpiry <> Value) then
  begin
    FExpiry := Value;
    FChanged := true;
  end;
end;

procedure TCookie.SetName(const Value: string);
begin
  if (FName <> Value) then
  begin
    FName := Value;
    FChanged := true;
  end;
end;

procedure TCookie.SetPath(const Value: string);
begin
  if (FPath <> Value) then
  begin
    FPath := Value;
    FChanged := true;
  end;
end;

procedure TCookie.SetValue(const Value: string);
begin
  if (FValue <> Value) then
  begin
    FValue := Value;
    FChanged := true;
  end;
end;


end.
