unit fMain;

{$mode delphi}

interface

uses
  SysUtils, DateUtils, Classes, WEBLib.Forms, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.StdCtrls, WEBLib.WebCtrls, WEBLib.Dialogs, WebLib.Storage,
  WEBLib.ExtCtrls, WEBLib.Buttons, WEBLib.WebTools, WEBLib.Menus,
  WEBLib.FlexControls, WEBLib.Grids, WEBLib.ComCtrls, WEBLib.Devices, web, WEBLib.Cookies;

type

  { TfrmMain }

  TfrmMain = class(TForm)
    cobNKLohnMitarbeiter: TComboBox;
    cobNKLohnLohnart: TComboBox;
    cobNKMaschMaschine: TComboBox;
    cobNKLohnMitarbeiterID: TComboBox;
    cobNKLohnLohnartID: TComboBox;
    cobNKMatMaterial: TComboBox;
    cobNKMaschMaschineID: TComboBox;
    cobNKMatMaterialID: TComboBox;
    cobProjekt: TComboBox;
    dtpNKLohnBis: TDateTimePicker;
    dtpNKLohnPauseVon: TDateTimePicker;
    dtpNKLohnPauseBis: TDateTimePicker;
    dtpNKMaschBis: TDateTimePicker;
    dtpNKLohnDatum: TDateTimePicker;
    dtpNKMaschDatum: TDateTimePicker;
    dtpNKLohnVon: TDateTimePicker;
    dtpNKMatDatum: TDateTimePicker;
    dtpNKMaschVon: TDateTimePicker;
    edNKLohnDauer: TEdit;
    edNKMaschDauer: TEdit;
    edNKMatDauer: TEdit;
    edNKMatFrei: TEdit;
    edNKMatProjekt: TEdit;
    edNKLohnProjekt: TEdit;
    edNKMaschProjekt: TEdit;
    edtSearch: TEdit;
    lblNKLohnBis: TLabel;
    lblNKLohnDatum: TLabel;
    lblNKLohnVon: TLabel;
    lblNKMaschBis: TLabel;
    lblNKMaschDatum: TLabel;
    lblNKLohnDauer: TLabel;
    lblNKMatDatum: TLabel;
    lblNKMaschDauer: TLabel;
    lblNKMatDauer: TLabel;
    lblNKMaschMaschine: TLabel;
    lblNKLohnMitarbeiter: TLabel;
    lblNKLohnLohnart: TLabel;
    lblNKMatFrei: TLabel;
    lblNKMatProjekt: TLabel;
    lblNKMatMaterial: TLabel;
    lblNKMaschVon: TLabel;
    lblNKLohnProjekt: TLabel;
    lblNKMaschProjekt: TLabel;
    pnlMenue: TPanel;
    pnlMenu: TPanel;
    pnlSearch: TPanel;
    img: TImageControl;
    pnlHeader: TPanel;
    lblError: TLabel;
    pnlSuche: TPanel;
    sbx: TScrollBox;
    sbxNKLohn: TScrollBox;
    sbxNKMaschine: TScrollBox;
    sbxNKMaterial: TScrollBox;
    wbtNKLohnSpeichern: TButton;
    wbtNKMaschSpeichern: TButton;
    wbtNKMatSpeichern: TButton;
    cobAdresse: TComboBox;
    sbNKMaterialBox: TScrollBox;
    sbNkLohnBox: TScrollBox;
    sbNkMaschineBox: TScrollBox;
    lblFilter: TLabel;
    lbBenutzer: TLabel;
    edNKLohnFahrzeit: TEdit;
    edNKLohnRuestzeit: TEdit;
    lblNKLohnFahrzeit: TLabel;
    lblNKLohnRuestzeit: TLabel;
    lblNKLohnPause1: TLabel;
    lblNKLohnPause2: TLabel;
    lblKarte: TLabel;
    WtDokumentNeuladen: TTimer;
    wsbLogout: TSpeedButton;
    wsbLohn: TSpeedButton;
    wsbMaschine: TSpeedButton;
    wsbMaterial: TSpeedButton;
    wsbAdresse: TSpeedButton;
    wsbProjekt: TSpeedButton;
    procedure cobNKLohnLohnartChange(Sender: TObject);
    procedure cobNKLohnMitarbeiterChange(Sender: TObject);
    procedure cobNKMaschMaschineChange(Sender: TObject);
    procedure cobNKMatMaterialChange(Sender: TObject);
    procedure dtpNKLohnBisExit(Sender: TObject);
    procedure dtpNKLohnVonExit(Sender: TObject);
    procedure dtpNKMaschBisExit(Sender: TObject);
    procedure dtpNKMaschVonExit(Sender: TObject);
    procedure edtSearchKeyDown(Sender: TObject; var AKey: Word; Shift: TShiftState);
    procedure FormCreate(Sender: TObject);
    procedure FormDestroy(Sender: TObject);
    procedure FormResize(Sender: TObject);
    procedure FormShow(Sender: TObject);
    procedure imgClick(Sender: TObject);
    procedure pnlMenuClick(Sender: TObject);
    procedure pnlSearchClick(Sender: TObject);
    procedure ShowSearch(hide:boolean);
    procedure wbtNKLohnSpeichernClick(Sender: TObject);
    procedure wbtNKMaschSpeichernClick(Sender: TObject);
    procedure wbtNKMatSpeichernClick(Sender: TObject);
    procedure dokumentNeuladen(Sender: TObject);
    procedure wsbAdresseClick(Sender: TObject);
    procedure wsbLogoutClick(Sender: TObject);
    procedure wsbLohnClick(Sender: TObject);
    procedure wsbMaschineClick(Sender: TObject);
    procedure wsbMaterialClick(Sender: TObject);
    procedure wsbProjektClick(Sender: TObject);
    procedure wsbPositionenClick(Sender: TObject);
    procedure dtpNKLohnPauseVonExit(Sender: TObject);
    procedure dtpNKLohnPauseBisExit(Sender: TObject);
  private
  protected
    procedure LoadDFMValues; override;
  public
    cs: TLocalStorage;
    token:String;
    ident:String;
    benutzer:String;
    DetailPk: String;
    DetailKuerzel: String;
    ProjektPk: String;
    LetzteHeight: Integer;
    Nachgeladen: Integer;
    Gefunden: Integer;
    Iphone: Boolean;
    Android: Boolean;
    pause: double;
    cobBenutzer: TComboBox;
    benutzerNr: string;
    dokumentPk: string;
    buttonDokTrue: Boolean;
    buttonErfTrue: Boolean;
    camPos: TCamera;

    procedure btnGetToken(Sender: TObject);
    procedure btnGetBenutzer(Sender: TObject);
    procedure btnNavClick(Sender: TObject);
    procedure btnKurztextClick(Sender: TObject);
    procedure btnLangtextClick(Sender: TObject);
    procedure AdresseAnzeigen(Sender: TObject);
    procedure ProjekteAnzeigen(Sender: TObject);
    procedure pnlNavClick(Sender: TObject);
    procedure pnlNavMouseEnter(Sender: TObject);
    procedure pnlNavMouseLeave(Sender: TObject);
    procedure ProcessToken;
    procedure ReleaseToken;
    procedure PrepareDetailMenu;
    procedure ShowDetailMenu;
    procedure PrepareDetailAPs;
    procedure ShowDetailAPs;
    procedure ShowDetailAPsNext;
    procedure PrepareMainMenu;
    procedure ShowMainMenu;
    procedure ShowMainMenuNext;
    procedure PrepareProjekte;
    procedure ShowProjekte;   
    procedure ShowProjekteNext;
    procedure PrepareProjektDetails; 
    procedure ShowProjektDetails;
    procedure PreparePositionen;
    procedure ShowPositionen;
    procedure ShowPositionenNext;
    procedure ShowFooterMenu(const Anfang: Integer);  
    procedure PrepareErfassung;
    procedure PrepareErfassungPersonal;
    procedure ShowErfassungPersonal;
    procedure PrepareErfassungLohnarten;
    procedure ShowErfassungLohnarten;
    procedure PrepareErfassungMaschinen;
    procedure ShowErfassungMaschinen;  
    procedure PrepareErfassungMaterial;
    procedure ShowErfassungMaterial;
    procedure PrepareNKZeilen;      
    procedure ShowNKZeilen;
    procedure ShowNextButton;
    procedure NextClick(Sender: TObject);
    procedure AskForToken;
    procedure ClearForm;
    procedure InitScreenDim;
    procedure AnzeigeSeite(const Seite: Integer);
    procedure AddresseOderProjekt(const Auswahl: Integer);
    procedure CountErfassung;
    procedure DeleteNK(Sender: TObject);
    procedure FelderZuruecksetzen;
    procedure PrepareForBenutzer;
    procedure AskForBenutzer;
    procedure PrepareBenutzerInfo;
    procedure SetBenutzerInfo;
    procedure ErfassungClick(Sender: TObject);
    procedure DokumenteAnzeigen(Sender: TObject);
    procedure PrepareDokumente;
    procedure ShowDokumente;
    procedure UpdateStatus(Sender: TObject);
    procedure ShowCamera(Sender: TObject);

    //property WebCamera.SnapShotAsImageData: TJSImageData;
  end;

var
  frmMain: TfrmMain;

implementation

uses utools;

var btn:TIBEWebbutton;
    btn2:TIBEWebbutton;
    sbtn:TIBEWebSpeedButton;
    lbl:TLabel;
    edt:TEdit;
    pnl,pnlx,pnl2:TIBEWebPanel;
    adrData:TIBEData;     
    ts:Integer;


{ TfrmMain }
procedure TfrmMain.FormCreate(Sender: TObject);
begin
  inherited;
  cs:=TLocalStorage.create;
  sbx.Color:=clwhite;      
  sbxNKLohn.Color:=clwhite;   
  sbxNKMaschine.Color:=clwhite;
  sbxNKMaterial.Color:=clwhite;
  adrData:=TIBEData.create;
  xhr:=TJSXMLHttpRequest.New;
  xhr.timeout:= 100000;
  xhr.addEventListener('load', @adrdata.OnLoad);
  ExecStringList := TStringList.Create;
  XHREXEC:=TJSXMLHttpRequest.New;                
  XHREXEC.addEventListener('load', @ExecGeladen);

  cobAdresse.AddItem('Kunde', nil);
  cobAdresse.AddItem('Lieferant', nil);
  cobAdresse.AddItem('Alle', nil);

  cobProjekt.AddItem('Alle', nil);
  cobProjekt.AddItem('Auftrag erteilt', nil);
  cobProjekt.AddItem('Auftrag in Ausführung', nil);

  Iphone := (Pos('IPHONE', uppercase(window.navigator.userAgent)) > 0) or (Pos('MACINTOSH', uppercase(window.navigator.userAgent)) > 0);
  Android := Pos('ANDROID', uppercase(window.navigator.userAgent)) > 0;
end;

procedure TfrmMain.InitScreenDim;
var I: Integer;
begin
  FScreenWidth:=window.innerWidth;
  FScreenHeight:=window.innerHeight-10;
  frmMain.Width:=ScreenWidth;
  frmMain.Font.Name := 'Helvetica';

  sbx.Width:=frmMain.ClientWidth;
  sbxNKLohn.Width:=frmMain.ClientWidth;
  sbxNKMaschine.Width:=frmMain.ClientWidth;
  sbxNKMaterial.Width:=frmMain.ClientWidth;
  sbx.Height:=frmMain.ClientHeight-sbx.top;
  sbxNKLohn.Height:=frmMain.ClientHeight-sbxNKLohn.top;
  sbxNKMaschine.Height:=frmMain.ClientHeight-sbxNKMaschine.top;
  sbxNKMaterial.Height:=frmMain.ClientHeight-sbxNKMaterial.top;

  pnlHeader.width:=sbx.width;
  edtSearch.width:=mxScreenWidth-edtSearch.left-60;
  pnlSearch.left:=edtSearch.left+edtSearch.width-1;
  pnlMenu.left:=pnlHeader.width-55;
  pnlSuche.width:=sbx.width;
  pnlMenue.width:=sbx.width;

  for i:=sbx.ComponentCount-1 downto 0 do begin
    if sbx.Components[i] is TIBEWebPanel then
      TIBEWebPanel(sbx.Components[i]).width := edtSearch.width + pnlsearch.width;
  end;

  wsbAdresse.ParentFont:= true;
  wsbAdresse.Font.Size := 16;
  wsbAdresse.width:= 90;
  wsbProjekt.Font.Size := 16;
  wsbProjekt.Left := 95;
  lblKarte.Font.Size := 16;
  lblKarte.Left := 190;
  lblKarte.Top:= 5;
  wsbLogout.Font.Size := 16;
  wsbLogout.width:= 78;

  sbNkLohnBox.Width := edtSearch.width + pnlsearch.width;
  sbNkLohnBox.Height := sbxNKLohn.Height - sbNkLohnBox.Top - 10;
  sbNkLohnBox.Font.Name := 'Helvetica';
  sbNkMaschineBox.Width := edtSearch.width + pnlsearch.width;
  sbNkMaschineBox.Height := sbxNKMaschine.Height - sbNkMaschineBox.Top - 10;
  sbNkMaschineBox.Font.Name := 'Helvetica';
  sbNKMaterialBox.Width:= edtSearch.width + pnlsearch.width;
  sbNKMaterialBox.Height := sbxNKMaterial.Height - sbNKMaterialBox.Top - 10;
  sbNKMaterialBox.Font.Name := 'Helvetica';

  lblFilter.Font.Name := 'Helvetica';
  lblFilter.Font.Style := lblFilter.Font.Style + [fsBold];
  wsbAdresse.Font.Name := 'Helvetica';
  wsbAdresse.Font.Style := wsbAdresse.Font.Style + [fsBold];
  wsbAdresse.Font.color := clBlue;
  wsbProjekt.Font.Name := 'Helvetica';
  wsbProjekt.Font.Style := wsbProjekt.Font.Style + [fsBold];
  wsbProjekt.Font.color := $0000A600;
  lblKarte.Font.Name := 'Helvetica';
  lblKarte.Font.Style := wsbProjekt.Font.Style + [fsBold];
  lblKarte.Font.color := $0000A600;
  wsbLogout.Font.Name := 'Helvetica';
  wsbLogout.Font.Style := wsbLogout.Font.Style + [fsBold];
  wsbLogout.Font.color := $0000A600;
  WsbLogout.Anchors := [akTop,akRight];
  WsbLogout.Left := pnlMenue.width - 105;
  lbBenutzer.Left := img.width + 20;
  lbBenutzer.Font.Color:= rgb(128,128,128);
  lbBenutzer.Font.Name := 'Helvetica';

  wsbLohn.Font.Name := 'Helvetica';
  wsbLohn.Font.Size := 13;
  wsbLohn.Font.Style := wsbLogout.Font.Style + [fsBold];
  wsbLohn.Font.color := $0000A600;
  wsbMaschine.Font.Name := 'Helvetica';
  wsbMaschine.Font.Size := 13;
  wsbMaschine.Font.Style := wsbLogout.Font.Style + [fsBold];
  wsbMaschine.Font.color := $0000A600;
  wsbMaschine.Width := 95;
  wsbMaterial.Font.Name := 'Helvetica';
  wsbMaterial.Font.Size := 13;
  wsbMaterial.Font.Style := wsbLogout.Font.Style + [fsBold];
  wsbMaterial.Font.color := $0000A600;
  wsbMaterial.Left := 197;

  Font.size:=5+itemheight div 4;
  if Font.size>22 then Font.size:=22;
  edtSearch.Font.size:=Font.size - 10;
  pnlSearch.Font.size:=16;
  pnlSearch.Font.Name := 'Helvetica';
  pnlsearch.Caption:='Suche';
  pnlMenu.Font.size:=18;
  pnlMenu.Caption := '&nbsp;&#8801;';
  lblFilter.Caption:= 'Filter:';

  //Menuleiste unterschiedlich je deviceart
  if Iphone then begin
   wsbAdresse.Width := wsbAdresse.Width - 5;
   wsbProjekt.Width := wsbProjekt.Width - 5;
   wsbProjekt.left := wsbProjekt.left - 5;
   lblKarte.left:= lblKarte.left+4;
   lblKarte.Top:= lblKarte.Top-1;
  end
  else if Android then begin
   lblKarte.left:= lblKarte.left+4;
   lblKarte.Top:= lblKarte.Top-3;
  end;

  //Verschiebungen für mobile allgemein
  if (Iphone) or (Android) then begin
    wsbProjekt.Left:= wsbProjekt.Left-4;
    lblKarte.Left:= lblKarte.Left - 15;

    wsbMaterial.Width:=wsbMaterial.Width + 8;
    wsbMaterial.Left:=wsbMaterial.Left + 7;

    dtpNKLohnPauseVon.Left:= 120;
    dtpNKLohnPauseVon.Width:= dtpNKLohnPauseVon.Width-25;
    lblNKLohnPause2.Left:=dtpNKLohnPauseVon.Left+dtpNKLohnPauseVon.Width+5;
    dtpNKLohnPauseBis.Left:=dtpNKLohnPauseVon.Left+dtpNKLohnPauseVon.Width+lblNKLohnPause2.Width+30;
    dtpNKLohnPauseBis.Width:=dtpNKLohnPauseVon.Width;
  end;

  if HandySize then begin
    wsbAdresse.Font.Size := 14;
    wsbProjekt.Font.Size := 14;
    lblKarte.Font.Size := 14;
    wsbLogout.Font.Size := 14;
    lblFilter.Font.Size := 14;
    lblNKLohnProjekt.Font.size := 12;
    edNKLohnProjekt.Font.size := 12;
    lblNKLohnMitarbeiter.Font.size := 12;
    cobNKLohnMitarbeiter.Font.size := 12;
    lblNKLohnLohnart.Font.size := 12;
    cobNKLohnLohnart.Font.size := 12;
    lblNKLohnDatum.Font.size := 12;
    dtpNKLohnDatum.Font.size := 12;
    lblNKLohnVon.Font.size := 12;
    dtpNKLohnVon.Font.size := 12;
    lblNKLohnBis.Font.size := 12;
    dtpNKLohnBis.Font.size := 12;
    lblNKLohnDauer.Font.size := 12;
    edNKLohnDauer.Font.size := 12;
    wbtNKLohnSpeichern.Font.Size := 12;
    sbNkLohnBox.Font.Size := 12;
    lblNKMaschProjekt.Font.size := 12;
    edNKMaschProjekt.Font.size := 12;
    lblNKMaschMaschine.Font.size := 12;
    cobNKMaschMaschine.Font.size := 12;
    lblNKMaschDatum.Font.size := 12;
    dtpNKMaschDatum.Font.size := 12;
    lblNKMaschVon.Font.size := 12;
    dtpNKMaschVon.Font.size := 12;
    lblNKMaschBis.Font.size := 12;
    dtpNKMaschBis.Font.size := 12;
    lblNKMaschDauer.Font.size := 12;
    edNKMaschDauer.Font.size := 12;
    wbtNKMaschSpeichern.Font.Size := 12;
    sbNkMaschineBox.Font.Size := 12;
    lblNKMatProjekt.Font.size := 12;
    edNKMatProjekt.Font.size := 12;
    lblNKMatMaterial.Font.size := 12;
    cobNKMatMaterial.Font.size := 12;
    lblNKMatFrei.Font.size := 12;
    edNKMatFrei.Font.size := 12;
    lblNKMatDatum.Font.size := 12;
    dtpNKMatDatum.Font.size := 12;
    lblNKMatDauer.Font.size := 12;
    edNKMatDauer.Font.size := 12;
    wbtNKMatSpeichern.Font.Size := 12;
    sbNKMaterialBox.Font.Size := 12;
    cobAdresse.Font.Size := 12;
    cobProjekt.Font.Size := 12;
    lbBenutzer.Font.Size := 12;
    edNKLohnFahrzeit.Font.Size := 12;
    edNKLohnRuestzeit.Font.Size := 12;
    lblNKLohnFahrzeit.Font.Size := 12;
    lblNKLohnRuestzeit.Font.Size := 12;
    dtpNKLohnPauseVon.Font.Size := 12;
    dtpNKLohnPauseBis.Font.Size := 12;
    lblNKLohnPause1.Font.Size := 12;
    lblNKLohnPause2.Font.Size := 12
  end
  else begin
    lblFilter.Font.Size := 16;
    lblNKLohnProjekt.Font.size := 11;
    edNKLohnProjekt.Font.size := 11;
    lblNKLohnMitarbeiter.Font.size := 11;
    cobNKLohnMitarbeiter.Font.size := 11;
    lblNKLohnLohnart.Font.size := 11;
    cobNKLohnLohnart.Font.size := 11;
    lblNKLohnDatum.Font.size := 11;
    dtpNKLohnDatum.Font.size := 11;
    lblNKLohnVon.Font.size := 11;
    dtpNKLohnVon.Font.size := 11;
    lblNKLohnBis.Font.size := 11;
    dtpNKLohnBis.Font.size := 11;
    lblNKLohnDauer.Font.size := 11;
    edNKLohnDauer.Font.size := 11;
    wbtNKLohnSpeichern.Font.Size := 11;
    sbNkLohnBox.Font.Size := 11;
    lblNKMaschProjekt.Font.size := 11;
    edNKMaschProjekt.Font.size := 11;
    lblNKMaschMaschine.Font.size := 11;
    cobNKMaschMaschine.Font.size := 11;
    lblNKMaschDatum.Font.size := 11;
    dtpNKMaschDatum.Font.size := 11;
    lblNKMaschVon.Font.size := 11;
    dtpNKMaschVon.Font.size := 11;
    lblNKMaschBis.Font.size := 11;
    dtpNKMaschBis.Font.size := 11;
    lblNKMaschDauer.Font.size := 11;
    edNKMaschDauer.Font.size := 11;     
    wbtNKMaschSpeichern.Font.Size := 11;
    sbNkMaschineBox.Font.Size := 11;
    lblNKMatProjekt.Font.size := 11;
    edNKMatProjekt.Font.size := 11;
    lblNKMatMaterial.Font.size := 11;
    cobNKMatMaterial.Font.size := 11;
    lblNKMatFrei.Font.size := 11;
    edNKMatFrei.Font.size := 11;
    lblNKMatDatum.Font.size := 11;
    dtpNKMatDatum.Font.size := 11;
    lblNKMatDauer.Font.size := 11;
    edNKMatDauer.Font.size := 11; 
    wbtNKMatSpeichern.Font.Size := 11; 
    sbNKMaterialBox.Font.Size := 11;
    cobAdresse.Font.Size := 14;
    cobProjekt.Font.Size := 14;
    lbBenutzer.Font.Size := 14;
    edNKLohnFahrzeit.Font.Size := 11;
    edNKLohnRuestzeit.Font.Size := 11;
    lblNKLohnFahrzeit.Font.Size := 11;
    lblNKLohnRuestzeit.Font.Size := 11;
    dtpNKLohnPauseVon.Font.Size := 11;
    dtpNKLohnPauseBis.Font.Size := 11;
    lblNKLohnPause1.Font.Size := 11;
    lblNKLohnPause2.Font.Size := 11;
  end;
end;       

procedure TfrmMain.AnzeigeSeite(const Seite: Integer);
var
    s: String;
begin
  case Seite of
    -1: begin
      sbx.Visible := FALSE;
      sbxNKLohn.Visible := FALSE;      
      sbxNKMaschine.Visible := FALSE;
      sbxNKMaterial.Visible := FALSE;
      sbNKMaterialBox.Visible := FALSE;
      wsbAdresse.Font.color := $0000A600;
      wsbProjekt.Font.color := $0000A600;
      //wsbKarte.Font.color := $0000A600;
      lblKarte.Font.color := $0000A600;
      wsbLohn.Visible := FALSE;      
      wsbMaschine.Visible := FALSE;
      wsbMaterial.Visible := FALSE;
      wsbLohn.Font.color := $0000A600;
      wsbMaschine.Font.color := $0000A600;
      wsbMaterial.Font.color := $0000A600;
      pnlSuche.Height := 72;
      camPos.Visible := FALSE;
    end;
    0: begin  //Haupt & Detailseiten
      sbx.Visible := TRUE;
      sbxNKLohn.Visible := FALSE;    
      sbxNKMaschine.Visible := FALSE;
      sbxNKMaterial.Visible := FALSE;
      sbNKMaterialBox.Visible := FALSE;
      camPos.Visible := FALSE;

      if (area = 'MainMenu') or (area = 'MainMenuNext') or (area = 'DetailMenu')
        or (area = 'DetailAPs') or (area = 'DetailAPsNext') or (area = 'ShowDokumente') or (area = '') then begin
        wsbAdresse.Font.color := clBlue;
      end
      else
        wsbAdresse.Font.color := $0000A600;   
      if (area = 'Projekte') or (area = 'ProjekteNext') or (area = 'ShowProjektDetails')
        or (area = 'ShowPositionen') or (area = 'ShowPositionenNext') then
        wsbProjekt.Font.color := clBlue
      else
        wsbProjekt.Font.color := $0000A600;

      if (area='ShowCamera') then camPos.Visible := FALSE;

        wsbLohn.Visible := FALSE;
        wsbMaschine.Visible := FALSE;
        wsbMaterial.Visible := FALSE;
        wsbLohn.Font.color := $0000A600;
        wsbMaschine.Font.color := $0000A600;
        wsbMaterial.Font.color := $0000A600;
        pnlSuche.Height := 72;
    end;
    1: begin  // Erfassung Lohn
      sbx.Visible := FALSE;
      sbxNKLohn.Visible := TRUE;    
      sbxNKMaschine.Visible := FALSE;
      sbxNKMaterial.Visible := FALSE;
      sbNKMaterialBox.Visible := FALSE;
      wsbAdresse.Font.color := $0000A600;
      wsbProjekt.Font.color := $0000A600;
      //wsbKarte.Font.color := $0000A600;
      lblKarte.Font.color := $0000A600;
      wsbLohn.Visible := TRUE;
      wsbMaschine.Visible := TRUE;
      wsbMaterial.Visible := TRUE;
      wsbLohn.Font.color := clBlue;
      wsbMaschine.Font.color := $0000A600;
      wsbMaterial.Font.color := $0000A600;
      cobNKLohnMitarbeiter.SetFocus;
      pnlSuche.Height := 40;
      camPos.Visible := FALSE;
    end;   
    2: begin   // Erfassung Maschine
      sbx.Visible := FALSE;
      sbxNKLohn.Visible := FALSE;
      sbxNKMaschine.Visible := TRUE;
      sbxNKMaterial.Visible := FALSE;
      sbNKMaterialBox.Visible := FALSE;
      wsbAdresse.Font.color := $0000A600;
      wsbProjekt.Font.color := $0000A600;
      //wsbKarte.Font.color := $0000A600;
      lblKarte.Font.color := $0000A600;
      wsbLohn.Visible := TRUE;
      wsbMaschine.Visible := TRUE;
      wsbMaterial.Visible := TRUE;
      wsbLohn.Font.color := $0000A600;
      wsbMaschine.Font.color := clBlue;
      wsbMaterial.Font.color := $0000A600;
      cobNKMaschMaschine.SetFocus;
      pnlSuche.Height := 40;
      camPos.Visible := FALSE;
    end;
    3: begin    // Erfassung Material
      sbx.Visible := FALSE;
      sbxNKLohn.Visible := FALSE;
      sbxNKMaschine.Visible := FALSE;
      sbxNKMaterial.Visible := TRUE;
      sbNKMaterialBox.Visible := TRUE;
      wsbAdresse.Font.color := $0000A600;
      wsbProjekt.Font.color := $0000A600;
      //wsbKarte.Font.color := $0000A600;
      lblKarte.Font.color := $0000A600;
      wsbLohn.Visible := TRUE;
      wsbMaschine.Visible := TRUE;
      wsbMaterial.Visible := TRUE;
      wsbLohn.Font.color := $0000A600;
      wsbMaschine.Font.color := $0000A600;
      wsbMaterial.Font.color := clBlue;
      cobNKMatMaterial.SetFocus;
      pnlSuche.Height := 40;
      camPos.Visible := FALSE;
    end;
    else begin
      sbx.Visible := TRUE;
      sbxNKLohn.Visible := FALSE;     
      sbxNKMaschine.Visible := FALSE;
      sbxNKMaterial.Visible := FALSE;
      sbNKMaterialBox.Visible := FALSE;
      wsbAdresse.Font.color := $0000A600;
      wsbProjekt.Font.color := $0000A600;
      //wsbKarte.Font.color := $0000A600;
      lblKarte.Font.color := $0000A600;
      wsbLohn.Visible := FALSE;
      wsbMaschine.Visible := FALSE;
      wsbMaterial.Visible := FALSE;
      wsbLohn.Font.color := $0000A600;
      wsbMaschine.Font.color := $0000A600;
      wsbMaterial.Font.color := $0000A600;
      pnlSuche.Height := 72;
      camPos.Visible := FALSE;
    end;
  end;
end;

procedure TfrmMain.edtSearchKeyDown(Sender: TObject; var AKey: Word;
  Shift: TShiftState);
begin
  if AKey=VK_RETURN then pnlSearchClick(pnlSearch);
end;

procedure TfrmMain.cobNKLohnMitarbeiterChange(Sender: TObject);
begin
  cobNKLohnMitarbeiterID.ItemIndex := cobNKLohnMitarbeiter.ItemIndex;
end;

procedure TfrmMain.cobNKMaschMaschineChange(Sender: TObject);
begin
  cobNKMaschMaschineID.ItemIndex := cobNKMaschMaschine.ItemIndex;
end;

procedure TfrmMain.cobNKMatMaterialChange(Sender: TObject);
begin
  cobNKMatMaterialID.ItemIndex := cobNKMatMaterial.ItemIndex;
end;

procedure TfrmMain.dtpNKLohnBisExit(Sender: TObject);
begin
  if (dtpNKLohnVon.Text <> '') and (dtpNKLohnBis.Text <> '') then begin
    edNKLohnDauer.text := FloatToStr(DateTimeDiff(dtpNKLohnVon.Time, dtpNKLohnBis.Time));
  end;
end;

procedure TfrmMain.dtpNKLohnVonExit(Sender: TObject);
begin
  if (dtpNKLohnVon.Text <> '') and (dtpNKLohnBis.Text <> '') then begin
    edNKLohnDauer.text := FloatToStr(DateTimeDiff(dtpNKLohnVon.Time, dtpNKLohnBis.Time));
  end;
end;

procedure TfrmMain.dtpNKLohnPauseVonExit(Sender: TObject);
begin
  if (dtpNKLohnPauseVon.Text <> '') and (dtpNKLohnPauseBis.Text <> '') then begin
    pause := DateTimeDiff(dtpNKLohnPauseVon.Time, dtpNKLohnPauseBis.Time);
  end;
end;

procedure TfrmMain.dtpNKLohnPauseBisExit(Sender: TObject);
begin
  if (dtpNKLohnPauseVon.Text <> '') and (dtpNKLohnPauseBis.Text <> '') then begin
    pause := DateTimeDiff(dtpNKLohnPauseVon.Time, dtpNKLohnPauseBis.Time);
  end;
end;

procedure TfrmMain.dtpNKMaschBisExit(Sender: TObject);
begin
  if (dtpNKMaschVon.Text <> '') and (dtpNKMaschBis.Text <> '') then begin
    edNKMaschDauer.text := FloatToStr(DateTimeDiff(dtpNKMaschVon.Time, dtpNKMaschBis.Time));
  end;
end;

procedure TfrmMain.dtpNKMaschVonExit(Sender: TObject);
begin
  if (dtpNKMaschVon.Text <> '') and (dtpNKMaschBis.Text <> '') then begin
    edNKMaschDauer.text := FloatToStr(DateTimeDiff(dtpNKMaschVon.Time, dtpNKMaschBis.Time));
  end;
end;

procedure TfrmMain.cobNKLohnLohnartChange(Sender: TObject);
begin
  cobNKLohnLohnartID.ItemIndex := cobNKLohnLohnart.ItemIndex;
end;

procedure TfrmMain.btnGetToken(Sender: TObject);
begin
  ShowSearch(false);
  AddresseOderProjekt(-1);
  lblError.Caption:='';
  if area='AskForToken' then
  begin
    token:=uppercase(edt.text);
    if (length(token)<6) then begin
      lblError.Caption:='Der Code muss genau <br>6 Zeichen lang sein';
    end;
    if (lblError.Caption='') then begin
      adrData.ExecSQL('TOKEN'+edt.text);
    end;
  end;
end;

procedure TfrmMain.btnGetBenutzer(Sender: TObject);
var sqlexec : string;
begin
  Execarea:= 'Count';
  sqlexec:= Token+ident+'Select count(REMOTEPASSWORT) FROM WEBSYNC_BENUTZER WHERE (NAME1 = '''+cobBenutzer.text+
                        ''') and (REMOTEPASSWORT ='''+edt.text+''')';
  sqlexec:=stringreplace(sqlexec,'''','''''',[rfReplaceAll]);
  xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, false);
  xhrexec.send;
  if (Gefunden > 0) then begin
    cs.SetValue('BENUTZER',cobBenutzer.text);
    pnlMenue.Visible := TRUE;
    img.Enabled := TRUE;
    PrepareBenutzerInfo;
  end
  else
  begin
    showmessage('Das eingegebende Passwort ist nicht korrekt!');
  end;
end;

procedure TfrmMain.PrepareBenutzerInfo;
begin
  area:='SetBenutzerInfo';
  adrData.ExecSQL(Token+ident+'select first 1 ID, NAME1, RECHTE FROM WEBSYNC_BENUTZER WHERE NAME1 = '''+cobBenutzer.text+'''');
end;

procedure TfrmMain.SetBenutzerInfo;
begin
  cs.SetValue('BENUTZERID',adrData.row(adrData.pk(0)).Values['ID']);
  cs.SetValue('RECHTE',adrData.row(adrData.pk(0)).Values['RECHTE']);

  Formshow(nil);
end;

procedure TfrmMain.btnNavClick(Sender: TObject);
begin
  PrepareMainMenu;
end;

procedure TfrmMain.btnKurztextClick(Sender: TObject);
var sqlexec: String;
begin       
  if TSpeedButton(Sender).Tag > 0 then begin
    Execarea := 'ShowKurztext';
    sqlexec := Token+ident+'select KURZTEXT_PLAIN from WEBSYNC_PROJPOS WHERE ID = '+IntToStr(TSpeedButton(Sender).Tag);
    xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, true);
    xhrexec.send;
  end
  else
    showMessage('TAG: ' + IntToStr(TSpeedButton(Sender).Tag));
end;

procedure TfrmMain.btnLangtextClick(Sender: TObject);         
var sqlexec, posID: String;
begin
  if (Sender is TLabel) then begin
    posID := TLabel(sender).name;
    delete(posID,1,1);
  end;

  if not(TLabel(Sender).Name = '') then begin
    Execarea := 'ShowLangtext';
    sqlexec := Token+ident+'select LANGTEXT_PLAIN from WEBSYNC_PROJPOS WHERE ID = '+posID;
    xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, true);
    xhrexec.send;
  end
  else
    showMessage('ID: ' + TLabel(Sender).Name);
end;

procedure TfrmMain.AdresseAnzeigen(Sender: TObject);
begin
  Area := 'MainMenu';
  PrepareDetailMenu;
end;

procedure TfrmMain.ProjekteAnzeigen(Sender: TObject);
begin
  edtSearch.Text := DetailKuerzel + ' (Adresse:'+DetailPk+')';
  PrepareProjekte;
end;

procedure TfrmMain.DokumenteAnzeigen(Sender: TObject);
begin
  if buttonDokTrue = False then begin
    showmessage('Es sind keine Dokumente vorhanden');
  end
  else if buttonDokTrue = True then begin
    if (area = 'DetailMenu') or (area = 'DetailAPs') or (area = 'DetailAPsNext') then begin
      dokumentPk := DetailPk;
    end
    else if (area = 'ShowProjektDetails') then begin
      dokumentPk := ProjektPk;
    end;
    PrepareDokumente;
  end;
end;

//Dokument wurde angefragt, Status wird auf 2 gesetzt und das Dokument soll nun
//in die Datenbank übertragen werden
procedure TfrmMain.UpdateStatus(Sender: TObject);
var datei_id, sqlexec :string;
begin
   if (Sender is TLabel) then begin
    datei_id := TLabel(sender).name;
    delete(datei_id,1,1);

    sqlexec:= Token+ident+'UPDATE WEBSYNC_DATEI '
        + 'SET STATUS = 2 '
        + 'WHERE ID = ' + datei_id;
    //ShowMessage(sqlexec);
    sqlexec:=stringreplace(sqlexec,'''','''''',[rfReplaceAll]);
    xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, false);
    xhrexec.send;

    PrepareDokumente; //Seite neuladen
  end
end;

procedure TfrmMain.pnlNavClick(Sender: TObject);
var AktPK, AktKuerzel: String;
begin
  if (Sender is TIBEWebPanel) then begin
    AktPK := TIBEWebPanel(sender).pk;   
    AktKuerzel := TIBEWebPanel(sender).pkuerzel;
  end
  else if (TControl(sender).Parent is TIBEWebPanel) then begin
    AktPK := TIBEWebPanel(TControl(sender).Parent).pk;
    AktKuerzel := TIBEWebPanel(TControl(sender).Parent).pkuerzel;
  end
  else if (TControl(sender).Parent.Parent is TIBEWebPanel) then begin
    AktPK := TIBEWebPanel(TControl(sender).Parent.Parent).pk;        
    AktKuerzel := TIBEWebPanel(TControl(sender).Parent.Parent).pkuerzel;
  end;
  if ((Area = 'MainMenu') or (area = 'MainMenuNext')) then begin
    DetailPk := AktPK;
    DetailKuerzel := AktKuerzel;
    PrepareDetailMenu;
  end
  else if ((Area = 'Projekte') or (area = 'ProjekteNext')) then begin
    ProjektPk := AktPK;
    PrepareProjektDetails;
  end
  else if ((Area = 'ShowPositionen') or (Area = 'ShowPositionenNext'))then begin
    PrepareProjektDetails;
  end;
end;

procedure TfrmMain.FormDestroy(Sender: TObject);
begin
  freeandnil(adrData);
  ExecStringList.Free;
  freeandnil(cs);
  inherited;
end;

procedure TfrmMain.FormResize(Sender: TObject);
begin
  if not (Android or IPhone) then
    InitScreenDim;
end;

procedure TfrmMain.FormShow(Sender: TObject);
var v: string;
begin
  InitScreenDim;
  token:=cs.GetValue('TOKEN');
  ident:=cs.GetValue('IDENT');
  AnzeigeSeite(0);
  WtDokumentNeuladen.enabled := false;

  benutzer:=cs.GetValue('BENUTZER');
  lbBenutzer.Caption := benutzer;

  if((cs.GetValue('RECHTE') = 'J') and (cobProjekt.Items.Count = 3))then begin
    //Benutzer hat Rechte für alle Projekte und dieses if soll nur ausgeführt werden wenn es noch nicht ausgeführt wurde
    cobProjekt.AddItem('Angebot erstellt', nil);
    cobProjekt.AddItem('Angebot abgegeben', nil);
    cobProjekt.AddItem('Auftrag in Gewährleistung', nil);
    cobProjekt.AddItem('Auftrag erledigt', nil);
    cobProjekt.AddItem('Auftrag nicht erteilt (Ablage)', nil);
    cobProjekt.AddItem('Direktfakturierung', nil);
    cobProjekt.AddItem('Ausschreibung', nil);
    cobProjekt.AddItem('Pflegeauftrag', nil);
  end;

  lblKarte.Caption:= '<a href="https:/'+'/greenwarecloud.de/browser/Googlemaps/dataGooglemaps.php?db='+cs.GetValue('DB')+'" target="_blank">Karte</a>';


  if ident='' then begin
    ShowSearch(false);
    AddresseOderProjekt(-1);
    AskForToken;
  end
  else if cs.GetValue('BENUTZERID') = '' then begin
    PrepareForBenutzer;
  end
  else begin
    area:='MainMenu';
    ShowSearch(true);
    AddresseOderProjekt(1);
    PrepareMainMenu;
  end;
  FelderZuruecksetzen;

  if Iphone then begin
     wsbLogout.left := wsbLogout.left+10;
  end;
  {if IPhone then                   
    lblError.Caption := 'IPHONETest!!!'
  else if Android then             
    lblError.Caption := 'ANDROIDTest!!!';
  if HandySize then
     lblError.Caption := lblError.Caption + ' - Handysize!'; }
end;

procedure TfrmMain.imgClick(Sender: TObject);
begin
  WtDokumentNeuladen.enabled := false;
  if not(cs.GetValue('ADRESSE')='')then begin
     edtSearch.Text := cs.GetValue('ADRESSE');
     PrepareMainMenu;
  end
  else begin
     edtSearch.Text := '';
     PrepareMainMenu;
  end;
end;

procedure TfrmMain.pnlMenuClick(Sender: TObject);
begin
  if pnlMenue.Visible then begin
     pnlMenue.Visible := FALSE;
     pnlSuche.Top := 45;
     sbx.Top := 110;
     sbxNKLohn.Top := 110;      
     sbxNKMaschine.Top := 110;
     sbxNKMaterial.Top := 110;
     sbx.Height := frmMain.ClientHeight-sbx.top;
     sbxNKLohn.Height := frmMain.ClientHeight-sbxNKLohn.top;
     sbxNKMaschine.Height := frmMain.ClientHeight-sbxNKMaschine.top;
     sbxNKMaterial.Height := frmMain.ClientHeight-sbxNKMaterial.top;
  end
  else begin                    
     pnlMenue.Visible := TRUE;
     pnlSuche.Top := 80;
     sbx.Top := 145;                              
     sbxNKLohn.Top := 145;
     sbxNKMaschine.Top := 145;
     sbxNKMaterial.Top := 145;
     sbx.Height := frmMain.ClientHeight-sbx.top;
     sbxNKLohn.Height := frmMain.ClientHeight-sbxNKLohn.top;    
     sbxNKMaschine.Height := frmMain.ClientHeight-sbxNKMaschine.top;
     sbxNKMaterial.Height := frmMain.ClientHeight-sbxNKMaterial.top;
  end;
end;

procedure TfrmMain.pnlNavMouseEnter(Sender: TObject);
begin
  TPanel(Sender).Color := $00F5F5F5;
end;

procedure TfrmMain.pnlNavMouseLeave(Sender: TObject);
begin                      
  TPanel(Sender).Color := clWhite;
end;         

procedure TfrmMain.pnlSearchClick(Sender: TObject);
begin
  if sbx.Visible then begin
    if (area = 'MainMenu') or (area = 'MainMenuNext') or (area = 'DetailMenu') or (area = 'DetailAPs') or (area = 'DetailAPsNext') then
      PrepareMainMenu
    else if (area = 'Projekte') or (area = 'ProjekteNext') or (area = 'ShowProjektDetails') or (area = 'ShowPositionen') or (area = 'ShowPositionenNext') then begin
      PrepareProjekte;
    end
    else
      PrepareMainMenu;
  end
  else if sbxNKLohn.Visible or sbxNKMaschine.Visible or sbxNKMaterial.Visible then
    PrepareProjektDetails
  else
    PrepareMainMenu;
end;

procedure TfrmMain.ShowSearch(hide: boolean);
begin
  pnlSearch.Visible:=hide;
  edtSearch.Visible:=hide;

  if hide then begin
    pnlSearch.Caption:='Suche';
    wsbLohn.Visible := FALSE;
    wsbMaterial.Visible := FALSE;
    wsbMaschine.Visible := FALSE;
  end
  else begin
    pnlSearch.Visible := FALSE;
  end;

end;

procedure TfrmMain.wbtNKLohnSpeichernClick(Sender: TObject);
var sqlexec, fahrzeit, ruestzeit, benutzerid : String;
    DauerTest, dauerMinusPause: double;
begin

  if not (cobNKLohnMitarbeiterID.ItemIndex > 0) then begin
    Showmessage('Bitte wählen Sie einen Mitarbeiter aus!');
    exit;
  end;   
  if not (cobNKLohnLohnartID.ItemIndex > 0) then begin
    Showmessage('Bitte wählen Sie eine Lohnart aus!');
    exit;
  end;
  if not (dtpNKLohnDatum.Date > 0) then begin
    Showmessage('Bitte wählen Sie ein Datum aus!');
    exit;
  end;   
  if (edNKLohnDauer.Text = '') or not TryStrToFloat(edNKLohnDauer.Text, DauerTest) or (StrToInt(edNKLohnDauer.Text) < 0)then begin
    Showmessage('Bitte geben Sie eine gültige Dauer ein!');
    exit;
  end;
  if (pos('--:',dtpNKLohnBis.Text) > 0) or (pos(':--:',dtpNKLohnBis.Text) > 0) or(dtpNKLohnBis.Text = '') then begin
    Showmessage('Es fehlen Zeitangaben. Bitte vervollständigen sie die Angaben!');
    exit;
  end;

  if (pos('--:',dtpNKLohnVon.Text) > 0) or (pos(':--:',dtpNKLohnVon.Text) > 0) or (dtpNKLohnVon.Text = '') then begin
    Showmessage('Es fehlen Zeitangaben. Bitte vervollständigen sie die Angaben!');
    exit;
  end;

  //optionale Felder
  if (edNKLohnFahrzeit.Text = '') then fahrzeit := '0' else fahrzeit := edNKLohnFahrzeit.Text;
  if (edNKLohnRuestzeit.Text = '') then ruestzeit := '0' else ruestzeit := edNKLohnRuestzeit.Text;

  if (pos(',',fahrzeit) > 0) or (pos(',',ruestzeit) > 0) then begin
    showmessage('Bitte geben sie die Zeit in Minuten an');
    exit;
  end;
  if (pos('.',fahrzeit) > 0) or (pos('.',ruestzeit) > 0) then begin
    showmessage('Bitte geben sie die Zeit in Minuten an');
    exit;
  end;

  Execarea := 'Count';  //Von-Bis Arbeitszeit des Mitarbeiter wird verglichen mit Datenbank Einträgen
  sqlexec := Token+ident
  +'SELECT count(id) FROM WEBSYNC_NACHKALKULATION'
  +'WHERE GELOESCHT = ''N'' AND BETRIEBSMITTEL_ID = '+cobNKLohnMitarbeiterID.Text+''
  +' AND ((ARBEITSBEGINN BETWEEN '''+dtpNKLohnVon.Text
  +''' AND '''+dtpNKLohnBis.Text+''') '
  +'OR (ARBEITSENDE BETWEEN '''+dtpNKLohnVon.Text
  +''' AND '''+dtpNKLohnBis.Text+''')) AND DATUM = '''+dtpNKLohnDatum.text+'''';

  sqlexec:=stringreplace(sqlexec,'''','''''',[rfReplaceAll]);
  xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, false);
  xhrexec.send;
  if(Gefunden > 0) then begin
    Showmessage('Für diesen Zeitraum und Mitarbeiter gibt es bereits einen Datensatz!');
    //bsp: Mitarbeiter hat schon einen Datensatz für 8uhr bis 15uhr
    exit;
  end;

  if (pause > 0)then begin
    dauerMinusPause := StrtoFloat(edNKLohnDauer.Text) - pause;
  end;

  benutzerid := cs.GetValue('BENUTZERID');

  sqlexec := Token+ident+'insert into WEBSYNC_NACHKALKULATION'
             + ' (PROJEKT_ID, DATUM, KOSTENART_ID, BETRIEBSMITTEL_ID, MENGE, EINHEIT_ID, ARBEITSBEGINN, ARBEITSPAUSE, ARBEITSENDE, LOHNART_ID, ERFASSTAM, ERFASSTDURCH_ID, RUESTZEIT, FAHRZEIT)'
             + ' values ('+ProjektPk+', ''' + dtpNKLohnDatum.text + ''', 1, ' + cobNKLohnMitarbeiterID.Text
             + ', ' + StringReplace(floattostr(dauerMinusPause), ',', '.', [rfReplaceAll]) + ', 25, ''' + dtpNKLohnVon.Text
             + ''', ''' + dtpNKLohnPauseVon.Text+'-'+dtpNKLohnPauseBis.Text +''', ''' + dtpNKLohnBis.Text
             + ''', ' + cobNKLohnLohnartID.Text + ', ''' + DateTimeToStr(now) + ''', '+benutzerid+', '
             + ruestzeit + ', ' + fahrzeit + ')';
  //ShowMessage(sqlexec);
  sqlexec:=stringreplace(sqlexec,'''','''''',[rfReplaceAll]);
  xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, false);
  xhrexec.send;
  FelderZuruecksetzen;
  PrepareNKZeilen;
end;

procedure TfrmMain.wbtNKMaschSpeichernClick(Sender: TObject);
var sqlexec, benutzerid : String;
    DauerTest: double;
begin
  if not (cobNKMaschMaschineID.ItemIndex > 0) then begin
    Showmessage('Bitte wählen Sie eine Maschine aus!');
    exit;
  end;
  if not (dtpNKMaschDatum.Date > 0) then begin
    Showmessage('Bitte wählen Sie ein Datum aus!');
    exit;
  end;
  if (edNKMaschDauer.Text = '') or not TryStrToFloat(edNKMaschDauer.Text, DauerTest) or (StrToInt(edNKMaschDauer.Text) < 0) then begin
    Showmessage('Bitte geben Sie eine gültige Dauer ein!');
    exit;
  end;

  if (pos(dtpNKMaschVon.Text,'--:') > 0) or (pos(dtpNKMaschVon.Text,':--:') > 0) or (dtpNKMaschVon.Text = '') then begin
    Showmessage('Es fehlen Zeitangaben. Bitte vervollständigen sie die Angaben!');
    exit;
  end;

  if (pos(dtpNKMaschBis.Text,'--:') > 0) or (pos(dtpNKMaschBis.Text,':--:') > 0) or (dtpNKMaschBis.Text = '') then begin
    Showmessage('Es fehlen Zeitangaben. Bitte vervollständigen sie die Angaben!');
    exit;
  end;
  benutzerid := cs.GetValue('BENUTZERID');

  sqlexec := Token+ident+'insert into WEBSYNC_NACHKALKULATION'
             + ' (PROJEKT_ID, DATUM, KOSTENART_ID, BETRIEBSMITTEL_ID, MENGE, EINHEIT_ID, ARBEITSBEGINN, ARBEITSENDE, ERFASSTAM, ERFASSTDURCH_ID)'
             + ' values ('+ProjektPk+', ''' + dtpNKMaschDatum.text + ''', 3, ' + cobNKMaschMaschineID.Text
             + ', ' + StringReplace(edNKMaschDauer.Text, ',', '.', [rfReplaceAll]) + ', 25, ''' + dtpNKMaschVon.Text
             + ''', ''' + dtpNKMaschBis.Text + ''', ''' + DateTimeToStr(now) + ''', '+benutzerid+')';
  //ShowMessage(sqlexec);
  sqlexec:=stringreplace(sqlexec,'''','''''',[rfReplaceAll]);
  xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, false);
  xhrexec.send;
  FelderZuruecksetzen;
  PrepareNKZeilen;
end;

procedure TfrmMain.wbtNKMatSpeichernClick(Sender: TObject);
var sqlexec, benutzerid : String;
    DauerTest: double;
begin
  if not (cobNKMatMaterial.Text = '---') and not(edNKMatFrei.Text = '') then begin
    Showmessage('Bitte wählen Sie nur ein Material aus oder geben Sie nur eine freie Textierung ein!');
    exit;
  end
  else if (cobNKMatMaterial.Text = '---') and (edNKMatFrei.Text = '') then begin
    Showmessage('Bitte wählen Sie ein Material aus oder geben Sie eine freie Textierung ein!');
    exit;
  end;

  if not (dtpNKMatDatum.Date > 0) then begin
    Showmessage('Bitte wählen Sie ein Datum aus!');
    exit;
  end;
  if (edNKMatDauer.Text = '') or not TryStrToFloat(edNKMatDauer.Text, DauerTest) or (StrToInt(edNKMatDauer.Text) < 0) then begin
    Showmessage('Bitte geben Sie eine gültige Dauer ein!');
    exit;
  end;
  benutzerid := cs.GetValue('BENUTZERID');

  if not(cobNKMatMaterial.Text = '---') then begin
    sqlexec := Token+ident+'insert into WEBSYNC_NACHKALKULATION'
               + ' (PROJEKT_ID, DATUM, KOSTENART_ID, BETRIEBSMITTEL_ID, MENGE, ERFASSTAM, ERFASSTDURCH_ID)'
               + ' values ('+ProjektPk+', ''' + dtpNKMatDatum.text + ''', 2, ' + cobNKMatMaterialID.Text
               + ', ' + StringReplace(edNKMatDauer.Text, ',', '.', [rfReplaceAll]) + ', ''' + DateTimeToStr(now) + ''','+benutzerid+')';
  end
  else begin
    sqlexec := Token+ident+'insert into WEBSYNC_NACHKALKULATION'
               + ' (PROJEKT_ID, DATUM, KOSTENART_ID, BETRIEBSMITTEL_ID, BESCHREIBUNG, MENGE, ERFASSTAM, ERFASSTDURCH_ID)'
               + ' values ('+ProjektPk+', ''' + dtpNKMatDatum.text + ''', 2, 0,''' + edNKMatFrei.Text
               + ''', ' + StringReplace(edNKMatDauer.Text, ',', '.', [rfReplaceAll]) + ', ''' + DateTimeToStr(now) + ''','+benutzerid+')';
  end;
  //ShowMessage(sqlexec);
  sqlexec:=stringreplace(sqlexec,'''','''''',[rfReplaceAll]);
  xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, false);
  xhrexec.send;
  FelderZuruecksetzen;
  PrepareNKZeilen;
end;

procedure TfrmMain.dokumentNeuladen(Sender: TObject);
begin
  prepareDokumente;
  //showmessage('test');
end;

procedure TfrmMain.wsbAdresseClick(Sender: TObject);
begin
  if not(cs.GetValue('ADRESSE')='') then begin
     edtSearch.Text := cs.GetValue('ADRESSE');
  end
  else begin
     edtSearch.Text := '';
  end;
     PrepareMainMenu;
end;

procedure TfrmMain.ErfassungClick(Sender: TObject);
begin
  if buttonErfTrue = False then begin
     showmessage('Nur Projekte mit dem Status "erteilt" oder "in Ausführung" können erfasst werden');
  end
  else if buttonErfTrue = True then begin
     PrepareErfassung;
  end;
end;

procedure TfrmMain.wsbPositionenClick(Sender: TObject);
begin
  PreparePositionen;
end;

procedure TfrmMain.wsbLogoutClick(Sender: TObject);
var cookies: TCookies;
begin
  AnzeigeSeite(0);
  WtDokumentNeuladen.enabled := false;
  area := 'ReleaseToken';
  adrData.ExecSQL('RELEASE_TOKEN'+token+ident);
  cs.RemoveKey('TOKEN');
  cs.RemoveKey('IDENT');
  cs.RemoveKey('ADRESSE');
  cs.RemoveKey('BENUTZER');
  cs.RemoveKey('BENUTZERID');
  cs.RemoveKey('RECHTE');
  cs.RemoveKey('DOKUTITEL');
  cs.RemoveKey('DB');

  cookies := TCookies.Create;
  try
    cookies.GetCookies;
    cookies.Delete('db');
  finally
    cookies.Free;
  end;

  lbBenutzer.caption := '';
  token := '';
  ident := '';
end;

procedure TfrmMain.wsbLohnClick(Sender: TObject);
begin
  PrepareErfassungPersonal;
end;

procedure TfrmMain.wsbMaschineClick(Sender: TObject);
begin
  PrepareErfassungMaschinen;
end;

procedure TfrmMain.wsbMaterialClick(Sender: TObject);
begin
  PrepareErfassungMaterial;
end;

procedure TfrmMain.wsbProjektClick(Sender: TObject);
begin
  if (area = 'MainMenu') then
    cs.SetValue('ADRESSE',edtSearch.Text)
  else
    edtSearch.Text := '';
  PrepareProjekte;
end;

procedure TfrmMain.PrepareDetailMenu;
var sqlexec: String;
begin
  ClearForm;
  WtDokumentNeuladen.enabled := false;
  area:='DetailMenu';
  ShowSearch(true);
  Gefunden := -1;
  Execarea := 'AnzahlDatensaetze';
  sqlexec := Token+ident+'select count(ID) from WEBSYNC_DATEI where adresse_id = ' + DetailPk + '' ;
  sqlexec:=stringreplace(sqlexec,'''','''''',[rfReplaceAll]);
  xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, false);
  xhrexec.send;
  AddresseOderProjekt(1);
  adrData.ExecSQL(Token+ident+'select ID,NUMMER,KUERZEL,FUNKTION,NAME1,NAME2,NAME3,NAME4,NAME5,STRASSE,ORT_PLZ,ORT_NAME,TELEFON,MOBIL,EMAIL from WEBSYNC_ADRESSE where id = ' + DetailPk + ' order by name1');
end;          

procedure TfrmMain.ShowDetailMenu;  // Adress-Detail-Ansicht
begin
  ClearForm;
  area:='DetailMenu';
  ShowSearch(false);
  AddresseOderProjekt(-1);
  if not(gefunden = 0) then cs.SetValue('DOKUTITEL',adrData.row(adrData.pk(0)).Values['Name1'] + ' ' + adrData.row(adrData.pk(0)).Values['Name2']);
  pnl:=TIBEWebPanel.create(sbx);
  pnl.initAdrDetails(sbx, adrData.row(adrData.pk(0)).Values['Name1'] + ' ' + adrData.row(adrData.pk(0)).Values['Name2'],
                     adrData.row(adrData.pk(0)).Values['Strasse'],
                     adrData.row(adrData.pk(0)).Values['ORT_PLZ'] + ' ' + adrData.row(adrData.pk(0)).Values['ORT_NAME'],
                     adrData.row(adrData.pk(0)).Values['TELEFON'],
                     adrData.row(adrData.pk(0)).Values['MOBIL'],
                     adrData.row(adrData.pk(0)).Values['EMAIL'],
                     adrData.pk(0),
                     gefunden);

  LetzteHeight := pnl.Top + pnl.Height;

  AnzeigeSeite(0);
  PrepareDetailAPs;
end;

procedure TfrmMain.PrepareDokumente;
var sqlexec: String;
begin
  area:='ShowDokumente';
  ClearForm;
  Nachgeladen := 0;
  Gefunden := -1;
  Execarea := 'AnzahlDatensaetze';
  sqlexec := Token+ident+'select count(ID) from WEBSYNC_DATEI where ADRESSE_ID= '+dokumentPk+' or PROJEKT_ID= '+dokumentPk+'';
  sqlexec:=stringreplace(sqlexec,'''','''''',[rfReplaceAll]);
  xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, true);
  xhrexec.send;
  ShowSearch(false);
  AddresseOderProjekt(-1);
  adrData.ExecSQL(Token+ident+'select first 10 ID, NAME1, ENDUNG, INFO, STATUS, BEZIEHUNGSART from WEBSYNC_DATEI where ADRESSE_ID= '+dokumentPk+' or PROJEKT_ID= '+dokumentPk+'');
end;

procedure TfrmMain.ShowDokumente;
var i, status2count: integer;
begin
  AnzeigeSeite(-1);
  ShowSearch(false);
  AddresseOderProjekt(-1);
  pnl:=TIBEWebPanel.create(sbx);
  pnl.initDokuUeberschrift(sbx,cs.GetValue('DOKUTITEL'));
  LetzteHeight := pnl.Top + pnl.Height;

  //lblerror.Caption:= inttostr(adrData.Count);
  for i:=0 to adrData.Count-1 do begin

    if pos('.',adrData.row(adrData.pk(i)).Values['ENDUNG']) = 0 then begin
     adrData.row(adrData.pk(i)).Values['ENDUNG'] := '.'+adrData.row(adrData.pk(i)).Values['ENDUNG'];
    end;

    if adrData.row(adrData.pk(i)).Values['STATUS'] = '2' then begin
     status2count := status2count + 1;
    end;

      pnl2:=TIBEWebPanel.create(sbx);
      pnl2.initDokuInhalt(sbx,
               adrData.row(adrData.pk(i)).Values['NAME1'],
               adrData.row(adrData.pk(i)).Values['ENDUNG'],
               adrData.row(adrData.pk(i)).Values['ID'],
               adrData.row(adrData.pk(i)).Values['INFO'],
               adrData.row(adrData.pk(i)).Values['STATUS']);
      LetzteHeight := LetzteHeight + pnl2.Height;
  end;

  if status2count = 0 then begin
   WtDokumentNeuladen.enabled := false;
  end;

  status2count := 0;
  ShowFooterMenu(pnl2.top+pnl2.Height);
  AnzeigeSeite(0);
end;

procedure TfrmMain.PrepareMainMenu;
var sqlexec, sql1: String;
begin
  ClearForm;
  WtDokumentNeuladen.enabled := false;
  AnzeigeSeite(0);
  Nachgeladen := 0;
  Gefunden := -1;
  Execarea := 'AnzahlDatensaetze';
  if cobAdresse.Text = 'Alle' then
  begin
     sqlexec := Token+ident+'select count(id) from WEBSYNC_ADRESSE where adressevon_id is null and name1<>'''' and (name1 containing '''+ReplaceSpecialChar(edtSearch.text)+''' or Kuerzel containing '''+ReplaceSpecialChar(edtSearch.text)+''')';
  end
  else if cobAdresse.Text = 'Kunde' then
  begin
     sqlexec := Token+ident+'select count(id) from WEBSYNC_ADRESSE where adressevon_id is null and name1<>'''' and (name1 containing '''+ReplaceSpecialChar(edtSearch.text)+''' or Kuerzel containing '''+ReplaceSpecialChar(edtSearch.text)+''') and ist_debitor = ''J''';
  end
  else if cobAdresse.Text = 'Lieferant' then
  begin
     sqlexec := Token+ident+'select count(id) from WEBSYNC_ADRESSE where adressevon_id is null and name1<>'''' and (name1 containing '''+ReplaceSpecialChar(edtSearch.text)+''' or Kuerzel containing '''+ReplaceSpecialChar(edtSearch.text)+''') and ist_kreditor = ''J''';
  end;

  sqlexec:=stringreplace(sqlexec,'''','''''',[rfReplaceAll]);
  xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, true);
  xhrexec.send;
  area:='MainMenu';
  ShowSearch(true);
  AddresseOderProjekt(1);

  sql1 := 'select first 10 ID,NUMMER,KUERZEL,FUNKTION,NAME1,NAME2,NAME3,NAME4,NAME5,STRASSE,ORT_PLZ,ORT_NAME,TELEFON,MOBIL,EMAIL from WEBSYNC_ADRESSE where adressevon_id is null and name1<>'''' and (name1 containing '''+ReplaceSpecialChar(edtSearch.text)+''' or Kuerzel containing '''+ReplaceSpecialChar(edtSearch.text)+''')';

  if cobAdresse.Text = 'Kunde' then begin
     adrData.ExecSQL(Token+ident+sql1+' and ist_debitor = ''J'' order by name1 asc');
  end
  else if cobAdresse.Text = 'Lieferant' then begin
     adrData.ExecSQL(Token+ident+sql1+' and ist_kreditor = ''J'' order by name1 asc');
  end
  else if cobAdresse.Text = 'Alle' then begin
     adrData.ExecSQL(Token+ident+sql1+' order by name1 asc');
  end;
end;

procedure TfrmMain.ShowMainMenu;                     // Adress-Listen-Ansicht
var i,ix:integer;
    l:TLabel;
begin
  AnzeigeSeite(-1);
  if ((Gefunden = -1) or (Gefunden = 0))and not (edtSearch.text = '')then begin
    pnl:=TIBEWebPanel.create(sbx);
    pnl.Height := 100;
    l:=TLabel.Create(sbx);
    l.Parent := sbx;
    l.Height := 100;
    l.Top := 7;
    l.Left:=10;
    if Handysize then l.font.size:=10 else l.font.size:=14;
    if Handysize then begin
       l.Caption:='Es wurden keine Adresse zu "'+edtSearch.text+'"<br>mit dem Filter "'+cobAdresse.Text+'" gefunden';
    end
    else begin
       l.Caption:='Es wurden keine Adresse zu "'+edtSearch.text+'" mit dem Filter "'+cobAdresse.Text+'" gefunden';
    end;
  end;

  for i:=0 to adrData.Count-1 do begin
      pnl:=TIBEWebPanel.create(sbx); 
      pnl.onclick := @pnlNavClick;
      pnl.onMouseEnter := @pnlNavMouseEnter;
      pnl.onMouseLeave := @pnlNavMouseLeave;
      pnl.initPanel(sbx, i, adrData.row(adrData.pk(i)).Values['KUERZEL'],
                    adrData.row(adrData.pk(i)).Values['NAME1'] + ' ' + adrData.row(adrData.pk(i)).Values['NAME2'],
                    adrData.row(adrData.pk(i)).Values['STRASSE'] + ' ' + adrData.row(adrData.pk(i)).Values['ORT_PLZ'] + ' ' + adrData.row(adrData.pk(i)).Values['ORT_NAME'],
                    '',
                    adrData.pk(i), area);

  end;
  ShowFooterMenu(pnl.top+pnl.Height);
  AnzeigeSeite(0);
  LetzteHeight := 0;
end;

procedure TfrmMain.ShowMainMenuNext;                     // Adress-Listen-Ansicht (Weitere!)
var i,ix:integer;
begin
  FreeAndNil(sbx.FindComponent('pnlFooter'));
  for i:=0 to adrData.Count-1 do begin
      pnl:=TIBEWebPanel.create(sbx);
      pnl.onclick := @pnlNavClick;
      pnl.onMouseEnter := @pnlNavMouseEnter;
      pnl.onMouseLeave := @pnlNavMouseLeave;
      pnl.initPanel(sbx, (Nachgeladen * 10) + i, adrData.row(adrData.pk(i)).Values['KUERZEL'],
                    adrData.row(adrData.pk(i)).Values['NAME1'] + ' ' + adrData.row(adrData.pk(i)).Values['NAME2'],
                    adrData.row(adrData.pk(i)).Values['STRASSE'] + ' ' + adrData.row(adrData.pk(i)).Values['ORT_PLZ'] + ' ' + adrData.row(adrData.pk(i)).Values['ORT_NAME'],
                    '',
                    adrData.pk(i), area);
  end;                           
  ShowFooterMenu(pnl.top+pnl.Height);
  if (Gefunden > ((Nachgeladen + 1) * 10)) then
    TSpeedButton(sbx.FindComponent('pnlFooter').FindComponent('btNext')).Visible := TRUE
  else
    TSpeedButton(sbx.FindComponent('pnlFooter').FindComponent('btNext')).Visible := FALSE;
end;

procedure TfrmMain.PrepareProjekte;
var sqlexec, sql1, sql2, sql3: String;
begin
  ClearForm;
  WtDokumentNeuladen.enabled := false;
  AnzeigeSeite(0);
  Nachgeladen := 0;
  Gefunden := -1;
  Execarea := 'AnzahlDatensaetze';
  if (pos('(Adresse:', edtSearch.Text) > 0) and not(cobProjekt.Text = 'Alle') then begin
    sqlexec := Token+ident+'select count(id) from WEBSYNC_PROJEKT WHERE ADRESSE_ID=' + StringReplace(copy(edtSearch.text, pos('(Adresse:', edtSearch.text)+9), ')', '', [rfReplaceAll])+' and PROJEKTSTATUS_NAME1 ='''+cobProjekt.Text+'''';
  end
  else if (pos('(Adresse:', edtSearch.Text) > 0)then begin
    sqlexec := Token+ident+'select count(id) from WEBSYNC_PROJEKT WHERE ADRESSE_ID=' + StringReplace(copy(edtSearch.text, pos('(Adresse:', edtSearch.text)+9), ')', '', [rfReplaceAll]);
  end
  else begin
    sqlexec := Token+ident+'select count(id) from WEBSYNC_PROJEKT WHERE PROJEKTNR containing '''+ReplaceSpecialChar(edtSearch.text)+''' or name1 containing '''+ReplaceSpecialChar(edtSearch.text)+''' '+sql3;
  end;
                  
  //ShowMessage(sqlexec);
  sqlexec:=stringreplace(sqlexec,'''','''''',[rfReplaceAll]);
  xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, true);
  xhrexec.send;
  area:='Projekte';
  ShowSearch(true);

  sql1 := 'select first 10 ID, PROJEKTNR, NAME1, KUERZEL, PROJEKTSTATUS_NAME1, PROJEKTSTATUS_KUERZEL, SACHBEARBEITER, PROJEKTLEITER FROM WEBSYNC_PROJEKT';
  sql2 := '(PROJEKTNR containing '''+ReplaceSpecialChar(edtSearch.text)+''' or name1 containing '''+ReplaceSpecialChar(edtSearch.text)+''' or kuerzel containing '''+ReplaceSpecialChar(edtSearch.text)+''') '+sql3;
  sql3 := 'order by id desc';
  if(cs.GetValue('RECHTE') = 'J')then begin
    if (pos('(Adresse:', edtSearch.Text) > 0) and (cobProjekt.Text = 'Alle') then begin
      adrData.ExecSQL(Token+ident+sql1+' WHERE ADRESSE_ID='+StringReplace(copy(edtSearch.text, pos('(Adresse:', edtSearch.text)+9), ')', '', [rfReplaceAll])+' '+sql3);
      lblFilter.Visible := FALSE;
      AddresseOderProjekt(2);
    end
    else if (pos('(Adresse:', edtSearch.Text) > 0) and not(cobProjekt.Text = 'Alle') then begin
      adrData.ExecSQL(Token+ident+sql1+' WHERE ADRESSE_ID='+StringReplace(copy(edtSearch.text, pos('(Adresse:', edtSearch.text)+9), ')', '', [rfReplaceAll])+' and PROJEKTSTATUS_NAME1 ='''+cobProjekt.Text+''' '+sql3);
      AddresseOderProjekt(2);
    end
     else if cobProjekt.Text = 'Alle' then begin
      adrData.ExecSQL(Token+ident+sql1+' WHERE '+sql2+' '+sql3);
      AddresseOderProjekt(2);
    end
    else begin
      adrData.ExecSQL(Token+ident+sql1+' WHERE PROJEKTSTATUS_NAME1 ='''+cobProjekt.Text+''' and '+sql2+' '+sql3);
      AddresseOderProjekt(2);
    end;
  end
  //else if(cs.GetValue('RECHTE') = 'N') then begin
  else begin
    if (pos('(Adresse:', edtSearch.Text) > 0) then begin
      adrData.ExecSQL(Token+ident+sql1+' WHERE ADRESSE_ID='+StringReplace(copy(edtSearch.text, pos('(Adresse:', edtSearch.text)+9), ')', '', [rfReplaceAll])+' AND (PROJEKTSTATUS_KUERZEL = ''AuA'' OR PROJEKTSTATUS_KUERZEL = ''AUErt'') '+sql3);
      lblFilter.Visible := FALSE;
      AddresseOderProjekt(-1);
    end
    else if cobProjekt.Text = 'Alle' then begin
      adrData.ExecSQL(Token+ident+sql1+' WHERE ('+sql2+') AND (PROJEKTSTATUS_KUERZEL = ''AuA'' OR PROJEKTSTATUS_KUERZEL = ''AUErt'') '+sql3);
      AddresseOderProjekt(2);
    end
    else begin
      adrData.ExecSQL(Token+ident+sql1+' WHERE PROJEKTSTATUS_NAME1 ='''+cobProjekt.Text+''' and '+sql2+' '+sql3);
      AddresseOderProjekt(2);
    end;
  end;
end;

procedure TfrmMain.ShowProjekte;
var i,ix:integer;
    l:TLabel;
begin
  AnzeigeSeite(-1);
  ShowSearch(true);
   if ((Gefunden = -1) or (Gefunden = 0))and not (edtSearch.text = '')then begin
    pnl:=TIBEWebPanel.create(sbx);
    pnl.Height := 100;
    l:=TLabel.Create(sbx);
    l.Parent := sbx;
    l.Height := 100;
    l.Top := 7;
    l.Left:=10;
    if Handysize then l.font.size:=10 else l.font.size:=14;
    if Handysize then begin
       l.Caption:='Es wurden keine Projekte zu "'+edtSearch.text+'"<br>mit dem Filter "'+cobProjekt.Text+'" gefunden';
    end
    else begin
       l.Caption:='Es wurden keine Projekte zu "'+edtSearch.text+'" mit dem Filter "'+cobProjekt.Text+'" gefunden';
    end;
  end;

  for i:=0 to adrData.Count-1 do begin
    pnl:=TIBEWebPanel.create(sbx);
    pnl.onclick := @pnlNavClick;
    pnl.onMouseEnter := @pnlNavMouseEnter;
    pnl.onMouseLeave := @pnlNavMouseLeave;
    pnl.initPanel(sbx, i, adrData.row(adrData.pk(i)).Values['PROJEKTSTATUS_NAME1'],
                  adrData.row(adrData.pk(i)).Values['NAME1'] + ' ' + adrData.row(adrData.pk(i)).Values['NAME2'],
                  adrData.row(adrData.pk(i)).Values['PROJEKTNR'] + ' - ' + adrData.row(adrData.pk(i)).Values['NAME1'],
                  adrData.row(adrData.pk(i)).Values['KUERZEL'],
                  adrData.pk(i), area);
  end;
  ShowFooterMenu(pnl.top+pnl.Height);
  AnzeigeSeite(0);
  LetzteHeight := 0;

end;

procedure TfrmMain.ShowProjekteNext;
var i,ix:integer;
begin
  FreeAndNil(sbx.FindComponent('pnlFooter'));
  for i:=0 to adrData.Count-1 do begin
    pnl:=TIBEWebPanel.create(sbx);
    pnl.onclick := @pnlNavClick;
    pnl.onMouseEnter := @pnlNavMouseEnter;
    pnl.onMouseLeave := @pnlNavMouseLeave;
    pnl.initPanel(sbx, (Nachgeladen * 10) +  i, adrData.row(adrData.pk(i)).Values['PROJEKTSTATUS_NAME1'],
                  adrData.row(adrData.pk(i)).Values['NAME1'] + ' ' + adrData.row(adrData.pk(i)).Values['NAME2'],
                  adrData.row(adrData.pk(i)).Values['PROJEKTNR'] + ' - ' + adrData.row(adrData.pk(i)).Values['NAME1'],
                  adrData.row(adrData.pk(i)).Values['KUERZEL'],
                  adrData.pk(i), area);
  end;
  ShowFooterMenu(pnl.top+pnl.Height);
  if (Gefunden > ((Nachgeladen + 1) * 10)) then
    TSpeedButton(sbx.FindComponent('pnlFooter').FindComponent('btNext')).Visible := TRUE
  else
    TSpeedButton(sbx.FindComponent('pnlFooter').FindComponent('btNext')).Visible := FALSE;
end;

procedure TfrmMain.PrepareProjektDetails;
var sqlexec: String;
begin
  ClearForm;
  AnzeigeSeite(0);
  area:='ShowProjektDetails';
  gefunden:= -1;
  Execarea := 'AnzahlDatensaetze';
  sqlexec := Token+ident+'select count(d.ID) from WEBSYNC_PROJEKT p LEFT outer JOIN WEBSYNC_DATEI d on d.PROJEKT_ID = p.ID where p.id = ' + ProjektPk + '' ;
  sqlexec:=stringreplace(sqlexec,'''','''''',[rfReplaceAll]);
  xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, false);
  xhrexec.send;
  ShowSearch(false);
  AddresseOderProjekt(-1);
  adrData.ExecSQL(Token+ident+'select ID, NAME1, PROJEKTNR, KUERZEL, DATUM, PROJEKTSTATUS_NAME1, PROJEKTLEITER, SACHBEARBEITER, AUFTRAGSWERT, ADRESSE_ID FROM WEBSYNC_PROJEKT WHERE ID='+ProjektPk+' order by id');
end;

procedure TfrmMain.ShowProjektDetails;
var i,ix:integer;
    Wert: String;
begin
  edtSearch.Text := '';
  if not(gefunden = 0) then cs.SetValue('DOKUTITEL',adrData.row(adrData.pk(0)).Values['PROJEKTNR'] + '-' + adrData.row(adrData.pk(0)).Values['KUERZEL']);
  cs.SetValue('PROJNAME',adrData.row(adrData.pk(0)).Values['NAME1']);
  cs.SetValue('PStatus',adrData.row(adrData.pk(0)).Values['PROJEKTSTATUS_NAME1']);
  DetailPk := adrData.row(adrData.pk(0)).Values['ADRESSE_ID'];

  AnzeigeSeite(-1);
  ShowSearch(false);
  AddresseOderProjekt(-1);

  Wert := adrData.row(adrData.pk(0)).Values['AUFTRAGSWERT'];
  Wert := StringReplace(Wert, '.', ',', [rfReplaceAll]);
  Wert := FloatToStrF(StrToFloat(Wert), ffNumber, 2, 2) + ' €';
  //Wert := StringReplace(Wert, ',', ';', [rfReplaceAll]);
  //Wert := StringReplace(Wert, '.', ',', [rfReplaceAll]);
  //Wert := StringReplace(Wert, ';', '.', [rfReplaceAll]);
  pnl:=TIBEWebPanel.create(sbx);
  pnl.initPrjDetails(sbx, adrData.row(adrData.pk(0)).Values['NAME1'],
                     adrData.row(adrData.pk(0)).Values['PROJEKTNR'],
                     adrData.row(adrData.pk(0)).Values['KUERZEL'],
                     adrData.row(adrData.pk(0)).Values['DATUM'],
                     adrData.row(adrData.pk(0)).Values['PROJEKTSTATUS_NAME1'],
                     adrData.row(adrData.pk(0)).Values['PROJEKTLEITER'],
                     adrData.row(adrData.pk(0)).Values['SACHBEARBEITER'],
                     Wert, cs.GetValue('RECHTE'), adrData.pk(0),
                     gefunden);
  ShowFooterMenu(pnl.top+pnl.Height);
  AnzeigeSeite(0);
  LetzteHeight := 0;
end;

procedure TfrmMain.PreparePositionen;
var sqlexec: String;
begin
  ClearForm;
  area:='ShowPositionen';
  Nachgeladen := 0;
  Gefunden := -1;
  Execarea := 'AnzahlDatensaetze';
  sqlexec := Token+ident+'select count(PROJEKT_ID) FROM WEBSYNC_PROJPOS where PROJEKT_ID='''+ProjektPk+'''';
  sqlexec:=stringreplace(sqlexec,'''','''''',[rfReplaceAll]);
  xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, true);
  xhrexec.send;
  ShowSearch(false);
  AddresseOderProjekt(-1);
  adrData.ExecSQL(Token+ident+'select first 10 pos.POSITIONSZAEHLER, pos.ID, pos.PROJEKT_ID, pos.POSNR, pos.POSITIONSART_NAME1, pos.KURZTEXT_SHORT, pos.LANGTEXT_SHORT, pos.MENGE, pos.EINHEIT_NAME1, pos.VKPREIS, pos.VKPREISGESAMT, proj.ADRESSE_ID,proj.NAME1 FROM WEBSYNC_PROJPOS as pos inner join websync_projekt as proj on proj.ID = pos.PROJEKT_ID where pos.PROJEKT_ID='+ProjektPk+' order by pos.POSITIONSZAEHLER');
end;

procedure TfrmMain.ShowPositionen;
var i,ix:integer;
    Wert: String;
    l:TLabel;
begin
  AnzeigeSeite(-1);
  ShowSearch(false);
  AddresseOderProjekt(-1);
  pnl:=TIBEWebPanel.create(sbx);
  pnl.initPrjPosUebertschrift(sbx,cs.GetValue('PROJNAME'));
  LetzteHeight := pnl.Top + pnl.Height;

  if ((Gefunden = -1) or (Gefunden = 0)) then begin
    pnl2:=TIBEWebPanel.create(sbx);
    l:=TLabel.Create(sbx);

    l.Parent := sbx;
    l.Height := 100;
    l.Font.Color := rgb(96, 96, 96);
    l.Left:=25;
    l.Caption:='Dieses Projekt hat keine Positionen.';

    if Handysize then begin
      l.font.size:=10;
      l.Top := 150;
      pnl2.top := 160;
    end
    else begin
      l.font.size:=14;
      l.Top := 170;
      pnl2.top := 180;
    end;
  end
  else begin
    for i:=0 to adrData.Count-1 do begin
        pnl2:=TIBEWebPanel.create(sbx);
        pnl2.onMouseEnter := @pnlNavMouseEnter;
        pnl2.onMouseLeave := @pnlNavMouseLeave;
        pnl2.initPrjPosInhalt(sbx,
                   adrData.row(adrData.pk(i)).Values['pos.ID'],
                   adrData.row(adrData.pk(i)).Values['pos.POSNR'],
                   adrData.row(adrData.pk(i)).Values['pos.POSITIONSART_NAME1'],
                   adrData.row(adrData.pk(i)).Values['pos.KURZTEXT_SHORT'],
                   adrData.row(adrData.pk(i)).Values['pos.VKPREIS'],
                   adrData.row(adrData.pk(i)).Values['pos.MENGE'],
                   adrData.row(adrData.pk(i)).Values['pos.EINHEIT_NAME1'],
                   adrData.row(adrData.pk(i)).Values['pos.VKPREISGESAMT'],
                   adrData.row(adrData.pk(i)).Values['pos.LANGTEXT_SHORT'],
                   cs.GetValue('RECHTE'));
        LetzteHeight := LetzteHeight + pnl2.Height;
    end;
  end;

  ShowFooterMenu(pnl2.top+pnl2.Height);
  if not((Gefunden = -1) or (Gefunden = 0)) then begin
    DetailPk := adrData.row(adrData.pk(0)).Values['proj.ADRESSE_ID'];
    ProjektPk:= adrData.row(adrData.pk(0)).Values['pos.PROJEKT_ID'];
  end;
  AnzeigeSeite(0);
end;

procedure TfrmMain.ShowPositionenNext;                     // Positionen-Ansicht (Weitere!)
var i,ix:integer;
begin
  FreeAndNil(sbx.FindComponent('pnlFooter'));
  for i:=0 to adrData.Count-1 do begin
      pnl:=TIBEWebPanel.create(sbx);
      //pnl.onclick := @pnlNavClick;
      pnl.onMouseEnter := @pnlNavMouseEnter;
      pnl.onMouseLeave := @pnlNavMouseLeave;
      pnl.initPrjPosInhalt(sbx,
                 adrData.row(adrData.pk(i)).Values['pos.ID'],
                 adrData.row(adrData.pk(i)).Values['pos.POSNR'],
                 adrData.row(adrData.pk(i)).Values['pos.POSITIONSART_NAME1'],
                 adrData.row(adrData.pk(i)).Values['pos.KURZTEXT_SHORT'],
                 adrData.row(adrData.pk(i)).Values['pos.VKPREIS'],
                 adrData.row(adrData.pk(i)).Values['pos.MENGE'],
                 adrData.row(adrData.pk(i)).Values['pos.EINHEIT_NAME1'],
                 adrData.row(adrData.pk(i)).Values['pos.VKPREISGESAMT'],
                 adrData.row(adrData.pk(i)).Values['pos.LANGTEXT_SHORT'],
                 cs.GetValue('RECHTE'));
      LetzteHeight := LetzteHeight + pnl.Height;
  end;
  ShowFooterMenu(pnl.top+pnl.Height);
  if (Gefunden > ((Nachgeladen + 1) * 10)) then
    TSpeedButton(sbx.FindComponent('pnlFooter').FindComponent('btNext')).Visible := TRUE
  else
    TSpeedButton(sbx.FindComponent('pnlFooter').FindComponent('btNext')).Visible := FALSE;
  //LetzteHeight := 0;
end;

procedure TfrmMain.ShowCamera(Sender: TObject);
var WebImageControl1: TImageControl;
begin
  ClearForm;
  area:='ShowCamera';
  AnzeigeSeite(0);
  ShowSearch(false);
  AddresseOderProjekt(-1);
  pnl:=TIBEWebPanel.create(sbx);
  pnl.initPrjPosUebertschrift(sbx,adrData.row(adrData.pk(0)).Values['proj.NAME1']);
  cs.SetValue('PROJNAME',adrData.row(adrData.pk(0)).Values['proj.NAME1']);
  LetzteHeight := pnl.Top + pnl.Height;

  pnl2:=TIBEWebPanel.create(sbx);
  pnl2.initPrjPosCam(sbx);
  camPos.start;
  //WebImageControl1.URL := WebCamera.SnapShotAsBase64;
  LetzteHeight := LetzteHeight + pnl2.Height;
  ShowFooterMenu(pnl2.top+pnl2.Height);
  DetailPk := adrData.row(adrData.pk(0)).Values['proj.ADRESSE_ID'];
  ProjektPk:= adrData.row(adrData.pk(0)).Values['pos.PROJEKT_ID'];
  AnzeigeSeite(0);
end;

procedure TfrmMain.ShowFooterMenu(const Anfang: Integer);   
var wllImpressum, wllDatenschutz: TLinkLabel;
    btNext: TSpeedbutton;
begin
  pnl := TIBEWebPanel.create(sbx);
  pnl.parent := sbx;
  pnl.Height := 25;
  pnl.BorderStyle := bsNone;
  pnl.top := Anfang;
  pnl.Left := 0;
  pnl.Width := ScreenWidth;
  pnl.Anchors := [akTop,akLeft,akRight];
  pnl.caption := '';
  pnl.Color := clwhite;   
  pnl.Name := 'pnlFooter';
  btNext := TSpeedbutton.Create(pnl);
  btNext.Parent := pnl;
  btNext.Height := 26;
  if HandySize then begin
    btNext.Font.Size := 9;
    btNext.Width := 70;
    btNext.left := Trunc(pnl.width / 2) - 35;
  end
  else begin
    btNext.Font.Size := 12;
    btNext.Width := 100;
    btNext.left := Trunc(pnl.width / 2) - 50;
  end;
  btNext.top := 0;
  btNext.caption := 'Weitere...';
  btNext.Onclick := @NextClick;                                                                                          
  if ((area = 'MainMenu') or (area = 'Projekte') or (area = 'DetailAPs') or (area = 'ShowPositionen')) and (Nachgeladen = 0) and (Gefunden > 10) then
    btNext.Visible := TRUE
  else
    btNext.Visible := FALSE;
  btNext.Name := 'btNext';
  wllImpressum := TLinkLabel.Create(pnl);
  wllImpressum.Parent := pnl;
  wllImpressum.AutoSize:= TRUE;      
  if HandySize then begin
    wllImpressum.Font.Size := 7;
    wllImpressum.left := pnl.width - 135;
  end
  else begin
    wllImpressum.Font.Size := 10;
    wllImpressum.left := pnl.width - 175;
  end;
  wllImpressum.top := 5;                         
  wllImpressum.Anchors := [akTop,akRight];
  wllImpressum.caption := '&nbsp;<a href="http://greenware.de/joomla/index.php/unternehmen/impressum" target="_blank">Impressum</a>';
  wllDatenschutz := TLinkLabel.Create(pnl);  
  wllDatenschutz.Parent := pnl;
  wllDatenschutz.AutoSize:= TRUE;
  if HandySize then begin
    wllDatenschutz.Font.Size := 7;
    wllDatenschutz.left := pnl.width - 80;
  end
  else begin
    wllDatenschutz.Font.Size := 10;
    wllDatenschutz.left := pnl.width - 100;
  end;
  wllDatenschutz.top := 5;
  wllDatenschutz.Anchors := [akTop,akRight];
  wllDatenschutz.caption := '&nbsp;<a href="http://greenware.de/joomla/index.php/datenschutzerklaerung" target="_blank">Datenschutz</a>';
end;

procedure TfrmMain.PrepareErfassung;
begin
  AnzeigeSeite(-1);
  area:='Erfasssung';
  ShowSearch(false);
  AddresseOderProjekt(-1);
  //pnlSearch.Visible := TRUE;
  PrepareErfassungPersonal;
end;

procedure TfrmMain.PrepareErfassungPersonal;
begin
  area:='Erfasssung-Personal';
  adrData.ExecSQL(Token+ident+'select ID, NUMMER, NAME1, NAME2 FROM WEBSYNC_BETRIEBSMITTEL WHERE KOSTENART_ID = 1 order by NAME1'); 
end;

procedure TfrmMain.ShowErfassungPersonal;        
var i: integer;
begin               
  cobNKLohnMitarbeiter.Items.Clear;
  cobNKLohnMitarbeiterID.Items.Clear;
  cobNKLohnMitarbeiter.Items.Add('---');
  cobNKLohnMitarbeiterID.Items.Add('0');
  for i:=0 to adrData.Count-1 do begin
    cobNKLohnMitarbeiter.Items.Add(adrData.row(adrData.pk(i)).Values['NAME1'] + ' ' + adrData.row(adrData.pk(i)).Values['NAME2'] + '(' + adrData.row(adrData.pk(i)).Values['NUMMER'] + ')');
    cobNKLohnMitarbeiterID.Items.Add(adrData.row(adrData.pk(i)).Values['ID']);
  end;
  PrepareErfassungLohnarten;
end;

procedure TfrmMain.PrepareErfassungLohnarten;
begin
  area:='Erfasssung-Lohnarten';
  adrData.ExecSQL(Token+ident+'select ID, NUMMER, NAME1 FROM WEBSYNC_LOHNART order by NAME1');
end;

procedure TfrmMain.ShowErfassungLohnarten;
var i: integer;
begin
  cobNKLohnLohnart.Items.Clear;
  cobNKLohnLohnartID.Items.Clear;
  cobNKLohnLohnart.Items.Add('---');
  cobNKLohnLohnartID.Items.Add('0');
  for i:=0 to adrData.Count-1 do begin
    cobNKLohnLohnart.Items.Add(adrData.row(adrData.pk(i)).Values['NAME1'] + '(' + adrData.row(adrData.pk(i)).Values['NUMMER'] + ')');
    cobNKLohnLohnartID.Items.Add(adrData.row(adrData.pk(i)).Values['ID']);
  end;
  AnzeigeSeite(1);   
  PrepareNKZeilen;
end;

procedure TfrmMain.PrepareErfassungMaschinen;
begin
  area:='Erfasssung-Maschinen';
  adrData.ExecSQL(Token+ident+'select ID, NUMMER, NAME1, NAME2 FROM WEBSYNC_BETRIEBSMITTEL WHERE KOSTENART_ID = 3 order by NAME1');
end;                                 

procedure TfrmMain.ShowErfassungMaschinen;
var i: integer;
begin
  cobNKMaschMaschine.Items.Clear;
  cobNKMaschMaschineID.Items.Clear;
  cobNKMaschMaschine.Items.Add('---');
  cobNKMaschMaschineID.Items.Add('0');
  for i:=0 to adrData.Count-1 do begin
    cobNKMaschMaschine.Items.Add(adrData.row(adrData.pk(i)).Values['NAME1'] + ' ' + adrData.row(adrData.pk(i)).Values['NAME2'] + '(' + adrData.row(adrData.pk(i)).Values['NUMMER'] + ')');
    cobNKMaschMaschineID.Items.Add(adrData.row(adrData.pk(i)).Values['ID']);
  end;
  AnzeigeSeite(2);  
  PrepareNKZeilen;
end;                 

procedure TfrmMain.PrepareErfassungMaterial;
begin
  area:='Erfasssung-Material';
  adrData.ExecSQL(Token+ident+'select first 100 ID, NUMMER, NAME1, NAME2 FROM WEBSYNC_BETRIEBSMITTEL WHERE KOSTENART_ID = 2 order by NAME1');
end;

procedure TfrmMain.ShowErfassungMaterial;
var i: integer;
begin                         
  cobNKMatMaterial.Items.Clear;
  cobNKMatMaterialID.Items.Clear;
  cobNKMatMaterial.Items.Add('---');
  cobNKMatMaterialID.Items.Add('0');
  for i:=0 to adrData.Count-1 do begin
  //cobNKMatMaterial.Items.Add(adrData.Value(i, 'NAME1') + ' ' + adrData.Value(i, 'NAME2') + '(' + adrData.Value(i, 'NUMMER') + ')');
  //cobNKMatMaterialID.Items.Add(adrData.Value(i, 'ID'));
    cobNKMatMaterial.Items.Add(adrData.row(adrData.pk(i)).Values['NAME1'] + ' ' + adrData.row(adrData.pk(i)).Values['NAME2'] + '(' + adrData.row(adrData.pk(i)).Values['NUMMER'] + ')');
    cobNKMatMaterialID.Items.Add(adrData.row(adrData.pk(i)).Values['ID']);
  end;          
  AnzeigeSeite(3);
  PrepareNKZeilen;
end;

procedure TfrmMain.PrepareNKZeilen;
begin
  area := 'NKZeilen';
  if sbxNKLohn.Visible then
    adrData.ExecSQL(Token+ident+'select ID, DATUM, BETRIEBSMITTEL_LD, MENGE, EINHEIT_LD, BESCHREIBUNG, LOHNART_LD, GELOESCHT, ARBEITSBEGINN, ARBEITSPAUSE, ARBEITSENDE, ERFASSTAM, ERFASSTVON, RUESTZEIT, FAHRZEIT FROM ZV_NACHKALKULATION WHERE PROJEKT_ID = ' + ProjektPk + ' and cast(ERFASSTAM as date) = ''' + DateToStr(now) + ''' and KOSTENART_ID = 1 and GELOESCHT = ''N'' order by ERFASSTAM desc')
  else if sbxNKMaschine.Visible then
    adrData.ExecSQL(Token+ident+'select ID, DATUM, BETRIEBSMITTEL_LD, MENGE, EINHEIT_LD, BESCHREIBUNG, LOHNART_LD, GELOESCHT, ARBEITSBEGINN, ARBEITSENDE, ERFASSTAM, ERFASSTVON  FROM ZV_NACHKALKULATION WHERE PROJEKT_ID = ' + ProjektPk + ' and cast(ERFASSTAM as date) = ''' + DateToStr(now) + ''' and KOSTENART_ID = 3 and GELOESCHT = ''N'' order by ERFASSTAM desc')
  else if sbxNKMaterial.Visible then
    adrData.ExecSQL(Token+ident+'select ID, DATUM, BETRIEBSMITTEL_LD, BETRIEBSMITTEL_ID, MENGE, EINHEIT_LD, BESCHREIBUNG, LOHNART_LD, GELOESCHT, ERFASSTAM, ERFASSTVON FROM ZV_NACHKALKULATION WHERE PROJEKT_ID = ' + ProjektPk + ' and cast(ERFASSTAM as date) = ''' + DateToStr(now) + ''' and KOSTENART_ID = 2 and GELOESCHT = ''N'' order by ERFASSTAM desc');
end;

procedure TfrmMain.ShowNKZeilen;   
var i, j, index: integer;
    l: TLabel;
    lastControl: TControl;
    b: TButton;
    material: String;
begin
//Erfassungs Scrollbox, erstellt je erfasster Daten ein Label + Löschbutton
  if sbxNKLohn.Visible then begin
    area:='Erfasssung-Personal';

    for j:=sbNkLohnBox.ComponentCount-1 downto 0 do begin
       sbNkLohnBox.Components[j].Destroy;
    end;

    for i:=0 to adrData.Count-1 do begin

       l := TLabel.Create(sbNkLohnBox);
       b := TButton.Create(sbNkLohnBox);

       l.font.size := 11;
       b.font.size := 11;
       b.Width:= 75;

       l.Parent := sbNkLohnBox;
       b.Parent := sbNkLohnBox;
       index := Pos('.', adrData.row(adrData.pk(i)).Values['MENGE']); //index = die Stelle des '.' als int
                                                                      //bsp 1.0000 soll 1.00 also - index = 2, beim copy 1 bis index+2 = 1.00

       b.Caption := 'Löschen';
       l.Caption :=  adrData.row(adrData.pk(i)).Values['DATUM']
             +'______'+ copy(adrData.row(adrData.pk(i)).Values['MENGE'],1,index+2) + ' (std)'
             +'<br><b>Mitarbeiter:</b> '+ adrData.row(adrData.pk(i)).Values['BETRIEBSMITTEL_LD']
             +'<br><b>Lohnart:</b> '+adrData.row(adrData.pk(i)).Values['LOHNART_LD']
             +'<br><b>Von:</b> '+ copy(adrData.row(adrData.pk(i)).Values['ARBEITSBEGINN'],1,8)
             +' ,<b>Bis:</b> '+ copy(adrData.row(adrData.pk(i)).Values['ARBEITSENDE'],1,8)
             +'<br><b>Pause:</b> '+ adrData.row(adrData.pk(i)).Values['ARBEITSPAUSE']
             +'<br><b>Rüstzeit:</b> '+ copy(adrData.row(adrData.pk(i)).Values['RUESTZEIT'],1,4) + ' (min)'
             +' ,<b>Fahrzeit:</b> '+ copy(adrData.row(adrData.pk(i)).Values['FAHRZEIT'],1,4) + ' (min)'
             +'<br><b>Erfasst am:</b>'+ copy(adrData.row(adrData.pk(i)).Values['ERFASSTAM'],1,19)
             +'<br><b>Erfasst von:</b> '+ adrData.row(adrData.pk(i)).Values['ERFASSTVON'];

       if Assigned(lastControl) then begin  //lastControl = Position des vorherigen Labels/Button
          l.Top := lastControl.Top + 144;   //gibt an wo die nächsten Daten angezeigt werden
          l.Left := 80;
          b.Top := lastControl.Top + 144;
       end
       else begin  //gibt an wo die ersten Daten angezeigt werden
          l.Top := 5;
          b.Top := 5;
          l.Left := 80;
       end;

       lastControl := l;

       b.Name := 'B' + adrData.row(adrData.pk(i)).Values['ID']; //bsp: B1 = Daten zu Id 1 werden gelöscht
       b.OnClick := DeleteNK;
    end;
  end
  else if sbxNKMaschine.Visible then begin 
    area:='Erfasssung-Maschinen';

     for j:=sbNkMaschineBox.ComponentCount-1 downto 0 do begin
       sbNkMaschineBox.Components[j].Destroy;
    end;

    for i:=0 to adrData.Count-1 do begin

       l := TLabel.Create(sbNkMaschineBox);
       b := TButton.Create(sbNkMaschineBox);

       l.font.size := 11;
       b.font.size := 11;
       b.Width:= 75;

       l.Parent := sbNkMaschineBox;
       b.Parent := sbNkMaschineBox;
       index := Pos('.', adrData.row(adrData.pk(i)).Values['MENGE']);

       b.Caption := 'Löschen';
       l.Caption := adrData.row(adrData.pk(i)).Values['DATUM']
             +'______'+ copy(adrData.row(adrData.pk(i)).Values['MENGE'],1,index+2) + ' (std)'
             +'<br><b>Maschine:</b>'+adrData.row(adrData.pk(i)).Values['BETRIEBSMITTEL_LD']
             +'<br><b>Von:</b> '+ copy(adrData.row(adrData.pk(i)).Values['ARBEITSBEGINN'],1,8)
             +' ,<b>Bis:</b> '+ copy(adrData.row(adrData.pk(i)).Values['ARBEITSENDE'],1,8)
             +'<br><b>Erfasst am:</b> '+ copy(adrData.row(adrData.pk(i)).Values['ERFASSTAM'],1,19)
             +'<br><b>Erfasst von:</b> '+ adrData.row(adrData.pk(i)).Values['ERFASSTVON'];
       if Assigned(lastControl) then begin
          l.Top := lastControl.Top + 93;
          l.Left := 80;
          b.Top := lastControl.Top + 93;
       end
       else begin
          l.Top := 5;
          b.Top := 5;
          l.Left := 80;
       end;

       lastControl := l;

       b.Name := 'B' + adrData.row(adrData.pk(i)).Values['ID'];
       b.OnClick := DeleteNK;
    end;
  end
  else if sbxNKMaterial.Visible then begin
    area:='Erfasssung-Material';

    for j:=sbNKMaterialBox.ComponentCount-1 downto 0 do begin
       sbNKMaterialBox.Components[j].Destroy;
    end;

    for i:=0 to adrData.Count-1 do begin

       l := TLabel.Create(sbNKMaterialBox);
       b := TButton.Create(sbNKMaterialBox);

       l.font.size := 11;
       b.font.size := 11;
       b.Width:= 75;

       l.Parent := sbNKMaterialBox;
       b.Parent := sbNKMaterialBox;

      if adrData.row(adrData.pk(i)).Values['BETRIEBSMITTEL_ID'] = '0' then begin
         material := adrData.row(adrData.pk(i)).Values['BESCHREIBUNG'];
      end
      else begin
         material := adrData.row(adrData.pk(i)).Values['BETRIEBSMITTEL_LD'];
      end;
      index := Pos('.', adrData.row(adrData.pk(i)).Values['MENGE']);

       b.Caption := 'Löschen';
       l.Caption := adrData.row(adrData.pk(i)).Values['DATUM']
             +'______'+ copy(adrData.row(adrData.pk(i)).Values['MENGE'],1,index-1) + ' (st)'
             +'<br><b>Material:</b> '+ material
             +'<br><b>Erfasst am:</b> '+ copy(adrData.row(adrData.pk(i)).Values['ERFASSTAM'],1,19)
             +'<br><b>Erfasst von:</b> '+ adrData.row(adrData.pk(i)).Values['ERFASSTVON'];

       if Assigned(lastControl) then begin
          l.Top := lastControl.Top + 73;
          l.Left := 80;
          b.Top := lastControl.Top + 73;
       end
       else begin
          l.Top := 5;
          b.Top := 5;
          l.Left := 80;
       end;

       lastControl := l;

       b.Name := 'B' + adrData.row(adrData.pk(i)).Values['ID'];
       b.OnClick := DeleteNK;
    end;
  end;  
end;

procedure TfrmMain.DeleteNK(Sender: TObject);
var
  Button: TButton;
  deleteId: string;
  sqlexec: String;
begin
  Button := Sender as TButton;
  deleteId := Button.Name;
  Delete(deleteId,1,1);

  sqlexec := Token+ident+'UPDATE WEBSYNC_NACHKALKULATION '
      + 'SET GELOESCHT = ''J'' '
      + 'WHERE ID = ' + deleteId;
  //ShowMessage(sqlexec);
  sqlexec:=stringreplace(sqlexec,'''','''''',[rfReplaceAll]);
  xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, false);
  xhrexec.send;
  PrepareNKZeilen;
end;

procedure TfrmMain.PrepareForBenutzer;
begin
  area:='PrepareForBenutzer';
  ShowSearch(FALSE);
  AddresseOderProjekt(-1);
  adrData.ExecSQL(Token+ident+'SELECT ID, NAME1, REMOTEPASSWORT FROM WEBSYNC_BENUTZER WHERE (AKTIV = ''J'') and not (REMOTEPASSWORT = '''')');
end;

procedure TfrmMain.AskForBenutzer;
 var i: Integer;
begin
  ClearForm;
  pnlMenue.Visible := FALSE;
  img.Enabled := FALSE;

  area:='AskForBenutzer';
  lbl:=TLabel.create(sbx);
  lbl.parent:=sbx;
  lbl.top:=50;
  lbl.left:=75;

  cobBenutzer := TComboBox.create(sbx);
  cobBenutzer.parent:=sbx;
  cobBenutzer.width := 200;
  cobBenutzer.top := 110;
  cobBenutzer.left:=75;
  //cobBenutzer.AddItem('Gast', nil);
   for i:=0 to adrData.Count-1 do begin
    cobBenutzer.AddItem(adrData.row(adrData.pk(i)).Values['NAME1'], nil);
  end;

  if HandySize then begin
    lbl.Font.Size := 12;
    cobBenutzer.Font.Size:= 10;
  end
  else
    lbl.Font.Size := 12;
    cobBenutzer.Font.Size:= 12;
    lbl.caption:='Wählen sie ihren Benutzer aus<br>und geben sie ihr Passwort ein';

    edt:=TEdit.create(sbx);
    edt.parent:=sbx;
    edt.top:=140;
    edt.left:=75;
    edt.width:=100;
    edt.passwordChar:='*';
    edt.text:='';
  if HandySize then
    edt.Font.Size := 12
  else
    edt.Font.Size := 12;
  btn:=TIBEWebbutton.create(sbx);
  btn.parent:=sbx;
  btn.onclick:=@btnGetBenutzer;
  btn.top:=170;
  btn.left:=75;
  btn.width:=100;
  btn.Caption:='anmelden';
  AnzeigeSeite(0);
  if HandySize then
    btn.Font.Size := 12
  else
    btn.Font.Size := 12;

  ShowSearch(FALSE);
  AddresseOderProjekt(-1);
  LetzteHeight := 0;
end;

procedure TfrmMain.ProcessToken;
var db: String;
    cookies: TCookies;
    cookie: TCookie;
    expire: TDateTime;
begin
  if copy(adrData.RawData,1,5)='IDENT' then begin  
    pnlMenue.Visible := TRUE;
    img.Enabled := TRUE;
    ident:=copy(adrData.RawData,6,6);
    token:=edt.text;
    db:=copy(adrData.RawData,12,4);
    cs.SetValue('TOKEN',token);
    cs.SetValue('IDENT',ident);
    cs.SetValue('DB',db);

    PrepareForBenutzer;
  end
  else begin
    lblError.Caption:=adrData.RawData;
  end;
end;            

procedure TfrmMain.ReleaseToken;
begin       
  showmessage(adrData.RawData);
  AskForToken;
end;

procedure TfrmMain.PrepareDetailAPs;
var sqlexec: String;
begin
  area:='DetailAPs';
  Nachgeladen := 0;
  Gefunden := -1;
  Execarea := 'AnzahlDatensaetze';
  sqlexec := Token+ident+'select count(id) from WEBSYNC_ADRESSE where ADRESSEVON_id = ' + DetailPk;
  sqlexec:=stringreplace(sqlexec,'''','''''',[rfReplaceAll]);
  xhrexec.open('GET', 'https://greenwarecloud.de/sqlgw/data.php?CMD='+sqlexec, false);
  xhrexec.send;
  ShowSearch(FALSE);
  AddresseOderProjekt(-1);
  //lblerror.Caption := Token+ident+'select ID,NUMMER,KUERZEL,FUNKTION,NAME1,NAME2,NAME3,NAME4,NAME5,STRASSE,ORT_PLZ,ORT_NAME,TELEFON,MOBIL,EMAIL from WEBSYNC_ADRESSE where ADRESSEVON_id = ' + DetailPk + ' order by name1';
  //lblerror.visible := TRUE;
  //https://greenwarecloud.de/sqlgw/data.php?CMD=000012N1A0H6select ID,NUMMER,KUERZEL,FUNKTION,NAME1,NAME2,NAME3,NAME4,NAME5,STRASSE,ORT_PLZ,ORT_NAME,TELEFON,MOBIL,EMAIL from WEBSYNC_ADRESSE where ADRESSEVON_id = ''105008'' order by name1
  adrData.ExecSQL(Token+ident+'select First 10 ID,NUMMER,KUERZEL,FUNKTION,NAME1,NAME2,NAME3,NAME4,NAME5,STRASSE,ORT_PLZ,ORT_NAME,TELEFON,MOBIL,EMAIL from WEBSYNC_ADRESSE where ADRESSEVON_id = ''' + DetailPk + ''' order by name1');
end;

procedure TfrmMain.ShowDetailAPs;    
var i: integer;
    APName: String;
begin
  area:='DetailAPs';
  ShowSearch(false);
  AddresseOderProjekt(-1);
  //pnlSearch.Visible := TRUE;
  if (adrData.Count = 0) then begin
    pnl:=TIBEWebPanel.create(sbx);
    APName := 'Für diese Adresse wurden keine Ansprechpartner erfasst.';
    pnl.initAdrAPs(sbx, 0, APName, '', '', '');
    LetzteHeight := LetzteHeight + pnl.Height;
  end
  else begin
    for i:=0 to adrData.Count-1 do begin
      pnl:=TIBEWebPanel.create(sbx);
      APName := '';
      if (trim(adrData.row(adrData.pk(i)).Values['KUERZEL']) <> '') then
        APName := trim(adrData.row(adrData.pk(i)).Values['KUERZEL']) + ' ';
      APName := APName + adrData.row(adrData.pk(i)).Values['NAME2'] + ' ' + adrData.row(adrData.pk(i)).Values['NAME1'];
      if (trim(adrData.row(adrData.pk(i)).Values['FUNKTION']) <> '') then
        APName := APName + ' - ' + trim(adrData.row(adrData.pk(i)).Values['FUNKTION']);
      pnl.initAdrAPs(sbx, i, APName,
                     adrData.row(adrData.pk(i)).Values['TELEFON'],
                     adrData.row(adrData.pk(i)).Values['MOBIL'],
                     adrData.row(adrData.pk(i)).Values['EMAIL']);  
      LetzteHeight := LetzteHeight + pnl.Height;
    end;
  end;
  ShowFooterMenu(LetzteHeight);
  //AnzeigeSeite(0);
  //LetzteHeight := 0;
end;      

procedure TfrmMain.ShowDetailAPsNext;
var i,ix:integer;             
    APName: String;
begin
  FreeAndNil(sbx.FindComponent('pnlFooter'));
  for i:=0 to adrData.Count-1 do begin
    pnl:=TIBEWebPanel.create(sbx);
    APName := '';
    if (trim(adrData.row(adrData.pk(i)).Values['KUERZEL']) <> '') then
      APName := trim(adrData.row(adrData.pk(i)).Values['KUERZEL']) + ' ';
    APName := APName + adrData.row(adrData.pk(i)).Values['NAME2'] + ' ' + adrData.row(adrData.pk(i)).Values['NAME1'];
    if (trim(adrData.row(adrData.pk(i)).Values['FUNKTION']) <> '') then
      APName := APName + ' - ' + trim(adrData.row(adrData.pk(i)).Values['FUNKTION']);
    pnl.initAdrAPs(sbx, (Nachgeladen * 10) + i, APName,
                   adrData.row(adrData.pk(i)).Values['TELEFON'],
                   adrData.row(adrData.pk(i)).Values['MOBIL'],
                   adrData.row(adrData.pk(i)).Values['EMAIL']);       
    LetzteHeight := LetzteHeight + pnl.Height;
  end;
  ShowFooterMenu(pnl.top+pnl.Height);
  if (Gefunden > ((Nachgeladen + 1) * 10)) then
    TSpeedButton(sbx.FindComponent('pnlFooter').FindComponent('btNext')).Visible := TRUE
  else
    TSpeedButton(sbx.FindComponent('pnlFooter').FindComponent('btNext')).Visible := FALSE;
end;

procedure TfrmMain.ShowNextButton;
begin
  Gefunden := StrToInt(ExecStringList[0]);
  if Assigned(sbx.FindComponent('pnlFooter')) then
    if Assigned(sbx.FindComponent('pnlFooter').FindComponent('btNext')) and (Gefunden > ((Nachgeladen + 1) * 10)) and (Gefunden < 10) then
        TSpeedButton(sbx.FindComponent('pnlFooter').FindComponent('btNext')).Visible := TRUE;
end;

procedure TfrmMain.NextClick(Sender: TObject);
var Skippe: String;
begin
  Nachgeladen := Nachgeladen + 1;
  Skippe := IntToStr(Nachgeladen * 10);
  if (area = 'MainMenu') or (area = 'MainMenuNext') then begin
    area := 'MainMenuNext';
    adrData.ExecSQL(Token+ident+'select first 10 SKIP ' + Skippe + ' ID,NUMMER,KUERZEL,FUNKTION,NAME1,NAME2,NAME3,NAME4,NAME5,STRASSE,ORT_PLZ,ORT_NAME,TELEFON,MOBIL,EMAIL from WEBSYNC_ADRESSE where adressevon_id is null and name1<>'''' and name1 containing '''+ReplaceSpecialChar(edtSearch.text)+''' order by name1');
  end
  else if (area = 'DetailAPs') or (area = 'DetailAPsNext') then begin
    area := 'DetailAPsNext';
    adrData.ExecSQL(Token+ident+'select First 10 SKIP ' + Skippe + ' ID,NUMMER,KUERZEL,FUNKTION,NAME1,NAME2,NAME3,NAME4,NAME5,STRASSE,ORT_PLZ,ORT_NAME,TELEFON,MOBIL,EMAIL from WEBSYNC_ADRESSE where ADRESSEVON_id = ' + DetailPk + ' order by name1');
  end
  else if (area = 'ShowPositionen') or (area = 'ShowPositionenNext') then begin
    area := 'ShowPositionenNext';
    adrData.ExecSQL(Token+ident+'select First 10 SKIP ' + Skippe + ' pos.POSITIONSZAEHLER, pos.ID, pos.PROJEKT_ID, pos.POSNR, pos.POSITIONSART_NAME1, pos.KURZTEXT_SHORT, pos.LANGTEXT_SHORT, pos.MENGE, pos.EINHEIT_NAME1, pos.VKPREIS, pos.VKPREISGESAMT, proj.ADRESSE_ID,proj.NAME1 FROM WEBSYNC_PROJPOS as pos inner join websync_projekt as proj on proj.ID = pos.PROJEKT_ID where pos.PROJEKT_ID='+ProjektPk+' order by pos.POSITIONSZAEHLER');
  end
  else if (area = 'Projekte') or (area = 'ProjekteNext') then begin
    area := 'ProjekteNext';
    if(cs.GetValue('RECHTE') = 'J')then begin
      if (pos('(Adresse:', edtSearch.Text) > 0) then
        adrData.ExecSQL(Token+ident+'select first 10 SKIP ' + Skippe + ' ID, PROJEKTNR, NAME1, KUERZEL, PROJEKTSTATUS_NAME1, PROJEKTSTATUS_KUERZEL, SACHBEARBEITER, PROJEKTLEITER FROM WEBSYNC_PROJEKT WHERE ADRESSE_ID='+StringReplace(copy(ReplaceSpecialChar(edtSearch.text), pos('(Adresse:', ReplaceSpecialChar(edtSearch.text))+9), ')', '', [rfReplaceAll])+'order by id desc')
      else
        adrData.ExecSQL(Token+ident+'select first 10 SKIP ' + Skippe + ' ID, PROJEKTNR, NAME1, KUERZEL, PROJEKTSTATUS_NAME1, PROJEKTSTATUS_KUERZEL, SACHBEARBEITER, PROJEKTLEITER FROM WEBSYNC_PROJEKT WHERE PROJEKTNR containing '''+ReplaceSpecialChar(edtSearch.text)+''' or name1 containing '''+ReplaceSpecialChar(edtSearch.text)+''' order by id desc');
    end
    else if(cs.GetValue('RECHTE') = 'N')then begin
      if (pos('(Adresse:', edtSearch.Text) > 0) then
        adrData.ExecSQL(Token+ident+'select first 10 SKIP ' + Skippe + ' ID, PROJEKTNR, NAME1, KUERZEL, PROJEKTSTATUS_NAME1, PROJEKTSTATUS_KUERZEL, SACHBEARBEITER, PROJEKTLEITER FROM WEBSYNC_PROJEKT WHERE ADRESSE_ID='+StringReplace(copy(ReplaceSpecialChar(edtSearch.text), pos('(Adresse:', ReplaceSpecialChar(edtSearch.text))+9), ')', '', [rfReplaceAll])+' AND (PROJEKTSTATUS_KUERZEL = ''AuA'' OR PROJEKTSTATUS_KUERZEL = ''AUErt'') order by id desc')
      else
        adrData.ExecSQL(Token+ident+'select first 10 SKIP ' + Skippe + ' ID, PROJEKTNR, NAME1, KUERZEL, PROJEKTSTATUS_NAME1, PROJEKTSTATUS_KUERZEL, SACHBEARBEITER, PROJEKTLEITER FROM WEBSYNC_PROJEKT WHERE (PROJEKTNR containing '''+ReplaceSpecialChar(edtSearch.text)+''' or name1 containing '''+ReplaceSpecialChar(edtSearch.text)+''') AND (PROJEKTSTATUS_KUERZEL = ''AuA'' OR PROJEKTSTATUS_KUERZEL = ''AUErt'') order by id desc');
    end;
  end;
end;

procedure TfrmMain.AskForToken;
var i: Integer;
begin
  ClearForm;
  pnlMenue.Visible := FALSE;
  img.Enabled := FALSE;

  area:='AskForToken';
  lbl:=TLabel.create(sbx);
  lbl.parent:=sbx;
  lbl.top:=50;
  lbl.left:=50;

  if HandySize then begin
    lbl.Font.Size := 10;
  end
  else
    lbl.Font.Size := 12;
    lbl.caption:='Bitte geben Sie einen Anmeldecode<br>für dieses Gerät ein!';

    edt:=TEdit.create(sbx);
    edt.parent:=sbx;
    edt.top:=140;
    edt.left:=75;
    edt.width:=100;
    edt.text:='';
    edt.MaxLength:=6;
    edt.CharCase:=wecUpperCase;
  if HandySize then
    edt.Font.Size := 10
  else
    edt.Font.Size := 12;

  btn:=TIBEWebbutton.create(sbx);
  btn.parent:=sbx;
  btn.onclick:=@btnGetToken;
  btn.top:=170;
  btn.left:=75;
  btn.width:=100;
  btn.Caption:='Anmelden';
  AnzeigeSeite(0);
  if HandySize then
    btn.Font.Size := 10
  else
    btn.Font.Size := 12;

  ShowSearch(FALSE);
  AddresseOderProjekt(-1);
  LetzteHeight := 0;
end;

procedure TfrmMain.AddresseOderProjekt(const Auswahl: Integer);
begin
  if Auswahl = 1 then begin
    cobAdresse.Visible:= true;
    cobProjekt.Visible:= false;
    lblFilter.Visible := true;
    pnlSuche.Height:= 72;
    sbx.Top := 160;
  end
  else if Auswahl = 2 then begin
    cobAdresse.Visible:= false;
    cobProjekt.Visible:= true;
    lblFilter.Visible := true;
    pnlSuche.Height:= 72;
    sbx.Top := 160;
  end
  else if Auswahl = -1 then begin
    cobAdresse.Visible:= false;
    cobProjekt.Visible:= false;
    lblFilter.Visible := false;
    pnlSuche.Height:= 50;
    sbx.Top := 120;
  end;
end;

procedure TfrmMain.FelderZuruecksetzen;
begin
  dtpNKLohnDatum.Date := now;
  dtpNKLohnVon.Time := now;
  dtpNKLohnBis.Time := now;
  dtpNKMaschDatum.Date := now;
  dtpNKMaschVon.Time := now;
  dtpNKMaschBis.Time := now;
  dtpNKMatDatum.Date := now;

  cobNKLohnMitarbeiter.ItemIndex := 0;
  cobNKMatMaterial.ItemIndex := 0;
  cobNKLohnLohnart.ItemIndex := 0;
  cobNKMaschMaschine.ItemIndex := 0;

  edNKLohnDauer.Text := '';
  edNKMaschDauer.Text := '';
  edNKMatDauer.Text := '';
  edNKMatFrei.Text := '';
  edNKLohnFahrzeit.Text := '';
  edNKLohnRuestzeit.Text := '';

end;

procedure TfrmMain.CountErfassung;
begin
  Gefunden := StrToInt(ExecStringList[0]);
end;

procedure TfrmMain.ClearForm;
var i:integer;
begin
  AnzeigeSeite(-1);
  for i:=sbx.ComponentCount-1 downto 0 do begin
    sbx.Components[i].Destroy;
  end;
end;

procedure TfrmMain.LoadDFMValues;
begin
  inherited LoadDFMValues;

  sbxNKMaschine := TScrollBox.Create(Self);
  cobNKMaschMaschine := TComboBox.Create(sbxNKMaschine);
  lblNKMaschMaschine := TLabel.Create(sbxNKMaschine);
  cobNKMaschMaschineID := TComboBox.Create(sbxNKMaschine);
  dtpNKMaschVon := TDateTimePicker.Create(sbxNKMaschine);
  lblNKMaschVon := TLabel.Create(sbxNKMaschine);
  dtpNKMaschBis := TDateTimePicker.Create(sbxNKMaschine);
  edNKMaschDauer := TEdit.Create(sbxNKMaschine);
  wbtNKMaschSpeichern := TButton.Create(sbxNKMaschine);
  lblNKMaschDauer := TLabel.Create(sbxNKMaschine);
  lblNKMaschDatum := TLabel.Create(sbxNKMaschine);
  dtpNKMaschDatum := TDateTimePicker.Create(sbxNKMaschine);
  edNKMaschProjekt := TEdit.Create(sbxNKMaschine);
  lblNKMaschProjekt := TLabel.Create(sbxNKMaschine);
  lblNKMaschBis := TLabel.Create(sbxNKMaschine);
  sbNkMaschineBox := TScrollBox.Create(sbxNKMaschine);
  sbxNKLohn := TScrollBox.Create(Self);
  cobNKLohnMitarbeiter := TComboBox.Create(sbxNKLohn);
  lblNKLohnMitarbeiter := TLabel.Create(sbxNKLohn);
  cobNKLohnMitarbeiterID := TComboBox.Create(sbxNKLohn);
  dtpNKLohnVon := TDateTimePicker.Create(sbxNKLohn);
  dtpNKLohnBis := TDateTimePicker.Create(sbxNKLohn);
  edNKLohnDauer := TEdit.Create(sbxNKLohn);
  wbtNKLohnSpeichern := TButton.Create(sbxNKLohn);
  lblNKLohnDauer := TLabel.Create(sbxNKLohn);
  dtpNKLohnDatum := TDateTimePicker.Create(sbxNKLohn);
  cobNKLohnLohnartID := TComboBox.Create(sbxNKLohn);
  cobNKLohnLohnart := TComboBox.Create(sbxNKLohn);
  lblNKLohnLohnart := TLabel.Create(sbxNKLohn);
  edNKLohnProjekt := TEdit.Create(sbxNKLohn);
  lblNKLohnProjekt := TLabel.Create(sbxNKLohn);
  lblNKLohnVon := TLabel.Create(sbxNKLohn);
  lblNKLohnBis := TLabel.Create(sbxNKLohn);
  lblNKLohnDatum := TLabel.Create(sbxNKLohn);
  sbNkLohnBox := TScrollBox.Create(sbxNKLohn);
  edNKLohnFahrzeit := TEdit.Create(sbxNKLohn);
  edNKLohnRuestzeit := TEdit.Create(sbxNKLohn);
  lblNKLohnFahrzeit := TLabel.Create(sbxNKLohn);
  lblNKLohnRuestzeit := TLabel.Create(sbxNKLohn);
  lblNKLohnPause1 := TLabel.Create(sbxNKLohn);
  dtpNKLohnPauseVon := TDateTimePicker.Create(sbxNKLohn);
  dtpNKLohnPauseBis := TDateTimePicker.Create(sbxNKLohn);
  lblNKLohnPause2 := TLabel.Create(sbxNKLohn);
  pnlMenue := TPanel.Create(Self);
  wsbAdresse := TSpeedButton.Create(pnlMenue);
  wsbProjekt := TSpeedButton.Create(pnlMenue);
  wsbLogout := TSpeedButton.Create(pnlMenue);
  lblKarte := TLabel.Create(pnlMenue);
  pnlSuche := TPanel.Create(Self);
  pnlSearch := TPanel.Create(pnlSuche);
  edtSearch := TEdit.Create(pnlSuche);
  wsbMaterial := TSpeedButton.Create(pnlSuche);
  wsbMaschine := TSpeedButton.Create(pnlSuche);
  wsbLohn := TSpeedButton.Create(pnlSuche);
  cobAdresse := TComboBox.Create(pnlSuche);
  cobProjekt := TComboBox.Create(pnlSuche);
  lblFilter := TLabel.Create(pnlSuche);
  sbx := TScrollBox.Create(Self);
  pnlHeader := TPanel.Create(Self);
  img := TImageControl.Create(pnlHeader);
  lblError := TLabel.Create(pnlHeader);
  pnlMenu := TPanel.Create(pnlHeader);
  lbBenutzer := TLabel.Create(pnlHeader);
  WtDokumentNeuladen := TTimer.Create(pnlHeader);
  camPos := TCamera.Create(pnlHeader);
  sbxNKMaterial := TScrollBox.Create(Self);
  cobNKMatMaterial := TComboBox.Create(sbxNKMaterial);
  lblNKMatMaterial := TLabel.Create(sbxNKMaterial);
  cobNKMatMaterialID := TComboBox.Create(sbxNKMaterial);
  edNKMatDauer := TEdit.Create(sbxNKMaterial);
  wbtNKMatSpeichern := TButton.Create(sbxNKMaterial);
  lblNKMatDauer := TLabel.Create(sbxNKMaterial);
  lblNKMatDatum := TLabel.Create(sbxNKMaterial);
  dtpNKMatDatum := TDateTimePicker.Create(sbxNKMaterial);
  edNKMatFrei := TEdit.Create(sbxNKMaterial);
  lblNKMatFrei := TLabel.Create(sbxNKMaterial);
  edNKMatProjekt := TEdit.Create(sbxNKMaterial);
  lblNKMatProjekt := TLabel.Create(sbxNKMaterial);
  sbNKMaterialBox := TScrollBox.Create(sbxNKMaterial);

  sbxNKMaschine.BeforeLoadDFMValues;
  cobNKMaschMaschine.BeforeLoadDFMValues;
  lblNKMaschMaschine.BeforeLoadDFMValues;
  cobNKMaschMaschineID.BeforeLoadDFMValues;
  dtpNKMaschVon.BeforeLoadDFMValues;
  lblNKMaschVon.BeforeLoadDFMValues;
  dtpNKMaschBis.BeforeLoadDFMValues;
  edNKMaschDauer.BeforeLoadDFMValues;
  wbtNKMaschSpeichern.BeforeLoadDFMValues;
  lblNKMaschDauer.BeforeLoadDFMValues;
  lblNKMaschDatum.BeforeLoadDFMValues;
  dtpNKMaschDatum.BeforeLoadDFMValues;
  edNKMaschProjekt.BeforeLoadDFMValues;
  lblNKMaschProjekt.BeforeLoadDFMValues;
  lblNKMaschBis.BeforeLoadDFMValues;
  sbNkMaschineBox.BeforeLoadDFMValues;
  sbxNKLohn.BeforeLoadDFMValues;
  cobNKLohnMitarbeiter.BeforeLoadDFMValues;
  lblNKLohnMitarbeiter.BeforeLoadDFMValues;
  cobNKLohnMitarbeiterID.BeforeLoadDFMValues;
  dtpNKLohnVon.BeforeLoadDFMValues;
  dtpNKLohnBis.BeforeLoadDFMValues;
  edNKLohnDauer.BeforeLoadDFMValues;
  wbtNKLohnSpeichern.BeforeLoadDFMValues;
  lblNKLohnDauer.BeforeLoadDFMValues;
  dtpNKLohnDatum.BeforeLoadDFMValues;
  cobNKLohnLohnartID.BeforeLoadDFMValues;
  cobNKLohnLohnart.BeforeLoadDFMValues;
  lblNKLohnLohnart.BeforeLoadDFMValues;
  edNKLohnProjekt.BeforeLoadDFMValues;
  lblNKLohnProjekt.BeforeLoadDFMValues;
  lblNKLohnVon.BeforeLoadDFMValues;
  lblNKLohnBis.BeforeLoadDFMValues;
  lblNKLohnDatum.BeforeLoadDFMValues;
  sbNkLohnBox.BeforeLoadDFMValues;
  edNKLohnFahrzeit.BeforeLoadDFMValues;
  edNKLohnRuestzeit.BeforeLoadDFMValues;
  lblNKLohnFahrzeit.BeforeLoadDFMValues;
  lblNKLohnRuestzeit.BeforeLoadDFMValues;
  lblNKLohnPause1.BeforeLoadDFMValues;
  dtpNKLohnPauseVon.BeforeLoadDFMValues;
  dtpNKLohnPauseBis.BeforeLoadDFMValues;
  lblNKLohnPause2.BeforeLoadDFMValues;
  pnlMenue.BeforeLoadDFMValues;
  wsbAdresse.BeforeLoadDFMValues;
  wsbProjekt.BeforeLoadDFMValues;
  wsbLogout.BeforeLoadDFMValues;
  lblKarte.BeforeLoadDFMValues;
  pnlSuche.BeforeLoadDFMValues;
  pnlSearch.BeforeLoadDFMValues;
  edtSearch.BeforeLoadDFMValues;
  wsbMaterial.BeforeLoadDFMValues;
  wsbMaschine.BeforeLoadDFMValues;
  wsbLohn.BeforeLoadDFMValues;
  cobAdresse.BeforeLoadDFMValues;
  cobProjekt.BeforeLoadDFMValues;
  lblFilter.BeforeLoadDFMValues;
  sbx.BeforeLoadDFMValues;
  pnlHeader.BeforeLoadDFMValues;
  img.BeforeLoadDFMValues;
  lblError.BeforeLoadDFMValues;
  pnlMenu.BeforeLoadDFMValues;
  lbBenutzer.BeforeLoadDFMValues;
  WtDokumentNeuladen.BeforeLoadDFMValues;
  camPos.BeforeLoadDFMValues;
  sbxNKMaterial.BeforeLoadDFMValues;
  cobNKMatMaterial.BeforeLoadDFMValues;
  lblNKMatMaterial.BeforeLoadDFMValues;
  cobNKMatMaterialID.BeforeLoadDFMValues;
  edNKMatDauer.BeforeLoadDFMValues;
  wbtNKMatSpeichern.BeforeLoadDFMValues;
  lblNKMatDauer.BeforeLoadDFMValues;
  lblNKMatDatum.BeforeLoadDFMValues;
  dtpNKMatDatum.BeforeLoadDFMValues;
  edNKMatFrei.BeforeLoadDFMValues;
  lblNKMatFrei.BeforeLoadDFMValues;
  edNKMatProjekt.BeforeLoadDFMValues;
  lblNKMatProjekt.BeforeLoadDFMValues;
  sbNKMaterialBox.BeforeLoadDFMValues;
  try
    Name := 'frmMain';
    Left := 1187;
    Height := 570;
    Top := 204;
    Width := 333;
    Caption := 'Main';
    Color := clWhite;
    OnCreate := FormCreate;
    OnDestroy := FormDestroy;
    OnResize := FormResize;
    OnShow := FormShow;
    ParentFont := True;
    sbxNKMaschine.SetParentComponent(Self);
    sbxNKMaschine.Name := 'sbxNKMaschine';
    sbxNKMaschine.Left := 0;
    sbxNKMaschine.Height := 505;
    sbxNKMaschine.Top := 120;
    sbxNKMaschine.Width := 333;
    sbxNKMaschine.AutoScroll := False;
    sbxNKMaschine.BorderStyle := bsNone;
    sbxNKMaschine.Visible := False;
    cobNKMaschMaschine.SetParentComponent(sbxNKMaschine);
    cobNKMaschMaschine.Name := 'cobNKMaschMaschine';
    cobNKMaschMaschine.Left := 120;
    cobNKMaschMaschine.Height := 24;
    cobNKMaschMaschine.Top := 41;
    cobNKMaschMaschine.Width := 200;
    cobNKMaschMaschine.Font.Height := -16;
    cobNKMaschMaschine.Font.Name := 'Tahoma';
    cobNKMaschMaschine.ParentFont := False;
    cobNKMaschMaschine.TabOrder := 1;
    cobNKMaschMaschine.OnChange := cobNKMaschMaschineChange;
    lblNKMaschMaschine.SetParentComponent(sbxNKMaschine);
    lblNKMaschMaschine.Name := 'lblNKMaschMaschine';
    lblNKMaschMaschine.Left := 15;
    lblNKMaschMaschine.Height := 15;
    lblNKMaschMaschine.Top := 45;
    lblNKMaschMaschine.Width := 51;
    lblNKMaschMaschine.Caption := 'Maschine';
    lblNKMaschMaschine.Color := clNone;
    lblNKMaschMaschine.HTMLType := tLABELTAG;
    cobNKMaschMaschineID.SetParentComponent(sbxNKMaschine);
    cobNKMaschMaschineID.Name := 'cobNKMaschMaschineID';
    cobNKMaschMaschineID.Left := 320;
    cobNKMaschMaschineID.Height := 24;
    cobNKMaschMaschineID.Top := 41;
    cobNKMaschMaschineID.Width := 100;
    cobNKMaschMaschineID.Font.Height := -16;
    cobNKMaschMaschineID.Font.Name := 'Tahoma';
    cobNKMaschMaschineID.ParentFont := False;
    cobNKMaschMaschineID.Visible := False;
    dtpNKMaschVon.SetParentComponent(sbxNKMaschine);
    dtpNKMaschVon.Name := 'dtpNKMaschVon';
    dtpNKMaschVon.Left := 120;
    dtpNKMaschVon.Height := 22;
    dtpNKMaschVon.Top := 98;
    dtpNKMaschVon.Width := 200;
    dtpNKMaschVon.BorderStyle := bsSingle;
    dtpNKMaschVon.Color := clWhite;
    dtpNKMaschVon.Date := 43670.664130162;
    dtpNKMaschVon.Font.Height := -16;
    dtpNKMaschVon.Font.Name := 'Tahoma';
    dtpNKMaschVon.Kind := dtkTime;
    dtpNKMaschVon.ParentFont := False;
    dtpNKMaschVon.TabOrder := 3;
    dtpNKMaschVon.OnExit := dtpNKMaschVonExit;
    lblNKMaschVon.SetParentComponent(sbxNKMaschine);
    lblNKMaschVon.Name := 'lblNKMaschVon';
    lblNKMaschVon.Left := 15;
    lblNKMaschVon.Height := 15;
    lblNKMaschVon.Top := 101;
    lblNKMaschVon.Width := 20;
    lblNKMaschVon.Caption := 'Von';
    lblNKMaschVon.Color := clNone;
    lblNKMaschVon.HTMLType := tLABELTAG;
    dtpNKMaschBis.SetParentComponent(sbxNKMaschine);
    dtpNKMaschBis.Name := 'dtpNKMaschBis';
    dtpNKMaschBis.Left := 120;
    dtpNKMaschBis.Height := 22;
    dtpNKMaschBis.Top := 126;
    dtpNKMaschBis.Width := 200;
    dtpNKMaschBis.BorderStyle := bsSingle;
    dtpNKMaschBis.Color := clWhite;
    dtpNKMaschBis.Date := 43670.664130162;
    dtpNKMaschBis.Font.Height := -16;
    dtpNKMaschBis.Font.Name := 'Tahoma';
    dtpNKMaschBis.Kind := dtkTime;
    dtpNKMaschBis.ParentFont := False;
    dtpNKMaschBis.TabOrder := 4;
    dtpNKMaschBis.OnExit := dtpNKMaschBisExit;
    edNKMaschDauer.SetParentComponent(sbxNKMaschine);
    edNKMaschDauer.Name := 'edNKMaschDauer';
    edNKMaschDauer.Left := 120;
    edNKMaschDauer.Height := 23;
    edNKMaschDauer.Top := 153;
    edNKMaschDauer.Width := 200;
    edNKMaschDauer.Font.Height := -16;
    edNKMaschDauer.Font.Name := 'Tahoma';
    edNKMaschDauer.ParentFont := False;
    edNKMaschDauer.TabOrder := 5;
    wbtNKMaschSpeichern.SetParentComponent(sbxNKMaschine);
    wbtNKMaschSpeichern.Name := 'wbtNKMaschSpeichern';
    wbtNKMaschSpeichern.Left := 120;
    wbtNKMaschSpeichern.Height := 25;
    wbtNKMaschSpeichern.Top := 185;
    wbtNKMaschSpeichern.Width := 96;
    wbtNKMaschSpeichern.Caption := 'speichern';
    wbtNKMaschSpeichern.TabOrder := 6;
    wbtNKMaschSpeichern.OnClick := wbtNKMaschSpeichernClick;
    lblNKMaschDauer.SetParentComponent(sbxNKMaschine);
    lblNKMaschDauer.Name := 'lblNKMaschDauer';
    lblNKMaschDauer.Left := 15;
    lblNKMaschDauer.Height := 15;
    lblNKMaschDauer.Top := 157;
    lblNKMaschDauer.Width := 73;
    lblNKMaschDauer.Caption := 'Dauer/Menge';
    lblNKMaschDauer.Color := clNone;
    lblNKMaschDauer.HTMLType := tLABELTAG;
    lblNKMaschDatum.SetParentComponent(sbxNKMaschine);
    lblNKMaschDatum.Name := 'lblNKMaschDatum';
    lblNKMaschDatum.Left := 15;
    lblNKMaschDatum.Height := 15;
    lblNKMaschDatum.Top := 73;
    lblNKMaschDatum.Width := 36;
    lblNKMaschDatum.Caption := 'Datum';
    lblNKMaschDatum.Color := clNone;
    lblNKMaschDatum.HTMLType := tLABELTAG;
    dtpNKMaschDatum.SetParentComponent(sbxNKMaschine);
    dtpNKMaschDatum.Name := 'dtpNKMaschDatum';
    dtpNKMaschDatum.Left := 120;
    dtpNKMaschDatum.Height := 22;
    dtpNKMaschDatum.Top := 70;
    dtpNKMaschDatum.Width := 200;
    dtpNKMaschDatum.BorderStyle := bsSingle;
    dtpNKMaschDatum.Color := clWhite;
    dtpNKMaschDatum.Date := 43670.664130162;
    dtpNKMaschDatum.Font.Height := -16;
    dtpNKMaschDatum.Font.Name := 'Tahoma';
    dtpNKMaschDatum.ParentFont := False;
    dtpNKMaschDatum.TabOrder := 2;
    edNKMaschProjekt.SetParentComponent(sbxNKMaschine);
    edNKMaschProjekt.Name := 'edNKMaschProjekt';
    edNKMaschProjekt.Left := 120;
    edNKMaschProjekt.Height := 23;
    edNKMaschProjekt.Top := 12;
    edNKMaschProjekt.Width := 200;
    edNKMaschProjekt.Font.Height := -16;
    edNKMaschProjekt.Font.Name := 'Tahoma';
    edNKMaschProjekt.ParentFont := False;
    edNKMaschProjekt.ReadOnly := True;
    lblNKMaschProjekt.SetParentComponent(sbxNKMaschine);
    lblNKMaschProjekt.Name := 'lblNKMaschProjekt';
    lblNKMaschProjekt.Left := 16;
    lblNKMaschProjekt.Height := 15;
    lblNKMaschProjekt.Top := 16;
    lblNKMaschProjekt.Width := 37;
    lblNKMaschProjekt.Caption := 'Projekt';
    lblNKMaschProjekt.Color := clNone;
    lblNKMaschProjekt.HTMLType := tLABELTAG;
    lblNKMaschBis.SetParentComponent(sbxNKMaschine);
    lblNKMaschBis.Name := 'lblNKMaschBis';
    lblNKMaschBis.Left := 15;
    lblNKMaschBis.Height := 15;
    lblNKMaschBis.Top := 128;
    lblNKMaschBis.Width := 15;
    lblNKMaschBis.Caption := 'Bis';
    lblNKMaschBis.Color := clNone;
    lblNKMaschBis.HTMLType := tLABELTAG;
    sbNkMaschineBox.SetParentComponent(sbxNKMaschine);
    sbNkMaschineBox.Name := 'sbNkMaschineBox';
    sbNkMaschineBox.Left := 16;
    sbNkMaschineBox.Height := 220;
    sbNkMaschineBox.Top := 224;
    sbNkMaschineBox.Width := 299;
    sbNkMaschineBox.AutoScroll := False;
    sbNkMaschineBox.BorderStyle := bsSingle;
    sbxNKLohn.SetParentComponent(Self);
    sbxNKLohn.Name := 'sbxNKLohn';
    sbxNKLohn.Left := 0;
    sbxNKLohn.Height := 505;
    sbxNKLohn.Top := 120;
    sbxNKLohn.Width := 333;
    sbxNKLohn.AutoScroll := False;
    sbxNKLohn.BorderStyle := bsNone;
    sbxNKLohn.Visible := False;
    cobNKLohnMitarbeiter.SetParentComponent(sbxNKLohn);
    cobNKLohnMitarbeiter.Name := 'cobNKLohnMitarbeiter';
    cobNKLohnMitarbeiter.Left := 120;
    cobNKLohnMitarbeiter.Height := 24;
    cobNKLohnMitarbeiter.Top := 40;
    cobNKLohnMitarbeiter.Width := 200;
    cobNKLohnMitarbeiter.Font.Height := -16;
    cobNKLohnMitarbeiter.Font.Name := 'Tahoma';
    cobNKLohnMitarbeiter.ParentFont := False;
    cobNKLohnMitarbeiter.TabOrder := 2;
    cobNKLohnMitarbeiter.OnChange := cobNKLohnMitarbeiterChange;
    lblNKLohnMitarbeiter.SetParentComponent(sbxNKLohn);
    lblNKLohnMitarbeiter.Name := 'lblNKLohnMitarbeiter';
    lblNKLohnMitarbeiter.Left := 15;
    lblNKLohnMitarbeiter.Height := 15;
    lblNKLohnMitarbeiter.Top := 44;
    lblNKLohnMitarbeiter.Width := 58;
    lblNKLohnMitarbeiter.Caption := 'Mitarbeiter';
    lblNKLohnMitarbeiter.Color := clNone;
    lblNKLohnMitarbeiter.HTMLType := tLABELTAG;
    cobNKLohnMitarbeiterID.SetParentComponent(sbxNKLohn);
    cobNKLohnMitarbeiterID.Name := 'cobNKLohnMitarbeiterID';
    cobNKLohnMitarbeiterID.Left := 320;
    cobNKLohnMitarbeiterID.Height := 24;
    cobNKLohnMitarbeiterID.Top := 40;
    cobNKLohnMitarbeiterID.Width := 100;
    cobNKLohnMitarbeiterID.Font.Height := -16;
    cobNKLohnMitarbeiterID.Font.Name := 'Tahoma';
    cobNKLohnMitarbeiterID.ParentFont := False;
    cobNKLohnMitarbeiterID.Visible := False;
    dtpNKLohnVon.SetParentComponent(sbxNKLohn);
    dtpNKLohnVon.Name := 'dtpNKLohnVon';
    dtpNKLohnVon.Left := 120;
    dtpNKLohnVon.Height := 22;
    dtpNKLohnVon.Top := 125;
    dtpNKLohnVon.Width := 200;
    dtpNKLohnVon.BorderStyle := bsSingle;
    dtpNKLohnVon.Color := clWhite;
    dtpNKLohnVon.Date := 43670.664130162;
    dtpNKLohnVon.Font.Height := -16;
    dtpNKLohnVon.Font.Name := 'Tahoma';
    dtpNKLohnVon.Kind := dtkTime;
    dtpNKLohnVon.ParentFont := False;
    dtpNKLohnVon.TabOrder := 5;
    dtpNKLohnVon.OnExit := dtpNKLohnVonExit;
    dtpNKLohnBis.SetParentComponent(sbxNKLohn);
    dtpNKLohnBis.Name := 'dtpNKLohnBis';
    dtpNKLohnBis.Left := 120;
    dtpNKLohnBis.Height := 22;
    dtpNKLohnBis.Top := 153;
    dtpNKLohnBis.Width := 200;
    dtpNKLohnBis.BorderStyle := bsSingle;
    dtpNKLohnBis.Color := clWhite;
    dtpNKLohnBis.Date := 43670.664130162;
    dtpNKLohnBis.Font.Height := -16;
    dtpNKLohnBis.Font.Name := 'Tahoma';
    dtpNKLohnBis.Kind := dtkTime;
    dtpNKLohnBis.ParentFont := False;
    dtpNKLohnBis.TabOrder := 6;
    dtpNKLohnBis.OnExit := dtpNKLohnBisExit;
    edNKLohnDauer.SetParentComponent(sbxNKLohn);
    edNKLohnDauer.Name := 'edNKLohnDauer';
    edNKLohnDauer.Left := 120;
    edNKLohnDauer.Height := 23;
    edNKLohnDauer.Top := 267;
    edNKLohnDauer.Width := 200;
    edNKLohnDauer.Font.Height := -16;
    edNKLohnDauer.Font.Name := 'Tahoma';
    edNKLohnDauer.ParentFont := False;
    edNKLohnDauer.TabOrder := 7;
    wbtNKLohnSpeichern.SetParentComponent(sbxNKLohn);
    wbtNKLohnSpeichern.Name := 'wbtNKLohnSpeichern';
    wbtNKLohnSpeichern.Left := 120;
    wbtNKLohnSpeichern.Height := 25;
    wbtNKLohnSpeichern.Top := 296;
    wbtNKLohnSpeichern.Width := 96;
    wbtNKLohnSpeichern.Caption := 'speichern';
    wbtNKLohnSpeichern.TabOrder := 8;
    wbtNKLohnSpeichern.OnClick := wbtNKLohnSpeichernClick;
    lblNKLohnDauer.SetParentComponent(sbxNKLohn);
    lblNKLohnDauer.Name := 'lblNKLohnDauer';
    lblNKLohnDauer.Left := 15;
    lblNKLohnDauer.Height := 15;
    lblNKLohnDauer.Top := 272;
    lblNKLohnDauer.Width := 31;
    lblNKLohnDauer.Caption := 'Dauer';
    lblNKLohnDauer.Color := clNone;
    lblNKLohnDauer.HTMLType := tLABELTAG;
    dtpNKLohnDatum.SetParentComponent(sbxNKLohn);
    dtpNKLohnDatum.Name := 'dtpNKLohnDatum';
    dtpNKLohnDatum.Left := 120;
    dtpNKLohnDatum.Height := 22;
    dtpNKLohnDatum.Top := 97;
    dtpNKLohnDatum.Width := 200;
    dtpNKLohnDatum.BorderStyle := bsSingle;
    dtpNKLohnDatum.Color := clWhite;
    dtpNKLohnDatum.Date := 43670.664130162;
    dtpNKLohnDatum.Font.Height := -16;
    dtpNKLohnDatum.Font.Name := 'Tahoma';
    dtpNKLohnDatum.ParentFont := False;
    dtpNKLohnDatum.TabOrder := 4;
    cobNKLohnLohnartID.SetParentComponent(sbxNKLohn);
    cobNKLohnLohnartID.Name := 'cobNKLohnLohnartID';
    cobNKLohnLohnartID.Left := 320;
    cobNKLohnLohnartID.Height := 24;
    cobNKLohnLohnartID.Top := 68;
    cobNKLohnLohnartID.Width := 100;
    cobNKLohnLohnartID.Font.Height := -16;
    cobNKLohnLohnartID.Font.Name := 'Tahoma';
    cobNKLohnLohnartID.ParentFont := False;
    cobNKLohnLohnartID.Visible := False;
    cobNKLohnLohnart.SetParentComponent(sbxNKLohn);
    cobNKLohnLohnart.Name := 'cobNKLohnLohnart';
    cobNKLohnLohnart.Left := 120;
    cobNKLohnLohnart.Height := 24;
    cobNKLohnLohnart.Top := 68;
    cobNKLohnLohnart.Width := 200;
    cobNKLohnLohnart.Font.Height := -16;
    cobNKLohnLohnart.Font.Name := 'Tahoma';
    cobNKLohnLohnart.ParentFont := False;
    cobNKLohnLohnart.TabOrder := 3;
    cobNKLohnLohnart.OnChange := cobNKLohnLohnartChange;
    lblNKLohnLohnart.SetParentComponent(sbxNKLohn);
    lblNKLohnLohnart.Name := 'lblNKLohnLohnart';
    lblNKLohnLohnart.Left := 15;
    lblNKLohnLohnart.Height := 15;
    lblNKLohnLohnart.Top := 73;
    lblNKLohnLohnart.Width := 41;
    lblNKLohnLohnart.Caption := 'Lohnart';
    lblNKLohnLohnart.Color := clNone;
    lblNKLohnLohnart.HTMLType := tLABELTAG;
    edNKLohnProjekt.SetParentComponent(sbxNKLohn);
    edNKLohnProjekt.Name := 'edNKLohnProjekt';
    edNKLohnProjekt.Left := 120;
    edNKLohnProjekt.Height := 23;
    edNKLohnProjekt.Top := 12;
    edNKLohnProjekt.Width := 200;
    edNKLohnProjekt.Font.Height := -16;
    edNKLohnProjekt.Font.Name := 'Tahoma';
    edNKLohnProjekt.ParentFont := False;
    edNKLohnProjekt.ReadOnly := True;
    edNKLohnProjekt.TabOrder := 1;
    lblNKLohnProjekt.SetParentComponent(sbxNKLohn);
    lblNKLohnProjekt.Name := 'lblNKLohnProjekt';
    lblNKLohnProjekt.Left := 16;
    lblNKLohnProjekt.Height := 15;
    lblNKLohnProjekt.Top := 16;
    lblNKLohnProjekt.Width := 37;
    lblNKLohnProjekt.Caption := 'Projekt';
    lblNKLohnProjekt.Color := clNone;
    lblNKLohnProjekt.HTMLType := tLABELTAG;
    lblNKLohnVon.SetParentComponent(sbxNKLohn);
    lblNKLohnVon.Name := 'lblNKLohnVon';
    lblNKLohnVon.Left := 15;
    lblNKLohnVon.Height := 15;
    lblNKLohnVon.Top := 128;
    lblNKLohnVon.Width := 20;
    lblNKLohnVon.Caption := 'Von';
    lblNKLohnVon.Color := clNone;
    lblNKLohnVon.HTMLType := tLABELTAG;
    lblNKLohnBis.SetParentComponent(sbxNKLohn);
    lblNKLohnBis.Name := 'lblNKLohnBis';
    lblNKLohnBis.Left := 15;
    lblNKLohnBis.Height := 15;
    lblNKLohnBis.Top := 156;
    lblNKLohnBis.Width := 15;
    lblNKLohnBis.Caption := 'Bis';
    lblNKLohnBis.Color := clNone;
    lblNKLohnBis.HTMLType := tLABELTAG;
    lblNKLohnDatum.SetParentComponent(sbxNKLohn);
    lblNKLohnDatum.Name := 'lblNKLohnDatum';
    lblNKLohnDatum.Left := 15;
    lblNKLohnDatum.Height := 15;
    lblNKLohnDatum.Top := 100;
    lblNKLohnDatum.Width := 36;
    lblNKLohnDatum.Caption := 'Datum';
    lblNKLohnDatum.Color := clNone;
    lblNKLohnDatum.HTMLType := tLABELTAG;
    sbNkLohnBox.SetParentComponent(sbxNKLohn);
    sbNkLohnBox.Name := 'sbNkLohnBox';
    sbNkLohnBox.Left := 16;
    sbNkLohnBox.Height := 104;
    sbNkLohnBox.Top := 336;
    sbNkLohnBox.Width := 299;
    sbNkLohnBox.AutoScroll := False;
    sbNkLohnBox.BorderStyle := bsSingle;
    edNKLohnFahrzeit.SetParentComponent(sbxNKLohn);
    edNKLohnFahrzeit.Name := 'edNKLohnFahrzeit';
    edNKLohnFahrzeit.Left := 120;
    edNKLohnFahrzeit.Height := 23;
    edNKLohnFahrzeit.Top := 210;
    edNKLohnFahrzeit.Width := 200;
    edNKLohnRuestzeit.SetParentComponent(sbxNKLohn);
    edNKLohnRuestzeit.Name := 'edNKLohnRuestzeit';
    edNKLohnRuestzeit.Left := 120;
    edNKLohnRuestzeit.Height := 23;
    edNKLohnRuestzeit.Top := 238;
    edNKLohnRuestzeit.Width := 200;
    lblNKLohnFahrzeit.SetParentComponent(sbxNKLohn);
    lblNKLohnFahrzeit.Name := 'lblNKLohnFahrzeit';
    lblNKLohnFahrzeit.Left := 16;
    lblNKLohnFahrzeit.Height := 15;
    lblNKLohnFahrzeit.Top := 215;
    lblNKLohnFahrzeit.Width := 70;
    lblNKLohnFahrzeit.Caption := 'Fahrzeit(min)';
    lblNKLohnFahrzeit.Color := clNone;
    lblNKLohnFahrzeit.HTMLType := tLABELTAG;
    lblNKLohnRuestzeit.SetParentComponent(sbxNKLohn);
    lblNKLohnRuestzeit.Name := 'lblNKLohnRuestzeit';
    lblNKLohnRuestzeit.Left := 15;
    lblNKLohnRuestzeit.Height := 15;
    lblNKLohnRuestzeit.Top := 243;
    lblNKLohnRuestzeit.Width := 70;
    lblNKLohnRuestzeit.Caption := 'Rüstzeit(min)';
    lblNKLohnRuestzeit.Color := clNone;
    lblNKLohnRuestzeit.HTMLType := tLABELTAG;
    lblNKLohnPause1.SetParentComponent(sbxNKLohn);
    lblNKLohnPause1.Name := 'lblNKLohnPause1';
    lblNKLohnPause1.Left := 16;
    lblNKLohnPause1.Height := 15;
    lblNKLohnPause1.Top := 185;
    lblNKLohnPause1.Width := 54;
    lblNKLohnPause1.Caption := 'Pause von';
    lblNKLohnPause1.Color := clNone;
    lblNKLohnPause1.HTMLType := tLABELTAG;
    dtpNKLohnPauseVon.SetParentComponent(sbxNKLohn);
    dtpNKLohnPauseVon.Name := 'dtpNKLohnPauseVon';
    dtpNKLohnPauseVon.Left := 88;
    dtpNKLohnPauseVon.Height := 22;
    dtpNKLohnPauseVon.Top := 181;
    dtpNKLohnPauseVon.Width := 105;
    dtpNKLohnPauseVon.BorderStyle := bsSingle;
    dtpNKLohnPauseVon.Color := clWhite;
    dtpNKLohnPauseVon.Date := 43670.6638888889;
    dtpNKLohnPauseVon.Font.Height := -16;
    dtpNKLohnPauseVon.Font.Name := 'Tahoma';
    dtpNKLohnPauseVon.Kind := dtkTime;
    dtpNKLohnPauseVon.ParentFont := False;
    dtpNKLohnPauseVon.TabOrder := 6;
    dtpNKLohnPauseVon.OnExit := dtpNKLohnPauseVonExit;
    dtpNKLohnPauseBis.SetParentComponent(sbxNKLohn);
    dtpNKLohnPauseBis.Name := 'dtpNKLohnPauseBis';
    dtpNKLohnPauseBis.Left := 216;
    dtpNKLohnPauseBis.Height := 22;
    dtpNKLohnPauseBis.Top := 181;
    dtpNKLohnPauseBis.Width := 104;
    dtpNKLohnPauseBis.BorderStyle := bsSingle;
    dtpNKLohnPauseBis.Color := clWhite;
    dtpNKLohnPauseBis.Date := 43670.6638888889;
    dtpNKLohnPauseBis.Font.Height := -16;
    dtpNKLohnPauseBis.Font.Name := 'Tahoma';
    dtpNKLohnPauseBis.Kind := dtkTime;
    dtpNKLohnPauseBis.ParentFont := False;
    dtpNKLohnPauseBis.TabOrder := 6;
    dtpNKLohnPauseBis.OnExit := dtpNKLohnPauseBisExit;
    lblNKLohnPause2.SetParentComponent(sbxNKLohn);
    lblNKLohnPause2.Name := 'lblNKLohnPause2';
    lblNKLohnPause2.Left := 198;
    lblNKLohnPause2.Height := 15;
    lblNKLohnPause2.Top := 185;
    lblNKLohnPause2.Width := 15;
    lblNKLohnPause2.Caption := 'Bis';
    lblNKLohnPause2.Color := clNone;
    lblNKLohnPause2.HTMLType := tLABELTAG;
    pnlMenue.SetParentComponent(Self);
    pnlMenue.Name := 'pnlMenue';
    pnlMenue.Left := 0;
    pnlMenue.Height := 35;
    pnlMenue.Top := 45;
    pnlMenue.Width := 333;
    pnlMenue.BorderColor := clSilver;
    pnlMenue.BorderStyle := bsNone;
    pnlMenue.Color := 16119285;
    pnlMenue.ElementFont := efCSS;
    wsbAdresse.SetParentComponent(pnlMenue);
    wsbAdresse.Name := 'wsbAdresse';
    wsbAdresse.Left := 5;
    wsbAdresse.Height := 31;
    wsbAdresse.Top := 2;
    wsbAdresse.Width := 84;
    wsbAdresse.Caption := 'Adresse';
    wsbAdresse.Flat := True;
    wsbAdresse.OnClick := wsbAdresseClick;
    wsbProjekt.SetParentComponent(pnlMenue);
    wsbProjekt.Name := 'wsbProjekt';
    wsbProjekt.Left := 88;
    wsbProjekt.Height := 31;
    wsbProjekt.Top := 2;
    wsbProjekt.Width := 80;
    wsbProjekt.Caption := 'Projekt';
    wsbProjekt.Flat := True;
    wsbProjekt.OnClick := wsbProjektClick;
    wsbLogout.SetParentComponent(pnlMenue);
    wsbLogout.Name := 'wsbLogout';
    wsbLogout.Left := 258;
    wsbLogout.Height := 31;
    wsbLogout.Top := 2;
    wsbLogout.Width := 72;
    wsbLogout.Caption := 'Logout';
    wsbLogout.Flat := True;
    wsbLogout.OnClick := wsbLogoutClick;
    lblKarte.SetParentComponent(pnlMenue);
    lblKarte.Name := 'lblKarte';
    lblKarte.Left := 176;
    lblKarte.Height := 15;
    lblKarte.Top := 8;
    lblKarte.Width := 27;
    lblKarte.Caption := 'Karte';
    lblKarte.Color := clNone;
    lblKarte.HTMLType := tLABELTAG;
    pnlSuche.SetParentComponent(Self);
    pnlSuche.Name := 'pnlSuche';
    pnlSuche.Left := 0;
    pnlSuche.Height := 72;
    pnlSuche.Top := 80;
    pnlSuche.Width := 333;
    pnlSuche.BorderColor := clSilver;
    pnlSuche.BorderStyle := bsNone;
    pnlSuche.Color := clWhite;
    pnlSearch.SetParentComponent(pnlSuche);
    pnlSearch.Name := 'pnlSearch';
    pnlSearch.Left := 280;
    pnlSearch.Height := 28;
    pnlSearch.Top := 5;
    pnlSearch.Width := 65;
    pnlSearch.BorderColor := clSilver;
    pnlSearch.BorderStyle := bsSingle;
    pnlSearch.Color := 15263976;
    pnlSearch.ParentFont := False;
    pnlSearch.OnClick := pnlSearchClick;
    edtSearch.SetParentComponent(pnlSuche);
    edtSearch.Name := 'edtSearch';
    edtSearch.Left := 10;
    edtSearch.Height := 28;
    edtSearch.Top := 5;
    edtSearch.Width := 273;
    edtSearch.Font.Name := 'Calibri';
    edtSearch.ParentFont := False;
    edtSearch.OnKeyDown := edtSearchKeyDown;
    wsbMaterial.SetParentComponent(pnlSuche);
    wsbMaterial.Name := 'wsbMaterial';
    wsbMaterial.Left := 182;
    wsbMaterial.Height := 25;
    wsbMaterial.Top := 5;
    wsbMaterial.Width := 80;
    wsbMaterial.Caption := 'Material';
    wsbMaterial.Flat := True;
    wsbMaterial.OnClick := wsbMaterialClick;
    wsbMaschine.SetParentComponent(pnlSuche);
    wsbMaschine.Name := 'wsbMaschine';
    wsbMaschine.Left := 97;
    wsbMaschine.Height := 25;
    wsbMaschine.Top := 5;
    wsbMaschine.Width := 80;
    wsbMaschine.Caption := 'Maschine';
    wsbMaschine.Flat := True;
    wsbMaschine.OnClick := wsbMaschineClick;
    wsbLohn.SetParentComponent(pnlSuche);
    wsbLohn.Name := 'wsbLohn';
    wsbLohn.Left := 12;
    wsbLohn.Height := 25;
    wsbLohn.Top := 5;
    wsbLohn.Width := 80;
    wsbLohn.Caption := 'Lohn';
    wsbLohn.Flat := True;
    wsbLohn.OnClick := wsbLohnClick;
    cobAdresse.SetParentComponent(pnlSuche);
    cobAdresse.Name := 'cobAdresse';
    cobAdresse.Left := 76;
    cobAdresse.Height := 30;
    cobAdresse.Top := 40;
    cobAdresse.Width := 240;
    cobAdresse.Visible := False;
    cobProjekt.SetParentComponent(pnlSuche);
    cobProjekt.Name := 'cobProjekt';
    cobProjekt.Left := 76;
    cobProjekt.Height := 28;
    cobProjekt.Top := 40;
    cobProjekt.Width := 240;
    cobProjekt.Visible := False;
    lblFilter.SetParentComponent(pnlSuche);
    lblFilter.Name := 'lblFilter';
    lblFilter.Left := 16;
    lblFilter.Height := 15;
    lblFilter.Top := 46;
    lblFilter.Width := 29;
    lblFilter.Caption := 'Filter:';
    lblFilter.Color := clNone;
    lblFilter.HTMLType := tLABELTAG;
    sbx.SetParentComponent(Self);
    sbx.Name := 'sbx';
    sbx.Left := 0;
    sbx.Height := 420;
    sbx.Top := 160;
    sbx.Width := 0;
    sbx.AutoScroll := False;
    sbx.BorderStyle := bsNone;
    pnlHeader.SetParentComponent(Self);
    pnlHeader.Name := 'pnlHeader';
    pnlHeader.Left := 0;
    pnlHeader.Height := 45;
    pnlHeader.Top := 0;
    pnlHeader.Width := 333;
    pnlHeader.BorderColor := clSilver;
    pnlHeader.BorderStyle := bsNone;
    pnlHeader.Color := 16119285;
    img.SetParentComponent(pnlHeader);
    img.Name := 'img';
    img.Left := 5;
    img.Height := 33;
    img.Top := 5;
    img.Width := 98;
    img.AutoSize := False;
    img.OnClick := imgClick;
    img.Picture.LoadFromFile('fmain.pnlHeader.img.Picture.jpg');
    lblError.SetParentComponent(pnlHeader);
    lblError.Name := 'lblError';
    lblError.Left := 110;
    lblError.Height := 1;
    lblError.Top := 0;
    lblError.Width := 1;
    lblError.Color := clNone;
    lblError.Font.Color := clRed;
    lblError.Font.Height := -12;
    lblError.Font.Style := [fsBold];
    lblError.HTMLType := tLABELTAG;
    lblError.ParentFont := False;
    pnlMenu.SetParentComponent(pnlHeader);
    pnlMenu.Name := 'pnlMenu';
    pnlMenu.Left := 280;
    pnlMenu.Height := 36;
    pnlMenu.Top := 5;
    pnlMenu.Width := 35;
    pnlMenu.BorderColor := clSilver;
    pnlMenu.BorderStyle := bsSingle;
    pnlMenu.Color := clWhite;
    pnlMenu.Visible := False;
    pnlMenu.OnClick := pnlMenuClick;
    lbBenutzer.SetParentComponent(pnlHeader);
    lbBenutzer.Name := 'lbBenutzer';
    lbBenutzer.Left := 120;
    lbBenutzer.Height := 23;
    lbBenutzer.Top := 15;
    lbBenutzer.Width := 100;
    lbBenutzer.AutoSize := False;
    lbBenutzer.Color := clNone;
    lbBenutzer.HTMLType := tLABELTAG;
    WtDokumentNeuladen.SetParentComponent(pnlHeader);
    WtDokumentNeuladen.Name := 'WtDokumentNeuladen';
    WtDokumentNeuladen.Left := 240;
    WtDokumentNeuladen.Height := 24;
    WtDokumentNeuladen.Top := 14;
    WtDokumentNeuladen.Width := 24;
    WtDokumentNeuladen.Enabled := False;
    WtDokumentNeuladen.Interval := 10000;
    WtDokumentNeuladen.OnTimer := dokumentNeuladen;
    camPos.SetParentComponent(pnlHeader);
    camPos.Name := 'camPos';
    camPos.Left := 160;
    camPos.Height := 300;
    camPos.Top := 160;
    camPos.Width := 400;
    camPos.CameraType := ctRear;
    camPos.Visible := False;
    sbxNKMaterial.SetParentComponent(Self);
    sbxNKMaterial.Name := 'sbxNKMaterial';
    sbxNKMaterial.Left := 0;
    sbxNKMaterial.Height := 505;
    sbxNKMaterial.Top := 120;
    sbxNKMaterial.Width := 333;
    sbxNKMaterial.AutoScroll := False;
    sbxNKMaterial.BorderStyle := bsNone;
    sbxNKMaterial.Visible := False;
    cobNKMatMaterial.SetParentComponent(sbxNKMaterial);
    cobNKMatMaterial.Name := 'cobNKMatMaterial';
    cobNKMatMaterial.Left := 120;
    cobNKMatMaterial.Height := 24;
    cobNKMatMaterial.Top := 40;
    cobNKMatMaterial.Width := 200;
    cobNKMatMaterial.Font.Height := -16;
    cobNKMatMaterial.Font.Name := 'Tahoma';
    cobNKMatMaterial.ParentFont := False;
    cobNKMatMaterial.TabOrder := 1;
    cobNKMatMaterial.OnChange := cobNKMatMaterialChange;
    lblNKMatMaterial.SetParentComponent(sbxNKMaterial);
    lblNKMatMaterial.Name := 'lblNKMatMaterial';
    lblNKMatMaterial.Left := 15;
    lblNKMatMaterial.Height := 15;
    lblNKMatMaterial.Top := 44;
    lblNKMatMaterial.Width := 43;
    lblNKMatMaterial.Caption := 'Material';
    lblNKMatMaterial.Color := clNone;
    lblNKMatMaterial.HTMLType := tLABELTAG;
    cobNKMatMaterialID.SetParentComponent(sbxNKMaterial);
    cobNKMatMaterialID.Name := 'cobNKMatMaterialID';
    cobNKMatMaterialID.Left := 320;
    cobNKMatMaterialID.Height := 24;
    cobNKMatMaterialID.Top := 40;
    cobNKMatMaterialID.Width := 100;
    cobNKMatMaterialID.Font.Height := -16;
    cobNKMatMaterialID.Font.Name := 'Tahoma';
    cobNKMatMaterialID.ParentFont := False;
    cobNKMatMaterialID.Visible := False;
    edNKMatDauer.SetParentComponent(sbxNKMaterial);
    edNKMatDauer.Name := 'edNKMatDauer';
    edNKMatDauer.Left := 120;
    edNKMatDauer.Height := 23;
    edNKMatDauer.Top := 124;
    edNKMatDauer.Width := 200;
    edNKMatDauer.Font.Height := -16;
    edNKMatDauer.Font.Name := 'Tahoma';
    edNKMatDauer.ParentFont := False;
    edNKMatDauer.TabOrder := 4;
    wbtNKMatSpeichern.SetParentComponent(sbxNKMaterial);
    wbtNKMatSpeichern.Name := 'wbtNKMatSpeichern';
    wbtNKMatSpeichern.Left := 120;
    wbtNKMatSpeichern.Height := 25;
    wbtNKMatSpeichern.Top := 156;
    wbtNKMatSpeichern.Width := 96;
    wbtNKMatSpeichern.Caption := 'speichern';
    wbtNKMatSpeichern.TabOrder := 5;
    wbtNKMatSpeichern.OnClick := wbtNKMatSpeichernClick;
    lblNKMatDauer.SetParentComponent(sbxNKMaterial);
    lblNKMatDauer.Name := 'lblNKMatDauer';
    lblNKMatDauer.Left := 15;
    lblNKMatDauer.Height := 15;
    lblNKMatDauer.Top := 128;
    lblNKMatDauer.Width := 73;
    lblNKMatDauer.Caption := 'Dauer/Menge';
    lblNKMatDauer.Color := clNone;
    lblNKMatDauer.HTMLType := tLABELTAG;
    lblNKMatDatum.SetParentComponent(sbxNKMaterial);
    lblNKMatDatum.Name := 'lblNKMatDatum';
    lblNKMatDatum.Left := 15;
    lblNKMatDatum.Height := 15;
    lblNKMatDatum.Top := 99;
    lblNKMatDatum.Width := 36;
    lblNKMatDatum.Caption := 'Datum';
    lblNKMatDatum.Color := clNone;
    lblNKMatDatum.HTMLType := tLABELTAG;
    dtpNKMatDatum.SetParentComponent(sbxNKMaterial);
    dtpNKMatDatum.Name := 'dtpNKMatDatum';
    dtpNKMatDatum.Left := 120;
    dtpNKMatDatum.Height := 22;
    dtpNKMatDatum.Top := 96;
    dtpNKMatDatum.Width := 200;
    dtpNKMatDatum.BorderStyle := bsSingle;
    dtpNKMatDatum.Color := clWhite;
    dtpNKMatDatum.Date := 43670.664130162;
    dtpNKMatDatum.Font.Height := -16;
    dtpNKMatDatum.Font.Name := 'Tahoma';
    dtpNKMatDatum.ParentFont := False;
    dtpNKMatDatum.TabOrder := 3;
    edNKMatFrei.SetParentComponent(sbxNKMaterial);
    edNKMatFrei.Name := 'edNKMatFrei';
    edNKMatFrei.Left := 120;
    edNKMatFrei.Height := 23;
    edNKMatFrei.Top := 68;
    edNKMatFrei.Width := 200;
    edNKMatFrei.Font.Height := -16;
    edNKMatFrei.Font.Name := 'Tahoma';
    edNKMatFrei.ParentFont := False;
    edNKMatFrei.TabOrder := 2;
    lblNKMatFrei.SetParentComponent(sbxNKMaterial);
    lblNKMatFrei.Name := 'lblNKMatFrei';
    lblNKMatFrei.Left := 15;
    lblNKMatFrei.Height := 15;
    lblNKMatFrei.Top := 72;
    lblNKMatFrei.Width := 81;
    lblNKMatFrei.Caption := 'freie Textierung';
    lblNKMatFrei.Color := clNone;
    lblNKMatFrei.HTMLType := tLABELTAG;
    edNKMatProjekt.SetParentComponent(sbxNKMaterial);
    edNKMatProjekt.Name := 'edNKMatProjekt';
    edNKMatProjekt.Left := 120;
    edNKMatProjekt.Height := 23;
    edNKMatProjekt.Top := 12;
    edNKMatProjekt.Width := 200;
    edNKMatProjekt.Font.Height := -16;
    edNKMatProjekt.Font.Name := 'Tahoma';
    edNKMatProjekt.ParentFont := False;
    edNKMatProjekt.ReadOnly := True;
    lblNKMatProjekt.SetParentComponent(sbxNKMaterial);
    lblNKMatProjekt.Name := 'lblNKMatProjekt';
    lblNKMatProjekt.Left := 16;
    lblNKMatProjekt.Height := 15;
    lblNKMatProjekt.Top := 16;
    lblNKMatProjekt.Width := 37;
    lblNKMatProjekt.Caption := 'Projekt';
    lblNKMatProjekt.Color := clNone;
    lblNKMatProjekt.HTMLType := tLABELTAG;
    sbNKMaterialBox.SetParentComponent(sbxNKMaterial);
    sbNKMaterialBox.Name := 'sbNKMaterialBox';
    sbNKMaterialBox.Left := 16;
    sbNKMaterialBox.Height := 228;
    sbNKMaterialBox.Top := 212;
    sbNKMaterialBox.Width := 299;
    sbNKMaterialBox.AutoScroll := False;
    sbNKMaterialBox.BorderStyle := bsSingle;
  finally
    sbxNKMaschine.AfterLoadDFMValues;
    cobNKMaschMaschine.AfterLoadDFMValues;
    lblNKMaschMaschine.AfterLoadDFMValues;
    cobNKMaschMaschineID.AfterLoadDFMValues;
    dtpNKMaschVon.AfterLoadDFMValues;
    lblNKMaschVon.AfterLoadDFMValues;
    dtpNKMaschBis.AfterLoadDFMValues;
    edNKMaschDauer.AfterLoadDFMValues;
    wbtNKMaschSpeichern.AfterLoadDFMValues;
    lblNKMaschDauer.AfterLoadDFMValues;
    lblNKMaschDatum.AfterLoadDFMValues;
    dtpNKMaschDatum.AfterLoadDFMValues;
    edNKMaschProjekt.AfterLoadDFMValues;
    lblNKMaschProjekt.AfterLoadDFMValues;
    lblNKMaschBis.AfterLoadDFMValues;
    sbNkMaschineBox.AfterLoadDFMValues;
    sbxNKLohn.AfterLoadDFMValues;
    cobNKLohnMitarbeiter.AfterLoadDFMValues;
    lblNKLohnMitarbeiter.AfterLoadDFMValues;
    cobNKLohnMitarbeiterID.AfterLoadDFMValues;
    dtpNKLohnVon.AfterLoadDFMValues;
    dtpNKLohnBis.AfterLoadDFMValues;
    edNKLohnDauer.AfterLoadDFMValues;
    wbtNKLohnSpeichern.AfterLoadDFMValues;
    lblNKLohnDauer.AfterLoadDFMValues;
    dtpNKLohnDatum.AfterLoadDFMValues;
    cobNKLohnLohnartID.AfterLoadDFMValues;
    cobNKLohnLohnart.AfterLoadDFMValues;
    lblNKLohnLohnart.AfterLoadDFMValues;
    edNKLohnProjekt.AfterLoadDFMValues;
    lblNKLohnProjekt.AfterLoadDFMValues;
    lblNKLohnVon.AfterLoadDFMValues;
    lblNKLohnBis.AfterLoadDFMValues;
    lblNKLohnDatum.AfterLoadDFMValues;
    sbNkLohnBox.AfterLoadDFMValues;
    edNKLohnFahrzeit.AfterLoadDFMValues;
    edNKLohnRuestzeit.AfterLoadDFMValues;
    lblNKLohnFahrzeit.AfterLoadDFMValues;
    lblNKLohnRuestzeit.AfterLoadDFMValues;
    lblNKLohnPause1.AfterLoadDFMValues;
    dtpNKLohnPauseVon.AfterLoadDFMValues;
    dtpNKLohnPauseBis.AfterLoadDFMValues;
    lblNKLohnPause2.AfterLoadDFMValues;
    pnlMenue.AfterLoadDFMValues;
    wsbAdresse.AfterLoadDFMValues;
    wsbProjekt.AfterLoadDFMValues;
    wsbLogout.AfterLoadDFMValues;
    lblKarte.AfterLoadDFMValues;
    pnlSuche.AfterLoadDFMValues;
    pnlSearch.AfterLoadDFMValues;
    edtSearch.AfterLoadDFMValues;
    wsbMaterial.AfterLoadDFMValues;
    wsbMaschine.AfterLoadDFMValues;
    wsbLohn.AfterLoadDFMValues;
    cobAdresse.AfterLoadDFMValues;
    cobProjekt.AfterLoadDFMValues;
    lblFilter.AfterLoadDFMValues;
    sbx.AfterLoadDFMValues;
    pnlHeader.AfterLoadDFMValues;
    img.AfterLoadDFMValues;
    lblError.AfterLoadDFMValues;
    pnlMenu.AfterLoadDFMValues;
    lbBenutzer.AfterLoadDFMValues;
    WtDokumentNeuladen.AfterLoadDFMValues;
    camPos.AfterLoadDFMValues;
    sbxNKMaterial.AfterLoadDFMValues;
    cobNKMatMaterial.AfterLoadDFMValues;
    lblNKMatMaterial.AfterLoadDFMValues;
    cobNKMatMaterialID.AfterLoadDFMValues;
    edNKMatDauer.AfterLoadDFMValues;
    wbtNKMatSpeichern.AfterLoadDFMValues;
    lblNKMatDauer.AfterLoadDFMValues;
    lblNKMatDatum.AfterLoadDFMValues;
    dtpNKMatDatum.AfterLoadDFMValues;
    edNKMatFrei.AfterLoadDFMValues;
    lblNKMatFrei.AfterLoadDFMValues;
    edNKMatProjekt.AfterLoadDFMValues;
    lblNKMatProjekt.AfterLoadDFMValues;
    sbNKMaterialBox.AfterLoadDFMValues;
  end;
end;

end.

