program index;

{$MODE Delphi}

uses
  WEBLib.Forms, fMain, uTools;

begin
  Application.Initialize;
  Application.MainFormOnTaskBar:=True;
  Application.CreateForm(TfrmMain, frmMain);
  Application.Run;
  Application.Free;
end.
