{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2019                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.StdCtrls;

interface

uses
  Classes, WEBLib.Controls, SysUtils, Web, WEBLib.Graphics, Types, WEBlib.WebTools, WEBLib.Forms;

type
  Single = Double;
  TEditCharCase = (wecLowerCase, wecNormal, wecMixedCase, wecUpperCase);
  TCheckBoxState = (cbChecked, cbGrayed, cbUnchecked);
  TDateTimeKind = (dtkDate, dtkTime);
  TEllipsisPosition = (epEndEllipsis, epNone);
  TTextLayout = (tlTop, tlCenter, tlBottom);
  TSysLinkType = (sltID, sltURL);

  TAutoCompletion = (acOff,acHonorificPrefix, aGivenName, acAdditionalName, acFamilyName, acHonorificSuffix,
    acNickName, acEmail, acUserName, acNewPassword, acCurrentPassword, acOrganizationTitle, acOrganization,
    acStreetAddress, acAddressLine1, acAddressLine2, acAddressLine3, acAddressLevel1, acAddressLevel2,
    acAddressLevel3, acAddressLevel4, acCountry, acCountryName, acPostalCode, acCCName, acCCGivenName,
    acCCAdditionalName, acCCFamilyName, acCCNumber, acExpiry, acExpiryMonth, acExpiryYear, ccCSC,
    acType, acTransactionCurrency, acTransactionAmount, acLanguage, acBirthday, acBirthdayDay,
    acBirthDayMonth, acBirthDayYear, acSex, acTelephone, acTelephoneCountryCode, acTelephoneNational,
    acTelephoneAreaCode, acTelephoneLocal, acTelephoneExtension, acIMPP, acURL, acPhoto);

  TEditType = (weString, weFloat, weHex, weNumeric, weSignedFloat, weSignedNumeric);

  THTMLType = (tLABELTAG, tSPAN, tDIV, tH1,tH2, tH3, tH4, tH5, tH6);

  TLinkClickEvent = procedure(Sender: TObject; Link: string; LinkType: TSysLinkType) of object;

  TCustomLabel = class(TCustomControl)
  private
    FContent: TJSElement;
    FAutoSize: boolean;
    FEllipsisPosition: TEllipsisPosition;
    FWordWrap: Boolean;
    FAlignment: TAlignment;
    FLayout: TTextLayout;
    FTransparent: boolean;
    FHTMLType: THTMLType;
    FFocusControl: TWinControl;
    FShowAccelChar: boolean;
    procedure SetLayout(const Value: TTextLayout);
    procedure SetAlignment(const Value: TAlignment);
    function GetContentHandle: TJSHTMLElement;
    procedure SetTransparent(const Value: boolean);
    procedure SetHTMLType(const Value: THTMLType);
  protected
    function GetOuterWidth: integer; override;
    function GetOuterHeight: integer; override;
    function CreateElement: TJSElement; override;
    function CreateLabelElement: TJSElement; virtual;
    function GetDisplayText: string; virtual;
    function CanShowFocus: boolean; override;
    procedure BindElement; override;
    procedure Loaded; override;
    procedure UpdateAutoSize; virtual;
    procedure UpdateElementData; override;
    procedure UpdateElementVisual; override;
    procedure SetAutoSize(AValue: boolean);
    procedure SetCaption(const AValue: string); override;
    procedure SetEllipsisPosition(AValue: TEllipsisPosition);
    property ContentHandle: TJSHTMLElement read GetContentHandle;
    property Alignment: TAlignment read FAlignment write SetAlignment;
    property AutoSize: boolean read FAutoSize write SetAutoSize;
    property EllipsisPosition: TEllipsisPosition read FEllipsisPosition write SetEllipsisPosition;
    property FocusControl: TWinControl read FFocusControl write FFocusControl;
    property Layout: TTextLayout read FLayout write SetLayout;
    property ShowAccelChar: boolean read FShowAccelChar write FShowAccelChar;
    property Transparent: boolean read FTransparent write SetTransparent;
    property WordWrap: Boolean read FWordWrap write FWordWrap;
    property HTMLType: THTMLType read FHTMLType write SetHTMLType;
    function GetWidth: Integer; override;
    function GetHeight: Integer; override;
  public
    procedure CreateInitialize; override;
  end;

  TLabel = class(TCustomLabel)
  published
    property Align;
    property Alignment;
    property AlignWithMargins;
    property Anchors;
    property AutoSize;
    property Caption;
    property Color;
    property EllipsisPosition;
    property Enabled;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property FocusControl;
    property Font;
    property Height;
    property HeightPercent;
    property Hint;
    property HTMLType;
    property Layout;
    property Left;
    property Margins;
    property ShowAccelChar;
    property ShowHint;
    property TextDirection;
    property Top;
    property Transparent;
    property Visible;
    property Width;
    property WidthPercent;
    property WordWrap;
    property OnClick;
    property OnDblClick;
  end;

  TWebLabel = class(TLabel);

  TCustomInput = class(TCustomControl)
  private
  protected
    function GetInputType: string; virtual;
    function CreateElement: TJSElement; override;
    procedure UpdateElementVisual; override;
  public
    procedure CreateInitialize; override;
  end;

  TCustomEdit = class(TCustomInput)
  private
    FCharCase: TEditCharCase;
    FMaxLength: integer;
    FReadOnly: Boolean;
    FText: string;
    FTextHint: string;
    FSelStart: Integer;
    FAlignment: TAlignment;
    FHideSelection: Boolean;
    FPasswordChar: Char;
    FOnChange: TNotifyEvent;
    FAutoSize: Boolean;
    FAutoSelect: Boolean;
    FSelLength: Integer;
    FNumeric: Boolean;
    FAutoCompletion: TAutoCompletion;
    FEditType: TEditType;
    FRequired: Boolean;
    FAutoFocus: boolean;
    FPattern: string;
    procedure SetAlignment(const Value: TAlignment);
    procedure SetHideSelection(const Value: Boolean);
    procedure SetAutoSelect(const Value: Boolean);
    procedure SetAutoSize(const Value: Boolean);
    function GetElementInputHandle: TJSHTMLInputElement;
    procedure SetSelLength(const Value: Integer);
    procedure SetSelStart(const Value: Integer);
    procedure SetPasswordChar(const Value: Char);
    procedure SetNumeric(const Value: Boolean);
    procedure SetAutoCompletion(const Value: TAutoCompletion);
    procedure SetAutoFocus(const Value: boolean);
    procedure SetRequired(const Value: Boolean);
    procedure SetPattern(const Value: string);
    function GetSelLength: Integer;
    function GetSelStart: Integer;
  protected
    function DoHandlePaste(Event: TEventListenerEvent): Boolean; virtual;
    function DoHandleChange(Event: TEventListenerEvent): Boolean;
    function IsCustomEditor: Boolean; virtual;
    procedure KeyPress(var Key: Char); override;
    function GetInputType: string; override;
    procedure PersistinHTML; override;
    function GetText: string;
    function GetDisplayText: string; virtual;
    function IsReadOnly: boolean; virtual;
    function Validate(AValue: string): boolean; virtual;
    procedure BindEvents; override;
    procedure UpdateElementData; override;
    procedure SetCharCase(AValue: TEditCharCase); virtual;
    procedure SetMaxLength(AValue: integer);
    procedure SetReadOnly(AValue: boolean);
    procedure SetText(AValue: string);
    procedure SetTextHint(AValue: string);
    property Font;
    property AutoCompletion: TAutoCompletion read FAutoCompletion write SetAutoCompletion;
    property AutoFocus: boolean read FAutoFocus write SetAutoFocus;
    property Color;
    property BorderStyle;
    property HideSelection: Boolean read FHideSelection write SetHideSelection;
    property CharCase: TEditCharCase read FCharCase write SetCharCase;
    property MaxLength: integer read FMaxLength write SetMaxLength;
    property Numeric: Boolean read FNumeric write SetNumeric;
    property PasswordChar: Char read FPasswordChar write SetPasswordChar;
    property Pattern: string read FPattern write SetPattern;
    property ReadOnly: Boolean read FReadOnly write SetReadOnly;
    property Required: Boolean read FRequired write SetRequired;
    property Text: string read GetText write SetText;
    property TextHint: string read FTextHint write SetTextHint;
    property AutoSize: Boolean read FAutoSize write SetAutoSize;
    property AutoSelect: Boolean read FAutoSelect write SetAutoSelect;
    property Alignment: TAlignment read FAlignment write SetAlignment;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnClick;
    property OnDblClick;
  public
    procedure CreateInitialize; override;
    property SelStart: Integer read GetSelStart write SetSelStart;
    property SelLength: Integer read GetSelLength write SetSelLength;
    procedure Clear;
    procedure ClearSelection;
    procedure Change; virtual;
    procedure SelectAll;
    property EditType: TEditType read FEditType write FEditType;
    property ElementInputHandle: TJSHTMLInputElement read GetElementInputHandle;
  end;

  TEdit = class(TCustomEdit)
  published
    property Alignment;
    property Align;
    property AlignWithMargins;
    property Anchors;
    property AutoCompletion;
    property AutoFocus;
    property AutoSize;
    property AutoSelect;
    property BorderStyle;
    property CharCase;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property Hint;
    property HideSelection;
    property Left;
    property PasswordChar;
    property Pattern;
    property Margins;
    property MaxLength;
    property ReadOnly;
    property Required;
    property ShowFocus;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Text;
    property TextDirection;
    property TextHint;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property OnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TWebEdit = class(TEdit);

  TSpinEdit = class(TCustomInput)
  private
    FIncrement: integer;
    FMaxValue: integer;
    FMinValue: integer;
    FValue: integer;
    FAutoSize: Boolean;
    FOnChange: TNotifyEvent;
    FReadOnly: boolean;
    function GetText: string;
    procedure SetText(const Value: string);
    function GetElementInputHandle: TJSHTMLInputElement;
    procedure SetReadOnly(const Value: boolean);
  protected
    procedure KeyPress(var Key: Char); override;
    procedure PersistinHTML; override;
    function GetInputType: string; override;
    procedure UpdateElementData; override;
    function GetValue: integer;
    procedure SetIncrement(AValue: integer);
    procedure SetMaxValue(AValue: integer);
    procedure SetMinValue(AValue: integer);
    procedure SetValue(AValue: integer);
    function DoHandleChange(Event: TEventListenerEvent): Boolean;
    function IsReadOnly: boolean; virtual;
    function GetDisplayText: string; virtual;
    procedure BindEvents; override;
    procedure Change; virtual;
  public
    procedure CreateInitialize; override;
    property ElementInputHandle: TJSHTMLInputElement read GetElementInputHandle;
    property Text: string read GetText write SetText;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property AutoSize: Boolean read FAutoSize write FAutoSize;
    property BorderStyle;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property Hint;
    property Increment: integer read FIncrement write SetIncrement;
    property Left;
    property Margins;
    property MaxValue: integer read FMaxValue write SetMaxValue;
    property MinValue: integer read FMinValue write SetMinValue;
    property ReadOnly: boolean read FReadOnly write SetReadOnly;
    property ShowFocus;
    property ShowHint;
    property TabStop;
    property TabOrder;
    property TextDirection;
    property Value: integer read GetValue write SetValue;
    property Visible;
    property Width;
    property WidthPercent;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TWebSpinEdit = class(TSpinEdit);

  TDateTimePicker = class(TCustomInput)
  private
    FDate: TDateTime;
    FKind: TDateTimeKind;
    FTime: TDateTime;
    FOnChange: TNotifyEvent;
    procedure SetDate(AValue: TDateTime);
    function GetDate: TDateTime;
    procedure SetTime(AValue: TDateTime);
    function GetTime: TDateTime;
    procedure SetKind(AValue: TDateTimeKind);
    procedure SetText(const Value: String);
    function GetText: String;
  protected
    function DoHandleChange(Event: TEventListenerEvent): Boolean;
    procedure BindEvents; override;
    procedure UpdateElementData; override;
    function CreateElement: TJSElement; override;
    function GetInputType: string; override;
    procedure Change; virtual;
  public
    procedure CreateInitialize; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BorderStyle;
    property Color;
    property Date: TDateTime read GetDate write SetDate;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Font;
    property Height;
    property HeightPercent;
    property Hint;
    property Kind: TDateTimeKind read FKind write SetKind;
    property Left;
    property Margins;
    property ShowFocus;
    property ShowHint;
    property TabStop;
    property TabOrder;
    property Text: String read GetText write SetText;
    property TextDirection;
    property Time: TDateTime read GetTime write SetTime;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property OnClick;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TWebDateTimePicker = class(TDateTimePicker);

  TButton = class(TCustomControl)
  private
    FModalResult: TModalResult;
    FDefault: Boolean;
    FCancel: Boolean;
    FButtonType: string;
    procedure SetButtonType(const Value: string);
  protected
    function CreateElement: TJSElement; override;
    procedure SetCaption(const AValue: string); override;
    procedure UpdateElementData; override;
    procedure UpdateElementVisual; override;
  public
    procedure CreateInitialize; override;
    property ModalResult: TModalResult read FModalResult write FModalResult;
    property Default: Boolean read FDefault write FDefault;
    property Cancel: Boolean read FCancel write FCancel;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BorderStyle;
    property ButtonType: string read FButtonType write SetButtonType;
    property Caption;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property Hint;
    property Left;
    property Margins;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property TextDirection;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property OnClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TWebButton = class(TButton);

  TCheckBox = class(TCustomControl)
  private
    FChecked: boolean;
    FState: TCheckBoxState;
  protected
    procedure Loaded; override;
    procedure UpdateElementData; override;
    procedure UpdateElementSize; override;
    procedure UpdateElementVisual; override;
    procedure PersistinHTML; override;
    procedure EnableTab; override;
    procedure DisableTab; override;
    function CreateElement: TJSElement; override;
    procedure SetChecked(AValue: boolean);
    function GetChecked: boolean;
    procedure SetState(AValue: TCheckBoxState);
    function GetState: TCheckBoxState;
    procedure SetCaption(const AValue: string); override;
    procedure SetEnabled(Value: boolean); override;
    function HandleLabelClick(Event: TJSMouseEvent): Boolean; virtual;
    procedure Click; override;
    function GetCheckElement: TJSHTMLElement;
  public
    procedure CreateInitialize; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Caption;
    property Checked: boolean read GetChecked write SetChecked;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property Hint;
    property Left;
    property Margins;
    property ShowHint;
    property State: TCheckBoxState read GetState write SetState;
    property TabOrder;
    property TabStop;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property OnClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TWebCheckBox = class(TCheckBox);

  TRadioButton = class(TCustomControl)
  private
    FChecked: boolean;
    FGroupName: string;
  protected
    procedure Loaded; override;
    procedure PersistInHTML; override;
    procedure EnableTab; override;
    procedure DisableTab; override;
    function CreateElement: TJSElement; override;
    procedure SetChecked(AValue: boolean);
    function GetChecked: boolean;
    procedure SetEnabled(Value: boolean); override;
    procedure SetCaption(const AValue: string); override;
    procedure SetGroupName(AValue: string);
    function HandleLabelClick(Event: TJSMouseEvent): Boolean; virtual;
    procedure UpdateElementSize; override;
    procedure UpdateElementData; override;
    procedure UpdateElementVisual; override;
    function GetRadioElement: TJSHTMLElement;
  public
    procedure CreateInitialize; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Caption;
    property Checked: boolean read GetChecked write SetChecked;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property GroupName: string read FGroupName write SetGroupName;
    property Height;
    property HeightPercent;
    property Hint;
    property Left;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Visible;
    property Width;
    property WidthPercent;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TWebRadioButton = class(TRadioButton);

  TListBox = class(TCustomControl)
  private
    FItems: TStrings;
    FItemIndex: integer;
    FMultiSelect: boolean;
    FSelected: TList;
    FItemHeight: Integer;
    FSorted: Boolean;
    FOnChange: TNotifyEvent;
    procedure SetItemHeight(const Value: Integer);
    procedure SetSorted(const Value: Boolean);
    function GetCount: Integer;
    function GetElementSelectHandle: TJSHTMLSelectElement;
    function GetSelCount: integer;
  protected
    function DoHandleChange(Event: TEventListenerEvent): Boolean;
    procedure DoItemsChange(Sender: TObject);
    procedure DoUpdateList; virtual;
    procedure PersistinHTML; override;
    function GetItemIndex: integer;
    function CreateElement: TJSElement; override;
    function GetSelected(AIndex: integer): boolean;
    procedure SetSelected(AIndex: Integer; AValue: boolean);
    procedure SetItems(AItems: TStrings);
    procedure SetItemIndex(AIndex: integer);
    procedure SetMultiSelect(AValue: boolean);
    procedure Loaded; override;
    procedure UpdateElementData; override;
    procedure UpdateElementVisual; override;
    procedure BindEvents; override;
    procedure Change; virtual;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure EndUpdate; override;
    procedure ClearSelection;
    procedure SelectAll;
    property Sorted: Boolean read FSorted write SetSorted;
    property Count: Integer read GetCount;
    property SelCount: integer read GetSelCount;
    property ElementSelectHandle: TJSHTMLSelectElement read GetElementSelectHandle;
    procedure AddItem(Item: string; AObject: TObject);
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BorderStyle;
    property Color;
    property Font;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Height;
    property HeightPercent;
    property Hint;
    property ItemHeight: Integer read FItemHeight write SetItemHeight;
    property ItemIndex: integer read GetItemIndex write SetItemIndex;
    property Items: TStrings read FItems write SetItems;
    property Left;
    property MultiSelect: boolean read FMultiSelect write SetMultiSelect;
    property Selected[i: Integer]: boolean read GetSelected write SetSelected;
    property ShowFocus;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property TextDirection;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TWebListBox = class(TListBox);

  TComboBoxStyle = (csDropDown, csSimple, csDropDownList, csOwnerDrawFixed,
    csOwnerDrawVariable);

  TCustomComboBox = class(TCustomControl)
  private
    FItems: TStrings;
    FItemIndex: integer;
    FStyle: TComboBoxStyle;
    FDroppedDown: Boolean;
    FOnChange: TNotifyEvent;
    function GetText: string;
    procedure SetText(const Value: string);
    procedure SetStyle(const Value: TComboBoxStyle);
    procedure SetDroppedDown(const Value: Boolean);
    function GetElementSelectHandle: TJSHTMLSelectElement;
  protected
    function DoHandleChange(Event: TEventListenerEvent): Boolean; virtual;
    procedure DoItemsChange(Sender: TObject);
    procedure DoUpdateList; virtual;
    function GetItemIndex: integer;
    function CreateElement: TJSElement; override;
    procedure BindEvents; override;
    procedure UpdateElementData; override;
    procedure SetItems(AItems: TStrings);
    procedure SetItemIndex(AIndex: integer);
    procedure Loaded; override;
    procedure Change; virtual;
    procedure PersistinHTML; override;
    property Items: TStrings read FItems write SetItems;
    property Align;
    property AlignWithMargins;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property Hint;
    property ItemIndex: integer read GetItemIndex write SetItemIndex;
    property Left;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Text: string read GetText write SetText;
    property Top;
    property Visible;
    property Width;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure Clear;
    procedure AddItem(Item: string; AObject: TObject);
    property ElementSelectHandle: TJSHTMLSelectElement read GetElementSelectHandle;
    property Style: TComboBoxStyle read FStyle write SetStyle;
    property DroppedDown: Boolean read FDroppedDown write SetDroppedDown;
  end;

  TComboBox = class(TCustomComboBox)
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property Hint;
    property ItemIndex;
    property Items;
    property Left;
    property ShowFocus;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Text;
    property TextDirection;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property OnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TWebComboBox = class(TComboBox);

  TFontPicker = class(TCustomComboBox)
  public
    procedure CreateInitialize; override;
    property Items;
  published
    property Align;
    property AlignWithMargins;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property Hint;
    property ItemIndex;
    property Left;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Text;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property OnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TWebFontPicker = class(TFontPicker);

  TFontSizePickerMode = (fmPointSize, fmRelativeSize);

  TFontSizePicker = class(TCustomComboBox)
  private
    FPickerMode: TFontSizePickerMode;
    procedure SetPickerMode(const AValue: TFontSizePickerMode);
  protected
    procedure Init; virtual;
  public
    procedure CreateInitialize; override;
    property Items;
  published
    property Align;
    property AlignWithMargins;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property Hint;
    property ItemIndex;
    property Left;
    property PickerMode: TFontSizePickerMode read FPickerMode write SetPickerMode;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Text;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property OnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TWebFontSizePicker = class(TFontSizePicker);


  TCaretPosition = TPoint;

  TCustomMemo = class(TCustomControl)
  private
    FWordWrap: Boolean;
    FBlockChange: Boolean;
    FLines: TStrings;
    FSelStart: Integer;
    FSelLength: Integer;
    FCaretPosition: TCaretPosition;
    FAutoSize: Boolean;
    FOnChange: TNotifyEvent;
    FReadOnly: boolean;
    FTextHint: string;
    function GetText: String;
    procedure SetText(const Value: String);
    procedure SetSelLength(const Value: Integer);
    procedure SetSelStart(const Value: Integer);
    procedure SetAutoSize(const Value: Boolean);
    function GetElementInputHandle: TJSHTMLInputElement;
    procedure SetReadOnly(const Value: boolean);
    function GetSelLength: Integer;
    function GetSelStart: Integer;
    procedure SetTextHint(const Value: string);
  protected
    function CreateElement: TJSElement; override;
    function DoHandleInput(Event: TEventListenerEvent): Boolean; virtual;
    function DoHandleChange(Event: TEventListenerEvent): Boolean; virtual;
    function IsReadOnly: boolean; virtual;
    procedure PersistinHTML; override;
    function GetDisplayText: string; virtual;
    procedure BindEvents; override;
    procedure UpdateElementData; override;
    procedure UpdateElementVisual; override;
    procedure SetLines(ALines: TStrings);
    procedure DoLinesChange(Sender: TObject);
    property Text: String read GetText write SetText;
    property Font;
    property Color;
    property BorderStyle;
    property AutoSize: Boolean read FAutoSize write SetAutoSize;
    property Lines: TStrings read FLines write SetLines;
    property ReadOnly: boolean read FReadOnly write SetReadOnly;
    property OnClick;
    property OnDblClick;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    procedure Change; virtual;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure Clear;
    property CaretPosition: TCaretPosition read FCaretPosition write FCaretPosition;
    property ElementInputHandle: TJSHTMLInputElement read GetElementInputHandle;
    property SelStart: Integer read GetSelStart write SetSelStart;
    property SelLength: Integer read GetSelLength write SetSelLength;
    property TextHint: string read FTextHint write SetTextHint;
    property WordWrap: Boolean read FWordWrap write FWordWrap;
  end;

  TMemo = class(TCustomMemo)
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property AutoSize;
    property BorderStyle;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property Hint;
    property Left;
    property Lines;
    property ReadOnly;
    property SelStart;
    property SelLength;
    property ShowFocus;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Text;
    property TextDirection;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property OnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TWebMemo = class(TMemo);

  TRadioGroup = class(TCustomControl)
  private
    FColumns: integer;
    FItems: TStrings;
    FItemIndex: integer;
    FOldItemIndex: integer;
    FOnChange: TNotifyEvent;
  protected
    function CreateElement: TJSElement; override;
    function GetItemIndex: integer;
    function DoHandleChange(Event: TEventListenerEvent): Boolean;
    procedure DoItemsChange(Sender: TObject);
    procedure DoRadioClick(Sender: TObject);
    procedure SetCaption(const AValue: string); override;
    procedure SetColumns(AValue: integer);
    procedure SetItems(AItems: TStrings);
    procedure SetItemIndex(AIndex: integer);
    procedure DoUpdateList; virtual;
    procedure Change; virtual;
    procedure Loaded; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure EndUpdate; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Caption;
    property Columns: integer read FColumns write SetColumns;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Height;
    property HeightPercent;
    property Items: TStrings read FItems write SetItems;
    property ItemIndex: integer read GetItemIndex write SetItemIndex;
    property Visible;
    property Width;
    property WidthPercent;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TWebRadioGroup = class(TRadioGroup);

  TColorPicker = class(TCustomInput)
  private
    FColor: TColor;
    FOnSelect: TNotifyEvent;
    function GetElementInputHandle: TJSHTMLInputElement;
  protected
    procedure UpdateElementVisual; override;
    function DoHandleChange(Event: TEventListenerEvent): Boolean;
    function GetInputType: string; override;
    function GetColor: TColor;
    procedure SetColor(AValue: TColor); reintroduce;
    procedure BindEvents; override;
    procedure Select; virtual;
  public
    procedure CreateInitialize; override;
    property ElementInputHandle: TJSHTMLInputElement read GetElementInputHandle;
  published
    property Color: TColor read GetColor write SetColor;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Height;
    property HeightPercent;
    property Left;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property OnClick;
    property OnSelect: TNotifyEvent read FOnSelect write FOnSelect;
  end;

  TWebColorPicker = class(TColorPicker);

  TScrollBarContent = class(TCustomControl)
  protected
    function CreateElement: TJSElement; override;
  end;

  TScrollBarKind = (sbHorizontal, sbVertical);
  TScrollBarInc = Integer;

  TScrollBar = class(TCustomControl)
  private
    FScrolling: Boolean;
    FContent: TScrollBarContent;
    FKind: TScrollBarKind;
    FPosition: Integer;
    FSmallChange: TScrollBarInc;
    FMax: Integer;
    FMin: Integer;
    FLargeChange: TScrollBarInc;
    FPageSize: Integer;
    FOnChange: TNotifyEvent;
    procedure SetKind(const Value: TScrollBarKind);
    procedure SetPosition(const Value: Integer);
    procedure SetMax(const Value: Integer);
    procedure SetMin(const Value: Integer);
    procedure SetPageSize(const Value: Integer);
    function GetPosition: Integer;
    function GetPageSize: Integer;
  protected
    function GetValue(XYPos: Double): Double;
    function CreateElement: TJSElement; override;
    function DoScroll(Event: TJSUIEvent): Boolean; virtual;
    procedure BindEvents; override;
    procedure UpdateElementVisual; override;
    procedure UpdateContent; virtual;
    procedure Loaded; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure SetBounds(X, Y, AWidth, AHeight: Integer); override;
  published
    property Align;
    property AlignWithMargins;
    property Height;
    property Kind: TScrollBarKind read FKind write SetKind;
    property Position: Integer read GetPosition write SetPosition;
    property Left;
    property SmallChange: TScrollBarInc read FSmallChange write FSmallChange;
    property LargeChange: TScrollBarInc read FLargeChange write FLargeChange;
    property Max: Integer read FMax write SetMax;
    property Min: Integer read FMin write SetMin;
    property PageSize: Integer read GetPageSize write SetPageSize;
    property Top;
    property Visible;
    property Width;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TWebScrollBar = class(TScrollBar);

function GetAutoCompletionName(aAutoCompletion: TAutoCompletion): string;

implementation

function GetAutoCompletionName(aAutoCompletion: TAutoCompletion): string;
begin
  case aAutoCompletion of
    acOff: Result := '';
    acHonorificPrefix: Result := 'honorific-prefix';
    aGivenName: Result := 'given-name';
    acAdditionalName: Result := 'additional-name';
    acFamilyName: Result := 'family-name';
    acHonorificSuffix: Result := 'honorific-suffix';
    acNickName: Result := 'nickname';
    acEmail: Result := 'email';
    acUserName: Result := 'username';
    acNewPassword: Result := 'new-password';
    acCurrentPassword: Result := 'current-password';
    acOrganizationTitle: Result := 'organization-title';
    acOrganization: Result := 'organization';
    acStreetAddress: Result := 'street-address';
    acAddressLine1: Result := 'address-line1';
    acAddressLine2: Result := 'address-line2';
    acAddressLine3: Result := 'address-line3';
    acAddressLevel1: Result := 'address-level1';
    acAddressLevel2: Result := 'address-level2';
    acAddressLevel3: Result := 'address-level3';
    acAddressLevel4: Result := 'address-level4';
    acCountry: Result := 'country';
    acCountryName: Result := 'country-name';
    acPostalCode: Result := 'postal-code';
    acCCName: Result := 'cc-name';
    acCCGivenName: Result := 'cc-given-name';
    acCCAdditionalName: Result := 'cc-additional-name';
    acCCFamilyName: Result := 'cc-family-name';
    acCCNumber: Result := 'cc-number';
    acExpiry: Result := 'cc-exp';
    acExpiryMonth: Result := 'cc-exp-month';
    acExpiryYear: Result := 'cc-exp-year';
    ccCSC: Result := 'cc-csc';
    acType: Result := 'cc-type';
    acTransactionCurrency: Result := 'transaction-currency';
    acTransactionAmount: Result := 'transaction-amount';
    acLanguage: Result := 'language';
    acBirthday: Result := 'bday';
    acBirthdayDay: Result := 'bday-day';
    acBirthDayMonth: Result := 'bday-month';
    acBirthDayYear: Result := 'bday-year';
    acSex: Result := 'sex';
    acTelephone: Result := 'tel';
    acTelephoneCountryCode: Result := 'tel-country-code';
    acTelephoneNational: Result := 'tel-national';
    acTelephoneAreaCode: Result := 'tel-area-code';
    acTelephoneLocal: Result := 'tel-local';
    acTelephoneExtension: Result := 'tel-extension';
    acIMPP: Result := 'impp';
    acURL: Result := 'url';
    acPhoto: Result := 'photo';
  end;
end;

{ TCustomLabel }

procedure TCustomLabel.CreateInitialize;
begin
  inherited;
  FAutoSize := true;
  FLayout := tlTop;
  FEllipsisPosition := epNone;
  FTransparent := True;
  Color := clWhite;
  FAlignment := taLeftJustify;
  TabStop := false;
  FShowAccelChar := true;
end;

function TCustomLabel.CanShowFocus: boolean;
begin
  Result := false;
end;

function TCustomLabel.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');

  FContent := CreateLabelElement;
  Result.appendChild(FContent);
end;

function TCustomLabel.CreateLabelElement: TJSElement;
begin
  Result := nil;

  case HTMLType of
  tSPAN: Result := document.createElement('SPAN');
  tDIV: Result := document.createElement('DIV');
  tH1: Result := document.createElement('H1');
  tH2: Result := document.createElement('H2');
  tH3: Result := document.createElement('H3');
  tH4: Result := document.createElement('H4');
  tH5: Result := document.createElement('H5');
  tH6: Result := document.createElement('H6');
  tLABELTAG: Result := document.createElement('LABEL');
  end;
end;

procedure TCustomLabel.BindElement;
begin
  FContent := Container.firstElementChild;
end;

function TCustomLabel.GetContentHandle: TJSHTMLElement;
begin
  Result := TJSHTMLElement(FContent);
end;

function TCustomLabel.GetDisplayText: string;
begin
  Result := Caption;
end;

function TCustomLabel.GetHeight: Integer;
begin
  if AutoSize and Assigned(ElementHandle) and Assigned(Parent) then
  begin
    Result := Round(TJSHTMLElement(ElementHandle).offsetHeight);
    if Result = 0 then
       Result := inherited GetHeight;
  end
  else
    Result := inherited GetHeight;
end;

function TCustomLabel.GetOuterHeight: integer;
begin
  Result := Height;
//  if AutoSize then  // take 1px inner border in account
//    Result := Result + 4;
end;

function TCustomLabel.GetOuterWidth: integer;
begin
  Result := Width;
//  if AutoSize then  // take 1px inner border in account
//    Result := Result + 4;
end;

function TCustomLabel.GetWidth: Integer;
begin
  if AutoSize and Assigned(ElementHandle) and Assigned(Parent) then
  begin
    Result := Round(TJSHTMLElement(ElementHandle).offsetWidth);

    if Result = 0 then
      Result := inherited GetWidth;
  end
  else
    Result := inherited GetWidth;
end;

procedure TCustomLabel.Loaded;
begin
  inherited;
  if ShowAccelChar and Assigned(FocusControl) then
    UpdateElementData;
end;

procedure TCustomLabel.SetAutoSize(AValue: boolean);
begin
  if (FAutoSize <> AValue) then
  begin
    FAutoSize := AValue;
    UpdateAutoSize;
    UpdateElement;
  end;
end;

procedure TCustomLabel.SetCaption(const AValue: string);
begin
  if Caption <> AValue then
  begin
    inherited SetCaption(AValue);
    UpdateAutoSize;
    UpdateElement;
  end;
end;

procedure TCustomLabel.SetEllipsisPosition(AValue: TEllipsisPosition);
begin
  if FEllipsisPosition <> AValue then
  begin
    FEllipsisPosition := AValue;
    if FEllipsisPosition <> epNone then
      FAutoSize := False;
    UpdateElement;
  end;
end;

procedure TCustomLabel.SetHTMLType(const Value: THTMLType);
begin
  if (FHTMLType <> Value) then
  begin
    FHTMLType := Value;

    if FHTMLType in [tH1..tH6] then
      ElementFont := efCSS;

    if Assigned(ElementHandle) and Assigned(FContent) then
    begin
      ElementHandle.removeChild(FContent);

      FContent := CreateLabelElement;

      ElementHandle.appendChild(FContent);

      UpdateElement;
    end;
  end;
end;

procedure TCustomLabel.SetLayout(const Value: TTextLayout);
begin
  if FLayout <> Value then
  begin
    FLayout := Value;
    UpdateElementVisual;
  end;
end;

procedure TCustomLabel.SetTransparent(const Value: boolean);
begin
  if (FTransparent <> Value) then
  begin
    FTransparent := Value;
    UpdateElementVisual;
  end;
end;

procedure TCustomLabel.SetAlignment(const Value: TAlignment);
begin
  if FAlignment <> Value then
  begin
    FAlignment := Value;
    UpdateElementVisual;
  end;
end;

procedure TCustomLabel.UpdateAutoSize;
begin
  if FAutoSize {and (Align = alNone)} then
  begin
    if Assigned(ElementHandle) then
    begin
      Width := -1;
      Height := -1;
    end;
    FEllipsisPosition := epNone;
  end;
end;

procedure TCustomLabel.UpdateElementData;
var
  lTxt: string;
  acc: string;
begin
  inherited;

  acc := '';

  if ShowAccelChar then
    lTxt := ProcessAccelerator(GetDisplayText,acc)
  else
    lTxt := GetDisplayText;

  if IsLinked and (lTxt = '') then
    Exit;

  if Assigned(ContentHandle) then
    ContentHandle.innerHTML := lTxt
  else
    ElementHandle.innerHTML := lTxt;

//    https://www.htmlgoodies.com/tutorials/html_401/article.php/3479621
  if (acc <> '') and Assigned(FocusControl) then
  begin
    ContentHandle['accesskey'] := acc;
    ContentHandle['for'] := FocusControl.GetID;
  end;

end;

procedure TCustomLabel.UpdateElementVisual;
begin
  inherited;

  if IsUpdating then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if Visible then
      ElementHandle.style.setProperty('display', 'table');

    ElementHandle['zindex'] := '1';

    if AutoSize and (Align = alNone) then
      ElementHandle.style.setProperty('overflow', '')
    else
      ElementHandle.style.setProperty('overflow', 'hidden');
  end;

  if Assigned(ContentHandle) then
  begin
    case Layout of
      tlTop: ContentHandle.style.setProperty('vertical-align', 'top');
      tlCenter: ContentHandle.style.setProperty('vertical-align', 'middle');
      tlBottom: ContentHandle.style.setProperty('vertical-align', 'bottom');
    end;

    case Alignment of
    taCenter: ContentHandle.style.setProperty('text-align', 'center');
    taRightJustify: ContentHandle.style.setProperty('text-align', 'right');
    end;

    if not Transparent then
      ContentHandle.style.setProperty('background-color', ColorToHTML(Color));

    ContentHandle.style.setProperty('display', 'table-cell');

    if ElementClassName = '' then
    begin
      if Enabled and (ElementFont = efProperty) and not IsLinked then
        ContentHandle.style.setProperty('color', ColorToHtml(Font.Color));

      SetElementPointer(ContentHandle, Cursor);

      if (ElementFont = efProperty) and not IsLinked then
        SetHTMLElementFont(ContentHandle, Font)
      else
      begin
        ContentHandle.style.setProperty('font-family', '');
        ContentHandle.style.setProperty('font-style', '');
        ContentHandle.style.setProperty('font-size', '');
      end;
    end;

    if FEllipsisPosition = epNone then
      ContentHandle.style.setProperty('text-overflow', 'clip')
    else
      ContentHandle.style.setProperty('text-overflow', 'ellipsis');

    if WordWrap then
      ContentHandle.style.setProperty('white-space', 'normal')
    else
      ContentHandle.style.setProperty('white-space', 'nowrap');

    // allow selection in labels
    ElementHandle.style.setProperty('user-select', '');

    // allow default caret on text
    if Cursor = crDefault then
      ElementHandle.style.setProperty('cursor', '');

    if AutoSize and (Align = alNone) then
    begin
      ElementHandle.style.removeProperty('width');
      ElementHandle.style.removeProperty('height');
    end;

  end;
end;

{ TCustomInput }

function TCustomInput.CreateElement: TJSElement;
begin
  Result := document.createElement('INPUT');
  Result['type'] := GetInputType;
end;

procedure TCustomInput.UpdateElementVisual;
begin
  inherited;

  if Assigned(Container) and not IsLinked then
  begin
    if (Color <> clWindow) then
      TJSHTMLInputElement(Container).style.setProperty('background-Color',ColorToHTML(Color));
    TJSHTMLInputElement(Container).style.setProperty('-moz-box-sizing', 'border-box');
    TJSHTMLInputElement(Container).style.setProperty('-webkit-box-sizing', 'border-box');
    TJSHTMLInputElement(Container).style.setProperty('box-sizing', 'border-box');
  end;
end;

procedure TCustomInput.CreateInitialize;
begin
  inherited;
  ShowFocus := true;
end;

function TCustomInput.GetInputType: string;
begin
   Result := 'EDIT';
end;

{ TCustomEdit }

procedure TCustomEdit.BindEvents;
begin
  inherited;
  if Assigned(ElementInputHandle) then
  begin
    ElementInputHandle.oninput := DoHandleChange;
    ElementInputHandle.addEventListener('paste',@DoHandlePaste);
  end;
end;

procedure TCustomEdit.Change;
begin
  if Assigned(ElementHandle) then
    FText := ElementInputHandle.value;

  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TCustomEdit.Clear;
begin
  Text := '';
end;

procedure TCustomEdit.ClearSelection;
var
  s: string;
begin
  s := Text;
  Text := Copy(s, 1, SelStart) + Copy(s, SelStart + SelLength, Length(s));
end;

procedure TCustomEdit.CreateInitialize;
begin
  inherited;
  FEditType := weString;
  FText := '';
  FCharCase := wecNormal;
  FMaxLength := 0;
  FReadOnly := false;
  FTextHint := '';
end;

function TCustomEdit.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin
  Change;
  Result := True;
end;

function TCustomEdit.DoHandlePaste(Event: TEventListenerEvent): Boolean;
var
  s: string;

begin
  asm
    var clipboardData = Event.clipboardData || window.clipboardData;
    s = clipboardData.getData('Text');
  end;

  if not Validate(s) then
  begin
    asm
      Event.preventDefault();
      Event.stopPropagation();
    end;
  end;

  Result := False;
end;

procedure TCustomEdit.SetReadOnly(AValue: Boolean);
begin
  FReadOnly := AValue;
  UpdateElement;
end;

procedure TCustomEdit.SetRequired(const Value: Boolean);
begin
  FRequired := Value;
  UpdateElement;
end;

procedure TCustomEdit.SetSelLength(const Value: Integer);
begin
  FSelLength := Value;
  UpdateElement;
end;

procedure TCustomEdit.SetSelStart(const Value: Integer);
begin
  FSelStart := Value;
  UpdateElement;
end;

procedure TCustomEdit.SetPasswordChar(const Value: Char);
begin
  FPasswordChar := Value;
  UpdateElement;
end;

procedure TCustomEdit.SetPattern(const Value: string);
begin
  if FPattern <> Value then
  begin
    FPattern := Value;
    UpdateElement;
  end;
end;

procedure TCustomEdit.SetNumeric(const Value: Boolean);
begin
  FNumeric := Value;
  UpdateElement;
end;

procedure TCustomEdit.SetText(AValue: string);
begin
  FText := AValue;
  UpdateElement;
end;

function TCustomEdit.GetText: string;
begin
  Result := FText;
  if Assigned(ElementInputHandle) then
    Result := ElementInputHandle.value;
end;

function TCustomEdit.IsCustomEditor: Boolean;
begin
  Result := False;
end;

function TCustomEdit.IsReadOnly: boolean;
begin
  Result := ReadOnly;
end;

procedure TCustomEdit.KeyPress(var Key: Char);
var
  isValid: boolean;

begin
  inherited KeyPress(Key);

  isValid := true;

  case EditType of
  weNumeric: isValid := (Key in ['0'..'9']);
  weSignedNumeric: isValid := (Key in ['0'..'9','+','-']);
  weFloat: isValid := (Key in ['0'..'9',',','.']);
  weSignedFloat: isValid := (Key in ['0'..'9',',','.','+','-']);
  weHex: isValid := (Key in ['0'..'9','A'..'F']);
  end;

  if not isValid then
    Key := #0;
end;

procedure TCustomEdit.SetTextHint(AValue: string);
begin
  FTextHint := AValue;
  UpdateElement;
end;

{$HINTS OFF}
procedure TCustomEdit.UpdateElementData;
var
  e: TJSHTMLElement;
  ss, sl: Integer;
begin
  inherited;
  if Assigned(ElementInputHandle) then
  begin
    if not IsLinked then
    begin
      case CharCase of
        wecUpperCase: ElementInputHandle.style.setProperty('text-transform', 'uppercase');
        wecLowerCase: ElementInputHandle.style.setProperty('text-transform', 'lowercase');
        wecMixedCase: ElementInputHandle.style.setProperty('text-transform', 'capitalize');
        wecNormal: ElementInputHandle.style.setProperty('text-transform', 'initial');
      end;
    end;

    ElementInputHandle.readOnly := IsReadOnly;

    if TextHint <> '' then
      ElementInputHandle.placeholder := TextHint;

    if not isLinked then
    begin
      ElementInputHandle['type'] := GetInputType;
      ElementInputHandle['role'] := 'textbox';

      if AutoCompletion <> acOff then
      begin
        TJSHTMLElement(ElementInputHandle).setAttribute('autocomplete','on');
        TJSHTMLElement(ElementInputHandle).setAttribute('name', GetAutoCompletionName(AutoCompletion));
      end
      else
      begin
        TJSHTMLElement(ElementInputHandle).removeAttribute('autocomplete');
        TJSHTMLElement(ElementInputHandle).removeAttribute('name');
      end;

      case Alignment of
      taLeftJustify: TJSHTMLElement(ElementInputHandle).style.removeProperty('text-align');
      taCenter: TJSHTMLElement(ElementInputHandle).style.setProperty('text-align', 'center');
      taRightJustify: TJSHTMLElement(ElementInputHandle).style.setProperty('text-align', 'right');
      end;
    end;

    if AutoFocus then
      ElementInputHandle.setAttribute('autofocus','')
    else
      ElementInputHandle.removeAttribute('autofocus');

    if Required then
      ElementInputHandle.setAttribute('required','')
    else
      ElementInputHandle.removeAttribute('required');

    if FPattern <> '' then
      ElementInputHandle.setAttribute('pattern',FPattern)
    else
      ElementInputHandle.removeAttribute('pattern');

    if MaxLength <= 0 then
      ElementInputHandle.removeAttribute('maxLength')
    else
      ElementInputHandle.maxLength := MaxLength;

    ElementInputHandle.value := GetDisplayText;

    if not Numeric and not isLinked and not IsCustomEditor then
    begin
      ss := FSelStart;
      sl := FSelStart + FSelLength;
      e := ElementInputHandle;
      asm
        setTimeout(function() {
          e.setSelectionRange(ss, sl);
        }, 1);
      end;
    end;

  end;
end;
{$HINTS ON}

function TCustomEdit.Validate(AValue: string): boolean;
var
  i: integer;
  Key: char;
  isvalid: boolean;
begin
  Result := true;

  for i := 1 to Length(AValue) do
  begin
    Key := AValue[i];
    isValid := true;

    case EditType of
    weNumeric: isValid := (Key in ['0'..'9']);
    weSignedNumeric: isValid := (Key in ['0'..'9','+','-']);
    weFloat: isValid := (Key in ['0'..'9',',','.']);
    weSignedFloat: isValid := (Key in ['0'..'9',',','.','+','-']);
    weHex: isValid := (Key in ['0'..'9','A'..'F']);
    end;

    if not IsValid then
    begin
      Result := false;
      break;
    end;
  end;
end;

procedure TCustomEdit.SelectAll;
begin
  if Assigned(ElementInputHandle) then
    ElementInputHandle.select;
end;

procedure TCustomEdit.SetAlignment(const Value: TAlignment);
begin
  FAlignment := Value;
  UpdateElement;
end;

procedure TCustomEdit.SetAutoCompletion(const Value: TAutoCompletion);
begin
  if (FAutoCompletion <> Value) then
  begin
    FAutoCompletion := Value;
    UpdateElement;
  end;
end;

procedure TCustomEdit.SetAutoFocus(const Value: boolean);
begin
  if FAutoFocus <> Value then
  begin
    FAutoFocus := Value;
    UpdateElement;
  end;
end;

procedure TCustomEdit.SetAutoSelect(const Value: Boolean);
begin
  if FAutoSelect <> Value then
  begin
    FAutoSelect := Value;
    UpdateElement;
  end;
end;

procedure TCustomEdit.SetAutoSize(const Value: Boolean);
begin
  if FAutoSize <> Value then
  begin
    FAutoSize := Value;
    UpdateElement;
  end;
end;

procedure TCustomEdit.SetCharCase(AValue: TEditCharCase);
begin
  FCharcase := AValue;
  UpdateElement;
end;

procedure TCustomEdit.SetHideSelection(const Value: Boolean);
begin
  FHideSelection := Value;
  UpdateElement;
end;

procedure TCustomEdit.SetMaxLength(AValue: integer);
begin
  FMaxLength := AValue;
  UpdateElementData;
end;

function TCustomEdit.GetDisplayText: string;
begin
  Result := FText;
end;

function TCustomEdit.GetElementInputHandle: TJSHTMLInputElement;
begin
  Result := TJSHTMLInputElement(Container);
end;

function TCustomEdit.GetInputType: string;
begin
  if PasswordChar <> '' then
    Result := 'PASSWORD'
  else if Numeric then
    Result := 'NUMBER'
  else
    Result := 'TEXT';
end;

function TCustomEdit.GetSelLength: Integer;
begin
  Result := ElementInputHandle.selectionEnd - ElementInputHandle.selectionStart;
end;

function TCustomEdit.GetSelStart: Integer;
begin
  Result := ElementInputHandle.selectionStart;
end;

procedure TCustomEdit.PersistinHTML;
begin
  ElementInputHandle.setAttribute('value', Text);
end;

{ TSpinEdit }

procedure TSpinEdit.CreateInitialize;
begin
  inherited;
  FIncrement := 1;
  FMaxValue := 0;
  FMinValue := 0;
  ShowFocus := true;
end;

procedure TSpinEdit.BindEvents; 
begin
  inherited;
  if Assigned(ElementInputHandle) then
  begin
    ElementInputHandle.oninput := DoHandleChange;
  end;
end;

function TSpinEdit.GetDisplayText: string;
begin
  Result := IntToStr(FValue);
end;

function TSpinEdit.GetElementInputHandle: TJSHTMLInputElement;
begin
  Result := TJSHTMLInputElement(Container);
end;

function TSpinEdit.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin
  Change;
  Result := True;
end;

procedure TSpinEdit.Change;
begin
  FValue := GetValue;

  if Assigned(OnChange) then
    OnChange(Self);
end;

function TSpinEdit.GetInputType: string;
begin
  Result := 'NUMBER';
end;

function TSpinEdit.GetText: string;
begin
  Result := '';
end;

function TSpinEdit.GetValue: integer;
var
  s: string;
begin
  Result := FValue;

  if not Assigned(Container) then
    Exit;

  s := TJSHTMLInputElement(Container).value;
  if (s <> '') then
    Result := StrToInt(s);
end;

function TSpinEdit.IsReadOnly: boolean;
begin
  Result := FReadOnly;
end;

procedure TSpinEdit.KeyPress(var Key: Char);
begin
  if not (Key in ['0'..'9']) then
  begin
    Key := #0;
    PreventDefault;
    StopPropagation;
  end;
  inherited;
end;

procedure TSpinEdit.SetIncrement(AValue: integer);
begin
  FIncrement := AValue;
  if Assigned(Container) then
    Container['step'] := IntToStr(AValue);
end;

procedure TSpinEdit.SetMaxValue(AValue: integer);
begin
  FMaxValue := AValue;
  if Assigned(Container) then
    Container['max'] := IntToStr(AValue);
end;

procedure TSpinEdit.SetMinValue(AValue: integer);
begin
  FMinValue := AValue;
  if Assigned(Container) then
    Container['min'] := IntToStr(AValue);
end;

procedure TSpinEdit.SetReadOnly(const Value: boolean);
begin
  if (FReadOnly <> Value) then
  begin
    FReadOnly := Value;
    UpdateElement;
  end;
end;

procedure TSpinEdit.SetText(const Value: string);
begin

end;

procedure TSpinEdit.PersistinHTML;
begin
  ElementInputHandle.setAttribute('value',IntToStr(Value));
end;

procedure TSpinEdit.SetValue(AValue: integer);
begin
  FValue := AValue;
  UpdateElement;
end;

procedure TSpinEdit.UpdateElementData;
begin
  inherited;
  if Assigned(Container) then
  begin
    Container['inputmode'] := 'numeric';
    Container['pattern'] := '[0-9]*';
    TJSHTMLInputElement(Container).value := GetDisplayText;
    ElementInputHandle.readOnly := IsReadOnly;
  end;
end;

{ TDateTimePicker }

function TDateTimePicker.GetInputType: string; 
begin
   if FKind = dtkDate then
    Result := 'DATE'
  else
    Result := 'TIME';
end;

procedure TDateTimePicker.SetDate(AValue: TDateTime);
begin
  if FDate <> AValue then
  begin
    FDate := AValue;
    UpdateElement;
  end;
end;

procedure TDateTimePicker.BindEvents;
begin
  inherited;
  if Assigned(Container) then
  begin
    TJSHTMLInputElement(Container).oninput := DoHandleChange;
  end;
end;

procedure TDateTimePicker.Change;
begin
  FTime := GetTime;
  FDate := GetDate;
  if Assigned(OnChange) then
    OnChange(Self);
end;

function TDateTimePicker.CreateElement: TJSElement;
begin
  Result := inherited CreateElement;
  if Assigned(Container) then
    Container['step'] := '1';
end;

procedure TDateTimePicker.CreateInitialize;
begin
  inherited;
  Date := now;
  ShowFocus := true;
end;

function TDateTimePicker.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin
  Change;
  Result := true;
end;

function TDateTimePicker.GetDate: TDateTime;
var
  str: string;
  ye,mo,da: string;
  yei,moi,dai: word;
  e: integer;
begin
  Result := FTime;
  if not Assigned(Container) then
    Exit;

  str := TJSHTMLInputElement(Container).value;

  if Kind = dtkDate then
  begin
    ye := copy(str,1,4);
    mo := copy(str,6,2);
    da := copy(str,9,2);

    val(ye, yei, e);
    val(mo, moi, e);
    val(da, dai, e);

    if (yei <> 0) and (moi <> 0) and (dai <> 0) then
      Result := EncodeDate(yei, moi, dai)
    else
      Result := 0;
  end
  else
    Result := 0;
end;

procedure TDateTimePicker.SetText(const Value: String);
begin
  if not Assigned(Container) then
    Exit;

 // TJSHTMLInputElement(Container).value := Value;
end;

procedure TDateTimePicker.SetTime(AValue: TDateTime);
begin
  if FTime <> AValue then
  begin
    FTime := AValue;
    UpdateElement;
  end;
end;

procedure TDateTimePicker.UpdateElementData;
begin
  inherited;

  if Assigned(Container) then
  begin
    case Kind of
      dtkTime: TJSHTMLInputElement(Container).value := FormatDateTime(LongTimeFormat, FTime);
      dtkDate: TJSHTMLInputElement(Container).value := FormatDateTime('yyyy-MM-dd', FDate);
    end;
  end;
end;

function TDateTimePicker.GetText: String;
begin
  Result := '';
  if not Assigned(Container) then
    Exit;

  Result := TJSHTMLInputElement(Container).value;
end;

function TDateTimePicker.GetTime: TDateTime;
var
  str: string;
  d: TDateTime;
begin
  Result := FTime;
  if not Assigned(Container) then
    Exit;

  str := TJSHTMLInputElement(Container).value;
  if TryStrToTime(str, d) then
    Result := d;
end;

procedure TDateTimePicker.SetKind(AValue: TDateTimeKind);
begin
  FKind := AValue;
  if Assigned(Container) then
  begin
    if AValue = dtkDate then
      Container['type'] := 'DATE'
    else
      Container['type'] := 'TIME';
  end;
end;

{ TButton }

procedure TButton.CreateInitialize;
begin
  inherited;
  if (csDesigning in ComponentState) then
    Caption := ElementID;
end;

function TButton.CreateElement: TJSElement;
begin
  Result := document.createElement('BUTTON');
  Result['type'] := 'BUTTON';
end;

procedure TButton.SetCaption(const AValue: string);
begin
  if Caption <> AValue then
  begin
    inherited SetCaption(AValue);
    UpdateElementData;
  end;
end;

procedure TButton.SetButtonType(const Value: string);
begin
  FButtonType := Value;
  UpdateElementData;
end;

procedure TButton.UpdateElementData;
var
  acc: string;
begin
  inherited;

  if IsLinked and (Caption = '') then
    Exit;

  if Assigned(ElementHandle) then
  begin
    ElementHandle.innerHTML := ProcessAccelerator(Caption,acc);

    if acc <> '' then
      ElementHandle['accesskey'] := acc;

    ElementHandle['role'] := 'button';
    ElementHandle['aria-label'] := Caption;

    if FButtonType <> '' then
      ElementHandle['type'] := FButtonType
    else
      ElementHandle['type'] := 'BUTTON';
  end;
end;

procedure TButton.UpdateElementVisual;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    ElementHandle.style.removeProperty('outline');
    ElementHandle.style.removeProperty('user-select');
  end;
end;

{ TCheckBox }

procedure TCheckBox.CreateInitialize;
begin
  inherited;
  if (csDesigning in ComponentState) then
     Caption := ElementID;

  ShowFocus := true;
end;

procedure TCheckBox.EnableTab;
var
  cb: TJSHTMLInputElement;
begin
  if TabStop and CanFocus and Assigned(Container) then
  begin
    cb := TJSHTMLInputElement(GetCheckElement);
    if Assigned(cb) then
      cb.setAttribute('tabindex',IntToStr(TabOrder + 1));
  end;
end;

procedure TCheckBox.DisableTab;
var
  cb: TJSHTMLInputElement;
begin
  if Assigned(Container) then
  begin
    cb := TJSHTMLInputElement(GetCheckElement);
    if Assigned(cb) then
      cb.setAttribute('tabindex','-1');
  end;
end;

procedure TCheckBox.Click;
begin
  inherited;
  FChecked := GetChecked;
end;

function TCheckBox.CreateElement: TJSElement;
var
  btn, lbl: TJSElement;
begin
  Result := document.createElement('SPAN');
  btn := document.createElement('INPUT');
  lbl := document.createElement('SPAN');

  btn['TYPE'] := 'CHECKBOX';
  btn['id'] := GetID;
  btn['role'] := 'checkbox';
  lbl['id'] := GetID + 'lbl';
  lbl['value'] := GetID;

  TJSHTMLElement(btn).style.setProperty('float','left');
  TJSHTMLElement(btn).style.setProperty('height','100%');
  TJSHTMLElement(btn).style.setProperty('vertical-align','middle');

  TJSHTMLElement(lbl).style.setProperty('vertical-align','middle');
  TJSHTMLElement(lbl).style.setProperty('min-height','100%');
  TJSHTMLElement(lbl).style.setProperty('height','100%');
  TJSHTMLElement(lbl).style.setProperty('position','absolute');
  TJSHTMLElement(lbl).style.setProperty('overflow','hidden');
  TJSHTMLElement(lbl).style.setProperty('display','inline-flex');
  TJSHTMLElement(lbl).style.setProperty('align-items','center');

  Result.appendChild(btn);
  Result.appendChild(lbl);
end;

function TCheckBox.HandleLabelClick(Event: TJSMouseEvent): Boolean;
var
  chk: TJSHTMLInputElement;
begin
  if Assigned(Container) then
  begin
    chk := TJSHTMLInputElement(GetCheckElement);
    chk.checked := not chk.checked;
  end;
  Result := true;
end;

procedure TCheckBox.Loaded;
var
  lbl: TJSHTMLInputElement;
begin
  inherited;

  if Assigned(Container) then
  begin
    if not IsLinked then
    begin
      lbl := TJSHTMLInputElement(Container.children[1]);
      lbl.OnClick := @HandleLabelClick;
    end;
  end;
end;

procedure TCheckBox.UpdateElementData;
var
  chk: TJSHTMLInputElement;
  btn: TJSElement;
begin
  inherited;

  if Assigned(Container) then
  begin
    chk := TJSHTMLInputElement(GetCheckElement);
    chk.disabled := not Enabled;
    chk.checked := FChecked;
    chk.indeterminate := (State = cbGrayed);

    if not IsLinked then
    begin
      if (Caption <> '') then
        Container.lastElementChild.innerHTML := Caption;

      ElementHandle.setAttribute('tabindex','-1');
    end;

    btn := TJSElement(ElementHandle.firstChild);
    if TabStop and Assigned(btn) then
      btn.setAttribute('tabindex',inttostr(TabOrder));
  end;
end;

procedure TCheckBox.UpdateElementSize;
begin
  inherited;

  if Assigned(Container) and not IsLinked then
  begin
    TJSHTMLElement(Container.firstElementChild).style.setProperty('height','100%');
  end;
end;

procedure TCheckBox.UpdateElementVisual;
begin
  inherited;

  if Assigned(ElementHandle) and not IsLinked then
  begin
    ElementHandle.style.setProperty('user-select','none');
  end;
end;

procedure TCheckBox.SetCaption(const AValue: string);
begin
  if Caption <> AValue then
  begin
    inherited SetCaption(AValue);
    if Assigned(Container) and not IsLinked then
      Container.lastElementChild.innerHTML := AValue;
  end;
end;

procedure TCheckBox.SetEnabled(Value: boolean);
begin
  inherited;
  if Assigned(Container) then
    TJSHTMLInputElement(GetCheckElement).disabled := not Value;
end;

procedure TCheckBox.SetChecked(AValue: boolean);
begin
  FChecked := AValue;
  UpdateElement;
end;

function TCheckBox.GetChecked: boolean;
begin
  if Assigned(Container) then
    FChecked := TJSHTMLInputElement(GetCheckElement).checked;

  Result := FChecked;
end;

function TCheckBox.GetCheckElement: TJSHTMLElement;
begin
  if IsLinked then
    Result := TJSHTMLElement(ElementHandle)
  else
    Result := TJSHTMLElement(Container.firstElementChild);
end;

procedure TCheckBox.SetState(AValue: TCheckBoxState);
begin
  FState := AValue;
  FChecked := FState = cbChecked;
  UpdateElement;
end;

function TCheckBox.GetState: TCheckBoxState;
begin
  Result := FState;
end;

procedure TCheckBox.PersistinHTML;
var
  cb: TJSHTMLInputElement;
begin
  if not Assigned(Container) then
    Exit;

  cb := TJSHTMLInputElement(GetCheckElement);

  if (cb.checked) then
    cb.setAttribute('checked','checked')
  else
    cb.removeAttribute('checked');
end;


{ TRadioButton }

procedure TRadioButton.CreateInitialize;
begin
  inherited;
  if (csDesigning in ComponentState) then
    Caption := ElementID;

  FGroupName := '';
  ShowFocus := true;
end;

procedure TRadioButton.EnableTab;
var
  rb: TJSHTMLInputElement;
begin
  if TabStop and CanFocus and Assigned(Container) then
  begin
    rb := TJSHTMLInputElement(GetRadioElement);
    rb.setAttribute('tabindex',IntToStr(TabOrder + 1));
  end;
end;

procedure TRadioButton.DisableTab;
var
  rb: TJSHTMLInputElement;
begin
  if Assigned(Container) then
  begin
    rb := TJSHTMLInputElement(GetRadioElement);
    rb.setAttribute('tabindex','-1');
  end;
end;

function TRadioButton.CreateElement: TJSElement;
var
  btn, lbl: TJSElement;
begin
  Result := document.createElement('LABEL');
  btn := document.createElement('INPUT');
  lbl := document.createElement('SPAN');
  btn['TYPE'] := 'RADIO';
  btn['id'] := GetID + 'rd';
  btn['name'] := GroupName;
  btn['role'] := 'radio';

  TJSHTMLInputElement(btn).disabled := not Enabled;
  TJSHTMLInputElement(btn).style.setProperty('vertical-align','middle');

  TJSHTMLInputElement(btn).style.setProperty('margin-top','-2px');

  lbl['id'] := GetID + 'lbl';
  lbl['value'] := GetID;

  Result.appendChild(btn);
  Result.appendChild(lbl);
end;

function TRadioButton.HandleLabelClick(Event: TJSMouseEvent): Boolean;
var
  rb: TJSHTMLInputElement;
begin
  if Assigned(Container) then
  begin
    rb := TJSHTMLInputElement(GetRadioElement);
    rb.checked := true;
  end;
  Result := true;
end;

procedure TRadioButton.Loaded;
var
  lbl: TJSHTMLInputElement;
begin
  inherited;

  if Assigned(Container) and not IsLinked then
  begin
    lbl := TJSHTMLInputElement(Container.children[1]);
    if Assigned(lbl) then
      lbl.OnClick := @HandleLabelClick;
  end;
end;

procedure TRadioButton.SetCaption(const AValue: string);
begin
  if (Caption <> AValue) then
  begin
    inherited SetCaption(AValue);
    UpdateElementData;
  end;
end;

procedure TRadioButton.SetEnabled(Value: boolean);
begin
  inherited;
  if Assigned(Container) then
    TJSHTMLInputElement(GetRadioElement).disabled := not Value;
end;

procedure TRadioButton.SetGroupName(AValue: string);
begin
  FGroupName := AValue;
  UpdateElementData;
end;

procedure TRadioButton.UpdateElementData;
var
  btn: TJSElement;

begin
  inherited;
  if Assigned(Container) then
  begin
    Container.firstElementChild['name'] := FGroupName;

    if not IsLinked then
    begin
      if (Caption <> '') then
        Container.lastElementChild.innerHTML := Caption;

      ElementHandle.setAttribute('tabindex','-1');
    end;

    btn := TJSElement(ElementHandle.firstChild);
    if TabStop and Assigned(btn) then
      btn.setAttribute('tabindex',inttostr(TabOrder));
  end;
end;

procedure TRadioButton.UpdateElementSize;
var
  el: TJSElement;
begin
  inherited;
  el := document.getElementById(GetID + 'rd');
  if Assigned(el) and not IsLinked then
  begin
    TJSHTMLElement(el).style.setProperty('height',InttoStr(Height)+'px');
  end;
end;

procedure TRadioButton.UpdateElementVisual;
begin
  inherited;

  if Assigned(ElementHandle) and not IsLinked then
  begin
    ElementHandle.style.removeProperty('overflow');
    ElementHandle.style.setProperty('user-select','none');
  end;

end;

procedure TRadioButton.SetChecked(AValue: boolean);
begin
  FChecked := AValue;
  if Assigned(Container) then
    TJSHTMLInputElement(GetRadioElement).checked := AValue;
end;

function TRadioButton.GetChecked: boolean;
begin
  Result := FChecked;
  if Assigned(Container) then
    Result := TJSHTMLInputElement(GetRadioElement).checked;
end;

function TRadioButton.GetRadioElement: TJSHTMLElement;
begin
  if IsLinked then
    Result := TJSHTMLElement(ElementHandle)
  else
    Result := TJSHTMLElement(Container.firstElementChild);
end;

procedure TRadioButton.PersistInHTML;
var
  rb: TJSHTMLInputElement;
begin
  if not Assigned(Container) then
    Exit;

  rb := TJSHTMLInputElement(GetRadioElement);
  if (rb.checked) then
    rb.setAttribute('checked','checked')
  else
    rb.removeAttribute('checked');
end;

{ TListBox }

procedure TListBox.CreateInitialize;
begin
  inherited;
  FItems := TStringList.Create;
  TStringList(FItems).OnChange := DoItemsChange;
  FMultiSelect := false;
  FSelected := TList.Create;
  ShowFocus := true;
  Width := 160;
  Height := 180;
end;

function TListBox.CreateElement: TJSElement;
begin
  Result := document.createElement('SELECT');
  Result['Size'] := '2';
end;

destructor TListBox.Destroy;
begin
  FItems.Free;
  FSelected.Free;
  inherited;
end;

function TListBox.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin
  Change;
  Result := True;
end;

procedure TListBox.AddItem(Item: string; AObject: TObject);
begin
  FItems.AddObject(Item, AObject);
end;

procedure TListBox.BindEvents;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    ElementHandle.onchange := DoHandleChange;
  end;
end;

procedure TListBox.Change;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TListBox.ClearSelection;
var
  i: integer;
begin
  ItemIndex := -1;

  if not Assigned(Container) then
    Exit;  

  for i := 0 to TJSHTMLSelectElement(Container).options.length - 1 do
    TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[i]).selected := false;
end;

procedure TListBox.SelectAll;
var
  i: integer;
begin
  if not Assigned(Container) then
    Exit;  

  for i := 0 to TJSHTMLSelectElement(Container).options.length - 1 do
  begin
    Selected[i] := true;
    TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[i]).selected := true;
  end;
end;

procedure TListBox.SetItems(AItems: TStrings);
begin
  FItems.Assign(AItems);
end;

function TListBox.GetCount: Integer;
begin
  Result := FItems.Count;
end;

function TListBox.GetElementSelectHandle: TJSHTMLSelectElement;
begin
  Result := TJSHTMLSelectElement(Container);
end;

function TListBox.GetItemIndex: integer;
begin
  Result := FItemIndex;
  if Assigned(Container) then
    Result := TJSHTMLSelectElement(Container).selectedIndex;
end;

procedure TListBox.PersistinHTML; 
var
  i: integer;
begin
  if not Assigned(Container) then
    Exit;

  for i := 0 to TJSHTMLSelectElement(Container).options.length - 1 do
  begin
    if TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[i]).selected then
      TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[i]).setAttribute('selected','selected')
    else
      TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[i]).removeAttribute('selected');
  end;
end;

procedure TListBox.SetItemHeight(const Value: Integer);
begin
  FItemHeight := Value;
end;

procedure TListBox.SetItemIndex(AIndex: integer);
begin
  if FItemIndex <> AIndex then
  begin
    FItemIndex := AIndex;
    UpdateElement;
  end;
end;

procedure TListBox.SetMultiSelect(AValue: boolean);
begin
  FMultiSelect := AValue;
  if Assigned(Container) then
    TJSHTMLSelectElement(Container).multiple := AValue;
end;

function TListBox.GetSelCount: integer;
var
  i: integer;
begin
  Result := 0;

  if Assigned(Container) then
  begin
    for i := 0 to FItems.Count - 1 do
    begin
      if TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[i]).selected then
        inc(Result);
    end;
  end;
end;

function TListBox.GetSelected(AIndex: integer): boolean;
begin
  if (AIndex < FSelected.Count) then
    Result := boolean(FSelected.Items[AIndex])
  else
    Result := False;

  if Assigned(Container) and (AIndex < TJSHTMLSelectElement(Container).options.length) then
    Result := TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[Aindex]).selected;
end;


procedure TListBox.Loaded;
begin
  inherited;
  DoUpdateList;
  UpdateElement;
end;

procedure TListBox.SetSelected(AIndex: integer; AValue: boolean);
begin  
  while (AIndex >= FSelected.Count) do
    FSelected.Add(false);

  FSelected.Items[AIndex] := AValue;

  if Assigned(Container) then
    TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[Aindex]).selected := AValue;
end;

procedure TListBox.SetSorted(const Value: Boolean);
begin
  FSorted := Value;
  TStringList(Items).Sort;
end;

procedure TListBox.UpdateElementData;
begin
  inherited;

  if Assigned(ElementSelectHandle) then
  begin
    ElementSelectHandle.style.setProperty('overflow', 'auto');
    ElementSelectHandle.selectedIndex := FItemIndex;
  end;
end;

procedure TListBox.UpdateElementVisual;
begin
  inherited;

  if Color <> clWindow then
    ElementHandle.style.setProperty('background-Color',ColorToHTML(Color));

  if Assigned(ElementSelectHandle) then
  begin
    ElementSelectHandle.style.setProperty('overflow', 'auto');
    ElementSelectHandle['role'] := 'listbox';
    ElementSelectHandle['aria-busy'] := 'true';
  end;
end;

procedure TListBox.DoItemsChange(Sender: TObject);
begin
  DoUpdateList;
end;

procedure TListBox.DoUpdateList;
var
  i: integer;
  opt: TJSHTMLOptionElement;
begin
  if not Assigned(Container) then
    Exit;

  if IsUpdating then
    Exit;

  for i := TJSHTMLSelectElement(Container).options.length - 1 downto 0 do
    TJSHTMLSelectElement(Container).remove(i);

  for i := 0 to FItems.Count - 1 do
  begin
    opt := TJSHTMLOptionElement.New(FItems[i]);
    opt['role'] := 'listitem';
    TJSHTMLSelectElement(Container).add(opt);
    Selected[i] := false;
  end;

  UpdateElementData;
end;

procedure TListBox.EndUpdate;
begin
  inherited;
  DoUpdateList;
end;

{ TCustomComboBox }

procedure TCustomComboBox.AddItem(Item: string; AObject: TObject);
begin
  FItems.AddObject(Item, AObject);
end;

procedure TCustomComboBox.BindEvents;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    ElementHandle.onchange := DoHandleChange;
  end;
end;

procedure TCustomComboBox.Clear;
begin
  Items.Clear;
end;

procedure TCustomComboBox.CreateInitialize;
begin
  inherited;
  FItems := TStringList.Create;
  TStringList(FItems).OnChange := DoItemsChange;
  ShowFocus := true;
end;

function TCustomComboBox.CreateElement: TJSElement;
begin
  Result := document.createElement('SELECT');
end;

destructor TCustomComboBox.Destroy;
begin
  FItems.Free;
  inherited;
end;

procedure TCustomComboBox.SetItems(AItems: TStrings);
begin
  FItems.Assign(AItems);
end;

procedure TCustomComboBox.SetStyle(const Value: TComboBoxStyle);
begin
  FStyle := Value;
end;

procedure TCustomComboBox.SetText(const Value: string);
var
  I: Integer;
begin
  for I := 0 to Items.Count - 1 do
  begin
    if Value = Items[I] then
      ItemIndex := I;
  end;
end;

procedure TCustomComboBox.UpdateElementData;
begin
  inherited;

  if Assigned(ElementSelectHandle) then
  begin
    ElementSelectHandle.selectedIndex := FItemIndex;
    ElementSelectHandle['role'] := 'combobox';
  end;
end;

function TCustomComboBox.GetElementSelectHandle: TJSHTMLSelectElement;
begin
  Result := TJSHTMLSelectElement(Container);
end;

function TCustomComboBox.GetItemIndex: integer;
begin
  Result := FItemIndex;
  if Assigned(Container) then
    Result := TJSHTMLSelectElement(Container).selectedIndex;
end;

function TCustomComboBox.GetText: string;
begin
  if ItemIndex >= 0 then
    Result := Items[ItemIndex]
  else
    Result := '';
end;

procedure TCustomComboBox.Change;
begin
  FItemIndex := GetItemIndex;
  if Assigned(OnChange) then
    OnChange(Self);
end;

function TCustomComboBox.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin
  Change;
  Result := True;
end;

procedure TCustomComboBox.Loaded;
begin
  inherited;
  DoUpdateList;
end;

procedure TCustomComboBox.PersistinHTML;
var
  sel: TJSHTMLElement;
begin
  inherited;

  sel := TJSHTMLElement(ElementHandle.children[ItemIndex]);
  if Assigned(sel) then
    sel.setAttribute('selected','selected');
end;

procedure TCustomComboBox.SetDroppedDown(const Value: Boolean);
begin
  FDroppedDown := Value;
end;

procedure TCustomComboBox.SetItemIndex(AIndex: integer);
begin
  if FItemIndex <> AIndex then
  begin
    FItemIndex := AIndex;
    UpdateElement;
  end;
end;

procedure TCustomComboBox.DoItemsChange(Sender: TObject);
begin
  DoUpdateList;
end;

procedure TCustomComboBox.DoUpdateList;
var
  i: integer;
  s: string;
  opt: TJSElement;
begin
  if not Assigned(Container) then
    Exit;

  for i := TJSHTMLSelectElement(Container).options.length - 1 downto 0 do
    TJSHTMLSelectElement(Container).remove(i);

  for i := 0 to FItems.Count - 1 do
  begin
    s := FItems[i];
    opt := document.createElement('OPTION');
    opt['value'] := s;
    opt.innerHTML := s;
    Container.appendChild(opt);
  end;

  UpdateElement;
end;


{ TFontPicker }

procedure TFontPicker.CreateInitialize;
var
  tst: boolean;
  
  procedure Add(s: string);
  begin
    asm
      tst = d.detect(s);
    end;
    if tst then
      Self.Items.Add(s);
  end;

begin
  inherited;

  asm
    var Detector = function() {
    // a font will be compared against all the three default fonts.
    // and if it doesn't match all 3 then that font is not available.
    var baseFonts = ['monospace', 'sans-serif', 'serif'];

    //we use m or w because these two characters take up the maximum width.
    // And we use a LLi so that the same matching fonts can get separated
    var testString = "mmmmmmmmmmlli";

    //we test using 72px font size, we may use any size. I guess larger the better.
    var testSize = '72px';

    var h = document.getElementsByTagName("body")[0];

    // create a SPAN in the document to get the width of the text we use to test
    var s = document.createElement("span");
    s.style.fontSize = testSize;
    s.innerHTML = testString;
    var defaultWidth = {};
    var defaultHeight = {};
    for (var index in baseFonts) {
        //get the default width for the three base fonts
        s.style.fontFamily = baseFonts[index];
        h.appendChild(s);
        defaultWidth[baseFonts[index]] = s.offsetWidth; //width for the default font
        defaultHeight[baseFonts[index]] = s.offsetHeight; //height for the defualt font
        h.removeChild(s);
    }

    function detect(font) {
        var detected = false;
        for (var index in baseFonts) {
            s.style.fontFamily = font + ',' + baseFonts[index]; // name of the font along with the base font for fallback.
            h.appendChild(s);
            var matched = (s.offsetWidth != defaultWidth[baseFonts[index]] || s.offsetHeight != defaultHeight[baseFonts[index]]);
            h.removeChild(s);
            detected = detected || matched;
        }
        return detected;
    }

    this.detect = detect;
    };

    var d = new Detector();
    //tst = d.detect("Arial");
  end;

  Add('Arial');
  Add('Arial Black');
  Add('Arial Narrow');
  Add('Courier');
  Add('Courier New');
  Add('Georgia');
  Add('Lucida Console');
  Add('Modena');
  Add('Monotype Corsiva');
  Add('Papyrus');
  Add('Tahoma');
  Add('Times');
  Add('Times New Roman');
  Add('Trebuchet MS');
  Add('Verdana');
  Add('Verona');
end;

{ TFontSizePicker }

procedure TFontSizePicker.CreateInitialize;
begin
  inherited;
  PickerMode := fmPointSize;
end;

procedure TFontSizePicker.Init;
begin
  Items.Clear;

  case PickerMode of
    fmPointSize:
    begin
      Items.Add('8');
      Items.Add('9');
      Items.Add('10');
      Items.Add('11');
      Items.Add('12');
      Items.Add('14');
      Items.Add('16');
      Items.Add('18');
      Items.Add('20');
      Items.Add('22');
      Items.Add('24');
      Items.Add('26');
      Items.Add('28');
      Items.Add('36');
      Items.Add('48');
      Items.Add('72');
    end;
    fmRelativeSize:
    begin
      Items.Add('8');
      Items.Add('9');
      Items.Add('10');
      Items.Add('14');
      Items.Add('18');
      Items.Add('24');
      Items.Add('36');
    end;
  end;
end;

procedure TFontSizePicker.SetPickerMode(const AValue: TFontSizePickerMode);
begin
  FPickerMode := AValue;
  Init;
end;


{ TCustomMemo }

function TCustomMemo.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin
  Change;
  Result := true;
end;

procedure TCustomMemo.BindEvents;
begin
  inherited;
  if Assigned(ElementInputHandle) then
    ElementInputHandle.oninput := DoHandleInput;

  if Assigned(ElementInputHandle) then
    ElementInputHandle.onchange := DoHandleChange;
end;

procedure TCustomMemo.Change;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TCustomMemo.Clear;
begin
  Lines.Clear;
end;

procedure TCustomMemo.CreateInitialize;
begin
  inherited;
  FLines := TStringList.Create;
  TStringList(FLines).OnChange := DoLinesChange;
  Width := 400;
  Height := 300;
  ShowFocus := true;
  ClipChildren := false;
end;

function TCustomMemo.CreateElement: TJSElement;
begin
  Result := document.createElement('TEXTAREA');
end;

destructor TCustomMemo.Destroy;
begin
  FLines.Free;
  inherited;
end;

function TCustomMemo.DoHandleInput(Event: TEventListenerEvent): Boolean;
begin
  GetText;
  Change;
  Result := True;
end;

procedure TCustomMemo.DoLinesChange(Sender: TObject);
begin
  UpdateElement;
end;

procedure TCustomMemo.SetAutoSize(const Value: Boolean);
begin
  if FAutoSize <> Value then
  begin
    FAutoSize := Value;
    UpdateElement;
  end;
end;

procedure TCustomMemo.SetLines(ALines: TStrings);
begin
  FLines.Assign(ALines);
end;

procedure TCustomMemo.SetReadOnly(const Value: boolean);
begin
  if (FReadOnly <> Value) then
  begin
    FReadOnly := Value;
    UpdateElement;
  end;
end;

procedure TCustomMemo.SetSelLength(const Value: Integer);
begin
  if FSelLength <> Value then
  begin
    FSelLength := Value;
    UpdateElement;
  end;
end;

procedure TCustomMemo.SetSelStart(const Value: Integer);
begin
  if FSelStart <> Value then
  begin
    FSelStart := Value;
    UpdateElement;
  end;
end;

procedure TCustomMemo.SetText(const Value: String);
begin
  FLines.Text := Value;
end;

procedure TCustomMemo.SetTextHint(const Value: string);
begin
  if FTextHint <> Value then
  begin
    FTextHint := Value;
    UpdateElement;
  end;

end;

{$HINTS OFF}
procedure TCustomMemo.UpdateElementData;
var
  ss,sl: integer;
  e: TJSHTMLElement;
begin
  inherited;
  if Assigned(ElementInputHandle) and not FBlockChange then
  begin
    ElementInputHandle.value := GetDisplayText;
    ElementInputHandle.setSelectionRange(SelStart, SelStart + SelLength);
    if not IsLinked then
      ElementInputHandle.style.setProperty('resize', 'none');
    ElementInputHandle.readOnly := IsReadOnly;

    if TextHint <> '' then
      ElementInputHandle.placeholder := TextHint;

    if not isLinked then
    begin
      ss := FSelStart;
      sl := FSelStart + FSelLength;
      e := ElementInputHandle;
      asm
        setTimeout(function() {
          e.setSelectionRange(ss, sl);
        }, 1);
      end;
    end;
  end;
end;
{$HINTS ON}

procedure TCustomMemo.UpdateElementVisual;
begin
  inherited;
  if Assigned(ElementInputHandle) and not FBlockChange and not IsLinked then
  begin
    ElementInputHandle.style.setProperty('overflow', 'auto');
    ElementInputHandle.style.setProperty('margin', '0');
    ElementInputHandle.style.setProperty('padding', '0');

    if (Color <> clWindow) then
      ElementInputHandle.style.setProperty('background-color', ColorToHtml(Color));
  end;
end;

function TCustomMemo.GetText: String;
begin
  if Assigned(ElementInputHandle) then
  begin
    FBlockChange := True;
    FLines.Text := ElementInputHandle.value;
    FBlockChange := False;
  end;

  Result := FLines.Text;
end;

function TCustomMemo.IsReadOnly: boolean;
begin
  Result := FReadOnly;
end;

procedure TCustomMemo.PersistinHTML;
begin
  ElementInputHandle.innerHTML := Lines.Text;
end;

function TCustomMemo.GetDisplayText: string;
begin
  Result := FLines.Text;
end;

function TCustomMemo.GetElementInputHandle: TJSHTMLInputElement;
begin
  Result := TJSHTMLInputElement(Container);
end;

function TCustomMemo.GetSelLength: Integer;
begin
  Result := ElementInputHandle.selectionEnd - ElementInputHandle.selectionStart;
end;

function TCustomMemo.GetSelStart: Integer;
begin
  Result := ElementInputHandle.selectionStart;
end;

{ TRadioGroup }

procedure TRadioGroup.CreateInitialize;
begin
  inherited;
  FColumns := 1;
  FItems := TStringList.Create;
  TStringList(FItems).OnChange := DoItemsChange;
  FItemIndex := -1;
  FOldItemIndex := -2;
  BorderWidth := 4;
end;

procedure TRadioGroup.Change;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

function TRadioGroup.CreateElement: TJSElement;
var
  legend: TJSElement;
begin
  Result := document.createElement('FIELDSET');
  legend := document.createElement('LEGEND');
  Result.appendChild(legend);
  legend.innerHTML := Caption;
  if Caption = '' then
    TJSHTMLElement(legend).style.setProperty('display', 'none')
  else
    TJSHTMLElement(legend).style.setProperty('display', '');

  TJSHTMLElement(Result).style.setProperty('display', 'inline-block');

  TJSHTMLElement(Result).style.setProperty('-webkit-padding-before','0px');
  TJSHTMLElement(Result).style.setProperty('-webkit-padding-after','0px');
  TJSHTMLElement(Result).style.setProperty('-webkit-padding-end','0px');
  TJSHTMLElement(Result).style.setProperty('-webkit-padding-start','0px');
end;

destructor TRadioGroup.Destroy;
begin
  FItems.Free;
  inherited;
end;

procedure TRadioGroup.Loaded;
begin
  inherited;
  DoUpdateList;
end;

procedure TRadioGroup.SetCaption(const AValue: string);
begin
  inherited SetCaption(AValue);

  if not Assigned(Container) then
    Exit;

  Container.firstElementChild.innerHTML := AValue;

  if AValue = '' then
    TJSHTMLElement(Container.firstElementChild).style.setProperty('display', 'none')
  else
    TJSHTMLElement(Container.firstElementChild).style.setProperty('display', '');
end;

procedure TRadioGroup.SetColumns(AValue: integer);
begin
  if (FColumns > 0) and (FColumns <> AValue) then
  begin
    FColumns := AValue;
    DoUpdateList;
  end;
end;

procedure TRadioGroup.SetItems(AItems: TStrings);
begin
  FItems.Assign(AItems);
  DoUpdateList;
end;

function TRadioGroup.GetItemIndex: integer;
var
  el: TJSElement;
  i: integer;
begin
  Result := FItemIndex;

  for i := 0 to FItems.Count -1 do
  begin
    el := document.getElementByID(GetID + IntToStr(i) + 'rd');
    if Assigned(el) then
    begin
      if TJSHTMLInputElement(el).checked then
        Result := i;
    end;
  end;
end;

procedure TRadioGroup.SetItemIndex(AIndex: integer);
var
  el: TJSElement;
begin
  // clear selection
  if (AIndex = -1) and (FItemIndex >= 0) then
  begin
    el := document.getElementByID(GetID + IntToStr(FItemIndex) + 'rd');
    if Assigned(el) then
      TJSHTMLInputElement(el).checked := False;
  end
  else
  begin
    // new selection
    FItemIndex := AIndex;
    if (AIndex >= 0) and (AIndex < Items.Count) then
    begin
      el := document.getElementByID(GetID + IntToStr(AIndex) + 'rd');
      if Assigned(el) then
        TJSHTMLInputElement(el).checked := True;
    end;
  end;
end;

function TRadioGroup.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin
  Change;
  Result := true;
end;

procedure TRadioGroup.DoItemsChange(Sender: TObject);
begin
  DoUpdateList;
end;

procedure TRadioGroup.DoRadioClick(Sender: TObject);
begin
  if ItemIndex = FOldItemIndex then
    Exit;

  Change;

  FOldItemIndex := ItemIndex;
end;

procedure TRadioGroup.DoUpdateList;
var
  i: integer;
  s: string;
  rd: TRadioButton;
  dx,dy,col,row: integer;
begin
  if not Assigned(Container) then
    Exit;

  if FItems.Count = 0 then
    Exit;

  dy := Height div (FItems.Count div Columns);
  dx := Width div FColumns;
  col := 0;
  row := 0;

  Container.innerHTML := '';

  for i := 0 to FItems.Count - 1 do
  begin
    s := FItems[i];
    rd := TRadioButton.Create(GetID + IntToStr(i));
    rd.ParentFont := false;
    rd.Font.Assign(Font);
    rd.Parent := Parent;
    rd.Caption := s;
    rd.GroupName := GetID;
    rd.Top := 16 + row * dy;
    rd.Left := 4 + col * dx;
    rd.WidthStyle := ssPercent;
    rd.WidthPercent := 100;
    rd.ElementPosition := epAbsolute;
    rd.OnClick := DoRadioClick;

    if FItemIndex = i then
      rd.Checked := true;

    TJSHTMLElement(rd.Container).style.setProperty('float', 'left');
    TJSHTMLElement(rd.Container).style.setProperty('width',  IntToStr(Trunc(100 / FColumns)) + '%');

    if i mod FColumns = 0 then
      TJSHTMLElement(rd.Container).style.setProperty('clear', 'left');

    Container.appendChild(rd.Container);

    if col < FColumns - 1 then
      inc(col)
    else
    begin
      col := 0;
      inc(row);
    end;
  end;
end;

procedure TRadioGroup.EndUpdate;
begin
  inherited;
  DoUpdateList;
end;

{ TColorPicker }

procedure TColorPicker.CreateInitialize;
begin
  inherited;
  FColor := clBlack;
end;

procedure TColorPicker.BindEvents; 
begin
  inherited;
  if Assigned(ElementInputHandle) then
  begin
    ElementInputHandle.oninput := DoHandleChange;
  end;
end;

function TColorPicker.GetElementInputHandle: TJSHTMLInputElement;
begin
  Result := TJSHTMLInputElement(Container);
end;

procedure TColorPicker.UpdateElementVisual;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    ElementHandle.style.setProperty('padding','0px'); 
    ElementHandle.style.setProperty('width', IntToStr(Width - 2) + 'px');
    ElementHandle.style.setProperty('height',IntToStr(Height - 2) + 'px');
  end;
end;

function TColorPicker.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin   
  Select;
  Result := true;
end;

procedure TColorPicker.Select;
begin
  if Assigned(OnSelect) then
    OnSelect(Self);
end;

function TColorPicker.GetColor: TColor;
begin
  Result := FColor;

  if Assigned(Container) then
    Result := HexToColor(TJSHTMLInputElement(Container).value);
end;

procedure TColorPicker.SetColor(AValue: TColor);
begin
  FColor := AValue;

  if Assigned(Container) then
    TJSHTMLInputElement(Container).value := ColorToHTML(AValue);
end;

function TColorPicker.GetInputType: string;
begin 
  Result := 'COLOR';
end;

{ TScrollBar }

procedure TScrollBar.CreateInitialize;
begin
  inherited;
  TabStop := True;
  FKind := sbHorizontal;
  FPosition := 0;
  FMin := 0;
  FMax := 100;
  FSmallChange := 1;
  FLargeChange := 1;

  FContent := TScrollBarContent.Create(Self);
  FContent.Parent := Self;
  FContent.Width := 121;
  FContent.Height := 17;

  Width := 121;
  Height := 17;

  UpdateContent;
end;

procedure TScrollBar.BindEvents;
begin
  inherited;
  if Assigned(ElementHandle) then
    ElementHandle.onscroll := @DoScroll;
end;

function TScrollBar.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
end;

destructor TScrollBar.Destroy;
begin
  FContent.Free;
  inherited;
end;

function TScrollBar.DoScroll(Event: TJSUIEvent): Boolean;
begin
  FScrolling := True;
  if Assigned(OnChange) then
    OnChange(Self);

  FScrolling := False;
  Result := True;
end;

function TScrollBar.GetPageSize: Integer;
begin
  Result := FPageSize;
  if Result = 0 then
    Result := 25;
end;

function TScrollBar.GetValue(XYPos: Double): Double;
begin
  Result := 0;
  if Assigned(FContent) then
  begin
    case Kind of
      sbHorizontal: Result := (XYPos / FContent.Width) * (Max - Min);
      sbVertical: Result := (XYPos / FContent.Height) * (Max - Min);
    end;
  end;
end;

function TScrollBar.GetPosition: Integer;
begin
  Result := 0;
  if Assigned(Element) then
  begin
    case Kind of
      sbHorizontal: Result := Round(GetValue(Element.scrollLeft));
      sbVertical: Result := Round(GetValue(Element.scrollTop));
    end;
  end;
end;

procedure TScrollBar.Loaded;
begin
  inherited;
  UpdateContent;
end;

procedure TScrollBar.SetBounds(X, Y, AWidth, AHeight: Integer);
begin
  inherited;
  UpdateContent;
end;

procedure TScrollBar.SetKind(const Value: TScrollBarKind);
begin
  if FKind <> Value then
  begin
    FKind := Value;
//    if not (csLoading in ComponentState) then
    begin
      case Kind of
        sbHorizontal:
        begin
          SetBounds(Left, Top, Height, 17)
        end;
        sbVertical:
        begin
          SetBounds(Left, Top, 17, Width)
        end;
      end;
    end;
    UpdateElement;
    UpdateContent;
  end;
end;

procedure TScrollBar.SetMax(const Value: Integer);
begin
  if FMax <> Value then
  begin
    FMax := Value;
    UpdateContent;
  end;
end;

procedure TScrollBar.SetMin(const Value: Integer);
begin
  if FMin <> Value then
  begin
    FMin := Value;
    UpdateContent;
  end;
end;

procedure TScrollBar.SetPageSize(const Value: Integer);
begin
  if FPageSize <> Value then
  begin
    FPageSize := Value;
    UpdateContent;
  end;
end;

procedure TScrollBar.SetPosition(const Value: Integer);
begin
  if FPosition <> Value then
  begin
    FPosition := Value;
    UpdateContent;
  end;
end;

procedure TScrollBar.UpdateElementVisual;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    ElementHandle.style.setProperty('overflow', 'auto');
    case Kind of
      sbHorizontal:
      begin
        ElementHandle.style.setProperty('overflow-y', 'hidden');
        ElementHandle.style.setProperty('overflow-x', '');
      end;
      sbVertical:
      begin
        ElementHandle.style.setProperty('overflow-y', '');
        ElementHandle.style.setProperty('overflow-x', 'hidden');
      end;
    end;
  end;
end;

procedure TScrollBar.UpdateContent;
var
  x, y, w, h: Integer;
  ps: Integer;
  v: Single;
begin
  if not Assigned(FContent) or not Assigned(ElementHandle) or FScrolling then
    Exit;

  ps := PageSize;
  v := (Max - Min) / ps;

  case Kind of
    sbHorizontal:
    begin
      y := 0;
      h := Height;
      w := Round(v * Width);
      x := Round(((FPosition - Min) / (Max - Min)) * w);
    end;
    sbVertical:
    begin
      x := 0;
      w := Width;
      h := Round(v * Height);
      y := Round(((FPosition - Min) / (Max - Min)) * h);
    end;
  end;

  FContent.SetBounds(0, 0, w, h);
  ElementHandle.scrollLeft := x;
  ElementHandle.scrollTop := y;
end;

{ TScrollBarContent }

function TScrollBarContent.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
end;

end.
