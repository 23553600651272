{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2019                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Controls;

interface

uses
  Classes, WEBLib.Graphics, Types, SysUtils, Web;

const
  VK_CANCEL = 3;
  VK_BACK = 8;
  VK_TAB = 9;
  VK_RETURN = 13;
  VK_SHIFT = 16;
  VK_CONTROL = 17;
  VK_MENU = 18;
  VK_PAUSE = 19;
  VK_CAPITAL = 20;
  VK_ESCAPE = 27;
  VK_SPACE = 32;
  VK_PRIOR = 33;
  VK_NEXT = 34;
  VK_END = 35;
  VK_HOME = 36;
  VK_LEFT = 37;
  VK_UP = 38;
  VK_RIGHT = 39;
  VK_DOWN = 40;
  VK_PRINT = 42;
  VK_SNAPSHOT = 44;
  VK_INSERT = 45;
  VK_DELETE = 46;
  VK_HELP = 47;
  VK_LWIN = 91;
  VK_RWIN = 92;
  VK_NUMPAD0 = 96;
  VK_NUMPAD1 = 97;
  VK_NUMPAD2 = 98;
  VK_NUMPAD3 = 99;
  VK_NUMPAD4 = 100;
  VK_NUMPAD5 = 101;
  VK_NUMPAD6 = 102;
  VK_NUMPAD7 = 103;
  VK_NUMPAD8 = 104;
  VK_NUMPAD9 = 105;
  VK_MULTIPLY = 106;
  VK_ADD = 107;
  VK_SEPARATOR = 108;
  VK_SUBTRACT = 109;
  VK_DECIMAL = 110;
  VK_DIVIDE = 111;
  VK_F1 = 112;
  VK_F2 = 113;
  VK_F3 = 114;
  VK_F4 = 115;
  VK_F5 = 116;
  VK_F6 = 117;
  VK_F7 = 118;
  VK_F8 = 119;
  VK_F9 = 120;
  VK_F10 = 121;
  VK_F11 = 122;
  VK_F12 = 123;
  VK_F13 = 124;
  VK_F14 = 125;
  VK_F15 = 126;
  VK_F16 = 127;
  VK_F17 = 128;
  VK_F18 = 129;
  VK_F19 = 130;
  VK_F20 = 131;
  VK_F21 = 132;
  VK_F22 = 133;
  VK_F23 = 134;
  VK_F24 = 135;
  VK_NUMLOCK = 144;

type
  TCursor = Integer;

const
  crDefault     = 0;
  crNone        = 1;
  crArrow       = 2;
  crCross       = 3;
  crIBeam       = 4;
  crSize        = 22;
  crSizeNESW    = 6;
  crSizeNS      = 7;
  crSizeNWSE    = 8;
  crSizeWE      = 9;
  crUpArrow     = 10;
  crHourGlass   = 11;
  crDrag        = 12;
  crNoDrop      = 13;
  crHSplit      = 14;
  crVSplit      = 15;
  crMultIDrag   = 16;
  crSQLWait     = 17;
  crNo          = 18;
  crAppStart    = 19;
  crHelp        = 20;
  crHandPoint   = 21;
  crSizeAll     = 22;

type
  TCSSCodeManager = class;
  Single = Double;
  TDragState = (dsDragEnter, dsDragLeave, dsDragMove);
  TDragMode = (dmManual, dmAutomatic);
  TDragKind = (dkDrag, dkDock);
  TDragObject = class(TObject);

  TAlign = (alNone, alTop, alBottom, alLeft, alRight, alClient, alCustom);
  TMouseButton = (mbLeft, mbRight, mbMiddle);
  TBorderStyle = (bsNone, bsSingle);
  TSizeStyle = (ssPercent, ssAbsolute, ssAuto);
  TScrollStyle = (ssNone, ssHorizontal, ssVertical, ssBoth);
  TStyleElements = set of (seFont, seClient, seBorder);

  TAnchorKind = (akLeft, akTop, akRight, akBottom);
  TAnchors = set of TAnchorKind;

  TMaterialGlyph = string;

  TElementClassName = string;
  TElementID = string;
  TElementFont = (efProperty, efCSS);
  TElementPosition = (epAbsolute, epRelative, epIgnore);

  TElementEvent = (eeClick, eeMouseDown, eeMouseUp, eeMouseMove, eeDblClick);
  TEventPropagation = set of TElementEvent;

  // Classes
  TShiftState = set of (ssShift, ssAlt, ssCtrl,
    ssLeft, ssRight, ssMIDdle, ssDouble, ssTouch, ssPen, ssCommand);

  TTextDirection = (tdDefault, tdLeftToRight, tdRightToLeft, tdInherit);

  TDragSourceObject = class(TObject)
  private
    FJSEvent: TJSEvent;
    FObject: TObject;
  public
    property Event: TJSEvent read FJSEvent write FJSEvent;
    property &Object: TObject read FObject write FObject;
  end;

  TNotifyEvent = procedure(Sender: TObject) of object;
  TMouseEvent = procedure(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X,Y: Integer) of object;
  TMouseWheelEvent = procedure(Sender: TObject; Shift: TShiftState; WheelDelta: Integer; MousePos: TPoint; var Handled: Boolean) of object;
  TMouseMoveEvent = procedure(Sender: TObject; Shift: TShiftState; X,Y: Integer) of object;
  TKeyEvent = procedure(Sender: TObject; var AKey: Word; Shift: TShiftState) of object;
  TKeyPressEvent = procedure(Sender: TObject; var AChar: Char) of object;
  TTouchEvent = procedure(Sender: TObject; X,Y: integer) of object;

  TDragOverEvent = procedure(Sender, Source: TObject; X, Y: Integer; State: TDragState; var Accept: Boolean) of object;
  TDragDropEvent = procedure(Sender, Source: TObject; X, Y: Integer) of object;
  TStartDragEvent = procedure(Sender: TObject; var DragObject: TDragObject) of object;
  TEndDragEvent = procedure(Sender, Target: TObject; X, Y: Integer) of object;

  TControlStyleValue = (csAcceptsControls, csSetCaption);
  TControlStyle = set of TControlStyleValue;

  TMargins = class(TPersistent)
  private 
    FOnChange: TNotifyEvent;
    FLeft, FTop, FRight, FBottom: Integer;
  protected
    procedure SetLeft(const Value: integer);
    procedure SetTop(const Value: integer);
    procedure SetRight(const Value: integer);
    procedure SetBottom(const Value: integer);
    procedure DoChange; virtual;
  public
    constructor Create; reintroduce;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    procedure Assign(Source: TPersistent); override;
  published
    property Left: Integer read FLeft write SetLeft default 3;
    property Top: Integer read FTop write SetTop default 3;
    property Right: Integer read FRight write SetRight default 3;
    property Bottom: Integer read FBottom write SetBottom default 3;
  end;

  TPadding = class(TPersistent)
  private
    FOnChange: TNotifyEvent;
    FLeft, FTop, FRight, FBottom: Integer;
  protected
    procedure SetLeft(const Value: integer);
    procedure SetTop(const Value: integer);
    procedure SetRight(const Value: integer);
    procedure SetBottom(const Value: integer);
    procedure DoChange; virtual;
  public
    constructor Create; reintroduce;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    procedure Assign(Source: TPersistent); override;
  published
    property Left: Integer read FLeft write SetLeft default 0;
    property Top: Integer read FTop write SetTop default 0;
    property Right: Integer read FRight write SetRight default 0;
    property Bottom: Integer read FBottom write SetBottom default 0;
  end;


  TPercentSize = 0..100;

  TControl = class(TComponent)
  private
    FCaptureDown: Boolean;
    FMouseInsideLayer: Boolean;
    FLayer: TJSElement;
    FCaptured: Boolean;
    FControlCreated: Boolean;
    FUpdateCount: integer;
    FBlockUpdateElement: Boolean;
    FElement: TJSElement;
    FID: string;
    FNew: Boolean;
    FContainer: TJSElement;
    FElementEvent: TJSEvent;

    FElementClassName: string;
    FColor: TColor;    
    FFont: TFont;
    FParent, FPrevParent: TControl;
    FControls: array of TControl;

    FOnDragOver: TDragOverEvent;
    FOnDragDrop: TDragDropEvent;
    FOnStartDrag: TStartDragEvent;
    FonEndDrag: TEndDragEvent;
    FOnClick: TNotifyEvent;
    FOnDblClick: TNotifyEvent;
    FOnMouseDown: TMouseEvent;
    FOnMouseUp: TMouseEvent;
    FOnMouseMove: TMouseMoveEvent;
    FOnKeyDown: TKeyEvent;
    FOnKeyUp: TKeyEvent;
    FOnKeyPress: TKeyPressEvent;
    FOnEnter: TNotifyEvent;
    FOnExit: TNotifyEvent;
    FEnabled: Boolean;
    FHint: string;
    FShowHint: Boolean;
    FTabOrder: Integer;
    FTabStop: Boolean;
    FVisible: Boolean;
    FWidth: Integer;
    FHeight: Integer;
    FTag: Integer;
//    FControlPosition: TControlPosition;
    FAlign: TAlign;
    FAnchors: TAnchors;
    FAlignWithMargins: boolean;
    FIsAligning: boolean;
    FOnMouseEnter: TNotifyEvent;
    FOnMouseLeave: TNotifyEvent;
    FCursor: TCursor;
    FDoubleBuffered: Boolean;
    FControlStyle: TControlStyle;
    FMargins: TMargins;
    FOnMouseWheel: TMouseWheelEvent;
    FParentDoubleBuffered: Boolean;
    FParentColor: Boolean;
    FParentFont: Boolean;
    FOnTouchMove: TTouchEvent;
    FOnTouchStart: TTouchEvent;
    FOnTouchEnd: TTouchEvent;
    FLinkTouchEvents: Boolean;
    FWidthStyle: TSizeStyle;
    FHeightStyle: TSizeStyle;
    FWidthPercent: TPercentSize;
    FHeightPercent: TPercentSize;
    FOrigRect: TRect;
    FOrigParentRect: TRect;
    FIsResizing: boolean;
    FShowFocus: boolean;
    FBorderWidth: integer;
    FOrigTop: integer;
    FOrigLeft: integer;
    FUpdateTopLeft: boolean;
    FEnablePropagation: Boolean;
    FParentShowHint: Boolean;
    FScriptLoaded: Boolean;
    FControlScriptCount: Integer;
    FControlScriptCountLoaded: Integer;
    FRequiredScripts: TStringList;
    FElementFont: TElementFont;
    FElementPosition: TElementPosition;
    FParentElement: TJSHTMLElement;
    FTagObject: TObject;
    FClipChildren: Boolean;
    FTextDirection: TTextDirection;
    FEventStopPropagation: TEventPropagation;
    FParentElementID: string;
    FOnResize: TNotifyEvent;
    FMouseMovePtr: pointer;
    FMouseDownPtr: pointer;
    FMouseUpPtr: pointer;
    FMouseEnterPtr: pointer;
    FMouseLeavePtr: pointer;
    FClickPtr: pointer;
    FDblClickPtr: pointer;
    FKeyDownPtr: pointer;
    FKeyUpPtr: pointer;
    FKeyPressPtr: pointer;
    FTouchStartPtr: pointer;
    FTouchEndPtr: pointer;
    FTouchMovePtr: pointer;
    FExitPtr: pointer;
    FEnterPtr: pointer;
    FWheelPtr: pointer;
    FLayerMouseEnterPtr: pointer;
    FLayerMouseLeavePtr: pointer;
    FScriptLoadedPtr: pointer;
    FChildOrder: integer;
    function GetControlsCount: Integer;
    function GetControls(Index: Integer): TControl;
    procedure SetVisible(AValue: Boolean);
    procedure SetWidth(AValue: Integer); virtual;
    procedure SetHeight(AValue: Integer); virtual;
    procedure SetHint(AValue: string);
    procedure SetShowHint(AValue: Boolean);
    procedure SetTabOrder(AValue: Integer);
    procedure SetTabStop(AValue: Boolean);
    procedure SetAlign(const Value: TAlign);
    procedure SetAlignWithMargins(const Value: boolean);
    procedure SetCursor(const Value: TCursor);
    function GetBoundsRect: TRect;
    procedure SetBoundsRect(const Value: TRect);
    procedure SetID(const Value: string);
    procedure SetMargins(const Value: TMargins);
    procedure SetAnchors(const Value: TAnchors);
    function GetElementEvent: TJSEvent;
    function GetClientOrigin: TPoint;
    procedure SetHeightStyle(const Value: TSizeStyle);
    procedure SetWidthStyle(const Value: TSizeStyle);
    procedure SetHeightPercent(const Value: TPercentSize);
    procedure SetWidthPercent(const Value: TPercentSize);
    procedure SetShowFocus(const Value: boolean);
    procedure SetBorderWidth(const Value: integer);
    function GetIsLinked: boolean;
    procedure SetScriptLoaded(const Value: boolean);
    procedure SetRequiredScripts(const Value: TStringList);
    procedure SetElementFont(const Value: TElementFont);
    procedure SetElementPosition(const Value: TElementPosition);
    procedure SetParentElement(const Value: TJSHTMLElement);
    procedure SetClipChildren(const Value: Boolean);
    function GetClientHeight: Integer;
    function GetClientWidth: Integer;
    procedure SetClientHeight(const Value: Integer);
    procedure SetClientWidth(const Value: Integer);
    procedure SetParentElementID(const Value: string);
    procedure SetChildOrderEx(const Value: integer);
  protected
    procedure SetEnabled(Value: Boolean); virtual;
    procedure RecreateCanvas; virtual;
    procedure VisibleChanging; virtual;
    function GetWidth: Integer; virtual;
    function GetHeight: Integer; virtual;
    function GetOuterWidth: integer; virtual;
    function GetOuterHeight: integer; virtual;
    function GetDesignWidth: integer; virtual;
    function GetDesignHeight: integer; virtual;
    function GetDesignLeft: integer; virtual;
    function GetDesignTop: integer; virtual;
    function GetLeft: Integer; override;
    function GetTop: Integer; override;
    function HandleAllocated: Boolean; virtual;
    function CreateElement: TJSElement; virtual;
    function ContainerElement: TJSElement; virtual;
    function IsStructuralElement: boolean; virtual;
    procedure RecreateElement; virtual;
    procedure BindElement; virtual;
    procedure CreateInitialize; virtual;
    procedure ClearControls; virtual;
    function GetMouseEventButton(Event: TJSMouseEvent): TMouseButton; virtual;
    function GetMouseEventShiftState(Event: TJSMouseEvent): TShiftState; virtual;
    function GetKeyBoardEventShiftState(Event: TJSKeyBoardEvent): TShiftState; virtual;
    function GetMouseWheelEventShiftState(Event: TJSWheelEvent): TShiftState; virtual;
    function GetTouchEventShiftState(Event: TJSTouchEvent): TShiftState; virtual;
    function HandleDoClick(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoWheel(Event: TJSWheelEvent): Boolean; virtual;
    function HandleDoDblClick(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoMouseDown(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoMouseUp(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoMouseMove(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoMouseLeave(Event: TJSMouseEvent): Boolean; virtual;
    function LayerHandleDoMouseEnter(Event: TJSMouseEvent): Boolean; virtual;
    function LayerHandleDoMouseLeave(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoMouseEnter(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoKeyDown(Event: TJSKeyBoardEvent): Boolean; virtual;
    function HandleDoKeyUp(Event: TJSKeyBoardEvent): Boolean; virtual;
    function HandleDoKeyPress(Event: TJSKeyBoardEvent): Boolean; virtual;
    function HandleDoExit(Event: TEventListenerEvent): Boolean; virtual;
    function HandleDoEnter(Event: TJSFocusEvent): Boolean; virtual;
    function HandleDoTouchStart(Event: TJSTouchEvent): Boolean; virtual;
    function HandleDoTouchMove(Event: TJSTouchEvent): Boolean; virtual;
    function HandleDoTouchEnd(Event: TJSTouchEvent): Boolean; virtual;
    procedure HandleFontChanged(Sender: TObject);
    procedure DoExit; virtual;
    procedure DoEnter; virtual;
    procedure Click; virtual;
    procedure UpdateElement; virtual;
    procedure UpdateElementSize; virtual;
    procedure UpdateElementVisual; virtual;
    procedure UpdateElementData; virtual;
    procedure UpdateParent; virtual;
    procedure InternalUpdateParent; virtual;
    procedure UpdateChildren(AControl: TControl); virtual;
    procedure PersistinHTML; virtual;
    procedure InitFromHTML; virtual;
    procedure DisableTab; virtual;
    procedure EnableTab; virtual;

    procedure SetElementClassName(AValue: string); virtual;
    procedure SetColor(AValue: TColor); virtual;
    procedure SetFont(AValue: TFont); virtual;
    procedure SetParent(AValue: TControl); virtual;
    procedure RegisterParent(AValue: TControl); virtual;
    procedure UnRegisterParent(AValue: TControl); virtual;

    procedure SetLeft(AValue: Integer); override;
    procedure SetTop(AValue: Integer); override;

    procedure MouseUp(Button: TMouseButton; Shift: TShiftState; X,Y: Integer); virtual;
    procedure MouseDown(Button: TMouseButton; Shift: TShiftState; X,Y: Integer); virtual;
    procedure MouseMove(Shift: TShiftState; X,Y: Integer); virtual;

    procedure TouchStart(X,Y: Integer); virtual;
    procedure TouchMove(X,Y: Integer); virtual;
    procedure TouchEnd(X,Y: Integer); virtual;

    procedure DoMouseEnter; virtual;
    procedure DoMouseLeave; virtual;
    procedure MouseWheel(Shift: TShiftState; WheelDelta: Integer; var Handled: Boolean); virtual;
    procedure DblClick; virtual;
    procedure KeyDown(var Key: Word; Shift: TShiftState); virtual;
    procedure KeyPress(var Key: Char); virtual;
    procedure KeyUp(var Key: Word; Shift: TShiftState); virtual;
    procedure SetFocus; virtual;
    procedure DoMarginsChanged(Sender: TObject); virtual;
    procedure DoRealign; virtual;
    procedure DoBoundsChange; virtual;
    procedure DoEndDrag(Target: TObject; X, Y: Integer); virtual;
    procedure DoStartDrag(var DragObject: TDragObject); virtual;
    procedure DragOver(Source: TObject; X, Y: Integer; State: TDragState;
      var Accept: Boolean); virtual;
    function IsFocused: Boolean; virtual;
    function Focused: Boolean; virtual;
    function GetID: string;
    function GetElementHandle: TJSHTMLElement; virtual;
    function GetElementBindHandle: TJSEventTarget; virtual;
    function GetElement: TJSElement;
    function GetKeyCode(AValue: string; IgnoreCase: boolean = true): Integer;
    function IsKeyCharacter(AValue: string): Boolean;
    function GetClientRect: TRect; virtual;
    property Container: TJSElement read FContainer write FContainer;
    property ElementBindHandle: TJSEventTarget read GetElementBindHandle;
    property Element: TJSElement read GetElement;
    property ElementEvent: TJSEvent read GetElementEvent write FElementEvent;

    procedure CreateControl; virtual;
    procedure ClearMethodPointers; virtual;
    procedure GetMethodPointers; virtual;
    procedure BindEvents; virtual;
    procedure UnbindEvents; virtual;
    procedure SetHTMLElementFont(he: TJSHTMLElement; Font: TFont); virtual;
    procedure AlignControls(AControl: TControl; var Rect: TRect); virtual;
    procedure AlignControl(AControl: TControl); virtual;
    procedure InitAnchoring; virtual;
    function AnchoringInitialized: boolean;
    procedure InitScript; virtual;
    procedure Realign; virtual;
    procedure Loaded; override;
    procedure InternalResize; virtual;
    procedure FontChanged; virtual;
    procedure DisposeOf;

    property Color: TColor read FColor write SetColor;
    property ControlCreated: boolean read FControlCreated;
    property Font: TFont read FFont write SetFont;
    property ClientOrigin: TPoint read GetClientOrigin;
    property OrigRect: TRect read FOrigRect;

    property OnClick: TNotifyEvent read FOnClick write FOnClick;
    property OnResize: TNotifyEvent read FOnResize write FOnResize;
    property OnDblClick: TNotifyEvent read FOnDblClick write FOnDblClick;
    property ControlStyle: TControlStyle read FControlStyle write FControlStyle;
    procedure HookElement;
    procedure CreateWithID(AID: string); virtual;
    procedure AddInstanceStyle(const css: string); virtual;
    procedure AddControlStyle(const css: string); virtual;
    procedure AddControlLink(const linkid, link: string); virtual;
    procedure AddControlScript(const link: string); virtual;
    function AddRequiredScripts: boolean;
    procedure AddRequiredScript(const link: string); virtual;
    procedure UpdateAnchoring; virtual;
    procedure UpdateChildAnchoring; virtual;
    procedure SetElementPointer(AElement: TJSHTMLElement; ACursor: TCursor);
    function GetWebClassName: string; virtual;
    function GetCSSManager: TCSSCodeManager; virtual;
    function IsUpdating: boolean;
    function CanShowFocus: boolean; virtual;
    procedure RequiredScriptLoaded(Event: TJSEvent); virtual;
    procedure InjectCSS; virtual;
    procedure StartCapture; virtual;
    property LinkTouchEvents: Boolean read FLinkTouchEvents write FLinkTouchEvents;
    property OnTouchStart: TTouchEvent read FOnTouchStart write FOnTouchStart;
    property OnTouchMove: TTouchEvent read FOnTouchMove write FOnTouchMove;
    property OnTouchEnd: TTouchEvent read FOnTouchEnd write FOnTouchEnd;
    property ShowFocus: boolean read FShowFocus write SetShowFocus;
    property BorderWidth: integer read FBorderWidth write SetBorderWidth;
    property ControlScriptCount: integer read FControlScriptCount write FControlScriptCount;
    property ControlScriptCountLoaded: integer read FControlScriptCountLoaded write FControlScriptCountLoaded;
    property RequiredScripts: TStringList read FRequiredScripts write SetRequiredScripts;
    property TextDirection: TTextDirection read FTextDirection write  FTextDirection;
    function RequiredBaseURL: string; virtual;
  public
    constructor Create(ID: string); virtual; overload;
    constructor Create(AOwner: TComponent); overload; override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure DragDrop(Source: TObject; X, Y: Integer); virtual;
    procedure Resize; virtual;
    procedure Capture;
    procedure ApplyName;
    procedure ReleaseCapture;
    procedure BringToFront;
    procedure SendToBack;
    procedure PreventDefault;
    procedure StopPropagation;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure Invalidate; virtual;
    procedure SetParentComponent(Value: TComponent); override;
    procedure SetBounds(X, Y, AWidth, AHeight: Integer); virtual;
    procedure XYToClient(X,Y: single; var AClientX, AClientY: single); virtual;
    function Captured: Boolean; virtual;
    function MakeScreenshot: TBitmap; virtual;
    function ClientToScreen(const Point: TPoint): TPoint; virtual;
    function ScreenToClient(const Point: TPoint): TPoint; virtual;
    function CanFocus: Boolean; virtual;
    property EventStopPropagation: TEventPropagation read FEventStopPropagation write FEventStopPropagation;
    property ChildOrder: integer read FChildOrder write SetChildOrderEx;
    property Controls[Index: Integer]: TControl read GetControls;
    property ControlCount: Integer read GetControlsCount;
    property Parent: TControl read FParent write SetParent;
    property ParentElement: TJSHTMLElement read FParentElement write SetParentElement;
    property ParentElementID: string read FParentElementID write  SetParentElementID;
    property BoundsRect: TRect read GetBoundsRect write SetBoundsRect;
    property DoubleBuffered: Boolean read FDoubleBuffered write FDoubleBuffered;
    property ParentDoubleBuffered: Boolean read FParentDoubleBuffered write FParentDoubleBuffered;
    property ParentColor: boolean read FParentColor write FParentColor;
    property ParentFont: boolean read FParentFont write FParentFont;
    property Align: TAlign read FAlign write SetAlign;
    property AlignWithMargins: boolean read FAlignWithMargins write SetAlignWithMargins;
    property ElementHandle: TJSHTMLElement read GetElementHandle;
    property ElementClassName: string read FElementClassName write SetElementClassName;
    property ElementFont: TElementFont read FElementFont write SetElementFont default efProperty;
    property ElementPosition: TElementPosition read FElementPosition write SetElementPosition default epAbsolute;
    property ElementID: string read GetID write SetID;
    property Margins: TMargins read FMargins write SetMargins;
    property ClientRect: TRect read GetClientRect;
    property EnablePropagation: Boolean read FEnablePropagation write FEnablePropagation default False;
    property ScriptLoaded: boolean read FScriptLoaded write SetScriptLoaded;
    property ClipChildren: Boolean read FClipChildren write SetClipChildren default True;

    property Anchors: TAnchors read FAnchors write SetAnchors;
    property Cursor: TCursor read FCursor write SetCursor;
    property Enabled: Boolean read FEnabled write SetEnabled;
    property HeightStyle: TSizeStyle read FHeightStyle write SetHeightStyle;
    property Height: Integer read GetHeight write SetHeight;
    property HeightPercent: TPercentSize read FHeightPercent write SetHeightPercent;
    property Hint: string read FHint write SetHint;
    property IsLinked: boolean read GetIsLinked;
    property ShowHint: Boolean read FShowHint write SetShowHint;
    property ParentShowHint: Boolean read FParentShowHint write FParentShowHint;
    property TabOrder: Integer read FTabOrder write SetTabOrder;
    property TabStop: Boolean read FTabStop write SetTabStop;
    property Tag: Integer read FTag write FTag;
    property TagObject: TObject read FTagObject write FTagObject;
    property Visible: Boolean read FVisible write SetVisible;
    property WidthStyle: TSizeStyle read FWidthStyle write SetWidthStyle;
    property ClientHeight: Integer read GetClientHeight write SetClientHeight;
    property ClientWidth: Integer read GetClientWidth write SetClientWidth;
    property Width: Integer read GetWidth write SetWidth;
    property WidthPercent: TPercentSize read FWidthPercent write SetWidthPercent;

    property OnDragOver: TDragOverEvent read FOnDragOver write FOnDragOver;
    property OnDragDrop: TDragDropEvent read FOnDragDrop write FOnDragDrop;
    property OnStartDrag: TStartDragEvent read FOnStartDrag write FOnStartDrag;
    property OnEndDrag: TEndDragEvent read FOnEndDrag write FOnEndDrag;

    property OnMouseWheel: TMouseWheelEvent read FOnMouseWheel write FOnMouseWheel;
    property OnMouseDown: TMouseEvent read FOnMouseDown write FOnMouseDown;
    property OnMouseUp: TMouseEvent read FOnMouseUp write FOnMouseUp;
    property OnMouseMove: TMouseMoveEvent read FOnMouseMove write FOnMouseMove;
    property OnMouseEnter: TNotifyEvent read FOnMouseEnter write FOnMouseEnter;
    property OnMouseLeave: TNotifyEvent read FOnMouseLeave write FOnMouseLeave;
    property OnKeyUp: TKeyEvent read FOnKeyUp write FOnKeyUp;
    property OnKeyDown: TKeyEvent read FOnKeyDown write FOnKeyDown;
    property OnKeyPress: TKeyPressEvent read FOnKeyPress write FOnKeyPress;
    property OnEnter: TNotifyEvent read FOnEnter write FOnEnter;
    property OnExit: TNotifyEvent read FOnExit write FOnExit;
  end;

  TWinControl = class(TControl)
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Cursor;
    property ElementClassName;
    property Enabled;
    property Height;
    property HeightStyle;
    property Hint;
    property Left;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Tag;
    property Top;
    property Visible;
    property Width;
    property WidthStyle;

    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseEnter;
    property OnMouseLeave;
    property OnKeyUp;
    property OnKeyDown;
    property OnKeyPress;
    property OnEnter;
    property OnExit;
  end;

  TCustomControl = class(TWinControl)
  private
    FCaption: string;
    FPixelRatio: Single;
    FPainting: boolean;
    FCanvas: TCanvas;
    FElementCanvas: TJSHTMLCanvasElement;
    FBorderStyle: TBorderStyle;
    FBorderColor: TColor;
    FCustomBorder: boolean;
    function GetCanvas: TCanvas;
  protected
    function GetPixelRatio: Single;
    procedure RecreateCanvas; override;
    procedure SetBorderStyle(const AValue: TBorderStyle); virtual;
    procedure SetBorderColor(const AValue: TColor); virtual;
    procedure SetName(const NewName: TComponentName); override;
    procedure SetCaption(const AValue: string); virtual;
    procedure CreateControl; override;
    procedure Loaded; override;
    procedure UpdateElementVisual; override;
    function CreateElement: TJSElement; override;
    function GetCanvasHeightOffset: Integer; virtual;
    function GetCanvasWidthOffset: Integer; virtual;
    property BorderStyle: TBorderStyle read FBorderStyle write SetBorderStyle default bsSingle;
    property BorderColor: TColor read FBorderColor write SetBorderColor default clSilver;
    procedure BindEvents; override;
    procedure Paint; virtual;
    property CustomBorder: boolean read FCustomBorder write FCustomBorder;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    property Caption: string read FCaption write SetCaption;
    function MakeScreenShot: TBitmap; override;
    procedure Resize; override;
    property Canvas: TCanvas read GetCanvas;
    property ElementCanvas: TJSHTMLCanvasElement read FElementCanvas;
    procedure Invalidate; override;
  end;

  TjQueryCustomControl = class(TWinControl)
  private
    FIsInitialized: boolean;
    procedure InitJQueryOnce;
  protected
    function CreateElement: TJSElement; override;
    procedure Loaded; override;
    procedure SetParent(AValue: TControl); override;
    function GetJQID: string;
    procedure InitJQuery; virtual;
  public
    procedure CreateInitialize; override;
  published
    property ElementPosition;
  end;

  TGraphicControl = class(TCustomControl);

  TScrollingGraphicControl = class(TCustomControl)
  protected
    function CreateElement: TJSElement; override;
  public
    procedure UpdateElement; override;
  end;

  TControlManager = class(TComponent)
  private
    FInstanceCount: integer;
  public
    constructor Create(AOwner: TComponent); override;
    function GetInstanceNumber: integer;
    procedure Reset;
  end;

  TCSSCodeFragment = class(TCollectionItem)
  private
    FControlClassname: string;
    FCSS: TStringList;
    procedure SetCSS(const Value: TStringList);
  public
    constructor Create(Collection: TCollection); override;
    destructor Destroy; override;
  published
    property ControlClassname: string read FControlClassname write FControlClassname;
    property CSS: TStringList read FCSS write SetCSS;
  end;

  TCSSCodeFragments = class(TOwnedCollection)
  private
    function GetItemEx(Index: integer): TCSSCodeFragment;
    procedure SetItemEx(Index: integer; const Value: TCSSCodeFragment);
  public
    constructor Create(AOwner: TComponent); reintroduce;
    function Add: TCSSCodeFragment; reintroduce;
    function Insert(Index: integer): TCSSCodeFragment; reintroduce;
    property Items[Index: integer]: TCSSCodeFragment read GetItemEx write SetItemEx;
  end;

  TCSSCodeManager = class(TComponent)
  private
    FCSSFragments: TCSSCodeFragments;
    procedure SetCSSFragments(const Value: TCSSCodeFragments);
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    function GetClassFragment(AClassname: string): TCSSCodeFragment;
    function GetClassCSS(AClassname: string): string;
  published
    property CSSFragments: TCSSCodeFragments read FCSSFragments write SetCSSFragments;
  end;

function FindGlobalComponent(const Name: string): TComponent;
function GetMousePos: TPoint;
function FindUniqueName(const Name: string): string;
function GetScrollBarHeight: integer;
function GetScrollBarWidth: integer;

implementation

uses
  WEBLib.Forms, Math;

var
  ControlManager: TControlManager;
  FMouseX, FMouseY: Integer;


function GetMousePos: TPoint;
begin
  Result := Point(FMouseX, FMouseY);
end;

function GetScrollBarWidth: integer;
var
  res: integer;
begin
  res := 0;
  asm
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    res = widthNoScroll - widthWithScroll;
  end;
  Result := res;
end;

function GetScrollBarHeight: integer;
var
  res: integer;
begin
  res := 0;
  asm
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.height = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    var HeightNoScroll = outer.offsetHeight;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.height = "100%";
    outer.appendChild(inner);

    var HeightWithScroll = inner.offsetHeight;

    // remove divs
    outer.parentNode.removeChild(outer);

    res = HeightNoScroll - HeightWithScroll;

    res = 0;
  end;
  Result := res;
end;


function FindGlobalComponent(const Name: string): TComponent;
begin
  Result := nil;
end;

function FindUniqueName(const Name: string): string;
begin
  Result := Name + IntToStr(ControlManager.GetInstanceNumber);
end;

{ TMargins }

procedure TMargins.SetLeft(const Value: integer);
begin
  if (Value <> FLeft) then
  begin
    FLeft := Value;
    DoChange;
  end;
end;

procedure TMargins.SetTop(const Value: integer);
begin
  if (Value <> FTop) then
  begin
    FTop := Value;
    DoChange;
  end;
end;

procedure TMargins.SetRight(const Value: integer);
begin
  if (Value <> FRight) then
  begin
    FRight := Value;
    DoChange;
  end;
end;

procedure TMargins.SetBottom(const Value: integer);
begin
  if (Value <> FBottom) then
  begin
    FBottom := Value;
    DoChange;
  end;
end;

procedure TMargins.DoChange; 
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

{ TControl }

function TControl.MakeScreenshot: TBitmap;
begin
  Result := nil;
end;

procedure TControl.MouseDown(Button: TMouseButton; Shift: TShiftState; X,
  Y: Integer);
begin
  if Assigned(OnMouseDown) then
   OnMouseDown(Self, Button, Shift, X, Y);
end;

procedure TControl.MouseMove(Shift: TShiftState; X, Y: Integer);
begin
  if Assigned(OnMouseMove) then
   OnMouseMove(Self, Shift, X, Y);
end;

procedure TControl.MouseUp(Button: TMouseButton; Shift: TShiftState; X,
  Y: Integer);
begin
  if Assigned(OnMouseUp) then
   OnMouseUp(Self, Button, Shift, X, Y);
end;

procedure TControl.MouseWheel(Shift: TShiftState; WheelDelta: Integer;
  var Handled: Boolean);
begin
  if Assigned(OnMouseWheel) then
    OnMouseWheel(Self, Shift, WheelDelta, Point(0, 0), Handled);
end;

procedure TControl.DoMouseLeave;
begin
  if Assigned(OnMouseLeave) then
    OnMouseLeave(Self);
end;

procedure TControl.DoMouseEnter;
begin
  if Assigned(OnMouseEnter) then
    OnMouseEnter(Self);
end;

procedure TControl.AlignControls(AControl: TControl; var Rect: TRect);
var
  j: Integer;

  procedure DoPosition(Control: TControl; AAlign: TAlign);
  var
    dl, dt, dr, db: integer;
  begin
    if not Control.AnchoringInitialized then
      InitAnchoring;

    Control.FUpdateTopLeft := true;

    if Control.AlignWithMargins then
    begin
      dl := Control.Margins.Left;
      dt := Control.Margins.Top;
      db := Control.Margins.Bottom;
      dr := Control.Margins.Right;
    end
    else
    begin
      dl := 0;
      dt := 0;
      db := 0;
      dr := 0;
    end;

    case AAlign of
      alTop:
      begin
        Control.SetBounds(Rect.Left + dl, Rect.Top + dt, Rect.Right - Rect.Left - dl - dr, Control.Height);
        Rect.Top := Rect.Top + Control.Height + dt + db;
      end;
      alBottom:
      begin
        Control.SetBounds(Rect.Left + dl, Rect.Bottom - Control.Height - db - 1, Rect.Right - Rect.Left - dl - dr - 1, Control.Height);
        Rect.Bottom := Rect.Bottom - Control.Height - dt - db;
      end;
      alLeft:
      begin
        Control.SetBounds(Rect.Left + dl, Rect.Top + dt, Control.Width, Rect.Bottom - Rect.Top - dt - db);
        Rect.Left := Rect.Left + Control.Width + dl + dr;
      end;
      alRight:
      begin
        Control.SetBounds(Rect.Right - Control.Width - dr, Rect.Top + dt, Control.Width, Rect.Bottom - Rect.Top - db - dt);
        Rect.Right := Rect.Right - Control.Width - dr - dl;
      end;
      alClient:
      begin
        Control.SetBounds(Rect.Left + dl, Rect.Top + dt, Rect.Right - Rect.Left - dl - dr, Rect.Bottom - Rect.Top - db - dt);
        Rect.Left := 0;
        Rect.Top := 0;
        Rect.Right := 0;
        Rect.Bottom := 0;
      end;
    end;

    Control.FUpdateTopLeft := false;
  end;

  procedure DoAlign(AAlign: TAlign);
  var
    i,j,ins: Integer;
    il: TList;
  begin
    il := TList.Create;

    for i := 0 to ControlCount - 1 do
    begin
      if (Controls[i].Align = AAlign) and (Controls[i].Visible) then
      begin
        ins := il.Count;

        for j := il.Count - 1 downto 0 do
        begin
          case AAlign of
          alTop:
            if Controls[i].FOrigTop < TControl(il.Items[j]).FOrigTop then
              ins := j;
          alLeft:
            if Controls[i].FOrigLeft < TControl(il.Items[j]).FOrigLeft then
              ins := j;
          alRight:
            if Controls[i].FOrigLeft + Controls[i].Width > TControl(il.Items[j]).FOrigLeft + TControl(il.Items[j]).Width then
              ins := j;
          alBottom:
            if Controls[i].FOrigTop + Controls[i].Height > TControl(il.Items[j]).FOrigTop + TControl(il.Items[j]).Height then
              ins := j;
          end;
        end;
        il.Insert(ins, Controls[i]);
      end;
    end;

    for i := 0 to il.Count - 1 do
    begin
      DoPosition(TControl(il.Items[i]), AAlign);
    end;

    il.Free;
  end;

begin
  DoAlign(alTop);
  DoAlign(alBottom);
  DoAlign(alLeft);
  DoAlign(alRight);
  DoAlign(alClient);
  DoAlign(alCustom);
  for j := 0 to ControlCount - 1 do
  begin
    Controls[j].AlignControl(Controls[j]);
  end;
end;

procedure TControl.ApplyName;
var
  s, prefix: String;
  frm: TCustomForm;
begin
  s := ClassName;
  Delete(s, 1, 1);

  prefix := '';
  frm := GetParentForm(Self);
  if Assigned(frm) then
    prefix := frm.ClassName;

  SetID(prefix+'_'+FindUniqueName(s));
end;

constructor TControl.Create(ID: string);
begin
  CreateWithID(ID);
end;

procedure TControl.HookElement;
var
  el: TJSElement;
  i: integer;
begin
  el := document.getElementByID(FID);
  Container := el;
  BindElement;
  BindEvents;

  for i := 0 to ControlCount - 1 do
    Controls[i].HookElement;
end;

function TControl.CanFocus: Boolean;
begin
  Result := True;
end;

function TControl.CanShowFocus: boolean;
begin
  Result := FShowFocus;
end;

procedure TControl.Capture;
begin
  FCaptureDown := True;
  window.setTimeout(@StartCapture, 100);
end;

procedure TControl.StartCapture;
var
  eh: TJSHTMLElement;
begin
  if not FCaptureDown then
    Exit;

  if Captured then
    ReleaseCapture;

  FMouseInsideLayer := true;

  FLayer := document.createElement('SPAN');
  document.body.appendChild(FLayer);

  eh := TJSHTMLElement(FLayer);
  eh.addEventListener('mouseenter', FLayerMouseEnterPtr);
  eh.addEventListener('mouseleave', FLayerMouseLeavePtr);
  eh.addEventListener('mousedown', FMouseDownPtr);
  eh.addEventListener('mouseup', FMouseUpPtr);
  eh.addEventListener('mousemove', FMouseMovePtr);
  eh.addEventListener('touchstart', FTouchStartPtr);
  eh.addEventListener('touchmove', FTouchMovePtr);
  eh.addEventListener('touchend', FTouchEndPtr);
  eh.addEventListener('touchcancel', FTouchEndPtr);

  eh.style.setProperty('top', '0');
  eh.style.setProperty('left', '0');
  eh.style.setProperty('right', '0');
  eh.style.setProperty('bottom', '0');

  eh.style.setProperty('webkit-user-select', 'none');
  eh.style.setProperty('moz-user-select', 'none');
  eh.style.setProperty('khtml-user-select', 'none');
  eh.style.setProperty('ms-user-select', 'none');
  eh.style.setProperty('user-select', 'none');
  eh.style.setProperty('position', 'absolute');

  eh.style.setProperty('z-index', '999999');

  FCaptured := True;
  UpdateElement;
end;

function TControl.Captured: Boolean;
begin
  Result := FCaptured and Assigned(FLayer);
end;

procedure TControl.ClearControls;
begin
  SetLength(FControls, 0);
end;

procedure TControl.Click;
begin
  if Assigned(OnClick) and Enabled then
    OnClick(Self);
end;

function TControl.ClientToScreen(const Point: TPoint): TPoint;
var
  Origin: TPoint;
begin
  Origin := ClientOrigin;
  Result.X := Point.X + Origin.X;
  Result.Y := Point.Y + Origin.Y;
end;

function TControl.ContainerElement: TJSElement;
begin
  Result := document.body;
end;

function TControl.RequiredBaseURL: string;
begin
  Result := '';
end;

procedure TControl.RequiredScriptLoaded(Event: TJSEvent);
begin
  (Event.Target as TJSHTMLScriptElement).Title := 'tmswebloaded';
  inc(FControlScriptCountLoaded);
end;

constructor TControl.Create(AOwner: TComponent);
var
  s: string;
  lPrefix, lName: string;
  frm: TCustomForm;

begin
  FTabStop := true;
  ClearMethodPointers;

  inherited Create(AOwner);

  FScriptLoaded := false;
  FClipChildren := True;
  FElementFont := efProperty;
  FElementPosition := epAbsolute;
  FParentElement := nil;
  FTextDirection := tdDefault;

  s := ClassName;
  Delete(s, 1, 1);

  lPrefix := '';
  frm := GetParentForm(Self);
  if Assigned(frm) then
    lPrefix := frm.ClassName;

  lName := lPrefix+'_'+FindUniqueName(s);
  CreateWithID(lName);
  if not (csDesigning in ComponentState) then
    Name := lName;

  EventStopPropagation := [eeClick, eeDblClick, eeMouseUp, eeMouseMove, eeMouseDown];
end;

procedure TControl.CreateControl;
begin
  if not Assigned(FElement) then
  begin
    FElement := CreateElement;

    if Assigned(FElement) then
    begin
      FControlCreated := True;
      Container := FElement;
      Container['id'] := ElementID;
      Container['zindex'] := '0';
      if FElementClassName <> '' then
        Container['class'] := FElementClassName;

      BindEvents;
      UpdateElement;
    end;
  end;
end;

function TControl.CreateElement: TJSElement;
begin
  Result := nil;
end;

procedure TControl.CreateInitialize;
begin
  FRequiredScripts := TStringList.Create;
  FOrigRect := Rect(-1,-1,-1,-1);
end;

procedure TControl.CreateWithID(AID: string);
var
  el: TJSElement;
begin
  ClearMethodPointers;

  FUpdateCount := 0;

  FControlCreated := False;
  FLinkTouchEvents := True;
  FIsResizing := false;

  if (Assigned(Owner) and (csLoading in Owner.ComponentState)) or not Assigned(Owner) then
    Loading;

  FElementPosition := epAbsolute;
  FWidthStyle := ssAbsolute;
  FHeightStyle := ssAbsolute;

  el := document.getElementByID(AID);

  if not Assigned(el) then
  begin
    Container := nil;
    FNew := True;
  end
  else
  begin
    Container := el;
    FNew := False;
    FControlCreated := true;
    FElementPosition := epRelative;
    BindElement;
    BindEvents;
  end;

  FID := AID;
  FFont := TFont.Create;
  FFont.OnChange := HandleFontChanged;
  FEnabled := True;
  FVisible := True;
  FLeft := 0;
  FTop := 0;
  FAlign := alNone;
  FAlignWithMargins := false;
  FIsAligning := false;
  ParentFont := true;
  FAnchors := [akLeft, akTop];
  FShowFocus := false;
  FBorderWidth := 0;
  FEnablePropagation := False;

  FColor := clWhite;

  FMargins := TMargins.Create;
  FMargins.OnChange := DoMarginsChanged;

  FParent := nil;
  FPrevParent := nil;

  ClearControls;
  CreateInitialize;
end;

procedure TControl.BindElement;
begin
end;

procedure TControl.AddControlLink(const linkid, link: string);
begin
  asm
    function writeLinkOnce(linkName, linkText) {
        var linkElement = document.getElementById(linkName);
        if (linkElement)
          return;
        linkElement = document.createElement('link');
        linkElement.id = linkName;
        linkElement.setAttribute('rel', 'stylesheet');
        linkElement.setAttribute('type', 'text/css');
        linkElement.setAttribute('href', linkText);
        document.getElementsByTagName('head')[0].appendChild(linkElement);
    }
    writeLinkOnce(linkid,link);
  end;

end;


{$HINTS OFF}
procedure TControl.AddControlScript(const link: string);
var
  id: string;
  script: TJSHTMLScriptElement;

begin
  id := ClassName;

  if not Assigned(document.getElementById(id)) then
  begin
    script := TJSHTMLScriptElement(document.createElement('script'));
    script.id := id;
    script.src := link;
    script.type_ := 'text/javascript';
    document.head.appendChild(script);
  end;
end;

procedure TControl.AddControlStyle(const css: string);
var
  cssname: string;
begin
  cssname := ClassName;

  asm
    function writeStylesOnce(styleName, cssText) {
        var styleElement = document.getElementById(styleName);
        if (styleElement) {
          //styleElement.innerHTML = cssText;
          return;
          }
        styleElement = document.createElement('style');
        styleElement.type = 'text/css';
        styleElement.id = styleName;
        styleElement.innerHTML = cssText;
        document.getElementsByTagName('head')[0].appendChild(styleElement);
    }
    writeStylesOnce(cssname,css);
  end;
end;

procedure TControl.AddInstanceStyle(const css: string);
var
  cssname: string;
begin
  cssname := Name;

  asm
    function writeStylesOnce(styleName, cssText) {
        var styleElement = document.getElementById(styleName);
        if (styleElement) {
          styleElement.innerHTML = cssText;
          return;
          }
        styleElement = document.createElement('style');
        styleElement.type = 'text/css';
        styleElement.id = styleName;
        styleElement.innerHTML = cssText;
        document.getElementsByTagName('head')[0].appendChild(styleElement);
    }
    writeStylesOnce(cssname,css);
  end;
end;

procedure TControl.AddRequiredScript(const link: string);
var
  id: string;
  script: TJSHTMLScriptElement;

begin
  script := TJSHTMLScriptElement(document.createElement('script'));
  script.src := link;
  script.type_ := 'text/javascript';
  script.title := 'tmswebloading';
  script.addEventListener('load', FScriptLoadedPtr);
  document.head.appendChild(script);
end;

function TControl.AddRequiredScripts: boolean;
var
  i,j: integer;
  el: TJSElement;
  scr: TJSHTMLScriptElement;
  found, loading: boolean;
begin
  FControlScriptCount := 0;

  for j := 0 to RequiredScripts.Count - 1 do
  begin
    found := false;
    loading := false;

    for i := 0 to document.head.children.length - 1 do
    begin
      el := TJSElement(document.head.children[i]);
      if (el.tagName = 'SCRIPT') then
      begin
        scr := TJSHTMLScriptElement(el);
        if (pos(RequiredScripts[j], scr.src) > 0) then
        begin
          found := true;

          if scr.title = 'tmswebloading' then
          begin
            loading := true;
            scr.addEventListener('load', FScriptLoadedPtr);
          end;

          break;
        end;
      end;
    end;

    if not found then
    begin
      inc(FControlScriptCount);
      AddRequiredScript( RequiredBaseURL + RequiredScripts[j]);
    end;

    if found and loading then
      inc(FControlScriptCount);
  end;

  Result := (FControlScriptCount > 0);
end;

{$HINTS ON}


procedure TControl.AlignControl(AControl: TControl);
var
  r: TRect;
  cr: TJSDOMRect;
  ovf, ovfx, ovfy: string;
  eh: TJSHTMLElement;
  el: TJSElement;
  frm: TCustomForm;
  ctop: integer;
begin
  if FIsAligning then
    Exit;

  if IsUpdating then
    Exit;

  frm := GetParentForm(Self);
  if Assigned(frm) and frm.IsUpdating then
    Exit;

  ctop := 0;

  if Assigned(frm) and (frm.FormContainer <> '') and (AControl is TCustomForm) then
  begin
    el := document.getElementById(frm.FormContainer);
    if Assigned(el) then
    begin
      cr := el.getBoundingClientRect;
      ctop := Round(cr.Top);
    end;
  end;

  FIsAligning := true;

  if not Assigned(AControl) then
    Exit;

  eh := AControl.ElementHandle;

  if Assigned(eh) then
  begin
    ovf := eh.style.getPropertyValue('overflow');
    ovfx := eh.style.getPropertyValue('overflow-x');
    ovfy := eh.style.getPropertyValue('overflow-y');
    if ClipChildren then
      eh.style.setProperty('overflow', 'hidden')
    else
      eh.style.setProperty('overflow', '');
  end;

  r := GetClientRect;

  if r.Bottom + ctop > window.innerHeight then
    r.Bottom := window.innerHeight - ctop;

  if Assigned(eh) and (eh.tagName = 'BODY') then
  begin
    if frm.FormContainer <> '' then
    begin
      r.Bottom := Round(Min(r.Bottom, window.innerHeight - 2));
      r.Right := Round(Min(r.Right, window.innerWidth - 2));
    end
    else
    begin
      //r.Bottom := window.innerHeight - 2;
      //r.Right := window.innerWidth - 2;
      r.Bottom := frm.Height;
      r.Right := frm.Width;
    end;
  end;

  AlignControls(AControl, r);

  if Assigned(eh) then
  begin
    eh.style.setProperty('overflow', ovf);
    eh.style.setProperty('overflow-x', ovfx);
    eh.style.setProperty('overflow-y', ovfy);
  end;

  FIsAligning := false;
end;

procedure TControl.DblClick;
begin
  if Assigned(OnDblClick) then
    OnDblClick(Self);
end;

destructor TControl.Destroy;
var
  i: integer;
  ctrl: TControl;
begin
  UnbindEvents;

  for i := ControlCount - 1 downto 0 do
  begin
    ctrl := Controls[i];
    ctrl.Free;
  end;

  if Assigned(Container) and Assigned(Parent) and Assigned(Parent.Container) then
  begin
    Parent.Container.removeChild(Container);
    Container := nil;
    Parent := nil;
    FControlCreated := False;
  end;

  FRequiredScripts.Free;
  FMargins.Free;
  FFont.Free;
  inherited;
end;

procedure TControl.DisableTab;
var
  i: integer;
begin
  if TabStop and CanFocus and Assigned(Container) then
    Container.setAttribute('tabindex','-1');

  for i := 0 to ControlCount - 1 do
  begin
    Controls[i].DisableTab;
  end;
end;

procedure TControl.DisposeOf;
var
  t: TControl;
begin
  t := Self;
  t.Free;
end;

procedure TControl.Assign(Source: TPersistent);
begin

end;

function TControl.GetElement: TJSElement;
begin
  Result := document.getElementByID(GetID);
end;

function TControl.GetElementBindHandle: TJSEventTarget;
begin
  Result := TJSEventTarget(GetElementHandle);
end;

function TControl.GetElementEvent: TJSEvent;
begin
  Result := FElementEvent;
end;

procedure TControl.SetEnabled(Value: Boolean);
begin
  if (FEnabled <> Value) then
  begin
    FEnabled := Value;
    UpdateElement;
  end;
end;

procedure TControl.SetVisible(AValue: Boolean);
begin
  if (FVisible <> AValue) then
  begin
    VisibleChanging;
    FVisible := AValue;
    UpdateElement;
    DoRealign;
  end;
end;

procedure TControl.SetWidth(AValue: Integer);
begin
  if (FWidth <> AValue) then
  begin
    FWidth := AValue;
    DoBoundsChange;

    // still do element height updating at runtime
    if IsLinked and not IsUpdating and Assigned(ElementHandle) then
    begin
      if AValue >= 0 then
        ElementHandle.style.setProperty('height',IntTostr(AValue))
      else
        ElementHandle.style.removeProperty('height');
    end;
  end;
end;

procedure TControl.SetWidthPercent(const Value: TPercentSize);
begin
  if (FWidthPercent <> Value) then
  begin
    FWidthPercent := Value;
    UpdateElementSize;
  end;
end;

procedure TControl.SetWidthStyle(const Value: TSizeStyle);
begin
  if (FWidthStyle <> Value) then
  begin
    FWidthStyle := Value;
    UpdateElementSize;
  end;
end;

procedure TControl.StopPropagation;
begin
  if Assigned(ElementEvent) and not EnablePropagation then
    ElementEvent.stopPropagation;
end;

procedure TControl.TouchEnd(X, Y: Integer);
begin
  if Assigned(OnTouchEnd) then
    OnTouchEnd(Self, X, Y);
end;

procedure TControl.TouchMove(X, Y: Integer);
begin
  if Assigned(OnTouchMove) then
    OnTouchMove(Self, X, Y);
end;

procedure TControl.TouchStart(X, Y: Integer);
begin
  if Assigned(OnTouchStart) then
    OnTouchStart(Self, X, Y);
end;

procedure TControl.SetHeight(AValue: Integer);
var
  dr: TJSDOMRect;
begin
  if FHeight <> AValue then
  begin
    if (Align in [alLeft, alRight, alClient]) and Assigned(ElementHandle) then
    begin
      dr := ElementHandle.getBoundingClientRect;
      if dr.top + AValue >= window.innerHeight then
      begin
        AValue := Round(window.innerHeight - dr.top);
      end;
    end;

    FHeight := AValue;

    DoBoundsChange;

    // still do element height updating at runtime
    if IsLinked and not IsUpdating and Assigned(ElementHandle) then
    begin
      if AValue >= 0 then
        ElementHandle.style.setProperty('height',IntTostr(AValue))
      else
        ElementHandle.style.removeProperty('height');
    end;
  end;
end;

procedure TControl.SetHeightPercent(const Value: TPercentSize);
begin
  if (FHeightPercent <> Value) then
  begin
    FHeightPercent := Value;
    UpdateElementSize;
  end;
end;

procedure TControl.SetHeightStyle(const Value: TSizeStyle);
begin
  if (FHeightStyle <> Value) then
  begin
    FHeightStyle := Value;
    UpdateElementSize;
  end;
end;

procedure TControl.SetLeft(AValue: Integer);
begin
  if FLeft <> AValue then
  begin
    FLeft := AValue;
    if not FUpdateTopLeft then
      FOrigLeft := AValue;

    UpdateElementSize;
    RecreateCanvas;
    InternalResize;

    if not (csLoading in ComponentState) and (Align <> alNone) and Assigned(Parent) then
    begin
      DoRealign;
    end;
  end;
end;

procedure TControl.SetMargins(const Value: TMargins);
begin
  FMargins.Assign(Value);
end;

procedure TControl.SetTop(AValue: Integer);
begin
  if FTop <> AValue then
  begin
    FTop := AValue;
    if not FUpdateTopLeft then
      FOrigTop := AValue;
    UpdateElementSize;
    RecreateCanvas;
    InternalResize;

    if not (csLoading in ComponentState) and (Align <> alNone) and Assigned(Parent) then
    begin
      DoRealign;
    end;
  end;
end;

procedure TControl.SetScriptLoaded(const Value: boolean);
begin
  FScriptLoaded := Value;
end;

procedure TControl.SetShowFocus(const Value: boolean);
begin
  if (FShowFocus <> Value) then
  begin
    FShowFocus := Value;
    UpdateElement;
  end;
end;

procedure TControl.SetShowHint(AValue: Boolean);
begin
  if FShowHint <> AValue then
  begin
    FShowHint := AValue;
    UpdateElement;
  end;
end;

procedure TControl.SetHint(AValue: string);
begin
  if FHint <> AValue then
  begin
    FHint := AValue;
    UpdateElement;
  end;
end;

procedure TControl.SetID(const Value: string);
begin
  FID := Value;
  if Assigned(FContainer) then
    FContainer['id'] := Value;
end;

procedure TControl.SetTabOrder(AValue: Integer);
begin
  if FTabOrder <> AValue then
  begin
    FTabOrder := AValue;
    UpdateElement;
  end;
end;

procedure TControl.SetTabStop(AValue: Boolean);
begin
  if FTabStop <> AValue then
  begin
    FTabStop := AValue;
    UpdateElement;
  end;
end;

function TControl.ScreenToClient(const Point: TPoint): TPoint;
var
  Origin: TPoint;
begin
  Origin := ClientOrigin;
  Result.X := Point.X - Origin.X;
  Result.Y := Point.Y - Origin.Y;
end;

procedure TControl.SendToBack;
begin
  if Assigned(ElementHandle) then
    ElementHandle.style.setProperty('z-index', '0');
end;

procedure TControl.BeginUpdate;
begin
  inc(FUpdateCount);
end;

procedure TControl.Invalidate;
begin

end;

procedure TControl.EnableTab;
var
  i: integer;
begin
  if TabStop and CanFocus then
  begin
    FContainer['tabindex'] := IntToStr(TabOrder + 1)
  end
  else
  begin
    FContainer.removeAttribute('tabindex');
  end;

  for i := 0 to ControlCount - 1 do
  begin
    Controls[i].EnableTab;
  end;
end;

procedure TControl.EndUpdate;
begin
  if (FUpdateCount > 0) then
  begin
    dec(FUpdateCount);

    if FUpdateCount = 0 then
      UpdateElement;
  end;
end;

function TControl.IsUpdating: boolean;
begin
  Result := FUpdateCount > 0;
end;

procedure TControl.SetAlign(const Value: TAlign);
begin
  if FAlign <> Value then
  begin
    FAlign := Value;
    DoRealign;
  end;
end;

procedure TControl.SetAlignWithMargins(const Value: boolean);
begin
  if FAlignWithMargins <> Value then
  begin
    FAlignWithMargins := Value;
    DoRealign;
  end;
end;

procedure TControl.DoMarginsChanged(Sender: TObject);
begin
  DoRealign;
end;

procedure TControl.DoRealign;
begin
  if Assigned(Parent) and not Parent.IsUpdating then
  begin
    Parent.AlignControl(Parent);
  end;
end;

procedure TControl.SetAnchors(const Value: TAnchors);
begin
  if (FAnchors <> Value) then
  begin
    FAnchors := Value;
    if not (csLoading in ComponentState) then
      UpdateAnchoring;
  end;
end;

procedure TControl.SetBorderWidth(const Value: integer);
begin
  if (FBorderWidth <> Value) then
  begin
    FBorderWidth := Value;
    UpdateElement;
  end;
end;

procedure TControl.SetBounds(X, Y, AWidth, AHeight: Integer);
begin
  if (X <> Left) or (Y <> Top) or (AWidth <> Width) or (AHeight <> Height) then
  begin
    FBlockUpdateElement := True;
    FLeft := X;
    FTop := Y;
    FWidth := AWidth;
    FHeight := AHeight;
    FBlockUpdateElement := False;
    UpdateElementSize;
    if Align <> alNone then
      DoRealign
    else
      Realign;
    RecreateCanvas;
    //InternalResize;
    DoBoundsChange;
    Invalidate;
    UpdateChildAnchoring;
  end;
end;

procedure TControl.SetBoundsRect(const Value: TRect);
begin
  SetBounds(Value.Left, Value.Top, Value.Right - Value.Left, Value.Bottom - Value.Top);
end;

procedure TControl.SetElementClassName(AValue: string);
begin
  if (FElementClassName <> AValue) then
  begin
    FElementClassName := AValue;
    if Assigned(FContainer) then
      FContainer['class'] := AValue;
    UpdateElement;
  end;
end;

procedure TControl.SetElementFont(const Value: TElementFont);
begin
  if (FElementFont <> Value) then
  begin
    FElementFont := Value;
    UpdateElement;
  end;
end;

procedure TControl.SetElementPointer(AElement: TJSHTMLElement;
  ACursor: TCursor);
begin
  case ACursor of
    crDefault: AElement.style.setProperty('cursor', 'default');
    crArrow: AElement.style.setProperty('cursor', 'auto');
    crNone: AElement.style.setProperty('cursor', 'none');
    crCross: AElement.style.setProperty('cursor', 'crosshair');
    crIBeam: AElement.style.setProperty('cursor', 'text');
    crSizeNESW: AElement.style.setProperty('cursor', 'nesw-resize');
    crSizeNS: AElement.style.setProperty('cursor', 'ns-resize');
    crSizeNWSE: AElement.style.setProperty('cursor', 'nwse-resize');
    crSizeWE: AElement.style.setProperty('cursor', 'ew-resize');
    crUpArrow: AElement.style.setProperty('cursor', '');
    crHourGlass: AElement.style.setProperty('cursor', 'wait');
    crDrag: AElement.style.setProperty('cursor', '');
    crNoDrop: AElement.style.setProperty('cursor', 'no-drop');
    crHSplit: AElement.style.setProperty('cursor', 'col-resize');
    crVSplit: AElement.style.setProperty('cursor', 'row-resize');
    crMultIDrag: AElement.style.setProperty('cursor', '');
    crSQLWait: AElement.style.setProperty('cursor', 'progress');
    crNo: AElement.style.setProperty('cursor', 'not-allowed');
    crAppStart: AElement.style.setProperty('cursor', 'wait');
    crHelp: AElement.style.setProperty('cursor', 'help');
    crHandPoint: AElement.style.setProperty('cursor', 'pointer');
    crSizeAll: AElement.style.setProperty('cursor', 'move');
  end;

end;

procedure TControl.SetElementPosition(const Value: TElementPosition);
begin
  if (FElementPosition <> Value) then
  begin
    FElementPosition := Value;
    UpdateElementSize;
    RecreateCanvas;
    InternalResize;
  end;
end;

procedure TControl.SetHTMLElementFont(he: TJSHTMLElement; Font: TFont);
var
  s: string;
begin
  he.style.setProperty('font-family', Font.Name);

  he.style.setProperty('font-style', 'normal');

  if fsBold in Font.Style then
    he.style.setProperty('font-weight', 'bold')
  else
    he.style.setProperty('font-weight', '');

  if fsItalic in Font.Style then
     he.style.setProperty('font-style', 'italic');

  s := '';

  if fsUnderline in Font.Style then
    s := 'underline';

  if fsStrikeOut in Font.Style then
  begin
    if s <> ''  then
       s := s + ' ';
     s:= s + 'line-through';
  end;

  if (s <> '') then
    he.style.setProperty('text-decoration', s);

  he.style.setProperty('font-size', IntToStr(Font.Size) + 'pt');
end;

{$HINTS OFF}
procedure TControl.SetFocus;
var
  e: TJSHTMLElement;
begin
  if not CanFocus then
    Exit;

  if Assigned(ElementHandle) then
  begin
    e := ElementHandle;
    asm
      setTimeout(function() {e.focus();}, 1);
    end;
  end;
end;
{$HINTS ON}

procedure TControl.SetFont(AValue: TFont);
begin
  FFont.Name := AValue.Name;
  FFont.Size := AValue.Size;
  FFont.Style := AValue.Style;
  FFont.Color := AValue.Color;
end;

procedure TControl.SetChildOrderEx(const Value: integer);
begin
  FChildOrder := Value;
end;

procedure TControl.SetClientHeight(const Value: Integer);
begin
  Height := Value;
end;

procedure TControl.SetClientWidth(const Value: Integer);
begin
  Width := Value;
end;

procedure TControl.SetClipChildren(const Value: Boolean);
begin
  FClipChildren := Value;
  UpdateElement;
end;

procedure TControl.SetColor(AValue: TColor);
begin
  FColor := AValue;
  UpdateElement;
end;

procedure TControl.SetCursor(const Value: TCursor);
begin
  FCursor := Value;
  UpdateElement;
end;

function TControl.GetID: string;
begin
  Result := FID;
end;

function TControl.GetIsLinked: boolean;
begin
  Result := not FNew;
end;

function TControl.GetElementHandle: TJSHTMLElement;
begin
  Result := nil;
  if Assigned(FContainer) and FControlCreated then
    Result := TJSHTMLElement(FContainer);
end;

function TControl.GetMouseEventButton(Event: TJSMouseEvent): TMouseButton;
begin
  Result := mbLeft;
  case Event.button of
    0: Result := mbLeft;
    1: Result := mbMiddle;
    2: Result := mbRight;
  end;
end;

function TControl.GetMouseEventShiftState(Event: TJSMouseEvent): TShiftState;
begin
  Result := [];
  if Event.shiftKey then
    Result := Result + [ssShift];

  if Event.ctrlKey then
    Result := Result + [ssCtrl];

  if Event.altKey then
    Result := Result + [ssAlt];

  if Event.buttons and 1 = 1 then
     Result := Result + [ssLeft];

  if Event.buttons and 2 = 2 then
     Result := Result + [ssRight];

  if Event.buttons and 4 = 4 then
     Result := Result + [ssMiddle];
end;

function TControl.GetMouseWheelEventShiftState(
  Event: TJSWheelEvent): TShiftState;
begin
  Result := [];
  if Event.shiftKey then
    Result := Result + [ssShift];
  if Event.ctrlKey then
    Result := Result + [ssCtrl];
  if Event.altKey then
    Result := Result + [ssAlt];
end;

function TControl.GetOuterHeight: integer;
begin
  Result := FHeight - FBorderWidth;
end;

function TControl.GetOuterWidth: integer;
begin
  Result := FWidth - FBorderWidth;
end;

function TControl.GetKeyboardEventShiftState(Event: TJSKeyboardEvent): TShiftState;
begin
  Result := [];
  if Event.shiftKey then
    Result := Result + [ssShift];
  if Event.ctrlKey then
    Result := Result + [ssCtrl];
  if Event.altKey then
    Result := Result + [ssAlt];
end;

procedure TControl.ClearMethodPointers;
begin
  FWheelPtr := nil;
  FClickPtr := nil;
  FDblClickPtr := nil;
  FMouseDownPtr := nil;
  FMouseUpPtr := nil;
  FMouseMovePtr := nil;
  FMouseLeavePtr := nil;
  FMouseEnterPtr := nil;
  FKeyDownPtr := nil;
  FKeyUpPtr := nil;
  FKeypressPtr := nil;
  FEnterPtr := nil;
  FExitPtr := nil;
  FTouchStartPtr := nil;
  FTouchEndPtr := nil;
  FTouchMovePtr := nil;
  FLayerMouseLeavePtr := nil;
  FLayerMouseEnterPtr := nil;
  FScriptLoadedPtr := nil;
end;

procedure TControl.GetMethodPointers;
begin
  if FWheelPtr = nil then
  begin
    FWheelPtr := @HandleDoWheel;
    FClickPtr := @HandleDoClick;
    FDblClickPtr := @HandleDoDblClick;
    FMouseDownPtr := @HandleDoMouseDown;
    FMouseUpPtr := @HandleDoMouseUp;
    FMouseMovePtr := @HandleDoMouseMove;
    FMouseLeavePtr := @HandleDoMouseLeave;
    FMouseEnterPtr := @HandleDoMouseEnter;
    FKeyDownPtr := @HandleDoKeyDown;
    FKeyUpPtr := @HandleDoKeyUp;
    FKeypressPtr := @HandleDoKeyPress;
    FEnterPtr := @HandleDoEnter;
    FExitPtr := @HandleDoExit;
    FTouchStartPtr := @HandleDoTouchStart;
    FTouchEndPtr := @HandleDoTouchEnd;
    FTouchMovePtr := @HandleDoTouchMove;
    FLayerMouseLeavePtr := @LayerHandleDoMouseLeave;
    FLayerMouseEnterPtr := @LayerHandleDoMouseEnter;
    FScriptLoadedPtr := @RequiredScriptLoaded;
  end;
end;

procedure TControl.BindEvents;
var
  eh: TJSEventTarget;
begin
  if Assigned(ElementBindHandle) then
  begin
    GetMethodPointers;
    eh := ElementBindHandle;
    eh.addEventListener('wheel', FWheelPtr);
    eh.addEventListener('click', FClickPtr);
    eh.addEventListener('dblclick', FDblClickPtr);
    eh.addEventListener('mousedown', FMouseDownPtr);
    eh.addEventListener('mouseup', FMouseUpPtr);
    eh.addEventListener('mousemove', FMouseMovePtr);
    eh.addEventListener('mouseleave', FMouseLeavePtr);
    eh.addEventListener('mouseenter', FMouseEnterPtr);
    eh.addEventListener('keydown', FKeyDownPtr);
    eh.addEventListener('keyup', FKeyUpPtr);
    eh.addEventListener('keypress', FKeyPressPtr);
    eh.addEventListener('focus', FEnterPtr);
    eh.addEventListener('blur', FExitPtr);

    eh.addEventListener('touchstart', FTouchStartPtr);
    eh.addEventListener('touchmove', FTouchMovePtr);
    eh.addEventListener('touchend', FTouchEndPtr);
    eh.addEventListener('touchcancel', FTouchEndPtr);
  end;
end;

procedure TControl.BringToFront;
begin
  if Assigned(ElementHandle) then
    ElementHandle.style.setProperty('z-index', '999998');
end;

function TControl.GetClientHeight: Integer;
begin
  Result := Height;
end;

function TControl.GetClientOrigin: TPoint;
var
  r: TJSDomRect;
begin
  Result := Point(0, 0);
  if Assigned(ElementHandle) then
  begin
    r := ElementHandle.getBoundingClientRect;
    Result := Point(Round(r.Left), Round(r.Top));
  end;
end;

function TControl.GetClientRect: TRect;
begin
  Result := Rect(0, 0, GetWidth, GetHeight);
end;

function TControl.GetClientWidth: Integer;
begin
  Result := Width;
end;

function TControl.GetKeyCode(AValue: string; IgnoreCase: boolean = true): Integer;
var
  i: Integer;
begin
  i := -1;

  case AValue of
    'Up': i := 38;
    'Down': i := 40;
    'Left': i := 37;
    'Right': i := 39;
    TJSKeyNames.BackSpace: i := 8;
    TJSKeyNames.Tab: i := 9;
    TJSKeyNames.Enter: i := 13;
    TJSKeyNames.Shift: i := 16;
    TJSKeyNames.Control: i := 17;
    TJSKeyNames.Alt: i := 18;
    TJSKeyNames.Pause: i := 19;
    TJSKeyNames.CapsLock: i := 20;
    TJSKeyNames.Escape: i := 27;
    TJSKeyNames.PageUp: i := 33;
    TJSKeyNames.PageDown: i := 34;
    TJSKeyNames._End: i := 35;
    TJSKeyNames.Home: i := 36;
    TJSKeyNames.ArrowLeft: i := 37;
    TJSKeyNames.ArrowUp: i := 38;
    TJSKeyNames.ArrowRight: i := 39;
    TJSKeyNames.ArrowDown: i := 40;
    TJSKeyNames.Insert: i := 45;
    TJSKeyNames.Delete: i := 46;

    TJSKeyNames.F1: i := 112;
    TJSKeyNames.F2: i := 113;
    TJSKeyNames.F3: i := 114;
    TJSKeyNames.F4: i := 115;
    TJSKeyNames.F5: i := 116;
    TJSKeyNames.F6: i := 117;
    TJSKeyNames.F7: i := 118;
    TJSKeyNames.F8: i := 119;
    TJSKeyNames.F9: i := 120;
    TJSKeyNames.F10: i := 121;
    TJSKeyNames.F11: i := 122;
    TJSKeyNames.F12: i := 123;
    TJSKeyNames.F13: i := 124;
    TJSKeyNames.F14: i := 125;
    TJSKeyNames.F15: i := 126;
    TJSKeyNames.F16: i := 127;
    TJSKeyNames.F17: i := 128;
    TJSKeyNames.F18: i := 129;
    TJSKeyNames.F19: i := 130;
    TJSKeyNames.F20: i := 131;
    else
    begin
      if Length(AValue) > 0 then
      begin
        i := Ord(AValue[1]);
        if IgnoreCase and (i >= 65) and (i <= 90) then
          i := i and $DF;
      end;
    end;
  end;

  Result := i;
end;

function TControl.GetLeft: Integer;
begin
  Result := FLeft;
  if (Result = -1) and Assigned(ElementHandle) and not (csLoading in ComponentState) then
    Result := Round(ElementHandle.offsetLeft);
end;

function TControl.GetTop: Integer;
begin
  Result := FTop;
  if (Result = -1) and Assigned(ElementHandle) and not (csLoading in ComponentState) then
    Result := Round(ElementHandle.offsetTop);
end;

function TControl.GetTouchEventShiftState(Event: TJSTouchEvent): TShiftState;
begin
  Result := [];
  if Event.shiftKey then
    Result := Result + [ssShift];
  if Event.ctrlKey then
    Result := Result + [ssCtrl];
  if Event.altKey then
    Result := Result + [ssAlt];
end;

function TControl.GetWebClassName: string;
begin
  Result := ClassName;
  Delete(Result, 1, 1);
  Result := 'TWeb' + Result;
end;

function TControl.GetWidth: Integer;
var
  cr: TJSDOMRect;
begin
  Result := FWidth;

  if not (csLoading in ComponentState) and Assigned(ElementHandle) then
  begin
    if (Result = -1) then
    begin
      Result := Round(ElementHandle.offsetWidth);
    end;

    if (WidthStyle = ssPercent) then
    begin
      cr := ElementHandle.getBoundingClientRect;
      Result := Round(cr.Right - cr.Left);
    end;
  end;
end;

function TControl.GetHeight: Integer;
var
  cr: TJSDOMRect;
begin
  Result := FHeight;

  if not (csLoading in ComponentState) and Assigned(ElementHandle) then
  begin
    if (Result = -1) then
    begin
      Result := Round(ElementHandle.offsetHeight);
    end;

    if (HeightStyle = ssPercent) then
    begin
      cr := ElementHandle.getBoundingClientRect;
      Result := Round(cr.Bottom - cr.Top);
    end;
  end;
end;

procedure TControl.KeyDown(var Key: Word; Shift: TShiftState);
begin
  if Assigned(OnKeyDown) then
    OnKeyDown(Self, Key, Shift);
end;

procedure TControl.KeyPress(var Key: Char);
begin
  if Assigned(OnKeyPress) then
    OnKeyPress(Self, Key);
end;

procedure TControl.KeyUp(var Key: Word; Shift: TShiftState);
begin
  if Assigned(OnKeyUp) then
    OnKeyUp(Self, Key, Shift);
end;

function TControl.LayerHandleDoMouseEnter(Event: TJSMouseEvent): Boolean;
begin
  if (Event.buttons = 0) and Captured and not FMouseInsideLayer then
  begin
    ReleaseCapture;
    HandleDoMouseUp(Event);
    HandleDoMouseLeave(Event);
  end;
  FMouseInsideLayer := True;
  Result := true;
end;

function TControl.LayerHandleDoMouseLeave(Event: TJSMouseEvent): Boolean;
begin
  FMouseInsideLayer := False;
  Result := true;
end;

procedure TControl.Loaded;
var
  i: integer;
begin
  inherited;
  
  for i := 0 to ControlCount - 1 do
  begin
    // handle relative child positions
    if (Controls[i].ElementPosition = epRelative) and not (Controls[i].IsLinked) and (Controls[i].ChildOrder >= 0) then
    begin
      if (Controls[i].ChildOrder < Container.childNodes.Length) then
        Container.insertBefore(Controls[i].Container, Container.childNodes[Controls[i].ChildOrder]);
    end;

    Controls[i].Loaded;
  end;

  case Align of
    alClient:
    begin
      if Assigned(Parent) then
        SetBounds(0, 0, Parent.FWidth, Parent.FHeight);
    end;
  end;




  Resize;

  UpdateElement;
end;

function TControl.HandleAllocated: Boolean;
begin
  Result := True;
end;

function TControl.HandleDoClick(Event: TJSMouseEvent): Boolean;
begin
  FElementEvent := Event;
  if (eeClick in EventStopPropagation) then
    StopPropagation;
  Click;
  Result := True;
  FElementEvent := nil;
end;

function TControl.HandleDoDblClick(Event: TJSMouseEvent): Boolean;
begin
  FElementEvent := Event;
  if (eeDblClick in EventStopPropagation) then
    StopPropagation;
  DblClick;
  Result := True;
  FElementEvent := nil;
end;

procedure TControl.XYToClient(X,Y: single; var AClientX, AClientY: single);
var
  p: TControl;
  cr: TJSDOMRect;
begin
  AClientX := X - Left + document.body.scrollLeft + document.documentElement.scrollLeft;
  AClientY := Y - Top + document.body.scrollTop + document.documentElement.scrollTop;

  p := Parent;
  while Assigned(p) do
  begin

    if (p is TCustomForm) then
    begin

      if Assigned((p as TCustomForm).Container) and ((p as TCustomForm).FormContainer = '') and ((p as TCustomForm).FormFileName <> '') and ((p as TCustomForm).Popup) then
      begin
        cr := (p as TCustomForm).Container.getBoundingClientRect;
        AClientX := AClientX - cr.Left;
        AClientY := AClientY - cr.Top;
      end;
    end;

    AClientX := AClientX - p.Left;
    AClientY := AClientY - p.Top;
    p := p.Parent;
  end;
end;

function TControl.HandleDoMouseDown(Event: TJSMouseEvent): Boolean;
var
  l, t: Single;
  ss: TShiftState;
  mb: TMouseButton;
begin
  FElementEvent := Event;
  if (eeMouseDown in EventStopPropagation) then
    StopPropagation;

  if not CanFocus then
    PreventDefault;

  XYToClient(Event.clientX, Event.clientY, l, t);

  ss := GetMouseEventShiftState(Event);
  mb := GetMouseEventButton(Event);

  MouseDown(mb, ss, Trunc(l), Trunc(t));
  Result := True;
  FElementEvent := nil;
end;

function TControl.HandleDoMouseUp(Event: TJSMouseEvent): Boolean;
var
  l, t: Single;
  ss: TShiftState;
  mb: TMouseButton;
begin
  FElementEvent := Event;
  if (eeMouseUp in EventStopPropagation) then
    StopPropagation;

  XYToClient(Event.clientX, Event.clientY, l, t);

  ss := GetMouseEventShiftState(Event);
  mb := GetMouseEventButton(Event);

  MouseUp(mb, ss, Trunc(l), Trunc(t));
  Result := True;
  FElementEvent := nil;
end;

function TControl.HandleDoWheel(Event: TJSWheelEvent): Boolean;
var
  ss: TShiftState;
  h: Boolean;
begin
  FElementEvent := Event;
  StopPropagation;

  ss := GetMouseWheelEventShiftState(Event);

  h := True;
  MouseWheel(ss, Trunc(-Event.deltay), h);
  Result := h;
  FElementEvent := nil;
end;

procedure TControl.HandleFontChanged(Sender: TObject);
var
  i: integer;
begin
  if (FUpdateCount = 0) then
  begin
    ParentFont := false;

    for i := 0 to ControlCount - 1 do
      begin
        if Controls[i].ParentFont then
        begin
          Controls[i].Font.Assign(Font);
          Controls[i].FontChanged;
        end;
      end;
  end;

  FontChanged;
end;

function TControl.HandleDoTouchStart(Event: TJSTouchEvent): Boolean;
var
  l,t: single;
  touch: TJSTouch;
  ss: TShiftState;
begin
  FElementEvent := Event;

  StopPropagation;

  if Event.Touches.length > 0 then
  begin
    touch := Event.touches.Touches[0];

    XYToClient(touch.clientX, touch.clientY, l, t);

    ss := GetTouchEventShiftState(Event);

    if LinkTouchEvents then
    begin
      MouseDown(mbLeft, ss, Trunc(l), Trunc(t));
    end;

    TouchStart(Trunc(l), Trunc(t));
  end;
  Result := True;
  FElementEvent := nil;
end;

function TControl.HandleDoTouchMove(Event: TJSTouchEvent): Boolean;
var
  l,t: single;
  touch: TJSTouch;
  ss: TShiftState;
begin
  FElementEvent := Event;

  StopPropagation;
  if Captured then
    PreventDefault;

  if Event.Touches.length > 0 then
  begin
    touch := Event.touches.Touches[0];

    XYToClient(touch.clientX, touch.clientY, l, t);

    ss := GetTouchEventShiftState(Event);

    if LinkTouchEvents then
      MouseMove(ss, Trunc(l), Trunc(t));

    TouchMove(Trunc(l), Trunc(t));
  end;
  FElementEvent := nil;
  Result := True;
end;

function TControl.HandleDoTouchEnd(Event: TJSTouchEvent): Boolean;
var
  l,t: single;
  touch: TJSTouch;
  ss: TShiftState;
begin
  FElementEvent := Event;
  ReleaseCapture;
  StopPropagation;

  if Event.Touches.length > 0 then
  begin
    touch := Event.touches.Touches[0];

    XYToClient(touch.clientX, touch.clientY, l, t);

    ss := GetTouchEventShiftState(Event);

    if LinkTouchEvents then
    begin
      MouseUp(mbLeft, ss, Trunc(l), Trunc(t));
    end;

    TouchEnd(Trunc(l), Trunc(t));
  end;
  FElementEvent := nil;
  Result := True;
end;

procedure TControl.DragDrop(Source: TObject; X, Y: Integer);
begin
  if Assigned(OnDragDrop) then
    OnDragDrop(Self, Source, X, Y);
end;

procedure TControl.DragOver(Source: TObject; X, Y: Integer;
  State: TDragState; var Accept: Boolean);
begin
  if Assigned(OnDragOver) then
    OnDragOver(Self, Source, X, Y, State, Accept);
end;

procedure TControl.DoBoundsChange;
var
  b: boolean;
  frm: TCustomForm;
begin
  UpdateElementSize;
  RecreateCanvas;

  if FIsResizing then
    Exit;

  FIsResizing := true;

  InternalResize;

  DoRealign;

  b := false;

  frm := GetParentForm(Self);
  if Assigned(frm) then
  begin
    b := frm.IsResizing;
    b := b or (TControl(frm).FUpdateCount > 0);
   // b := b or TControl(frm).FIsAligning;
  end;

  b := b or (FUpdateCount > 0);

  b := b or FIsAligning;

  if not b then
    UpdateChildAnchoring;

  FIsResizing := false;
end;

procedure TControl.DoEndDrag(Target: TObject; X, Y: Integer);
begin
  if Assigned(OnEndDrag) then
    OnEndDrag(Self, Target, X, Y);
end;

procedure TControl.DoStartDrag(var DragObject: TDragObject); 
begin
  if Assigned(OnStartDrag) then
    OnStartDrag(Self, DragObject);
end;

function TControl.Focused: Boolean;
begin
  Result := FContainer = document.activeElement;
end;


procedure TControl.FontChanged;
begin
  UpdateElement;
end;

function TControl.HandleDoMouseLeave(Event: TJSMouseEvent): Boolean;
begin
  if Captured then
    Exit;

  Event.stopPropagation;
  DoMouseLeave;
  Result := true;
end;

function TControl.HandleDoMouseEnter(Event: TJSMouseEvent): Boolean;
begin
  if Captured then
    Exit;

  Event.stopPropagation;
  DoMouseEnter;
  Result := true;
end;

function TControl.HandleDoMouseMove(Event: TJSMouseEvent): Boolean;
var
  l, t: Single;
  ss: TShiftState;
begin
  FElementEvent := Event;
  if (eeMouseMove in EventStopPropagation) then
    StopPropagation;

  XYToClient(Event.clientX, Event.clientY, l, t);

  ss := GetMouseEventShiftState(Event);

  MouseMove(ss, Trunc(l), Trunc(t));
  Result := True;
  FElementEvent := nil;
end;

function TControl.HandleDoKeyDown(Event: TJSKeyBoardEvent): Boolean;
var
  k: Word;
  ss: TShiftState;
  undef: boolean;
begin
  FElementEvent := Event;
  StopPropagation;

  asm
    undef = (Event.key == undefined);
  end;

  if not undef then
  begin
    k := GetKeyCode(Event.Key, true);

    ss := GetKeyboardEventShiftState(Event);

    KeyDown(k, ss);
  end;

  Result := True;
  if k = 0 then
    PreventDefault;

  FElementEvent := nil;
end;

function TControl.HandleDoKeyUp(Event: TJSKeyBoardEvent): Boolean;
var
  k: Word;
  ss: TShiftState;
  c: Char;
  undef: boolean;
begin
  FElementEvent := Event;
  StopPropagation;

  asm
    undef = (Event.key == undefined);
  end;

  if not undef then
  begin
    k := GetKeyCode(Event.Key, true);

    ss := GetKeyboardEventShiftState(Event);

    // #27 is not send as char in the browser
    if (k = 27) then
    begin
      c := #27;
      KeyPress(c);
    end;

    KeyUp(k, ss);
  end;

  Result := True;
  if k = 0 then
    PreventDefault;
  FelementEvent := nil;
end;

function TControl.HandleDoKeyPress(Event: TJSKeyBoardEvent): Boolean;
var
  c: Char;
begin
  FElementEvent := Event;
  StopPropagation;

  if IsKeyCharacter(Event.Key) then
  begin
    c := Chr(GetKeyCode(Event.Key, false));
    KeyPress(c);
  end;

  Result := True;
  if c = #0 then
    PreventDefault;
  FElementEvent := nil;
end;

function TControl.HandleDoEnter(Event: TJSFocusEvent): Boolean;
begin
  FElementEvent := Event;
  StopPropagation;

  DoEnter;
  Result := True;
  FElementEvent := nil;
end;

function TControl.HandleDoExit(Event: TEventListenerEvent): Boolean;
begin
  DoExit;
  Result := True;
end;

procedure TControl.SetParent(AValue: TControl);
begin
  if FParent <> AValue then
  begin
    if Assigned(FParent) then
      FParent.UnRegisterParent(Self);

    FPrevParent := FParent;
    FParent := AValue;

    if Assigned(FParent) then
      FParent.RegisterParent(Self);

    UpdateParent;
    UpdateElement;
    InitScript;
  end;
end;

procedure TControl.SetParentComponent(Value: TComponent);
begin
  if (Parent <> Value) and (Value is TControl) then
   SetParent(TControl(Value));
end;

procedure TControl.SetParentElement(const Value: TJSHTMLElement);
begin
  if FNew then
  begin
    ElementPosition := epRelative;
    CreateControl;
    FParentElement := Value;

    if Assigned(FParentElement) then
    begin
      FParentElement.appendChild(Container);
    end;
  end
  else
  begin
    if Assigned(FParentElement) then
      FParentElement.removeChild(Container);

    FParentElement := Value;

    if Assigned(FParentElement) then
      FParentElement.appendChild(Container);
  end;

  UpdateElement;
  InitScript;
  Invalidate;
end;

procedure TControl.SetParentElementID(const Value: string);
var
  el: TJSHTMLElement;
begin
  FParentElementID := Value;

  el := TJSHTMLElement(document.getElementById(FParentElementID));

  if Assigned(el) then
    SetParentElement(el);
end;

procedure TControl.SetRequiredScripts(const Value: TStringList);
begin
  FRequiredScripts.Assign(Value);
end;

procedure TControl.Realign;
begin
  AlignControl(Self);
end;

procedure TControl.RecreateCanvas;
begin
end;

procedure TControl.RecreateElement;
begin
  if Assigned(Container) then
  begin
    UnbindEvents;
    Container.parentNode.removeChild(Container);
    FElement := nil;
    CreateControl;

    if Assigned(Parent) and Assigned(Parent.Container) then
      Parent.Container.appendChild(Container);
  end;
end;

procedure TControl.RegisterParent(AValue: TControl);
begin
  SetLength(FControls, Length(FControls) + 1);
  FControls[Length(FControls) - 1] := AValue;
end;

procedure TControl.ReleaseCapture;
var
  eh: TJSHTMLElement;
begin
  FCaptureDown := False;

  if FCaptured and Assigned(FLayer) then
  begin
    eh := TJSHTMLElement(FLayer);
    eh.removeEventListener('mouseenter', FLayerMouseEnterPtr);
    eh.removeEventListener('mouseleave', FLayerMouseLeavePtr);
    eh.removeEventListener('mousedown', FMouseDownPtr);
    eh.removeEventListener('mouseup', FMouseUpPtr);
    eh.removeEventListener('mousemove', FMouseMovePtr);
    eh.removeEventListener('touchstart', FTouchStartPtr);
    eh.removeEventListener('touchmove', FTouchMovePtr);
    eh.removeEventListener('touchend', FTouchEndPtr);
    eh.removeEventListener('touchcancel', FTouchEndPtr);

    document.body.removeChild(eh);
    FCaptured := False;
    FLayer := nil;
    UpdateElement;
  end;
end;

procedure TControl.Resize;
begin
  if Assigned(FOnResize) then
    FOnResize(Self);
end;

function TControl.AnchoringInitialized: boolean;
begin
  Result := (OrigRect.Left <> -1) or (OrigRect.Top <> -1);
end;

procedure TControl.InitAnchoring;
var
  i: integer;
begin
  if AnchoringInitialized then
    Exit;

  if (Self is TForm) then
    FOrigRect := Rect(0, 0, GetDesignWidth, GetDesignHeight)
  else
    FOrigRect := Rect(GetDesignLeft,GetDesignTop,GetDesignLeft + GetDesignWidth, GetDesignTop + GetDesignHeight);

  if Assigned(Parent) then
  begin
    if (Parent is TForm) then
      FOrigParentRect := Rect(0,0, Parent.GetDesignWidth, Parent.GetDesignHeight)
    else
    begin
      InitAnchoring;
      FOrigParentRect := Rect(Parent.GetDesignLeft, Parent.GetDesignTop, Parent.GetDesignLeft + Parent.GetDesignWidth, Parent.GetDesignTop + Parent.GetDesignHeight);
    end;
  end;

  for i := 0 to ControlCount - 1 do
    Controls[i].InitAnchoring;
end;

procedure TControl.InjectCSS;
var
  cssname,css: string;
  cssmgr: TCSSCodeManager;
begin
  cssname := GetWebClassname;

  css := '';

  cssmgr := GetCSSManager;

  if Assigned(cssmgr) then
    css := cssmgr.GetClassCSS(cssname);

  if (css <> '') then
    AddControlStyle(css);
end;

procedure TControl.InternalResize;
begin
  if (csLoading in ComponentState) then
    Exit;

  Resize;
end;

procedure TControl.InternalUpdateParent;
var
  p: TControl;
begin
  p := FPrevParent;
  if FNew then
  begin
    CreateControl;

    if Assigned(Container) then
    begin
      if Assigned(Parent) and not Assigned(Parent.Container) then
        Parent.CreateControl;

      if (Parent <> p) and Assigned(p) and Assigned(p.Container) then
        p.Container.removeChild(Container);

      if Assigned(Parent) and Assigned(Parent.Container) then
      begin
        Parent.Container.appendChild(Container);
      end;
    end;
  end;
end;

function TControl.IsFocused: Boolean;
begin
  Result := FContainer = document.activeElement;
end;

function TControl.IsKeyCharacter(AValue: string): Boolean;
begin
  case AValue of
    'Up',
    'Down',
    'Left',
    'Right',
    TJSKeyNames.Shift,
    TJSKeyNames.Control,
    TJSKeyNames.Alt,
    TJSKeyNames.Pause,
    TJSKeyNames.CapsLock,
    TJSKeyNames.PageUp,
    TJSKeyNames.PageDown,
    TJSKeyNames._End,
    TJSKeyNames.Home,
    TJSKeyNames.ArrowLeft,
    TJSKeyNames.ArrowUp,
    TJSKeyNames.ArrowRight,
    TJSKeyNames.ArrowDown,
    TJSKeyNames.Insert,
    TJSKeyNames.Delete,
    TJSKeyNames.F1,
    TJSKeyNames.F2,
    TJSKeyNames.F3,
    TJSKeyNames.F4,
    TJSKeyNames.F5,
    TJSKeyNames.F6,
    TJSKeyNames.F7,
    TJSKeyNames.F8,
    TJSKeyNames.F9,
    TJSKeyNames.F10,
    TJSKeyNames.F11,
    TJSKeyNames.F12,
    TJSKeyNames.F13,
    TJSKeyNames.F14,
    TJSKeyNames.F15,
    TJSKeyNames.F16,
    TJSKeyNames.F17,
    TJSKeyNames.F18,
    TJSKeyNames.F19,
    TJSKeyNames.F20: Result := False;
    else
      Result := True;
  end;
end;

function TControl.IsStructuralElement: boolean;
begin
  Result := false;
end;

procedure TControl.UnbindEvents;
var
  eh: TJSEventTarget;
begin
  if Assigned(ElementBindHandle) then
  begin
    eh := ElementBindHandle;

    eh.removeEventListener('wheel', FWheelPtr);
    eh.removeEventListener('click', FClickPtr);
    eh.removeEventListener('dblclick', FDblClickPtr);
    eh.removeEventListener('mousedown', FMouseDownPtr);
    eh.removeEventListener('mouseup', FMouseUpPtr);
    eh.removeEventListener('mousemove', FMouseMovePtr);
    eh.removeEventListener('mouseleave', FMouseLeavePtr);
    eh.removeEventListener('mouseenter', FMouseEnterPtr);
    eh.removeEventListener('keydown', FKeyDownPtr);
    eh.removeEventListener('keyup', FKeyUpPtr);
    eh.removeEventListener('keypress', FKeyPressPtr);
    eh.removeEventListener('focus', FEnterPtr);
    eh.removeEventListener('blur', FExitPtr);

    eh.removeEventListener('touchstart', FTouchStartPtr);
    eh.removeEventListener('touchmove', FTouchMovePtr);
    eh.removeEventListener('touchend', FTouchEndPtr);
    eh.removeEventListener('touchcancel', FTouchEndPtr);
  end;
end;

procedure TControl.UnRegisterParent(AValue: TControl);
var
  i: Integer;
  flg: Boolean;
begin
  flg := false;

  for i := 0 to Length(FControls) - 1 do
  begin
    if (FControls[i] = AValue) then
    begin
      flg := True;
    end;

    if (flg) and (i < Length(FControls) - 1) then
      FControls[i] := FControls[i+1];
  end;

  if flg then
    SetLength(FControls, Length(FControls) - 1);
end;

procedure TControl.UpdateAnchoring;
var
  dxr,dyr: integer;
  dxo,dyo: integer;
  dxw,dyw: integer;
  br,r: TRect;
begin
  if (csLoading in ComponentState) then
    Exit;

  if not FControlCreated then
    Exit;

  if Assigned(Parent) and not Parent.AnchoringInitialized then
    Exit;

  if (OrigRect.Left = -1) and (OrigRect.Top = -1) then
    InitAnchoring;

  if (Align <> alClient) then
  begin
    if Assigned(Parent) and not (Self is TForm) then
    begin
      r := Parent.BoundsRect;

      dxr := (r.Right - r.Left) - (FOrigParentRect.Right - FOrigParentRect.Left);
      dyr := (r.Bottom - r.Top) - (FOrigParentRect.Bottom - FOrigParentRect.Top);

      br := FOrigRect;

      dxo := 0;
      dyo := 0;
      dxw := 0;
      dyw := 0;

      if (akRight in Anchors) then
      begin
        if (akLeft in Anchors) then
          dxw := dxr
        else
          dxo := dxr;
      end;

      if (akBottom in Anchors) then
      begin
        if (akTop in Anchors) then
          dyw := dyr
        else
          dyo := dyr;
      end;

      if (akBottom in Anchors) or (akRight in Anchors) then
      begin
        SetBounds(br.Left + dxo, br.Top + dyo, br.Right - br.Left + dxw, br.Bottom - br.Top  + dyw);
      end;
    end;
  end;

  UpdateChildAnchoring;
end;

procedure TControl.UpdateChildAnchoring;
var
  i: integer;
begin
  for i := 0 to ControlCount - 1 do
  begin
    Controls[i].UpdateAnchoring;
  end;
end;

procedure TControl.UpdateChildren(AControl: TControl);
var
  I: Integer;
  c: TControl;
begin
  if Assigned(AControl) then
  begin
    AControl.DoRealign;
    if AControl is TCustomControl then
    begin
      (AControl as TCustomControl).RecreateCanvas;
      (AControl as TCustomControl).Invalidate;
    end;

    for I := 0 to AControl.ControlCount - 1 do
    begin
      c := AControl.Controls[I];
      UpdateChildren(c);
    end;
  end;
end;

procedure TControl.PersistinHTML;
var
  i: integer;
begin
  for i := 0 to ControlCount - 1 do
    Controls[i].PersistinHTML;
end;

procedure TControl.InitFromHTML;
var
  i: integer;
begin
  for i := 0 to ControlCount - 1 do
    Controls[i].InitFromHTML;
end;

procedure TControl.InitScript;
begin
  // script to initialize any javascript control
end;

procedure TControl.PreventDefault;
begin
  if Assigned(ElementEvent) then
    ElementEvent.preventDefault;
end;

procedure TControl.UpdateElement;
begin
  if FBlockUpdateElement or (FUpdateCount > 0) then
    Exit;

  if Assigned(ElementHandle) and (ElementHandle <> ContainerElement) and not IsUpdating then
  begin
    UpdateElementData;

    if not IsLinked then
      UpdateElementVisual;
  end;
end;

procedure TControl.UpdateElementData;
var
  eh: TJSHTMLElement;
begin
  eh := ElementHandle;
  if Assigned(eh) then
  begin
    if not (csDesigning in ComponentState) then
    begin
      if Visible then
        eh.style.setProperty('display', '')
      else
        eh.style.setProperty('display', 'none');
    end;

    EnableTab;

    if Enabled then
      FContainer.removeAttribute('disabled')
    else
      FContainer.setAttribute('disabled','');

    if IsLinked and (Hint = '') then
      Exit;

    if ShowHint and (Hint <> '') then
       FContainer['title'] := Hint
    else
       FContainer.removeAttribute('title');

    if not Enabled then
      eh.style.removeProperty('color');
  end;
end;

procedure TControl.UpdateElementSize;
var
  eh: TJSHTMLElement;
  offsLeft,offsTop,ow: integer;
begin
  if FBlockUpdateElement or (FUpdateCount > 0) then
    Exit;

  if IsLinked then
    Exit;

  if Assigned(ElementHandle) and (ElementHandle <> ContainerElement) then
  begin
    eh := ElementHandle;

    offsLeft := 0;
    offsTop := 0;

    if Assigned(Parent) and (Parent.IsStructuralElement) then
    begin
      offsLeft := Parent.Left;
      offsTop := Parent.Top;
    end;

    if (ElementPosition = epAbsolute) then
    begin
      if FTop <> -1 then
        eh.style.setProperty('top', IntToStr(FTop + offsTop) + 'px')
      else
        eh.style.setProperty('top', '');

      if FLeft <> -1 then
        eh.style.setProperty('left', IntToStr(FLeft + offsLeft) + 'px')
      else
        eh.style.setProperty('left', '');
    end
    else
    begin
      eh.style.removeProperty('top');
      eh.style.removeProperty('left');
    end;

    if (FWidthStyle = ssAbsolute) then
    begin
      ow := GetOuterWidth;
      if FWidth <> -1 then
        eh.style.setProperty('width', IntToStr(ow) + 'px')
      else
        eh.style.removeProperty('width');
    end;

    if (FWidthStyle = ssAuto) then
    begin
      eh.style.removeProperty('width');
    end;

    if (FHeightStyle = ssAbsolute) then
    begin
      if FHeight <> -1 then
        eh.style.setProperty('height', IntToStr(GetOuterHeight) + 'px')
      else
        eh.style.removeProperty('height');
    end;

    if (FHeightStyle = ssAuto) then
    begin
      eh.style.removeProperty('height');
    end;

    if (FWidthStyle = ssPercent) then
    begin
      if FWidth <> -1 then
        eh.style.setProperty('width', IntToStr(FWidthPercent) + '%')
      else
        eh.style.removeProperty('width');
    end;

    if (FHeightStyle = ssPercent) then
    begin
      if FHeight <> -1 then
        eh.style.setProperty('height', IntToStr(FHeightPercent) + '%')
      else
        eh.style.removeProperty('height');
    end;

    if FElementPosition = epAbsolute then
      eh.style.setProperty('position', 'absolute')
    else if FElementPosition = epRelative then
      eh.style.setProperty('position', 'relative')
    else
      eh.style.removeProperty('position');
  end;
end;

procedure TControl.UpdateElementVisual;
var
  eh: TJSHTMLElement;
begin
  eh := ElementHandle;

  if not Assigned(eh) then
    Exit;

  if IsUpdating then
    Exit;

//  if (csLoading in ComponentState) then
//    Exit;

  if ParentFont and Assigned(Parent) then
  begin
    Font.Assign(Parent.Font);
  end;

  if ElementClassName = '' then
  begin
    if ClipChildren then
      eh.style.setProperty('overflow', 'hidden')
    else
      eh.style.setProperty('overflow', '');
  end;

  if (ElementClassName = '') then
    SetElementPointer(eh, Cursor);

  if Captured then
    TJSHTMLElement(FLayer).style.setProperty('cursor', eh.style.getPropertyValue('cursor'));

  if (ElementClassName <> '') or CanShowFocus then
    eh.style.setProperty('outline', '')
  else
    eh.style.setProperty('outline', 'none');

  UpdateElementSize;

  case FTextDirection of
  tdDefault: eh.style.removeProperty('direction');
  tdRightToLeft: eh.style.setProperty('direction','rtl');
  tdLeftToRight: eh.style.setProperty('direction','ltr');
  tdInherit: eh.style.setProperty('direction','inherit');
  end;

  if ElementClassName = '' then
  begin
    eh.style.setProperty('webkit-user-select', 'none');
    eh.style.setProperty('moz-user-select', 'none');
    eh.style.setProperty('khtml-user-select', 'none');
    eh.style.setProperty('ms-user-select', 'none');
    eh.style.setProperty('user-select', 'none');
    eh.style.setProperty('-webkit-tap-highlight-color', 'transparent');
  end;

  if (ElementClassName = '') and (ElementFont = efProperty) and not IsLinked then
  begin
    if Enabled then
      eh.style.setProperty('color', ColorToHtml(Font.Color));

    SetHTMLElementFont(eh, Font);
  end
  else
  begin
    eh.style.setProperty('color', '');
    eh.style.setProperty('font-family', '');
    eh.style.setProperty('font-style', '');
    eh.style.setProperty('font-size', '');
  end;
end;

procedure TControl.UpdateParent;
begin
  InternalUpdateParent;
  if (csLoading in ComponentState) and Assigned(Parent) and not (csLoading in Parent.ComponentState) then
    Loaded;
  UpdateChildren(FPrevParent);
  UpdateChildren(Parent);
end;

procedure TControl.VisibleChanging;
begin

end;

function TControl.GetBoundsRect: TRect;
begin
  Result.Left := Left;
  Result.Top := Top;
  Result.Right := Left + Width;
  Result.Bottom := Top + Height;
end;

function TControl.GetControlsCount: Integer;
begin
  Result := Length(FControls);
end;

function TControl.GetCSSManager: TCSSCodeManager;
var
  frm: TCustomForm;
  i: integer;
begin
  Result := nil;

  frm := GetParentForm(Self);

  if Assigned(frm) then
  begin
    for i := 0 to frm.ComponentCount - 1 do
    begin
      if frm.Components[i] is TCSSCodeManager then
      begin
        Result := frm.Components[i] as TCSSCodeManager;
      end;
    end;
  end;
end;

function TControl.GetDesignHeight: integer;
begin
  Result := FHeight;
end;

function TControl.GetDesignLeft: integer;
begin
  Result := FLeft;
end;

function TControl.GetDesignTop: integer;
begin
  Result := FTop;
end;

function TControl.GetDesignWidth: integer;
begin
  Result := FWidth;
end;

function TControl.GetControls(Index: Integer): TControl;
begin
  Result := FControls[Index];
end;

procedure TControl.DoEnter;
begin
  if Assigned(OnEnter) then
    OnEnter(Self);
end;

procedure TControl.DoExit;
begin
  if Assigned(OnExit) then
    OnExit(Self);
end;

{ TCustomControl }

procedure TCustomControl.CreateInitialize;
begin
  inherited;
  FCaption := '';
  ControlStyle := ControlStyle + [csSetCaption];
  FBorderStyle := bsSingle;
  FBorderColor := clSilver;
  FPainting := false;
  FWidth := 100;
  FHeight := 25;
  FWidthPercent := 100;
  FHeightPercent := 100;
  FCustomBorder := false;
end;

procedure TCustomControl.BindEvents;
begin
  inherited;
end;

procedure TCustomControl.CreateControl;
begin
  inherited;
  RecreateCanvas;
end;

function TCustomControl.CreateElement: TJSElement;
begin
  FElementCanvas := TJSHTMLCanvasElement(document.createElement('CANVAS'));
  if csAcceptsControls in ControlStyle then
  begin
    Result := document.createElement('SPAN');
    Result.appendChild(FElementCanvas);
    FElementCanvas['id'] := ElementID + '_Canvas';
    FElementCanvas['zindex'] := '-1';
  end
  else
    Result := FElementCanvas;
end;

destructor TCustomControl.Destroy;
begin
  if Assigned(FCanvas) then
    FCanvas.Free;
  inherited;
end;

function TCustomControl.GetCanvas: TCanvas;
begin
  if not Assigned(FCanvas) then
  begin
    CreateControl;
  end;
  Result := FCanvas;
end;

function TCustomControl.GetCanvasHeightOffset: Integer;
begin
  Result := 0;
end;

function TCustomControl.GetCanvasWidthOffset: Integer;
begin
  Result := 0;
end;

function TCustomControl.GetPixelRatio: Single;
var
  res: single;
begin
  asm
    var ctx = document.createElement("canvas").getContext("2d"),
        dpr = window.devicePixelRatio || 1,
        bsr = ctx.webkitBackingStorePixelRatio ||
              ctx.mozBackingStorePixelRatio ||
              ctx.msBackingStorePixelRatio ||
              ctx.oBackingStorePixelRatio ||
              ctx.backingStorePixelRatio || 1;
    res = dpr / bsr
  end;
  Result := res;
end;

procedure TCustomControl.Invalidate;
var
  px: Single;
begin
  inherited;
  if (Parent = nil) or (csDestroying in ComponentState) then
    Exit;

  px := GetPixelRatio;
  if (px <> FPixelRatio) then
    RecreateCanvas;

  FPixelRatio := GetPixelRatio;

  FPainting := true;

  if Assigned(FCanvas) then
    FCanvas.Clear;

  Paint;
  FPainting := false;
end;

procedure TCustomControl.Loaded;
begin
  inherited;
  Invalidate;
end;

function TCustomControl.MakeScreenShot: TBitmap;
begin
  Result := TBitmap.Create;
  Result.LoadFromCanvas(Canvas);
end;

procedure TCustomControl.Paint;
begin
end;

procedure TCustomControl.RecreateCanvas;
var
  px: Single;
  el: TJSHTMLCanvasElement;
  h, w: Integer;
begin
  if Assigned(FElementCanvas) then
  begin
    if not FPainting then
    begin
      el := TJSHTMLCanvasElement(document.getElementByID(GetID+'_Canvas'));

      // it is already found
      if Assigned(el) then
      begin
        FElementCanvas := el;
        FCanvas.Free;
        FCanvas := nil;
      end;

      px := GetPixelRatio;
      h := Height - GetCanvasHeightOffset;
      w := Width - GetCanvasWidthOffset;

      if ElementPosition = epRelative then
        FElementCanvas.style.setProperty('position', 'relative')
      else
        FElementCanvas.style.setProperty('position', 'absolute');

      FElementCanvas.style.setProperty('height', IntToStr(h) + 'px');
      FElementCanvas.style.setProperty('width', IntToStr(w) + 'px');
      FElementCanvas.height := Round(h * px);
      FElementCanvas.width := Round(w * px);

      FElementCanvas.getContextAs2DContext('2d').scale(px, px);
    end;

    if not Assigned(FCanvas) then
      FCanvas := TCanvas.Create(FElementCanvas);
  end;
end;

procedure TCustomControl.Resize;
begin
  inherited;
  Invalidate;
end;

procedure TCustomControl.SetBorderColor(const AValue: TColor);
begin
  if (FBorderColor <> AValue) then
  begin
    FBorderColor := AValue;
    UpdateElement;
  end;
end;

procedure TCustomControl.SetBorderStyle(const AValue: TBorderStyle);
begin
  if FBorderStyle <> AValue then
  begin
    FBorderStyle := AValue;
    UpdateElement;
  end;
end;

procedure TCustomControl.SetCaption(const AValue: string);
begin
  FCaption := AValue;
  ControlStyle := ControlStyle - [csSetCaption];
end;

procedure TCustomControl.SetName(const NewName: TComponentName);
var
  cs: TControlStyle;
begin
  inherited SetName(NewName);
  if (csSetCaption in ControlStyle) and (csDesigning in ComponentState) then
  begin
    cs := ControlStyle;
    SetCaption(NewName);
    ControlStyle := cs;
  end;
end;

procedure TCustomControl.UpdateElementVisual;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    if not IsLinked and (ElementClassName = '') then
    begin
      if BorderStyle = bsSingle then // default border style
      begin
        if CustomBorder then
        begin
          ElementHandle.style.setProperty('border-style', 'solid');
          ElementHandle.style.setProperty('border-width', '1px');
          ElementHandle.style.setProperty('border-color', ColorToHTML(BorderColor));
        end
        else
          ElementHandle.style.setProperty('border-style', '')
      end
      else
        ElementHandle.style.setProperty('border-style', 'none');
    end;
  end;
end;

{ TScrollingGraphicControl }

procedure TScrollingGraphicControl.UpdateElement;
begin
  inherited;
  if Assigned(ElementHandle) then
    ElementHandle.style.setProperty('overflow', 'auto');
end;


function TScrollingGraphicControl.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

{ TMargins }

procedure TMargins.Assign(Source: TPersistent);
begin
  if Source is TMargins then
  begin
    FLeft := (Source as TMargins).Left;
    FTop := (Source as TMargins).Top;
    FBottom := (Source as TMargins).Bottom;
    FRight := (Source as TMargins).Right;
  end
  else
    inherited;
end;

constructor TMargins.Create;
begin
  FLeft := 3;
  FTop := 3;
  FBottom := 3;
  FRight := 3;
end;

{ TControlManager }

constructor TControlManager.Create(AOwner: TComponent);
begin
  inherited;
  FInstanceCount := 0;
end;

function TControlManager.GetInstanceNumber: integer;
begin
  Inc(FInstanceCount);
  Result := FInstanceCount;
end;

procedure TControlManager.Reset;
begin
  FInstanceCount := 0;
end;

{ TCSSCodeFragment }

constructor TCSSCodeFragment.Create(Collection: TCollection);
begin
  inherited;
  FCSS := TStringList.Create;
end;

destructor TCSSCodeFragment.Destroy;
begin
  FCSS.Free;
  inherited;
end;

procedure TCSSCodeFragment.SetCSS(const Value: TStringList);
begin
  FCSS.Assign(Value);
end;

{ TCSSCodeFragments }

function TCSSCodeFragments.Add: TCSSCodeFragment;
begin
  Result := TCSSCodeFragment(inherited Add);
end;

constructor TCSSCodeFragments.Create(AOwner: TComponent);
begin
  inherited Create(AOwner, TCSSCodeFragment);
end;

function TCSSCodeFragments.GetItemEx(Index: integer): TCSSCodeFragment;
begin
  Result := TCSSCodeFragment(inherited Items[Index]);
end;

function TCSSCodeFragments.Insert(Index: integer): TCSSCodeFragment;
begin
  Result := TCSSCodeFragment(inherited Insert(Index));
end;

procedure TCSSCodeFragments.SetItemEx(Index: integer;
  const Value: TCSSCodeFragment);
begin
  inherited Items[Index] := Value;
end;

{ TCSSCodeManager }

constructor TCSSCodeManager.Create(AOwner: TComponent);
begin
  inherited;
  FCSSFragments := TCSSCodeFragments.Create(Self);
end;

destructor TCSSCodeManager.Destroy;
begin
  FCSSFragments.Free;
  inherited;
end;

function TCSSCodeManager.GetClassCSS(AClassname: string): string;
var
  CSSCodeFragment: TCSSCodeFragment;
begin
  Result := '';

  CSSCodeFragment := GetClassFragment(AClassName);

  if Assigned(CSSCodeFragment) then
    Result := CSSCodeFragment.CSS.Text;
end;

function TCSSCodeManager.GetClassFragment(AClassname: string): TCSSCodeFragment;
var
  i: integer;
begin
  Result := nil;
  for i := 0 to CSSFragments.Count - 1 do
  begin
    if CSSFragments.Items[i].ControlClassName = AClassname then
      Result := CSSFragments.Items[i];
  end;
end;

procedure TCSSCodeManager.SetCSSFragments(const Value: TCSSCodeFragments);
begin
  FCSSFragments.Assign(Value);
end;

{ TjQueryCustomContol }

function TjQueryCustomControl.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

procedure TjQueryCustomControl.CreateInitialize;
begin
  inherited;
  FIsInitialized := false;
end;

function TjQueryCustomControl.GetJQID: string;
begin
  Result := '#' + GetID;
end;

procedure TjQueryCustomControl.InitJQuery;
begin
  //
end;

procedure TjQueryCustomControl.InitJQueryOnce;
begin
  if IsUpdating then
    Exit;

  if FIsInitialized then
    Exit;

  FIsInitialized := true;
  InitJQuery;
end;

procedure TjQueryCustomControl.Loaded;
begin
  inherited;
  InitJQueryOnce;
end;

procedure TjQueryCustomControl.SetParent(AValue: TControl);
begin
  inherited;
  InitJQueryOnce;
end;

{ TPadding }

procedure TPadding.Assign(Source: TPersistent);
begin
  if (Source is TPadding) then
  begin
    FLeft := (Source as TPadding).Left;
    FRight := (Source as TPadding).Right;
    FTop := (Source as TPadding).Top;
    FBottom := (Source as TPadding).Bottom;
  end;
end;

constructor TPadding.Create;
begin
  inherited;
  FLeft := 0;
  FTop := 0;
  FRight := 0;
  FBottom := 0;
end;

procedure TPadding.DoChange;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TPadding.SetBottom(const Value: integer);
begin
  if (FBottom <> Value) then
  begin
    FBottom := Value;
    DoChange;
  end;
end;

procedure TPadding.SetLeft(const Value: integer);
begin
  if (FLeft <> Value) then
  begin
    FLeft := Value;
    DoChange;
  end;
end;

procedure TPadding.SetRight(const Value: integer);
begin
  if (FRight <> Value) then
  begin
    FRight := Value;
    DoChange;
  end;
end;

procedure TPadding.SetTop(const Value: integer);
begin
  if (FTop <> Value) then
  begin
    FTop := Value;
    DoChange;
  end;
end;

initialization
  ControlManager := TControlManager.Create(nil);

end.
