{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2019                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.ExtCtrls;

{$modeswitch externalclass}

interface

uses
  Classes, SysUtils, Types, WEBLib.Controls, WEBLib.StdCtrls, WEBLib.Graphics,
  Web, WEBLib.WebTools;

type
  TCustomLinkLabel = class(TCustomLabel)
  private
    FDisplText: string;
    FOnLinkClick: TLinkClickEvent;
    FWidthStyle: TSizeStyle;
    FHeightPercent: TPercentSize;
    FHeightStyle: TSizeStyle;
    FWidthPercent: TPercentSize;
    procedure SetHeightPercent(const Value: TPercentSize); reintroduce;
    procedure SetHeightStyle(const Value: TSizeStyle); reintroduce;
    procedure SetWidthPercent(const Value: TPercentSize); reintroduce;
    procedure SetWidthStyle(const Value: TSizeStyle); reintroduce;
  protected
    function GetOuterWidth: integer; override;
    function GetOuterHeight: integer; override;
    function GetDisplayText: string; override;

    procedure SetCaption(const AValue: string); override;
    procedure BindEvents; override;
    function DoLinkClick(Event: TJSMouseEvent): boolean;
  public
    constructor Create(AOwner: TComponent); overload; override;
  published
    property Align;
    property Alignment;
    property AlignWithMargins;
    property Anchors;
    property AutoSize;
    property Caption;
    property Color;
    property EllipsisPosition;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property Hint;
    property Layout;
    property Left;
    property Margins;
    property ShowHint;
    property Top;
    property Visible;
    property Width;
    property WordWrap;

    property HeightStyle: TSizeStyle read FHeightStyle write SetHeightStyle default ssAbsolute;
    property WidthStyle: TSizeStyle read FWidthStyle write SetWidthStyle default ssAbsolute;
    property HeightPercent: TPercentSize read FHeightPercent write SetHeightPercent default 100;
    property WidthPercent: TPercentSize read FWidthPercent write SetWidthPercent default 100;

    property OnClick;
    property OnDblClick;
    property OnLinkClick: TLinkClickEvent read FOnLinkClick write FOnLinkClick;
  end;

  TLinkLabel = class(TCustomLinkLabel)
  published
    property Caption;
  end;

  TWebLinkLabel = class(TLinkLabel);

  TTrackBarOrientation = (trHorizontal, trVertical);

  TTrackBar = class(TCustomInput)
  private
    FMax: integer;
    FMin: integer;
    FPosition: integer;
    FOnChange: TNotifyEvent;
    FOrientation: TTrackBarOrientation;
    procedure SetOrientation(const Value: TTrackBarOrientation);
  protected
    function GetElementInputHandle: TJSHTMLInputElement;
    function GetInputType: string; override;
    function DoHandleChange(Event: TEventListenerEvent): Boolean;
    procedure BindEvents; override;
    procedure SetMax(AValue: integer);
    procedure SetMin(AValue: integer);
    procedure SetPosition(AValue: integer);
    function GetPosition: integer;
    procedure DoUpdate;
    procedure Change; virtual;
    procedure CreateControl; override;
    procedure UpdateElementVisual; override;
  public
    procedure CreateInitialize; override;
    property ElementInputHandle: TJSHTMLInputElement read GetElementInputHandle;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Height;
    property HeightPercent;
    property Left;
    property Margins;
    property Max: integer read FMax write SetMax;
    property Min: integer read FMin write SetMin;
    property Orientation: TTrackBarOrientation read FOrientation write SetOrientation;
    property Position: integer read GetPosition write SetPosition;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property OnClick;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TWebTrackBar = class(TTrackBar);

  TTimer = class(TCustomControl)
  private
    FInterval: integer;
    FTimerID: integer;
    FOnTimer: TNotifyEvent;
  protected
    function CreateElement: TJSElement; override;
    procedure SetEnabled(Value: Boolean); override;
    procedure SetInterval(AValue: integer);
    procedure SetParent(AValue: TControl); override;
    procedure DoTimer;
    procedure DoUpdateTimer;
    procedure DoClearTimer;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property Enabled;
    property Interval: integer read FInterval write SetInterval;
    property OnTimer: TNotifyEvent read FOnTimer write FOnTimer;
  end;

  TWebTimer = class(TTimer);

  TGeolocationEvent = procedure(Sender: TObject; Lat,Lon,Alt:double) of object;

  TGeoLocation = class(TCustomControl)
  private
    FOnGeolocation: TGeolocationEvent;
  protected
    procedure DoHandleGeolocation(APosition: TJSPosition);
  public
    function HasGeolocation: boolean;
    procedure GetGeolocation;
  published
    property OnGeolocation: TGeolocationEvent read FOnGeolocation write FOnGeolocation;
  end;

  TWebGeoLocation = class(TGeoLocation);

  TPaintBox = class(TGraphicControl)
  private
    FOnPaint: TNotifyEvent;
  protected
    procedure Paint; override;
    procedure UpdateElementVisual; override;
  public
  published
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property HeightPercent;
    property Margins;
    property WidthPercent;
    property OnPaint: TNotifyEvent read FOnPaint write FOnPaint;
    property OnTouchStart;
    property OnTouchMove;
    property OnTouchEnd;
  end;

  TWebPaintBox = class(TPaintBox);

  TURLPicture = class(TPersistent)
  private
    FOnChange: TNotifyEvent;
    FFilename: string;
  protected
  public
    procedure LoadFromFile(AFileName: string);
    property Filename: string read FFilename;
  published
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TCustomImageControl = class(TCustomControl)
  private
    FURL: string;
    FPicture: TURLPicture;
    FAutoSize: boolean;
    procedure SetURL(AURL: string);
    function GetBase64Img: string;
  protected
    function HandleDoDrag(aEvent: TJSDragEvent): boolean; virtual;
    function HandleDoDragEnd(aEvent: TJSDragEvent): boolean; virtual;
    function HandleDoDragExit(aEvent: TJSDragEvent): boolean; virtual;
    function HandleDoDragOver(aEvent: TJSDragEvent): boolean; virtual;
    function HandleDoDragStart(aEvent: TJSDragEvent): boolean; virtual;
    function HandleDoDrop(aEvent: TJSDragEvent): boolean; virtual;

    procedure SetPicture(const Value: TURLPicture);
    procedure PictureChanged(Sender: TObject);
    procedure BindEvents; override;
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    property Picture: TURLPicture read FPicture write SetPicture;
    property URL: string read FURL write SetURL;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    property Base64Image: string read GetBase64Img;
  published
    property AutoSize: boolean read FAutoSize write FAutoSize;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property HeightPercent;
    property ShowHint;
    property WidthPercent;
  end;

  TImageControl = class(TCustomImageControl)
  published
    property Picture;
    property URL;
  end;

  TWebImageControl = class(TImageControl);

  TCustomPanel = class(TCustomControl)
  private
    FAutoSize: Boolean;
    FPadding: TPadding;
    FShowCaption: boolean;
    FLabel: TJSHTMLElement;
    procedure SetShowCaption(const Value: boolean);
    procedure SetPadding(const Value: TPadding);
  protected
    function CreateElement: TJSElement; override;
    procedure SetCaption(const AValue: string); override;
    procedure SetBorderStyle(const AValue: TBorderStyle); override;
    procedure SetAutoSize(AValue: boolean);
    procedure UpdateElementVisual; override;
    property AutoSize: boolean read FAutoSize write SetAutoSize;
    property Padding: TPadding read FPadding write SetPadding;
    property ShowCaption: boolean read FShowCaption write SetShowCaption;
    function GetOuterWidth: integer; override;
    function GetOuterHeight: integer; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure EndUpdate; override;
  end;

  TPanel = class(TCustomPanel)
  published
    property AutoSize;
    property BorderColor;
    property BorderStyle;
    property Caption;
    property Color;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Font;
    property HeightPercent;
    property Padding;
    property ShowCaption;
    property WidthPercent;
  end;

  TDivPanel = class(TPanel)
  protected
    function CreateElement: TJSElement; override;
  end;

  TWebPanel = class(TPanel);

  TCustomGroupBox = class(TCustomControl)
  private
    FCaption: string;
    FCaptionSpan: TJSHTMLElement;
    FControlSpan: TJSHTMLElement;
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElementData; override;
    procedure UpdateElementVisual; override;
    property Caption: string read FCaption write FCaption;
  public
    procedure CreateInitialize; override;
  end;

  TGroupBox = class(TCustomGroupBox)
  published
    property Caption;
    property Color;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property HeightPercent;
    property WidthPercent;
  end;

  TWebGroupBox = class(TGroupBox);

  TScrollBox = class(TCustomPanel)
  private
    FAutoScroll: boolean;
    FScrollBars: TScrollStyle;
    function GetScrollLeft: integer;
    function GetScrollTop: integer;
    procedure SetScrollLeft(const Value: integer);
    procedure SetScrollTop(const Value: integer);
    procedure SetScrollBars(const Value: TScrollStyle);
  protected
    procedure UpdateElement; override;
    procedure SetAutoScroll(AValue: boolean);
    function GetClientRect: TRect; override;
  public
    procedure CreateInitialize; override;
    property ScrollLeft: integer read GetScrollLeft write SetScrollLeft;
    property ScrollTop: integer read GetScrollTop write SetScrollTop;
    procedure EndUpdate; override;
  published
    property AutoScroll: boolean read FAutoScroll write SetAutoScroll;
    property BorderStyle;
    property Color;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property HeightPercent;
    property ScrollBars: TScrollStyle read FScrollBars write SetScrollBars;
    property WidthPercent;
    property OnResize;
  end;

  TWebScrollBox = class(TScrollBox);

  TCustomDragControl = class(TCustomControl)
  private
    FText: string;
    procedure SetText(const AValue: string);
  protected
    function HandleDoDrag(aEvent: TJSDragEvent): boolean; virtual;
    function HandleDoDragEnd(aEvent: TJSDragEvent): boolean; virtual;
    function HandleDoDragExit(aEvent: TJSDragEvent): boolean; virtual;
    function HandleDoDragOver(aEvent: TJSDragEvent): boolean; virtual;
    function HandleDoDragStart(aEvent: TJSDragEvent): boolean; virtual;
    function HandleDoDrop(aEvent: TJSDragEvent): boolean; virtual;

    procedure BindEvents; override;
    procedure Paint; override;
  published
    property Text: string read FText write SetText;
  end;

  TSplitter = class(TCustomControl)
  private
    FLayer: TJSElement;
    FFirstMove: boolean;
    FSplitControl: TControl;
    FSizing: boolean;
    FSizingX,FSizingY: double;
    FOriginalWidth,FOriginalHeight: integer;
    FGripColor: TColor;
    FOnMoved: TNotifyEvent;
    FOnMove: TNotifyEvent;
    procedure SetGripColor(const Value: TColor);
  protected
    function HandleDocDoMouseMove(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDocDoMouseUp(Event: TJSMouseEvent): Boolean; virtual;
    procedure DoMouseEnter; override;

    procedure MouseUp(Button: TMouseButton; Shift: TShiftState; X,Y: Integer); override;
    procedure MouseDown(Button: TMouseButton; Shift: TShiftState; X,Y: Integer); override;
    procedure Paint; override;
  public
    procedure CreateInitialize; override;
  published
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property GripColor: TColor read FGripColor write SetGripColor;
    property OnClick;
    property OnMove: TNotifyEvent read FOnMove write FOnMove;
    property OnMoved: TNotifyEvent read FOnMoved write FOnMoved;
  end;

  TWebSplitter = class(TSplitter);

  TVerticalAlignment = (vaTop, vaCenter, vaBottom);
  TGridPanelExpandStyle = (esAddRows, esAddColumns);

  TGridPanelRow = class(TCollectionItem)
  private
    FSizeStyle: TSizeStyle;
    FValue: integer;
    FMarginBottom: integer;
    FMarginTop: integer;
    FAlignment: TVerticalAlignment;
    FElementClassName: string;
  protected
    function HeightAttribute: string;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
  published
    property Alignment: TVerticalAlignment read FAlignment write FAlignment default vaTop;
    property ElementClassName: string read FElementClassName write FElementClassName;
    property MarginTop: integer read FMarginTop write FMarginTop;
    property MarginBottom: integer read FMarginBottom write FMarginBottom;
    property SizeStyle: TSizeStyle read FSizeStyle write FSizeStyle;
    property Value: integer read FValue write FValue;
  end;

  TGridPanelRows = class(TOwnedCollection)
  private
    function GetItem(Index: integer): TGridPanelRow; reintroduce;
    procedure SetItem(Index: integer; const Value: TGridPanelRow); reintroduce;
  public
    constructor Create(AOwner: TComponent); reintroduce;
    function Add: TGridPanelRow; reintroduce;
    function Insert(Index: integer): TGridPanelRow; reintroduce;
    property Items[Index: integer]: TGridPanelRow read GetItem write SetItem;
  end;

  TGridPanelColumn = class(TCollectionItem)
  private
    FSizeStyle: TSizeStyle;
    FValue: integer;
    FMarginLeft: integer;
    FAlignment: TAlignment;
    FMarginRight: integer;
    FElementClassName: string;
  protected
    function WidthAttribute: string;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
  published
    property Alignment: TAlignment read FAlignment write FAlignment default taLeftJustify;
    property ElementClassName: string read FElementClassName write FElementClassName;
    property MarginLeft: integer read FMarginLeft write FMarginLeft;
    property MarginRight: integer read FMarginRight write FMarginRight;
    property SizeStyle: TSizeStyle read FSizeStyle write FSizeStyle;
    property Value: integer read FValue write FValue;
  end;

  TGridPanelColumns = class(TOwnedCollection)
  private
    function GetItem(Index: integer): TGridPanelColumn; reintroduce;
    procedure SetItem(Index: integer; const Value: TGridPanelColumn); reintroduce;
  public
    constructor Create(AOwner: TComponent); reintroduce;
    function Add: TGridPanelColumn; reintroduce;
    function Insert(Index: integer): TGridPanelColumn; reintroduce;
    property Items[Index: integer]: TGridPanelColumn read GetItem write SetItem;
  end;

  TControlCollectionItem = class(TCollectionItem)
  private
    FControl: TWinControl;
    FRow: integer;
    FColumn: integer;
  published
    property Column: integer read FColumn write FColumn;
    property Row: integer read FRow write FRow;
    property Control: TWinControl read FControl write FControl;
  end;

  TControlCollection = class(TOwnedCollection)
  private
    function GetItem(Index: Integer): TControlCollectionItem; reintroduce;
    procedure SetItem(Index: Integer; const Value: TControlCollectionItem); reintroduce;
  public
    constructor Create(AOwner: TComponent); reintroduce;
    function Add: TControlCollectionItem; reintroduce;
    function Insert(Index: Integer): TControlCollectionItem; reintroduce;
    property Items[Index: Integer]: TControlCollectionItem read GetItem write SetItem;
  end;

  TGridPanel = class(TCustomControl)
  private
    FUpdateTable: boolean;
    FRowCollection: TGridPanelRows;
    FColumnCollection: TGridPanelColumns;
    FControlCollection: TControlCollection;
    FGridLineWidth: integer;
    FGridLineColor: TColor;
    FExpandStyle: TGridPanelExpandStyle;
    FTbl: TJSElement;
    FTblBody: TJSElement;
    procedure SetColumnCollection(const Value: TGridPanelColumns);
    procedure SetRowCollection(const Value: TGridPanelRows);
    procedure SetControlCollection(const Value: TControlCollection);
  protected
    function CreateTable: TJSElement; virtual;
    function CreateRow(AIndex: integer): TJSElement; virtual;
    function CreateElement: TJSElement; override;
    procedure UpdateTable;
    procedure UpdateElement; override;
    procedure UpdateElementVisual; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure AddControl(AControl: TWinControl);
    procedure RemoveControl(AControl: TWinControl);
    procedure EndUpdate; override;
  published
    property ControlCollection: TControlCollection read FControlCollection write SetControlCollection;
    property ColumnCollection: TGridPanelColumns read FColumnCollection write SetColumnCollection;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property ExpandStyle: TGridPanelExpandStyle read FExpandStyle write FExpandStyle;
    property GridLineWidth: integer read FGridLineWidth write FGridLineWidth;
    property GridLineColor: TColor read FGridLineColor write FGridLineColor;
    property HeightPercent;
    property RowCollection: TGridPanelRows read FRowCollection write SetRowCollection;
    property WidthPercent;
  end;

  TWebGridPanel = class(TGridPanel);

  TMultiMediaType = (mtVideo, mtAudio);

  TMultiMediaVolume = 0..100;

  TMultimediaPlayer = class(TCustomControl)
  private
    FURL: string;
    FMultimediaType: TMultimediaType;
    FAutoPlay: boolean;
    FControls: boolean;
    FMuted: boolean;
    FLoop: boolean;
    FVolume: TMultiMediaVolume;
    FPlaybackRate: double;
    procedure SetAutoPlay(const Value: boolean);
    procedure SetControls(const Value: boolean);
    procedure SetMuted(const Value: boolean);
    procedure SetLoop(const Value: boolean);
    procedure SetURL(const Value: string);
    procedure SetVolume(const Value: TMultiMediaVolume);
    function GetCurrentTime: double;
    procedure SetCurrentTime(const Value: double);
    function GetDuration: double;
    function GetEnded: boolean;
    function GetPaused: boolean;
    procedure SetPlaybackRate(const Value: double);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
  public
    procedure CreateInitialize; override;
    procedure Play;
    procedure Pause;
    procedure ReLoad;
    property CurrentTime: double read GetCurrentTime write SetCurrentTime;
    property Duration: double read GetDuration;
    property Ended: boolean read GetEnded;
    property Paused: boolean read GetPaused;
  published
    property AutoPlay: boolean read FAutoPlay write SetAutoPlay;
    property Controls: boolean read FControls write SetControls;
    property Loop: boolean read FLoop write SetLoop;
    property MultimediaType: TMultimediaType read FMultiMediaType write FMultiMediaType;
    property Muted: boolean read FMuted write SetMuted;
    property PlaybackRate: double read FPlaybackRate write SetPlaybackRate;
    property URL: string read FURL write SetURL;
    property Volume: TMultiMediaVolume read FVolume write SetVolume;
  end;

  TWebMultiMediaPlayer = class(TMultiMediaPlayer);

  THTMLContainer = class(TCustomControl)
  private
    FHTML: TStringList;
    FScrollStyle: TScrollStyle;
    procedure SetHTML(const Value: TStringList);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure HTMLChanged(Sender: TObject); virtual;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property HeightPercent;
    property HTML: TStringList read FHTML write SetHTML;
    property ScrollStyle: TScrollStyle read FScrollStyle write FScrollStyle;
    property WidthPercent;
  end;

  TWebHTMLContainer = class(THTMLContainer);

  THTMLForm = class(TCustomControl)
  private
    FOnSubmit: TNotifyEvent;
  protected
    procedure UpdateElement; override;
    function CreateElement: TJSElement; override;
    function IsStructuralElement: boolean; override;
  protected
    function DoHandleSubmit(Event: TJSEvent): boolean; virtual;
    procedure BindEvents; override;
  public
    function CheckValidity: boolean;
  published
    property OnSubmit: TNotifyEvent read FOnSubmit write FOnSubmit;
  end;

  TWebHTMLForm = class(THTMLForm);

  TBadge = class(TCustomControl)
  private
    FColor: TColor;
    FTextColor: TColor;
    FText: string;
    procedure SetBkColor(const Value: TColor);
    procedure SetTextColor(const Value: TColor);
    procedure SetText(const Value: string);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure SetElementClassName(AValue: string); override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property Color: TColor read FColor write SetBkColor;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property Text: string read FText write SetText;
    property TextColor: TColor read FTextColor write SetTextColor;
  end;


  TWebBadge = class(TBadge);

  TAccordionSection = class(TCollectionItem)
  private
    FCaption: string;
    FContent: string;
    FTag: integer;
    FExpanded: boolean;
    procedure SetExpanded(const Value: boolean);
  public
    procedure Assign(Source: TPersistent); override;
    property Expanded: boolean read FExpanded write SetExpanded;
    function CaptionElement: TJSHTMLElement;
    function PanelElement: TJSHTMLElement;
  published
    property Caption: string read FCaption write FCaption;
    property Content: string read FContent write FContent;
    property Tag: integer read FTag write FTag;
  end;

  TAccordionSections = class(TOwnedCollection)
  private
    function GetItem(Index: integer): TAccordionSection; reintroduce;
    procedure SetItem(Index: integer; const Value: TAccordionSection);
  public
    constructor Create(AOwner: TComponent); overload;
    function Add: TAccordionSection; reintroduce;
    function Insert(Index: integer): TAccordionSection; reintroduce;
    property Items[Index: integer]: TAccordionSection read GetItem write SetItem; default;
  end;

  TAccordionSectionEvent =  procedure(Sender: TObject; ASection: TAccordionSection) of object;
  TAccordionSectionAllowEvent =  procedure(Sender: TObject; ASection: TAccordionSection; var Allow: boolean) of object;

  TAccordionSectionRenderEvent =  procedure(Sender: TObject; ASection: TAccordionSection; ACaption, APanel: TJSHTMLElement) of object;

  TAccordion = class(TCustomControl)
  private
    FStyleRendered: boolean;
    FSections: TAccordionSections;
    FOnCollapsing: TAccordionSectionAllowEvent;
    FOnExpanding: TAccordionSectionAllowEvent;
    FOnCollapsed: TAccordionSectionEvent;
    FOnExpanded: TAccordionSectionEvent;
    FOnRenderSection: TAccordionSectionRenderEvent;
    procedure SetSections(const Value: TAccordionSections);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure RenderAccordion;
    procedure RenderStyle;
    function DoAccordionClick(Event: TJSEvent): Boolean; virtual;
    procedure UpdateElementVisual; override;
    procedure Expand(ASection: TAccordionSection);
    procedure Collapse(ASection: TAccordionSection);
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure EndUpdate; override;
    property Sections: TAccordionSections read FSections write SetSections;
    property OnCollapsed: TAccordionSectionEvent read FOnCollapsed write FOnCollapsed;
    property OnCollapsing: TAccordionSectionAllowEvent read FOnCollapsing write FOnCollapsing;
    property OnExpanded: TAccordionSectionEvent read FOnExpanded write FOnExpanded;
    property OnExpanding: TAccordionSectionAllowEvent read FOnExpanding write FOnExpanding;
    property OnRenderSection: TAccordionSectionRenderEvent read FOnRenderSection write FOnRenderSection;
  end;

  TWebAccordion = class(TAccordion);


  TGridStyle = (gTemplateColumns, gTemplateRows);

  TResponsiveLayoutItem = class(TCollectionItem)
  private
    FWidth: integer;
    FStyleType: TGridStyle;
    FStyle: string;
    FColumnGap: string;
    FRowGap: string;
    FTag: integer;
    FDescription: string;
    FMargins: TMargins;
    procedure SetMargins(const Value: TMargins);
  public
    constructor Create(AOwner: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property ColumnGap: string read FColumnGap write FColumnGap;
    property Description: string read FDescription write FDescription;
    property Margins: TMargins read FMargins write SetMargins;
    property RowGap: string read FRowGap write FRowGap;
    property StyleType: TGridStyle read FStyleType write FStyleType;
    property Style: string read FStyle write FStyle;
    property Tag: integer read FTag write FTag;
    property Width: integer read FWidth write FWidth;
  end;

  TResponsiveLayout = class(TCollection)
  private
    function GetItem(Index: integer): TResponsiveLayoutItem; reintroduce;
    procedure SetItem(Index: integer; const Value: TResponsiveLayoutItem);
  public
    function GetLayoutForWidth(w: integer): TResponsiveLayoutItem;
    constructor Create(AOwner: TComponent); reintroduce;
    function Add(AWidth: integer; AStyle: string): TResponsiveLayoutItem; overload; reintroduce;
    function Add: TResponsiveLayoutItem; overload; reintroduce;
    function Insert(Index: integer): TResponsiveLayoutItem; reintroduce;
    property Items[Index: integer]: TResponsiveLayoutItem read GetItem write SetItem;
  end;

  //https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout

  TResponsiveLayoutChangeEvent = procedure(Sender: TObject; ALayout: TResponsiveLayoutItem) of object;

  TResponsiveGridPanel = class(TCustomControl)
  private
    FLayout: TResponsiveLayout;
    FActiveLayoutItem: TResponsiveLayoutItem;
    FOldWidth: integer;
    FControlCollection: TControlCollection;
    FOnLayoutChange: TResponsiveLayoutChangeEvent;
    procedure SetControlCollection(const Value: TControlCollection);
    function HandleResize(Event: TEventListenerEvent): boolean; virtual;
    procedure SetResponsiveStyle;
  protected
    procedure BindEvents; override;
    procedure UnbindEvents; override;
    procedure UpdateControls;
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
  public
    constructor Create(AOwner: TComponent); overload; override;
    destructor Destroy; override;
    procedure CreateInitialize; override;
    procedure Resize; override;
    procedure AddControl(AControl: TWinControl);
    procedure RemoveControl(AControl: TWinControl);
    property ControlCollection: TControlCollection read FControlCollection write SetControlCollection;
    procedure EndUpdate; override;
    property Layout: TResponsiveLayout read FLayout write FLayout;
    property OnLayoutChange: TResponsiveLayoutChangeEvent read FOnLayoutChange write FOnLayoutChange;
    property Visible;
  end;

  TWebResponsiveGridPanel = class(TResponsiveGridPanel);



implementation

var
  geolocation : TJSGeoLocation; external name 'navigator.geolocation';


{ TCustomLinkLabel }

procedure TCustomLinkLabel.SetCaption(const AValue: string);
var
  iopen, iopena, iopeni, iopenend, iopenendt, iclose: integer;
  scaption, shref, stext, sanchor, sid, stype: string;
  anchor, span, contentelement: TJSElement;
begin
  inherited SetCaption(AValue);

  if not Assigned(Container) then
    Exit;

  contentelement := ContentHandle;

  if (ElementID <> '') then
    contentelement := document.getElementById(ElementID);

  contentelement.innerHTML := '';

  scaption := Caption;

  sanchor := '<a href="';
  sid := '<a id="';

  if Assigned(FOnLinkClick) and ((pos(sanchor, scaption) > 0) or (pos(sid, scaption) > 0)) then
  begin
    while (Pos(sanchor, scaption) > 0) or (Pos(sid, scaption) > 0) do
    begin
      //find first link in string
      iopena := pos(sanchor, scaption);
      iopeni := pos(sid, scaption);

      if ((iopena < iopeni) or (iopeni <= 0)) and (iopena > 0) then
      begin
        stype := '#url#';
        iopen := iopena + length(sanchor);
      end
      else
      begin
        stype := '#id#';
        iopen := iopeni + length(sid);
      end;

      iopenend := pos('">', scaption);
      iopenendt := pos('" ', scaption);
      if (iopenendt > 0) and (iopenendt < iopenend) then
        iopenend := iopenendt;

      iclose := pos('</a>', scaption);
      shref := copy(scaption, iopen, iopenend - iopen);

      iopenend := pos('">', scaption);
      iclose := pos('</a>', scaption);
      stext := copy(scaption, iopenend + 2, iclose - (iopenend + 2));

      //add prefix text
      span := document.createElement('SPAN');
      span.innerHTML := copy(scaption, 0, iopen);
      contentelement.appendChild(span);

      //add link
      anchor := document.createElement('A');
      anchor['href'] := '#';
      anchor['id'] := stype + shref;
      anchor.innerHTML := stext;
      TJSHTMLElement(anchor).onclick := DoLinkClick;
      contentelement.appendChild(anchor);

      //remove prefix + link
      Delete(scaption, 1, Pos('</a>', scaption) + 3);
    end;

    //suffix text
    span := document.createElement('SPAN');
    span.innerHTML := scaption;
    contentelement.appendChild(span);
    FDisplText := scaption;
  end
  else
  begin
    FDisplText := StringReplace(AValue, '> <', '>&nbsp;<', [rfReplaceAll]);
    contentelement.innerHTML := FDisplText;
  end;
end;

procedure TCustomLinkLabel.SetHeightPercent(const Value: TPercentSize);
begin
  FHeightPercent := Value;
end;

procedure TCustomLinkLabel.SetHeightStyle(const Value: TSizeStyle);
begin
  FHeightStyle := Value;
end;

procedure TCustomLinkLabel.SetWidthPercent(const Value: TPercentSize);
begin
  FWidthPercent := Value;
end;

procedure TCustomLinkLabel.SetWidthStyle(const Value: TSizeStyle);
begin
  FWidthStyle := Value;
end;

procedure TCustomLinkLabel.BindEvents;
begin
  inherited;
end;

constructor TCustomLinkLabel.Create(AOwner: TComponent);
begin
  inherited;
  FWidthStyle := ssAbsolute;
  FWidthPercent := 100;
  FHeightStyle := ssAbsolute;
  FHeightPercent := 100;
  Transparent := false;
end;

function TCustomLinkLabel.DoLinkClick(Event: TJSMouseEvent): boolean;
var
 slink, stype, svalue: string;
 ltype: TSysLinkType;
begin
  svalue := event.target['id'];
  stype := '#url#';
  ltype := sltURL;

  if not (pos(stype, svalue) > 0) then
  begin
    stype := '#id#';
    ltype := sltID;
  end;

  slink := StringReplace(svalue, stype, '', []);

  if Assigned(FOnLinkClick) then
    FOnLinkClick(Self, slink, ltype);

  Result := true;
end;


function TCustomLinkLabel.GetDisplayText: string;
begin
  Result := FDisplText;
end;

function TCustomLinkLabel.GetOuterHeight: integer;
begin
  Result := Height;
  if AutoSize then
    Result := Result + 4;
end;

function TCustomLinkLabel.GetOuterWidth: integer;
begin
  Result := Width;
  if AutoSize then
    Result := Result + 4;
end;

{ TTrackBar }

function TTrackBar.GetElementInputHandle: TJSHTMLInputElement;
begin
  Result := TJSHTMLInputElement(Container);
end;

function TTrackBar.GetInputType: string;
begin
  Result := 'range';
end;

procedure TTrackBar.Change;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

{$HINTS OFF}
function TTrackBar.DoHandleChange(Event: TEventListenerEvent): Boolean;
var
  el: TJSHTMLElement;
begin
  el := ElementHandle;
  asm
    this.FPosition = el.value;
  end;

  Change;
  Result := True;
end;
{$HINTS ON}

procedure TTrackBar.BindEvents;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    ElementHandle.oninput := DoHandleChange;
  end;
end;

procedure TTrackBar.CreateControl;
begin
  inherited;
  DoUpdate;
end;

procedure TTrackBar.CreateInitialize;
begin
  inherited;
  FMax := 100;
  FMin := 0;
  FPosition := 0;
end;

procedure TTrackBar.SetMax(AValue: integer);
begin
  if (FMax <> AValue) then
  begin
    FMax := AValue;
    DoUpdate;
  end;
end;

procedure TTrackBar.SetMin(AValue: integer);
begin
  if (FMin <> AValue) then
  begin
    FMin := AValue;
    DoUpdate;
  end;
end;

procedure TTrackBar.SetOrientation(const Value: TTrackBarOrientation);
var
  ow, oh: integer;
begin
  if (FOrientation <> Value) then
  begin
    ow := Width;
    oh := Height;
    FOrientation := Value;
    if not (csLoading in ComponentState) then
    begin
      Width := oh;
      Height := ow;
    end;
    UpdateElement;
  end;
end;

{$HINTS OFF}
procedure TTrackBar.DoUpdate;
var
  el: TJSHTMLElement;
begin
  if not Assigned(Container) then
    Exit;

  Container['max'] := IntToStr(FMax);
  Container['min'] := IntToStr(FMin);

  el := ElementHandle;

  asm
    el.value = this.FPosition;
    el.setAttribute('value',this.FPosition);
  end;
end;
{$HINTS ON}

procedure TTrackBar.SetPosition(AValue: integer);
begin
  if (FPosition <> AValue) then
  begin
    FPosition := AValue;
    DoUpdate;
  end;
end;

procedure TTrackBar.UpdateElementVisual;
var
  el: TJSHTMLElement;
begin
  inherited;

  el := ElementHandle;

  if Orientation = trHorizontal then
  begin
    el.removeAttribute('orient');
    el.style.removeProperty('writing-mode');
    el.style.removeProperty('-webkit-appearance');
    el.style.removeProperty('height');
    if WidthStyle = ssAbsolute then
      el.style.setProperty('width', IntToStr(Width));
  end
  else
  begin
    el.setAttribute('orient','vertical');
    el.style.setProperty('writing-mode','bt-lt');
    el.style.setProperty('-webkit-appearance','slider-vertical');
    el.style.removeProperty('width');
    if HeightStyle = ssAbsolute then
      el.style.setProperty('height', IntToStr(Height));
  end;

end;

function TTrackBar.GetPosition: integer;
var
  s: string;
begin
  Result := FPosition;

  if not Assigned(Container) then
    Exit;

  s := TJSHTMLInputElement(Container).value;
  if (s <> '') then
    Result := StrToInt(s);
end;

{ TTimer }

procedure TTimer.CreateInitialize;
begin
  inherited;
  FInterval := 1000;
  FTimerID := -1;
  Enabled := True;
end;

function TTimer.CreateElement: TJSElement;
begin
  Result := nil;
  DoUpdateTimer;
end;

procedure TTimer.SetEnabled(Value: boolean);
begin
  inherited SetEnabled(Value);
  DoUpdateTimer;
end;

procedure TTimer.SetInterval(AValue: integer);
begin
  FInterval := AValue;
  DoUpdateTimer;
end;

procedure TTimer.SetParent(AValue: TControl);
begin
  //
  // Timer has no parent
end;

procedure TTimer.DoTimer;
begin
  if Assigned(FOnTimer) then
    FOnTimer(Self);
end;

destructor TTimer.Destroy;
begin
  DoClearTimer;
  inherited;
end;

procedure TTimer.DoClearTimer;
begin
  if FTimerID <> -1 then
  begin
    window.clearInterval(FTimerID);
    FTimerID := -1;
  end;
end;

procedure TTimer.DoUpdateTimer;
begin
  DoClearTimer;

  if Enabled then
    FTimerID := window.setInterval(@DoTimer, FInterval);
end;

{ TPaintBox }

procedure TPaintBox.UpdateElementVisual;
begin
  inherited;

  if (csDesigning in ComponentState) then
  begin
    ElementHandle.style.setProperty('border', '1px dotted gray');
  end;
end;

procedure TPaintBox.Paint;
begin
  inherited;
  if Assigned(OnPaint) then
    OnPaint(Self);
end;

{ TScrollBox }

procedure TScrollBox.CreateInitialize;
begin
  inherited;
  FAutoScroll := true;
  FScrollBars := ssBoth;
end;

procedure TScrollBox.UpdateElement;
begin
  inherited;
  if Assigned(ElementHandle) and not IsUpdating then
  begin
    case ScrollBars of
    ssNone:
      begin
        ElementHandle.style.setProperty('overflow', 'hidden');
      end;
    ssVertical:
      begin
        ElementHandle.style.removeProperty('overflow');
        ElementHandle.style.setProperty('overflow-x', 'hidden');
        ElementHandle.style.setProperty('overflow-y', 'auto');
      end;
    ssHorizontal:
      begin
        ElementHandle.style.removeProperty('overflow');
        ElementHandle.style.setProperty('overflow-x', 'auto');
        ElementHandle.style.setProperty('overflow-y', 'hidden');
      end;
    ssBoth:
      begin
        ElementHandle.style.setProperty('overflow', 'auto');
      end;
    end;

    if Visible then
      ElementHandle.style.setProperty('display', 'inline-block');
  end;
end;

procedure TScrollBox.EndUpdate;
begin
  inherited;
  AlignControl(Self);
  // re-align in case align had effect on scrollbar appearance
  AlignControl(Self);
end;

function TScrollBox.GetClientRect: TRect;
var
  dw, dh: integer;
begin
  dw := 0;
  dh := 0;

  if ElementHandle.scrollHeight > ElementHandle.ClientHeight then
    dw := 16; //GetScrollBarWidth;

  if ElementHandle.scrollWidth > ElementHandle.ClientWidth then
    dh := 16; //GetScrollBarHeight;

  Result := Rect(0,0, TJSHTMLElement(ElementHandle).clientWidth - dw, TJSHTMLElement(ElementHandle).clientHeight - dh);
end;

function TScrollBox.GetScrollLeft: integer;
begin
  Result := ElementHandle.scrollLeft;
end;

function TScrollBox.GetScrollTop: integer;
begin
  Result := ElementHandle.scrollTop;
end;

procedure TScrollBox.SetAutoScroll(AValue: boolean);
begin
  FAutoScroll := AValue;
end;

procedure TScrollBox.SetScrollBars(const Value: TScrollStyle);
begin
  if (FScrollBars <> Value) then
  begin
    FScrollBars := Value;
    UpdateElement;
  end;
end;

procedure TScrollBox.SetScrollLeft(const Value: integer);
begin
  ElementHandle.scrollLeft := Value;
end;

procedure TScrollBox.SetScrollTop(const Value: integer);
begin
  ElementHandle.scrollTop := Value;
end;

{ TCustomImageControl }

procedure TCustomImageControl.BindEvents;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    Container['draggable'] := 'true';
    Container['droppable'] := 'true';
    ElementHandle.ondrag := HandleDoDrag;
    ElementHandle.ondragend := HandleDoDragEnd;
    ElementHandle.ondragexit := HandleDoDragExit;
    ElementHandle.ondragover := HandleDoDragOver;
    ElementHandle.ondragstart := HandleDoDragStart;
    ElementHandle.ondrop := HandleDoDrop;
  end;
end;

procedure TCustomImageControl.CreateInitialize;
begin
  inherited;
  FPicture := TURLPicture.Create;
  FPicture.OnChange := PictureChanged;
  Color := clNone;
  TabStop := false;
  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;
end;

destructor TCustomImageControl.Destroy;
begin
  FPicture.Free;
  inherited Destroy;
end;


function TCustomImageControl.GetBase64Img: string;
begin
  Result := GetBase64Image(ElementHandle);
end;

function TCustomImageControl.CreateElement: TJSElement;
begin
  Result := document.createElement('IMG');
  if URL <> '' then
    Result.setAttribute('src', URL);
end;

function TCustomImageControl.HandleDoDrag(aEvent: TJSDragEvent): boolean;
begin
  //
  Result := true;
end;

function TCustomImageControl.HandleDoDragEnd(aEvent: TJSDragEvent): boolean;
var
  obj: TObject;
begin
  //
//  PreventDefault;
  DoEndDrag(obj,0,0);
  Result := true;
end;

function TCustomImageControl.HandleDoDragExit(aEvent: TJSDragEvent): boolean;
begin
  //
  Result := true;
end;

function TCustomImageControl.HandleDoDragOver(aEvent: TJSDragEvent): boolean;
var
  allow: boolean;
  obj: TObject;
begin
//  PreventDefault;
  AEvent.preventDefault;

  DragOver(obj, 0, 0, dsDragMove, allow);
  Result := true;
end;

function TCustomImageControl.HandleDoDragStart(aEvent: TJSDragEvent): boolean;
var
  obj: TDragObject;

begin
  AEvent.preventDefault;

  AEvent.dataTransfer.effectAllowed := 'copy';
  AEvent.dataTransfer.dropEffect := 'copy';
  AEvent.dataTransfer.setData('text', 'Hello World');

  DoStartDrag(obj);
  Result := true;
end;

function TCustomImageControl.HandleDoDrop(aEvent: TJSDragEvent): boolean;
begin
  DragDrop(TObject(aEvent), aEvent.clientX, aEvent.clientY);
  Result := true;
end;

procedure TCustomImageControl.SetURL(AURL: string);
begin
  FURL := AURL;
  if Assigned(Container) then
  begin
    Container['src'] := AURL;
  end;
end;

procedure TCustomImageControl.UpdateElement;
begin
  inherited;
end;

procedure TCustomImageControl.SetPicture(const Value: TURLPicture);
begin
  FPicture.Assign(Value);
end;

procedure TCustomImageControl.PictureChanged(Sender: TObject);
begin
  SetURL(Picture.FileName);
end;

{ TCustomDragControl }

procedure TCustomDragControl.BindEvents;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    Container['draggable'] := 'true';
    ElementHandle.ondragend := HandleDoDragEnd;
    ElementHandle.ondragexit := HandleDoDragExit;
    ElementHandle.ondragover := HandleDoDragOver;
    ElementHandle.ondragstart := HandleDoDragStart;
    ElementHandle.ondrop := HandleDoDrop;
  end;
end;

function TCustomDragControl.HandleDoDrag(aEvent: TJSDragEvent): boolean;
begin
  //OutputDebugString('do drag');
  Result := true;
end;

function TCustomDragControl.HandleDoDragEnd(aEvent: TJSDragEvent): boolean;
begin
  DoEndDrag(Self, aEvent.clientX, aEvent.clientY);
  Result := true;
end;

function TCustomDragControl.HandleDoDragExit(aEvent: TJSDragEvent): boolean;
begin
  //OutputDebugString('do drag exit');
  Result := true;
end;

function TCustomDragControl.HandleDoDragOver(aEvent: TJSDragEvent): boolean;
var
  Accept: boolean;
begin
  aEvent.preventDefault();
  //OutputDebugString('do drag over');
  Accept := true;
  DragOver(Self, aEvent.clientX, aEvent.clientY, dsDragMove, Accept);
  Result := true;
end;

function TCustomDragControl.HandleDoDragStart(aEvent: TJSDragEvent): boolean;
var
  obj: TDragObject;
begin
  aEvent.dataTransfer.setData('text',  Text);
  DoStartDrag(obj);
  Result := true;
end;

function TCustomDragControl.HandleDoDrop(aEvent: TJSDragEvent): boolean;
begin
  aEvent.preventDefault();
  Text := aEvent.dataTransfer.getData('text');
  DragDrop(Self, aEvent.clientX, aEvent.clientY);
  Result := true;
end;

procedure TCustomDragControl.Paint;
begin
  Canvas.Pen.Color := clRed;
  Canvas.Pen.Width := 1;
  Canvas.Pen.Style := psSolid;
  Canvas.Brush.Style := bsSolid;
  Canvas.Brush.Color := clYellow;
  Canvas.Rectangle(ClientRect);

  Canvas.TextOut(10,10,FText);
end;

procedure TCustomDragControl.SetText(const AValue: string);
begin
  if (FText <> AValue) then
  begin
    FText := AValue;
    Invalidate;
  end;
end;

{ TSplitter }

procedure TSplitter.CreateInitialize;
 begin
   inherited;
   Cursor := crHSplit;
   Width := 6;
   Height := 100;
   Align := alLeft;
   FSplitControl := nil;
   GripColor := clWhite;
 end;

 procedure TSplitter.Paint;
 var
   xofs,dx: integer;
   yofs,dy: integer;
   i: integer;
 begin
   Canvas.Brush.Color := Color;
   Canvas.Pen.Color := Color;
   Canvas.Brush.Style := bsSolid;
   Canvas.Rectangle(ClientRect);

   if (Align in [alLeft, alRight]) then
   begin
     dx := 0;
     dy := 6;
     yofs := (Height div 2) - 9;
     xofs := (Width div 2);
   end;

   if (Align in [alTop, alBottom]) then
   begin
     dx := 6;
     dy := 0;
     xofs := (Width div 2) - 9;
     yofs := (Height div 2) - 1;
   end;

   Canvas.Brush.Color := GripColor;
   Canvas.Pen.Color := GripColor;

   for i := 0 to 2 do
   begin
     Canvas.Rectangle(xofs, yofs, xofs + 2, yofs + 2);
     xofs := xofs + dx;
     yofs := yofs + dy;
   end;
 end;

procedure TSplitter.SetGripColor(const Value: TColor);
begin
  if (FGripColor <> Value) then
  begin
    FGripColor := Value;
    Invalidate;
  end;
end;

procedure TSplitter.MouseUp(Button: TMouseButton; Shift: TShiftState; X,Y: Integer);
begin
  inherited;
  FSizing := false;
  FSplitControl := nil;
  ReleaseCapture;
end;

procedure TSplitter.MouseDown(Button: TMouseButton; Shift: TShiftState; X,Y: Integer);
var
  r,rc: TRect;
  i: integer;
  c: TControl;
  eh: TJSHTMLElement;
begin
  inherited;

  FSizing := true;

  r := Rect(Left, Top, Left + Width, Top + Height);

  if Assigned(Parent) then
  begin
    for i := 0 to Parent.ControlCount - 1 do
    begin
      c := Parent.Controls[i];

      if (c.Align = Align) and (c <> Self) then
      begin
        rc := Rect(c.Left, c.Top, c.Left + c.Width, c.Top + c.Height);

        //OutputDebugString(c.GetID+':'+inttostr(rc.Right)+':'+inttostr(r.Left));

        if (Align = alLeft) and (rc.Right - r.Left < 4) then
        begin
          FSplitControl := c;
          FOriginalWidth := FSplitControl.Width;
          FSizing := true;
          FSizingX := X;
          FSizingY := Y;
          break;
          //OutputDebugString('left:'+c.GetID);
        end;

        if (Align = alRight) and (rc.Left - r.Right < 4) then
        begin
          FSplitControl := c;
          FOriginalWidth := FSplitControl.Width;
          FSizing := true;
          FSizingX := X;
          FSizingY := Y;
          break;
          //OutputDebugString('left:'+c.GetID);
        end;

        if (Align = alTop) and (rc.Bottom - r.Top < 4) then
        begin
          FSplitControl := c;
          FOriginalHeight := FSplitControl.Height;
          FSizing := true;
          FSizingX := X;
          FSizingY := Y;
          break;
          //OutputDebugString('top:'+c.GetID);
        end;

        if (Align = alBottom) and (rc.Top - r.Bottom < 4) then
        begin
          FSplitControl := c;
          FOriginalHeight := FSplitControl.Height;
          FSizing := true;
          FSizingX := X;
          FSizingY := Y;
          break;
          //OutputDebugString('top:'+c.GetID);
        end;

      end;
    end;
  end;

  FLayer := document.createElement('SPAN');
  document.body.appendChild(FLayer);

  eh := TJSHTMLElement(FLayer);
  eh.style.setProperty('top', '0');
  eh.style.setProperty('left', '0');
  eh.style.setProperty('right', '0');
  eh.style.setProperty('bottom', '0');

  eh.style.setProperty('webkit-user-select', 'none');
  eh.style.setProperty('moz-user-select', 'none');
  eh.style.setProperty('khtml-user-select', 'none');
  eh.style.setProperty('ms-user-select', 'none');
  eh.style.setProperty('user-select', 'none');
  eh.style.setProperty('position', 'absolute');

  if (Align in [alLeft, alRight]) then
    eh.style.setProperty('cursor', 'col-resize');

  if (Align in [alTop, alBottom]) then
    eh.style.setProperty('cursor', 'row-resize');

  FFirstMove := true;

  eh.addEventListener('mousemove',@HandleDocDoMouseMove);
  eh.addEventListener('mouseup',@HandleDocDoMouseUp);

  //Capture;
end;


procedure TSplitter.DoMouseEnter;
begin
  inherited;
  if (Align in [alLeft, alRight]) then
    Cursor := crHSplit;

  if (Align in [alTop, alBottom]) then
    Cursor := crVSplit;
end;

function TSplitter.HandleDocDoMouseMove(Event: TJSMouseEvent): Boolean;
var
  dx,dy: integer;
begin

  if FSizing and Assigned(FSplitControl) then
  begin
    if FFirstMove then
    begin
      FSizingX := Event.clientX;
      FSizingY := Event.clientY;
      FFirstMove := false;
    end
    else
    begin
      dx := Round(Event.clientX - FSizingX);
      dy := Round(Event.clientY - FSizingY);

      if Align = alLeft then
        FSplitControl.Width := FOriginalWidth + dx;

      if Align = alRight then
        FSplitControl.Width := FOriginalWidth - dx;

      if Align = alTop then
        FSplitControl.Height := FOriginalHeight + dy;

      if Align = alBottom then
        FSplitControl.Height := FOriginalHeight - dy;

      DoRealign;

      if Assigned(OnMove) then
        OnMove(Self);

    end;
  end;
  Result := true;
end;

function TSplitter.HandleDocDoMouseUp(Event: TJSMouseEvent): Boolean;
begin
  FSizing := false;
  FSplitControl := nil;
  FFirstMove := true;
  FLayer.parentNode.removeChild(FLayer);
  Result := true;
  if Assigned(OnMoved) then
    OnMoved(Self);
end;

{ TDivPanel }

function TDivPanel.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

{ TCustomPanel }

procedure TCustomPanel.CreateInitialize;
begin
  inherited;
  FAutoSize := False;
  Color := clBtnFace;
  TabStop := False;
  CustomBorder := true;
  ShowCaption := true;
  FLabel := nil;
  FPadding := TPadding.Create;
  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;
end;

destructor TCustomPanel.Destroy;
begin
  FPadding.Free;
  inherited;
end;

procedure TCustomPanel.EndUpdate;
begin
  inherited;
  if AutoSize then
    AutoSize := true;
end;

function TCustomPanel.GetOuterHeight: integer;
begin
  Result := inherited GetOuterHeight;
end;

function TCustomPanel.GetOuterWidth: integer;
begin
  Result := inherited GetOuterWidth;
end;

function TCustomPanel.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
end;

procedure TCustomPanel.SetAutoSize(AValue: boolean);
var
  i: integer;
  mx,my: integer;
  r: TJSDomRect;
  el: TJSElement;
begin
  FAutoSize := AValue;
  if FAutoSize and not IsUpdating then
  begin
    if (WidthStyle = ssAbsolute) and (HeightStyle = ssAbsolute) then
    begin
      mx := 0;
      my := 0;
      for i := 0 to ControlCount - 1 do
      begin
        if Controls[i].WidthStyle = ssAbsolute then
        begin
          if Controls[i].Left + Controls[i].Width > mx then
            mx := Controls[i].Left + Controls[i].Width;
        end
        else
        begin
          el := Controls[i].ElementHandle;
          if Assigned(el) then
          begin
            r := el.getBoundingClientRect;
            if r.X - Left + r.Width > mx then
              mx := Round(r.X  - Left + r.Width);
          end;
        end;

        if Controls[i].HeightStyle = ssAbsolute then
        begin
          if Controls[i].Top + Controls[i].Height > my then
            my := Controls[i].Top + Controls[i].Height;
        end
        else
        begin
          el := Controls[i].ElementHandle;
          if Assigned(el) then
          begin
            r := el.getBoundingClientRect;
            if r.Y - Top + r.Height > my then
              my := Round(r.Y - Top + r.Height);
          end;
        end;
      end;

      Width := mx;
      Height := my;
    end
    else
    begin
      Width := -1;
      Height := -1;
    end;

    UpdateElement;
  end;
end;

procedure TCustomPanel.SetBorderStyle(const AValue: TBorderStyle);
begin
  inherited;
  UpdateElement;
end;

procedure TCustomPanel.SetCaption(const AValue: string);
begin
  inherited SetCaption(AValue);

  if Assigned(ElementHandle) and ShowCaption then
  begin
    if not Assigned(FLabel) then
    begin
      FLabel := TJSHTMLElement(document.createElement('SPAN'));
      FLabel.innerHTML := Caption;
      ElementHandle.appendChild(FLabel);
    end
    else
      FLabel.innerHTML := Caption;
  end;
end;

procedure TCustomPanel.SetPadding(const Value: TPadding);
begin
  FPadding.Assign(Value);
end;

procedure TCustomPanel.SetShowCaption(const Value: boolean);
begin
  if (FShowCaption <> Value) then
  begin
    FShowCaption := Value;
    UpdateElementVisual;
  end;
end;

procedure TCustomPanel.UpdateElementVisual;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    if not IsLinked then
    begin
      if AutoSize then
      begin
        ElementHandle.style.setProperty('overflow', '');
        ElementHandle.style.setProperty('white-space', 'normal');
        if Visible then
          ElementHandle.style.setProperty('display', 'inline');
      end
      else
      begin
        ElementHandle.style.setProperty('overflow', 'hidden');
        ElementHandle.style.setProperty('white-space', 'nowrap');
        if Visible then
          ElementHandle.style.setProperty('display', 'inline-block');
      end;

      ElementHandle.style.setProperty('padding-left',inttostr(Padding.Left)+'px');
      ElementHandle.style.setProperty('padding-right',inttostr(Padding.Right)+'px');
      ElementHandle.style.setProperty('padding-top',inttostr(Padding.Top)+'px');
      ElementHandle.style.setProperty('padding-bottom',inttostr(Padding.Bottom)+'px');
      ElementHandle.style.setProperty('box-sizing','border-box');

      if (ElementClassName = '') and (Color <> clNone) then
        ElementHandle.style.setProperty('background-color', ColorToHTML(Color));
    end;

    if Assigned(FLabel) then
    begin
      if ShowCaption then
        FLabel.innerHTML := Caption
      else
        FLabel.innerHTML := '';
    end;

    // do not block selection in child controls
    ElementHandle.style.setProperty('user-select', '');

    // allow default caret on text
    if Cursor = crDefault then
      ElementHandle.style.setProperty('cursor', '');
  end;
end;


{ TURLPicture }

procedure TURLPicture.LoadFromFile(AFileName: string);
begin
  FFilename := AFileName;
  if Assigned(OnChange) then
    OnChange(Self);
end;


{ TGeoLocation }

procedure TGeoLocation.DoHandleGeolocation(APosition: TJSPosition);
begin
  if Assigned(OnGeolocation) then
    OnGeolocation(Self, APosition.Coords.Latitude, APosition.Coords.Longitude, APosition.Coords.Altitude);
end;

procedure TGeoLocation.GetGeolocation;
begin
  if Assigned(geolocation) then
  begin
    geolocation.getCurrentPosition(TJSGeoLocationCallback(@DoHandleGeoLocation));
  end;
end;

function TGeoLocation.HasGeolocation: boolean;
begin
  Result := Assigned(geolocation);
end;

{ TGridPanelRows }

function TGridPanelRows.Add: TGridPanelRow;
begin
  Result := TGridPanelRow(inherited Add);
end;

constructor TGridPanelRows.Create(AOwner: TComponent);
begin
   inherited Create(AOwner, TGridPanelRow);
end;

function TGridPanelRows.GetItem(Index: integer): TGridPanelRow;
begin
  Result := TGridPanelRow(inherited Items[Index]);
end;

function TGridPanelRows.Insert(Index: integer): TGridPanelRow;
begin
  Result := TGridPanelRow(inherited Insert(Index));
end;

procedure TGridPanelRows.SetItem(Index: integer; const Value: TGridPanelRow);
begin
  inherited Items[Index] := Value;
end;

{ TGridPanelColumns }

function TGridPanelColumns.Add: TGridPanelColumn;
begin
  Result := TGridPanelColumn(inherited Add);
end;

constructor TGridPanelColumns.Create(AOwner: TComponent);
begin
  inherited Create(AOwner, TGridPanelColumn);
end;

function TGridPanelColumns.GetItem(Index: integer): TGridPanelColumn;
begin
  Result := TGridPanelColumn(inherited Items[Index]);
end;

function TGridPanelColumns.Insert(Index: integer): TGridPanelColumn;
begin
  Result := TGridPanelColumn(inherited Insert(Index));
end;

procedure TGridPanelColumns.SetItem(Index: integer;
  const Value: TGridPanelColumn);
begin
  inherited Items[Index] := Value;
end;

{ TGridPanel }

procedure TGridPanel.AddControl(AControl: TWinControl);
begin
  FControlCollection.Add.Control := AControl;
  AControl.Parent := Self;

  if FControlCollection.Count > FColumnCollection.Count * FRowCollection.Count then
  begin
    if ExpandStyle = esAddRows then
      FRowCollection.Add
    else
      FColumnCollection.Add;
  end;
end;

function TGridPanel.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
end;

procedure TGridPanel.CreateInitialize;
begin
  inherited;
  FUpdateTable := true;
  FRowCollection := TGridPanelRows.Create(Self);
  FColumnCollection := TGridPanelColumns.Create(Self);
  FControlCollection := TControlCollection.Create(Self);
  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;
end;

function TGridPanel.CreateRow(AIndex: integer): TJSElement;
var
  row, cell: TJSElement;
  i: integer;
  ps: string;

begin
  if (GridLineColor <> clNone) and (GridLineWidth > 0) then
    ps := IntToStr(GridLineWidth)+'px solid '+ ColorToHTML(GridLineColor)
  else
    ps := '0px';

  row := document.createElement('tr');
  if (ElementClassName = '') then
    TJSHTMLElement(row).style.setProperty('border', ps);

  if RowCollection.Items[AIndex].MarginTop <> 0 then
    TJSHTMLElement(row).style.setProperty('margin-top', IntToStr(RowCollection.Items[AIndex].MarginTop));

  if RowCollection.Items[AIndex].MarginBottom <> 0 then
    TJSHTMLElement(row).style.setProperty('margin-bottom', IntToStr(RowCollection.Items[AIndex].MarginBottom));

  if (RowCollection.Items[AIndex].ElementClassName <> '') then
  begin
    TJSHTMLElement(row).setAttribute('class', RowCollection.Items[AIndex].ElementClassName);
  end;

  case RowCollection.Items[AIndex].Alignment of
  vaCenter: TJSHTMLElement(row).setAttribute('valign','middle');
  vaBottom: TJSHTMLElement(row).setAttribute('valign','bottom');
  end;

  if (ElementClassName = '') then
    TJSHTMLElement(row).style.setProperty('border', ps);

  row.setAttribute('height', RowCollection.Items[AIndex].HeightAttribute);

  for i := 0 to ColumnCollection.Count - 1 do
  begin
    cell := document.createElement('td');
    if (ElementClassName = '') then
      TJSHTMLElement(cell).style.setProperty('border', ps);

    if ColumnCollection.Items[i].MarginLeft <> 0 then
      TJSHTMLElement(cell).style.setProperty('margin-left', IntToStr(ColumnCollection.Items[i].MarginLeft));

    if ColumnCollection.Items[i].MarginRight <> 0 then
      TJSHTMLElement(cell).style.setProperty('margin-right', IntToStr(ColumnCollection.Items[i].MarginRight));

    if (ColumnCollection.Items[i].ElementClassName <> '') then
    begin
      TJSHTMLElement(cell).setAttribute('class', ColumnCollection.Items[i].ElementClassName);
    end;

    case ColumnCollection.Items[i].Alignment of
    taCenter: TJSHTMLElement(cell).setAttribute('align','center');
    taRightJustify: TJSHTMLElement(cell).setAttribute('align','right');
    end;

    cell.setAttribute('id', Name+'R'+IntToStr(AIndex)+'C'+IntToStr(i));

    if AIndex = 0 then
    begin
      TJSHTMLElement(cell).style.setProperty('width',ColumnCollection.Items[i].WidthAttribute);
    end;

    //cellText := document.createTextNode('cell is row '+IntToStr(j)+', column '+IntToStr(i));
    //cell.appendChild(cellText);
    row.appendChild(cell);
  end;

  Result := row;
end;


function TGridPanel.CreateTable: TJSElement;
var
  row: TJSElement;
  j: integer;
  ps: string;
begin
  FTbl := document.createElement('table');

  FTbl.setAttribute('width','100%');
  FTbl.setAttribute('height','100%');

  if ElementClassName <> '' then
    FTbl.setAttribute('class', ElementClassName)
  else
  begin
    if (GridLineColor <> clNone) and (GridLineWidth > 0) then
      ps := IntToStr(GridLineWidth)+'px solid '+ ColorToHTML(GridLineColor)
    else
      ps := '0px';

    TJSHTMLElement(FTbl).style.setProperty('border', ps);
    TJSHTMLElement(FTbl).style.setProperty('border-collapse','collapse');
  end;

  if (Color <> clNone) and (ElementClassName = '') then
    TJSHTMLElement(FTbl).style.setProperty('background-color', ColorToHTML(Color));

  FTblBody := document.createElement('tbody');

  // cells creation
  for  j := 0 to RowCollection.Count - 1 do
  begin
    row := CreateRow(j);
    FTblBody.appendChild(row);
  end;

  // append the <tbody> inside the <table>
  FTbl.appendChild(FTblBody);
  Result := FTbl;
end;

procedure TGridPanel.UpdateTable;
var
  i,j,k: integer;
  fragment: TJSDocumentFragment;
  ctrlid,destid: string;
  control: TWinControl;
  el,row: TJSElement;
  isPercent: boolean;

begin
  i := 0;
  j := 0;

  if FTblBody.childNodes.length < RowCollection.Count then
  begin
    isPercent := true;
    for k := 0 to RowCollection.Count - 1 do
    begin
      if RowCollection.Items[k].SizeStyle <> ssPercent then
      begin
        isPercent := false;
        break;
      end;
    end;

    if isPercent then
    begin
      for k := 0 to RowCollection.Count - 1 do
      begin
        RowCollection.Items[k].Value := Trunc(100/RowCollection.Count);
      end;
    end;
  end;

  while FTblBody.childNodes.length > RowCollection.Count do
  begin
    FTblBody.removeChild(FTblBody.childNodes[FTblBody.childNodes.length - 1]);
  end;

  for k := 0 to ControlCollection.Count - 1 do
  begin
    if Assigned(ControlCollection.Items[k].Control) then
    begin
      fragment := document.createDocumentFragment();
      control := ControlCollection.Items[k].Control;
      ControlCollection.Items[k].Column := i;
      ControlCollection.Items[k].Row := j;

      control.ElementPosition := epRelative;
      control.ChildOrder := -1;
      ctrlid := control.GetID;

      if control.Align = alLeft then
      begin
        control.HeightStyle := ssPercent;
        control.Height := 100;
        if Assigned(control.ElementHandle) then
          TJSHTMLElement(control.ElementHandle).style.setProperty('float','left');
      end;

      if control.Align = alRight then
      begin
        control.HeightStyle := ssPercent;
        control.Height := 100;
        if Assigned(control.ElementHandle) then
          TJSHTMLElement(control.ElementHandle).style.setProperty('float','right');
      end;

      if (control.Align in [alTop, alBottom]) then
      begin
        control.WidthStyle := ssPercent;
        control.Width := 100;
      end;

      if control.Align = alClient then
      begin
        control.WidthStyle := ssPercent;
        control.HeightStyle := ssPercent;
        control.Width := 100;
        control.Height:= 100;
      end;

      row := TJSElement(FTblBody.childNodes[j]);
      if Assigned(row) then
      begin
        row.setAttribute('height', RowCollection.Items[j].HeightAttribute);
        if (RowCollection.Items[j].ElementClassName <> '') then
          row.setAttribute('class', RowCollection.Items[j].ElementClassName)
        else
          row.removeAttribute('class');
      end;

      fragment.appendChild(document.getElementById(ctrlid));

      destid := Name + 'R'+ inttostr(j) + 'C' + inttostr(i);

      el := document.getElementById(destid);

      if not Assigned(el) then
      begin
        row := CreateRow(j);
        FTblBody.appendChild(row);
        el := document.getElementById(destid);
        row.setAttribute('height', RowCollection.Items[j].HeightAttribute);
      end
      else
      begin
        if (j = 0) then
        begin
          if ColumnCollection.Items[i].SizeStyle = ssAbsolute then
          begin
            TJSHTMLElement(FTbl).removeAttribute('width');
            TJSHTMLElement(FTbl).removeAttribute('height');
          end
          else
          begin
            TJSHTMLElement(FTbl).setAttribute('width','100%');
            TJSHTMLElement(FTbl).setAttribute('height','100%');
          end;

          TJSHTMLElement(el).style.setProperty('width',ColumnCollection.Items[i].WidthAttribute);
        end;
      end;

      el.appendChild(fragment);

      if control.Align = alTop then
        TJSHTMLElement(el).style.setProperty('vertical-align','top');

      if control.Align = alBottom then
        TJSHTMLElement(el).style.setProperty('vertical-align','bottom');

      inc(i);
      if i = ColumnCollection.Count then
      begin
        i := 0;
        inc(j);
      end;
    end;
  end;
end;

destructor TGridPanel.Destroy;
begin
  FRowCollection.Free;
  FColumnCollection.Free;
  FControlCollection.Free;
  inherited;
end;

procedure TGridPanel.EndUpdate;
begin
  inherited;
  UpdateTable;
end;

procedure TGridPanel.RemoveControl(AControl: TWinControl);
var
  i,r,c: integer;
  row: TJSElement;
  d: double;

begin
  for i := 0 to FControlCollection.Count - 1 do
  begin
    if FControlCollection.Items[i].Control =  AControl then
    begin
      r := FControlCollection.Items[i].Row;
      c := FControlCollection.Items[i].Column;

      row := TJSElement(FTblBody.childNodes[r]);

      TJSHTMLElement(row.childNodes[c]).innerHTML := '';

      FControlCollection.Delete(i);
      break;
    end;
  end;

  if ColumnCollection.Count > 0 then
  begin
    d := ControlCollection.Count / ColumnCollection.Count;

    if Frac(d) > 0 then
      d := Trunc(d + 1);

    while d < RowCollection.Count do
    begin
      RowCollection.Delete(RowCollection.Count - 1);
    end;
  end;

end;

procedure TGridPanel.SetColumnCollection(const Value: TGridPanelColumns);
begin
  FColumnCollection.Assign(Value);
end;

procedure TGridPanel.SetControlCollection(const Value: TControlCollection);
begin
  FControlCollection.Assign(Value);
end;

procedure TGridPanel.SetRowCollection(const Value: TGridPanelRows);
begin
  FRowCollection.Assign(Value);
end;

procedure TGridPanel.UpdateElement;
begin
  inherited;

  if Assigned(ElementHandle) and not IsUpdating then
  begin
    // do not block selection in child controls
    ElementHandle.style.setProperty('user-select', '');

    // allow default caret on text
    if Cursor = crDefault then
      ElementHandle.style.setProperty('cursor', '');

    if FUpdateTable and Assigned(Container) then
    begin
      TJSElement(Container).appendChild(CreateTable);
      UpdateTable;
      FUpdateTable := false;
    end;
  end;
end;

procedure TGridPanel.UpdateElementVisual;
begin
  inherited;

  if (csDesigning in ComponentState) then
  begin
    ElementHandle.style.setProperty('border', '1px dotted gray');
  end;
end;

{ TGridPanelColumn }

procedure TGridPanelColumn.Assign(Source: TPersistent);
begin
  if (Source is TGridPanelColumn) then
  begin
    FAlignment := (Source as TGridPanelColumn).Alignment;
    FSizeStyle := (Source as TGridPanelColumn).SizeStyle;
    FElementClassName := (Source as TGridPanelColumn).ElementClassName;
    FValue := (Source as TGridPanelColumn).Value;
    FMarginLeft := (Source as TGridPanelColumn).MarginLeft;
    FMarginRight := (Source as TGridPanelColumn).MarginRight;
  end;
end;

constructor TGridPanelColumn.Create(ACollection: TCollection);
begin
  inherited;
  FSizeStyle := ssPercent;
  FMarginLeft := 0;
  FMarginRight := 0;
  FAlignment := taLeftJustify;
end;

function TGridPanelColumn.WidthAttribute: string;
begin
  Result := '';
  case SizeStyle of
  ssPercent: Result := IntTostr(Value)+'%';
  ssAbsolute: Result := IntTostr(Value)+'px';
  end;
end;

{ TGridPanelRow }

procedure TGridPanelRow.Assign(Source: TPersistent);
begin
  if (Source is TGridPanelRow) then
  begin
    FAlignment := (Source as TGridPanelRow).Alignment;
    FSizeStyle := (Source as TGridPanelRow).SizeStyle;
    FElementClassName := (Source as TGridPanelRow).ElementClassName;
    FValue := (Source as TGridPanelRow).Value;
    FMarginTop := (Source as TGridPanelRow).MarginTop;
    FMarginBottom := (Source as TGridPanelRow).MarginBottom;
  end;
end;

constructor TGridPanelRow.Create(ACollection: TCollection);
begin
  inherited;
  FSizeStyle := ssPercent;
  FAlignment := vaTop;
  FMarginTop := 0;
  FMarginBottom := 0;
end;

function TGridPanelRow.HeightAttribute: string;
begin
  Result := '';
  case SizeStyle of
  ssPercent: Result := IntTostr(Value)+'%';
  ssAbsolute: Result := IntTostr(Value)+'px';
  end;
end;

{ TControlCollection }

function TControlCollection.Add: TControlCollectionItem;
begin
  Result := TControlCollectionItem(inherited Add);
end;

constructor TControlCollection.Create(AOwner: TComponent);
begin
  inherited Create(AOwner, TControlCollectionItem);
end;

function TControlCollection.GetItem(Index: Integer): TControlCollectionItem;
begin
  Result := TControlCollectionItem(inherited Items[Index]);
end;

function TControlCollection.Insert(Index: Integer): TControlCollectionItem;
begin
  Result := TControlCollectionItem(inherited Insert(Index));
end;

procedure TControlCollection.SetItem(Index: Integer;
  const Value: TControlCollectionItem);
begin
  Items[Index] := Value;
end;

{ TWebMultimediaPlayer }

function TMultimediaPlayer.CreateElement: TJSElement;
var
  src: TJSElement;
begin
  if MultimediaType = mtVideo then
    Result := document.createElement('VIDEO')
  else
    Result := document.createElement('AUDIO');

  src := document.createElement('SOURCE');
  Result.appendChild(src);
end;

procedure TMultimediaPlayer.CreateInitialize;
begin
  inherited;
  FVolume := 100;
  FPlaybackRate := 1;
  FMuted := false;
  FAutoPlay := false;
  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;
end;

function TMultimediaPlayer.GetCurrentTime: double;
var
  el: TJSElement;
  i: double;
begin
  el := ElementHandle;
  if Assigned(el) then
  begin
    asm
      i = el.currentTime;
    end;
  end;

  Result := i;
end;

function TMultimediaPlayer.GetDuration: double;
var
  el: TJSElement;
  i: double;
begin
  el := ElementHandle;
  if Assigned(el) then
  begin
    asm
      i = el.duration;
    end;
  end;
  Result := i;
end;

function TMultimediaPlayer.GetEnded: boolean;
var
  el: TJSElement;
  e: boolean;
begin
  el := ElementHandle;
  if Assigned(el) then
  begin
    asm
      e = el.ended;
    end;
  end;

  Result := e;
end;

function TMultimediaPlayer.GetPaused: boolean;
var
  el: TJSElement;
  e: boolean;
begin
  el := ElementHandle;
  if Assigned(el) then
  begin
    asm
      e = el.paused;
    end;
  end;

  Result := e;
end;

procedure TMultimediaPlayer.Pause;
var
  el: TJSElement;
begin
  el := ElementHandle;
  if Assigned(el) then
  begin
    asm
      el.pause();
    end;
  end;

end;

procedure TMultimediaPlayer.Play;
var
  el: TJSElement;
begin
  el := ElementHandle;
  if Assigned(el) then
  asm
    el.play();
  end;
end;

procedure TMultimediaPlayer.ReLoad;
var
  el: TJSElement;
begin
  el := ElementHandle;
  if Assigned(el) then
  asm
    el.load();
  end;
end;

procedure TMultimediaPlayer.SetAutoPlay(const Value: boolean);
begin
  if (FAutoPlay <> Value) then
  begin
    FAutoPlay := Value;
    UpdateElement;
  end;
end;

procedure TMultimediaPlayer.SetControls(const Value: boolean);
begin
  if (FControls <> Value) then
  begin
    FControls := Value;
    UpdateElement;
  end;
end;

procedure TMultimediaPlayer.SetCurrentTime(const Value: double);
var
  el: TJSElement;
begin
  el := ElementHandle;
  if Assigned(el) then
  asm
    el.currentTime = Value;
  end;
end;

procedure TMultimediaPlayer.SetLoop(const Value: boolean);
begin
  if (FLoop <> Value) then
  begin
    FLoop := Value;
    UpdateElement;
  end;
end;

procedure TMultimediaPlayer.SetMuted(const Value: boolean);
begin
  if (FMuted <> Value) then
  begin
    FMuted := Value;
    UpdateElement;
  end;
end;

procedure TMultimediaPlayer.SetPlaybackRate(const Value: double);
begin
  if (FPlaybackRate <> Value) then
  begin
    FPlaybackRate := Value;
    UpdateElement;
  end;
end;

procedure TMultimediaPlayer.SetURL(const Value: string);
begin
  if (FURL <> Value) then
  begin
    FURL := Value;
    UpdateElement;
  end;
end;

procedure TMultimediaPlayer.SetVolume(const Value: TMultiMediaVolume);
begin
  if (FVolume <> Value) then
  begin
    FVolume := Value;
    UpdateElement;
  end;
end;

{$HINTS OFF}
procedure TMultimediaPlayer.UpdateElement;
var
  el: TJSNode;
  vid: TJSElement;

  function booltoattr(b: boolean): string;
  begin
    if b then
      result := 'true'
    else
      result := 'false';
  end;

begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    vid := TJSElement(ElementHandle);
    asm
      vid.controls = this.FControls;
      vid.playbackRate = this.FPlaybackRate;
      vid.muted = this.FMuted;
    end;

    //ElementHandle.setAttribute('controls',booltoattr(FControls));
    ElementHandle.setAttribute('autoplay',booltoattr(FAutoplay));
    ElementHandle.setAttribute('muted',booltoattr(FMuted));
    ElementHandle.setAttribute('volume',Format('%.2f',[FVolume/100]));

    ElementHandle.setAttribute('src',url);

//    el := ElementHandle.firstChild;
//    if Assigned(el) then
//    begin
//      (el as TJSElement).setAttribute('src',url);
//      (el as TJSElement).setAttribute('type','video/mp4');
//    end;
  end;
end;

{$HINTS ON}

{ TCustomGroupBox }

function TCustomGroupBox.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');

  FControlSpan := TJSHTMLElement(document.createElement('SPAN'));
  FCaptionSpan := TJSHTMLElement(document.createElement('SPAN'));

  Result.appendChild(FControlSpan);
  Result.appendChild(FCaptionSpan);
end;

procedure TCustomGroupBox.CreateInitialize;
begin
  inherited;
  Color := clBtnFace;
  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;
end;

procedure TCustomGroupBox.UpdateElementData;
begin
  inherited;
  if Assigned(FCaptionSpan) then
  begin
    FCaptionSpan.innerHTML := FCaption;
  end;
end;

procedure TCustomGroupBox.UpdateElementVisual;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    ElementHandle.style.setProperty('overflow', 'visible');

    ElementHandle.style.setProperty('white-space', 'nowrap');

    if Visible then
      ElementHandle.style.setProperty('display', 'inline-block');

    ElementHandle.style.setProperty('webkit-user-select', 'none');
    ElementHandle.style.setProperty('moz-user-select', 'none');
    ElementHandle.style.setProperty('khtml-user-select', 'none');
    ElementHandle.style.setProperty('ms-user-select', 'none');
    ElementHandle.style.setProperty('user-select', 'none');

    ElementHandle.style.setProperty('background-color', ColorToHTML(Color));
    ElementHandle.style.setProperty('border-bottom','1px solid lightgray');

    FCaptionSpan.style.setProperty('left','8px');
    FCaptionSpan.style.setProperty('top','0px');
    FCaptionSpan.style.setProperty('position','absolute');
    FCaptionSpan.style.setProperty('background-color', ColorToHTML(Color));
    FCaptionSpan.style.setProperty('zindex','100');

    FControlSpan.style.setProperty('display','inline-block');
    FControlSpan.style.setProperty('width','100%');
    FControlSpan.style.setProperty('height','98%');
    FControlSpan.style.setProperty('margin-top','8px');
    FControlSpan.style.setProperty('border-style', 'solid');
    FControlSpan.style.setProperty('border-width', '1px');
    FControlSpan.style.setProperty('border-color', 'lightgray');
    FControlSpan.style.setProperty('background-color', ColorToHTML(Color));
  end;
end;

{ THTMLContainer }


function THTMLContainer.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

procedure THTMLContainer.CreateInitialize;
begin
  inherited;
  FHTML := TStringList.Create;
  FHTML.OnChange := HTMLChanged;
  FScrollStyle := ssBoth;
end;

destructor THTMLContainer.Destroy;
begin
  FHTML.Free;
  inherited;
end;

procedure THTMLContainer.HTMLChanged(Sender: TObject);
begin
  UpdateElement;
end;

procedure THTMLContainer.SetHTML(const Value: TStringList);
begin
  FHTML.Assign(Value);
end;

procedure THTMLContainer.UpdateElement;
begin
  inherited;
  if not IsUpdating and Assigned(ElementHandle) and Assigned(Container) then
  begin
    if Assigned(FHTML) then
      Container.innerHTML := FHTML.Text;

    case ScrollStyle of
    ssBoth: ElementHandle.style.setProperty('overflow','auto');
    ssNone: ElementHandle.style.setProperty('overflow','');
    ssVertical: ElementHandle.style.setProperty('overflow-y','auto');
    ssHorizontal: ElementHandle.style.setProperty('overflow-x','auto');
    end;
  end;
end;


{ THMTMLForm }

{$HINTS OFF}
procedure THTMLForm.BindEvents;
begin
  inherited;
  ElementHandle.addEventListener('submit',@DoHandleSubmit);
end;

function  THTMLForm.CheckValidity: boolean;
var
  el: TJSHTMLElement;
  res: boolean;
begin
  el := ElementHandle;
  asm
    res = el.checkValidity();
  end;
  Result := res;
end;
{$HINTS ON}

function THTMLForm.CreateElement: TJSElement;
begin
  Result := document.createElement('FORM');
end;

function THTMLForm.DoHandleSubmit(Event: TJSEvent): boolean;
begin
  ElementEvent := Event;
  if Assigned(OnSubmit) then
    OnSubmit(Self);
  Result := true;
end;

function THTMLForm.IsStructuralElement: boolean;
begin
  Result := true;
end;

procedure THTMLForm.UpdateElement;
begin
  // do nothing
end;

{ TBadge }

function TBadge.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
end;

procedure TBadge.CreateInitialize;
var
  css: string;
begin
  inherited;
  FColor := clRed;
  FTextColor := clWhite;
  FText := '1';

  css := 'span.tmsbadge {' +
  '  background: #FF0000;' +
  '  border-radius: 0.8em;' +
  '  -moz-border-radius: 0.8em;' +
  '  -webkit-border-radius: 0.8em;' +
  '  color: #ffffff;' +
  '  display: inline-block;' +
  '  line-height: 1.6em;' +
  '  margin-right: 5px;' +
  '  text-align: center;' +
  '  width: 1.6em;' +
  '}';

  AddControlStyle(css);
end;

destructor TBadge.Destroy;
begin

  inherited;
end;

procedure TBadge.SetBkColor(const Value: TColor);
begin
  FColor := Value;
  UpdateElement;
end;

procedure TBadge.SetElementClassName(AValue: string);
begin
  inherited;
  UpdateElement;
end;

procedure TBadge.SetText(const Value: string);
begin
  FText := Value;
  UpdateElement;
end;

procedure TBadge.SetTextColor(const Value: TColor);
begin
  FTextColor := Value;
  UpdateElement;
end;

procedure TBadge.UpdateElement;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    ElementHandle.innerHTML := FText;

    if ElementClassName = '' then
    begin
      ElementHandle.setAttribute('class','tmsbadge');
      ElementHandle.style.setProperty('color',ColorToHTML(TextColor));
      ElementHandle.style.setProperty('background-color',ColorToHTML(Color));
    end
    else
    begin
      ElementHandle.style.removeProperty('color');
      ElementHandle.style.removeProperty('background-color');
    end;

    ElementHandle.style.removeProperty('width');
    ElementHandle.style.removeProperty('height');
  end;
end;

{ TAccordion }

procedure TAccordion.Collapse(ASection: TAccordionSection);
var
  el,pnl: TJSHTMLElement;
  LClass: string;
begin
  el := ASection.CaptionElement;

  LClass := 'accordionactive_'+Name;

  if el.classList.contains(LClass) then
    el.classList.remove(LClass);

  pnl := TJSHTMLElement(el.nextElementSibling);
  pnl.style.setProperty('max-height','0')
end;

function TAccordion.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

procedure TAccordion.CreateInitialize;
begin
  inherited;

  FSections := TAccordionSections.Create(Self);
  FStyleRendered := false;
end;

destructor TAccordion.Destroy;
begin
  FSections.Free;
  inherited;
end;

function TAccordion.DoAccordionClick(Event: TJSEvent): Boolean;
var
  el: TJSElement;
  pnl: TJSHTMLElement;
  s: string;
  Allow: boolean;
  ASection: TAccordionSection;
  i,e: integer;
begin
  asm
    el = Event.srcElement;
  end;

  Allow := true;
  ASection := nil;

  if el.hasAttribute('id') then
  begin
    s := el['id'];
    s := Copy(s, pos(Name,s) + Length(Name) + 1, Length(s));
    val(s,i,e);

    if (e = 0) and (i < Sections.Count) then
      ASection := Sections[i];
  end;

  if el.classlist.contains('accordionactive_'+Name) then
  begin
    if Assigned(OnCollapsing) then
      OnCollapsing(Self, ASection, Allow);
  end
  else
  begin
    if Assigned(OnExpanding) then
      OnExpanding(Self, ASection, Allow);
  end;

  if not Allow then
    Exit;

  el.classList.toggle('accordionactive_'+Name);

  pnl := TJSHTMLElement(el.nextElementSibling);

  s := pnl.style.getPropertyValue('max-height');

  if (s <> '0px') and (s <> '') then
  begin
    pnl.style.setProperty('max-height','0')
  end
  else
  begin
    pnl.style.setProperty('max-height', IntToStr(pnl.scrollHeight) + 'px');
  end;

  if el.classlist.contains('accordionactive_'+Name) then
  begin
    ASection.FExpanded := true;
    if Assigned(OnExpanded) then
      OnExpanded(Self, ASection);
  end
  else
  begin
    ASection.FExpanded := false;
    if Assigned(OnCollapsed) then
      OnCollapsed(Self, ASection);
  end;

  Result := true;
end;

procedure TAccordion.EndUpdate;
begin
  inherited;
  RenderStyle;
  RenderAccordion;
end;

procedure TAccordion.Expand(ASection: TAccordionSection);
var
  el,pnl: TJSHTMLElement;
  LClass: string;
begin
  el := ASection.CaptionElement;

  LClass := 'accordionactive_'+Name;

  if el.classList.contains(LClass) then
    el.classList.add(LClass);

  pnl := TJSHTMLElement(el.nextElementSibling);
  pnl.style.setProperty('max-height', IntToStr(pnl.scrollHeight) + 'px');
end;

procedure TAccordion.RenderAccordion;
var
  i: integer;
  sp,btn,divel,p: TJSHTMLElement;
begin
  if ElementHandle.childNodes.length > 0 then
  begin
    while Assigned(ElementHandle.firstChild) do
       ElementHandle.removeChild(ElementHandle.firstChild);
  end;

  sp := TJSHTMLElement(document.createElement('SPAN'));
  ElementHandle.appendChild(sp);

  for i := 0 to FSections.Count - 1 do
  begin
    btn := TJSHTMLElement(document.createElement('BUTTON'));
    btn.innerHTML := FSections.Items[i].Caption;
    btn['id'] := Name + '_' + inttostr(i);
    btn.setAttribute('class','accordion_'+Name);
    btn.addEventListener('click',@DoAccordionClick);
    divel := TJSHTMLElement(document.createElement('DIV'));
    divel.setAttribute('class','accordionpanel_'+Name);
    p := TJSHTMLElement(document.createElement('P'));
    p.style.SetProperty('user-select','text');
    p.innerHTML := FSections[i].Content;

    sp.appendChild(btn);
    sp.appendChild(divel);
    divel.appendChild(p);

    if Assigned(OnRenderSection) then
      OnRenderSection(Self, FSections.Items[i], btn, p);
  end;
end;

procedure TAccordion.RenderStyle;
var
  css: string;
begin
  if FStyleRendered then
    Exit;

  FStyleRendered := true;

  css :=
  '.accordion_'+ Name +' {'+
  'background-color: #eee;'+
  'color: #444;'+
  'cursor: pointer;'+
  'padding: 18px;'+
  'width: 100%;'+
  'text-align: left;'+
  'border: none;' +
  'outline: none;' +
  'transition: 0.4s;'+
  '}' +#13#10 +

  '.accordionactive_'+Name+', .accordion_'+Name+'::hover {'+
  'background-color: #ccc;'+
  '}' +#13#10 +

  '.accordionactive_'+Name+'::before {'+
  'transform: rotate(90deg);'+
  '}' +#13#10 +

  '.accordion_'+Name+'::before {'+
  '  content: "\25B6";'+
  '  font-size: 13px;'+
//  '  color: #777;'+
  '  display: inline-block;'+
  '  margin-right: 5px;'+
  '}' +#13#10 +

  '.accordionpanel_'+Name+' {'+
  'padding: 0 18px;'+
  'background-color: white;'+
  'max-height: 0;'+
  'overflow: hidden;'+
  'transition: max-height 0.2s ease-out;'+
  '}';

  AddInstanceStyle(css);
end;

procedure TAccordion.SetSections(const Value: TAccordionSections);
begin
  FSections.Assign(Value);
end;

procedure TAccordion.UpdateElement;
begin
  inherited;
end;

procedure TAccordion.UpdateElementVisual;
begin
  inherited;
  ElementHandle.style.setProperty('overflow-y','auto');
  ElementHandle.style.setProperty('overflow-x','hidden');
end;

{ TAccordionSections }

function TAccordionSections.Add: TAccordionSection;
begin
  Result := TAccordionSection(inherited Add);
end;

constructor TAccordionSections.Create(AOwner: TComponent);
begin
  inherited Create(AOwner, TAccordionSection);
end;

function TAccordionSections.GetItem(Index: integer): TAccordionSection;
begin
  Result := TAccordionSection(inherited Items[Index]);
end;

function TAccordionSections.Insert(Index: integer): TAccordionSection;
begin
  Result := TAccordionSection(inherited Insert(Index));
end;

procedure TAccordionSections.SetItem(Index: integer;
  const Value: TAccordionSection);
begin
  inherited Items[Index] := Value;
end;

{ TResponsiveGridPanel }

procedure TResponsiveGridPanel.AddControl(AControl: TWinControl);
begin
  FControlCollection.Add.Control := AControl;
  AControl.Parent := Self;
end;

procedure TResponsiveGridPanel.BindEvents;
begin
  inherited;
  window.addEventListener('resize', @HandleResize);
end;

constructor TResponsiveGridPanel.Create(AOwner: TComponent);
begin
  inherited;
end;

function TResponsiveGridPanel.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

procedure TResponsiveGridPanel.CreateInitialize;
begin
  inherited;
  FControlCollection := TControlCollection.Create(Self);
  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;

  FActiveLayoutItem := nil;
  FLayout := TResponsiveLayout.Create(Self);
end;

destructor TResponsiveGridPanel.Destroy;
begin
  FControlCollection.Free;
  FLayout.Free;
  inherited;
end;

procedure TResponsiveGridPanel.EndUpdate;
begin
  inherited;
  UpdateControls;
end;

function TResponsiveGridPanel.HandleResize(Event: TEventListenerEvent): boolean;
var
  w: integer;
begin
  Result := true;
  w := Width;
  if FOldWidth <> w then
  begin
    FOldWidth  := w;
    Resize;
  end;
end;

procedure TResponsiveGridPanel.RemoveControl(AControl: TWinControl);
var
  i: integer;

begin
  for i := 0 to FControlCollection.Count - 1 do
  begin
    if FControlCollection.Items[i].Control =  AControl then
    begin
      FControlCollection.Delete(i);
      break;
    end;
  end;
end;

procedure TResponsiveGridPanel.Resize;
begin
  inherited;
  SetResponsiveStyle;
end;

procedure TResponsiveGridPanel.SetControlCollection(
  const Value: TControlCollection);
begin
  FControlCollection.Assign(Value);
end;

procedure TResponsiveGridPanel.SetResponsiveStyle;
var
  li: TResponsiveLayoutItem;
begin
  li := Layout.GetLayoutForWidth(Width);

  if Assigned(li) then
  begin
    if li.StyleType = gTemplateColumns then
    begin
      ElementHandle.style.setProperty('grid-template-columns', li.Style);
      ElementHandle.style.removeProperty('grid-template-rows');
    end
    else
    begin
      ElementHandle.style.removeProperty('grid-template-columns');
      ElementHandle.style.setProperty('grid-template-rows', li.Style);
    end;

    ElementHandle.style.setProperty('grid-row-gap', li.RowGap);
    ElementHandle.style.setProperty('grid-column-gap', li.ColumnGap);

    ElementHandle.style.setProperty('margin-left', inttostr(li.Margins.Left)+'px');
    ElementHandle.style.setProperty('margin-top', inttostr(li.Margins.Top)+'px');
    ElementHandle.style.setProperty('margin-right', inttostr(li.Margins.Right)+'px');
    ElementHandle.style.setProperty('margin-bottom', inttostr(li.Margins.Bottom)+'px');

  end;

  if li <> FActiveLayoutItem then
  begin
    FActiveLayoutItem := li;
    if Assigned(OnLayoutChange) then
      OnLayoutChange(Self, FActiveLayoutItem);
  end;
end;

procedure TResponsiveGridPanel.UnbindEvents;
begin
  inherited;
  window.removeEventListener('resize', @HandleResize);
end;

procedure TResponsiveGridPanel.UpdateControls;
var
  i: integer;
  el: TJSHTMLElement;
  fragment: TJSDocumentFragment;
  control: TControl;
  ctrlid: string;
begin
  for i := 0 to ControlCollection.Count - 1 do
  begin
    el := TJSHTMLElement(document.createElement('DIV'));
    ElementHandle.appendChild(el);

    fragment := document.createDocumentFragment();
    control := ControlCollection.Items[i].Control;
    control.ElementPosition := epRelative;
    control.ChildOrder := -1;
    ctrlid := control.GetID;
    fragment.appendChild(document.getElementById(ctrlid));

    el.appendChild(fragment);
  end;
end;

procedure TResponsiveGridPanel.UpdateElement;
begin
  inherited;

  ElementHandle.style.setProperty('display','grid');

  SetResponsiveStyle;
end;

{ TLayout }

function TResponsiveLayout.Add: TResponsiveLayoutItem;
begin
  Result := TResponsiveLayoutItem(inherited Add);
end;

function TResponsiveLayout.Add(AWidth: integer;
  AStyle: string): TResponsiveLayoutItem;
begin
  Result := Add;
  Result.Width := AWidth;
  Result.Style := AStyle;
end;

constructor TResponsiveLayout.Create(AOwner: TComponent);
begin
  inherited Create(TResponsiveLayoutItem);
end;

function TResponsiveLayout.GetItem(Index: integer): TResponsiveLayoutItem;
begin
  Result := TResponsiveLayoutItem(inherited Items[Index]);
end;

function TResponsiveLayout.GetLayoutForWidth(w: integer): TResponsiveLayoutItem;
var
  i,d,j,l,mx: integer;

begin
  Result := nil;
  if Count = 0 then
    Exit;

  d := $FFFF;
  j := -1;
  mx := 0;

  for i := 0 to Count - 1 do
  begin
    if Items[i].Width > mx then
    begin
      mx := Items[i].Width;
      l := i;
    end;

    if w < Items[i].Width then
    begin
      if Items[i].Width - w < d then
      begin
        d := Items[i].Width - w;
        j := i;
      end;
    end;
  end;

  if j = -1 then
    j := l;

  Result := Items[j];
end;

function TResponsiveLayout.Insert(Index: integer): TResponsiveLayoutItem;
begin
  Result := TResponsiveLayoutItem(inherited Insert(Index));
end;

procedure TResponsiveLayout.SetItem(Index: integer; const Value: TResponsiveLayoutItem);
begin
  inherited Items[Index] := Value;
end;

{ TResponsiveLayoutItem }

procedure TResponsiveLayoutItem.Assign(Source: TPersistent);
begin
  if (Source is TResponsiveLayoutItem) then
  begin
    FColumnGap := (Source as TResponsiveLayoutItem).ColumnGap;
    FDescription := (Source as TResponsiveLayoutItem).Description;
    FRowGap := (Source as TResponsiveLayoutItem).RowGap;
    FWidth := (Source as TResponsiveLayoutItem).Width;
    FStyle := (Source as TResponsiveLayoutItem).Style;
    FStyleType := (Source as TResponsiveLayoutItem).StyleType;
    FTag := (Source as TResponsiveLayoutItem).Tag;
    FMargins.Assign((Source as TResponsiveLayoutItem).Margins);
  end;
end;

constructor TResponsiveLayoutItem.Create(AOwner: TCollection);
begin
  inherited;
  FMargins := TMargins.Create;

  FMargins.Left := 0;
  FMargins.Top := 0;
  FMargins.Right := 0;
  FMargins.Bottom := 0;
end;

destructor TResponsiveLayoutItem.Destroy;
begin
  FMargins.Free;
  inherited;
end;

procedure TResponsiveLayoutItem.SetMargins(const Value: TMargins);
begin
  FMargins.Assign(Value);
end;

{ TAccordionSection }

procedure TAccordionSection.Assign(Source: TPersistent);
begin
  if (Source is TAccordionSection) then
  begin
    FCaption := (Source as TAccordionSection).Caption;
    FContent := (Source as TAccordionSection).Content;
    FTag := (Source as TAccordionSection).Tag;
  end;
end;

function TAccordionSection.CaptionElement: TJSHTMLElement;
begin
  Result := TJSHTMLElement(document.getElementById(((Collection as TAccordionSections).Owner as TAccordion).Name+'_'+inttostr(Index)));
end;

function TAccordionSection.PanelElement: TJSHTMLElement;
var
  el: TJSHTMLElement;
begin
  el := CaptionElement;
  Result := TJSHTMLElement(el.nextElementSibling);
end;

procedure TAccordionSection.SetExpanded(const Value: boolean);
begin
  if (FExpanded <> Value) then
  begin
    FExpanded := Value;

    if FExpanded then
      ((Collection as TAccordionSections).Owner as TAccordion).Expand(Self)
    else
      ((Collection as TAccordionSections).Owner as TAccordion).Collapse(Self);
  end;
end;

end.
