{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2019                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Graphics;

{$modeswitch externalclass}

interface

uses
  Classes, Types, Web, JS;

const
  SysDefault = $20000000;

  clNone = -1;
  clBlack = $000000;
  clMaroon = $000080;
  clGreen = $008000;
  clOlive = $008080;
  clNavy = $800000;
  clPurple = $800080;
  clTeal = $008080;
  clGray = $808080;
  clSilver = $C0C0C0;
  clRed = $0000FF;
  clLime = $00FF00;
  clYellow = $00FFFF;
  clBlue = $FF0000;
  clFuchsia = $FF00FF;
  clAqua = $FFFF00;
  clLtGray = $C0C0C0;
  clDkGray = $808080;
  clWhite = $FFFFFF;
  clDefault = SysDefault;
  clBtnFace = $F0F0F0;
  clWindowText = clBlack;
  clWindow = clWhite;
  clHighlight = $D77800;
  clHighlightText = clWhite;
  clInfoText = clBlack;
  clInfoBk = $E1FFFF;
  clActiveCaption = $D1B499;
  clInactiveCaption =  $E2C5AA;
  clHotLight = $CC6600;

  clMoneyGreen = $C0DCC0;
  clSkyBlue = $F0CAA6;
  clCream = $F0FBFF;
  clMedGray = $A0A0A0;

  clWebAliceblue = $FFF8F0;
  clWebAntiquewhite = $D7EBFA;
  clWebAqua = $FFFF00;
  clWebAquamarine = $D4FF7F;
  clWebAzure = $FFFFF0;
  clWebBeige = $DCF5F5;
  clWebBisque = $C4E4FF;
  clWebBlack = $000000;
  clWebBlanchedalmond = $CDEBFF;
  clWebBlue = $FF0000;
  clWebBlueviolet = $E22B8A;
  clWebBrown = $2A2AA5;
  clWebBurlywood = $87B8DE;
  clWebCadetblue = $A09E5F;
  clWebChartreuse = $00FF7F;
  clWebChocolate = $1E69D2;
  clWebCoral = $507FFF;
  clWebCornflowerblue = $ED9564;
  clWebCornsilk = $DCF8FF;
  clWebCrimson = $3C14DC;
  clWebCyan = $FFFF00;
  clWebDarkblue = $8B0000;
  clWebDarkcyan = $8B8B00;
  clWebDarkgoldenrod = $0B86B8;
  clWebDarkgray = $A9A9A9;
  clWebDarkgreen = $006400;
  clWebDarkgrey = $A9A9A9;
  clWebDarkkhaki = $6BB7BD;
  clWebDarkmagenta = $8B008B;
  clWebDarkolivegreen = $2F6B55;
  clWebDarkorange = $008CFF;
  clWebDarkorchid = $CC3299;
  clWebDarkred = $00008B;
  clWebDarksalmon = $7A96E9;
  clWebDarkseagreen = $8FBC8F;
  clWebDarkslateblue = $8B3D48;
  clWebDarkslategray = $4F4F2F;
  clWebDarkslategrey = $4F4F2F;
  clWebDarkturquoise = $D1CE00;
  clWebDarkviolet = $D30094;
  clWebDeeppink = $9314FF;
  clWebDeepskyblue = $FFBF00;
  clWebDimgray = $696969;
  clWebDimgrey = $696969;
  clWebDodgerblue = $FF901E;
  clWebFirebrick = $2222B2;
  clWebFloralwhite = $F0FAFF;
  clWebForestgreen = $228B22;
  clWebFuchsia = $FF00FF;
  clWebGainsboro = $DCDCDC;
  clWebGhostwhite = $FFF8F8;
  clWebGold = $00D7FF;
  clWebGoldenrod = $20A5DA;
  clWebGray = $808080;
  clWebGreen = $008000;
  clWebGreenyellow = $2FFFAD;
  clWebGrey = $808080;
  clWebHoneydew = $F0FFF0;
  clWebHotpink = $B469FF;
  clWebIndianred = $5C5CCD;
  clWebIndigo = $82004B;
  clWebIvory = $F0FFFF;
  clWebKhaki = $8CE6F0;
  clWebLavender = $FAE6E6;
  clWebLavenderblush = $F5F0FF;
  clWebLawngreen = $00FC7C;
  clWebLemonchiffon = $CDFAFF;
  clWebLightblue = $E6D8AD;
  clWebLightcoral = $8080F0;
  clWebLightcyan = $FFFFE0;
  clWebLightgoldenrodyellow = $D2FAFA;
  clWebLightgray = $D3D3D3;
  clWebLightgreen = $90EE90;
  clWebLightgrey = $D3D3D3;
  clWebLightpink = $C1B6FF;
  clWebLightsalmon = $7AA0FF;
  clWebLightseagreen = $AAB220;
  clWebLightskyblue = $FACE87;
  clWebLightslategray = $998877;
  clWebLightslategrey = $998877;
  clWebLightsteelblue = $DEC4B0;
  clWebLightyellow = $E0FFFF;
  clWebLtGray = $C0C0C0;
  clWebMedGray = $A4A0A0;
  clWebDkGray = $808080;
  clWebMoneyGreen = $C0DCC0;
  clWebLegacySkyBlue = $F0CAA6;
  clWebCream = $F0FBFF;
  clWebLime = $00FF00;
  clWebLimegreen = $32CD32;
  clWebLinen = $E6F0FA;
  clWebMagenta = $FF00FF;
  clWebMaroon = $000080;
  clWebMediumaquamarine = $AACD66;
  clWebMediumblue = $CD0000;
  clWebMediumorchid = $D355BA;
  clWebMediumpurple = $DB7093;
  clWebMediumseagreen = $71B33C;
  clWebMediumslateblue = $EE687B;
  clWebMediumspringgreen = $9AFA00;
  clWebMediumturquoise = $CCD148;
  clWebMediumvioletred = $8515C7;
  clWebMidnightblue = $701919;
  clWebMintcream = $FAFFF5;
  clWebMistyrose = $E1E4FF;
  clWebMoccasin = $B5E4FF;
  clWebNavajowhite = $ADDEFF;
  clWebNavy = $800000;
  clWebOldlace = $E6F5FD;
  clWebOlive = $008080;
  clWebOlivedrab = $238E6B;
  clWebOrange = $00A5FF;
  clWebOrangered = $0045FF;
  clWebOrchid = $D670DA;
  clWebPalegoldenrod = $AAE8EE;
  clWebPalegreen = $98FB98;
  clWebPaleturquoise = $EEEEAF;
  clWebPalevioletred = $9370DB;
  clWebPapayawhip = $D5EFFF;
  clWebPeachpuff = $B9DAFF;
  clWebPeru = $3F85CD;
  clWebPink = $CBC0FF;
  clWebPlum = $DDA0DD;
  clWebPowderblue = $E6E0B0;
  clWebPurple = $800080;
  clWebRed = $0000FF;
  clWebRosybrown = $8F8FBC;
  clWebRoyalblue = $E16941;
  clWebSaddlebrown = $13458B;
  clWebSalmon = $7280FA;
  clWebSandybrown = $60A4F4;
  clWebSeagreen = $578B2E;
  clWebSeashell = $EEF5FF;
  clWebSienna = $2D52A0;
  clWebSilver = $C0C0C0;
  clWebSkyblue = $EBCE87;
  clWebSlateblue = $CD5A6A;
  clWebSlategray = $908070;
  clWebSlategrey = $908070;
  clWebSnow = $FAFAFF;
  clWebSpringgreen = $7FFF00;
  clWebSteelblue = $B48246;
  clWebTan = $8CB4D2;
  clWebTeal = $808000;
  clWebThistle = $D8BFD8;
  clWebTomato = $4763FF;
  clWebTurquoise = $D0E040;
  clWebViolet = $EE82EE;
  clWebWheat = $B3DEF5;
  clWebWhite = $FFFFFF;
  clWebWhitesmoke = $F5F5F5;
  clWebYellow = $00FFFF;
  clWebYellowgreen = $32CD9A;

  ANSI_CHARSET = 0;
  DEFAULT_CHARSET = 1;
  SYMBOL_CHARSET = 2;
  MAC_CHARSET = 77;
  SHIFTJIS_CHARSET = 128;
  HANGEUL_CHARSET = 129;
  JOHAB_CHARSET = 130;
  GB2312_CHARSET = 134;
  CHINESEBIG5_CHARSET = 136;
  GREEK_CHARSET = 161;
  TURKISH_CHARSET = 162;
  HEBREW_CHARSET = 177;
  ARABIC_CHARSET = 178;
  BALTIC_CHARSET = 186;
  RUSSIAN_CHARSET = 204;
  THAI_CHARSET = 222;
  EASTEUROPE_CHARSET = 238;
  OEM_CHARSET = 255;

type
  Single = Double;
  TColor = type NativeInt;

  TVerticalAlignment = (taAlignTop, taAlignBottom, taVerticalCenter);

  TPenStyle = (psSolid, psDash, psDot, psDashDot, psDashDotDot, psClear,
    psInsideFrame, psUserStyle, psAlternate);

  TBrushStyle = (bsSolid, bsClear, bsHorizontal, bsVertical,
    bsFDiagonal, bsBDiagonal, bsCross, bsDiagCross);

  TFontStyle = (fsBold, fsItalic, fsStrikeOut, fsUnderline);

  TFontStyles = set of TFontStyle;

  TFontCharset = 0..255;

  TCanvasPointF = record
    X: Double;
    Y: Double;
  end;

  TCanvasRectF = record
    Left, Top, Right, Bottom: Double;
  end;

  TCanvasSizeF = record
    cx: Double;
    cy: Double;
  end;
  
  TPen = class(TPersistent)
  private
    FWidth: Integer;
    FColor: TColor;
    FStyle: TPenStyle;
    procedure SetColor(const Value: TColor);
  public
    constructor Create; reintroduce;
    procedure Assign(Source: TPersistent); override;
  published
    property Color: TColor read FColor write SetColor;
    property Width: Integer read FWidth write FWidth;
    property Style: TPenStyle read FStyle write FStyle;
  end;

  TBrush = class(TPersistent)
  private
    FColor: TColor;
    FStyle: TBrushStyle;
  public
    constructor Create; reintroduce;
    procedure Assign(Source: TPersistent); override;
  published
    property Color: TColor read FColor write FColor;
    property Style: TBrushStyle read FStyle write FStyle;
  end;

  TFont = class(TPersistent)
  private
    FName: string;
    FSize: Integer;
    FColor: TColor;
    FStyle: TFontStyles;
    FOnChange: TNotifyEvent;
    FHeight: Integer;
    FCharset: TFontCharset;
    procedure SetHeight(const Value: Integer);
  protected
    procedure SetName(const AName: string);
    procedure SetSize(const ASize: Integer);
    procedure SetColor(const AColor: TColor);
    procedure SetStyle(const AStyle: TFontStyles);
    procedure DoChange; virtual;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create; reintroduce;
    function ToString: string; override;
  published
    property Charset: TFontCharset read FCharset write FCharset;
    property Name: string read FName write SetName;
    property Height: Integer read FHeight write SetHeight;
    property Style: TFontStyles read FStyle write SetStyle;
    property Color: TColor read FColor write SetColor;
    property Size: Integer read FSize write SetSize;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TCanvas = class;

  TStream = TObject;

  TBitmap = class;

  TGraphic = class(TObject)
  private
    FAddToQueue: Boolean;
    FCanvasElement: TJSHTMLCanvasElement;
    FEmpty: Boolean;
    FData: string;
    FCanvas: TCanvas;
    FOnChange: TNotifyEvent;
    FImage: TJSObject;
    FBitmap: TBitmap;
    FURL: String;
    procedure SetHeight(const Value: Integer);
    procedure SetWidth(const Value: Integer);
    function GetCanvas: TCanvas;
  protected
    procedure DoChange;
    procedure SetURL(const URL: string);
    function GetWidth: Integer;
    function GetHeight: Integer;
    procedure RecreateCanvas;
    procedure DoBeginScene(Sender: TObject);
    procedure DoEndScene(Sender: TObject);
    procedure AssignEvents;
    procedure CreateImage;
    procedure LoadFromCache(AData: String);
  public
    class function CreateFromResource(AResource: String): TGraphic; overload;
    class function CreateFromResource(AResource: String; AInstance: NativeUInt): TGraphic; overload;
    class function CreateFromURL(AURL: String): TGraphic; overload;
    class function CreateFromURL(AURL: String; AInstance: NativeUInt): TGraphic; overload;
    function Image: TJSObject;
    function Empty: Boolean;
    function GetBase64Image: string;
    constructor Create(URL: string); overload;
    constructor Create(Img: TJSObject); overload;
    constructor Create; overload; reintroduce;
    property Width: Integer read GetWidth write SetWidth;
    property Height: Integer read GetHeight write SetHeight;
    procedure CaptureCanvas; virtual;
    procedure LoadFromCanvas(ACanvas: TCanvas); virtual;
    procedure SetSize(AWidth, AHeight: Integer);
    procedure Assign(Source: TGraphic); virtual;
    procedure LoadFromURL(AURL: string); virtual; overload;
    procedure LoadFromURL(AURL: string; AHInstance: Integer); virtual; overload;
    procedure LoadFromFile(AFileName: string); virtual;
    procedure LoadFromResource(AResource: string); virtual; overload;
    procedure LoadFromResource(AResource: string; AHInstance: Integer); virtual; overload;
    procedure LoadFromStream(AStream: TStream);
    property Canvas: TCanvas read GetCanvas;
    property Bitmap: TBitmap read FBitmap;
  published
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property URL: string read FURL write SetURL;
  end;

  TBitmap = class(TGraphic)
  end;

  TCanvas = class(TObject)
  private
    FElementCanvas: TJSHTMLCanvasElement;
    FContext: TJSCanvasRenderingContext2D;
    FPen: TPen;
    FBrush: TBrush;
    FFont: TFont;
    FPathOpen: boolean;
    FPathX, FPathY: Double;
    FClipRect: TCanvasRectF;
    FOnEndScene: TNotifyEvent;
    FOnBeginScene: TNotifyEvent;
    procedure SetClipRect(const Value: TCanvasRectF);
  protected
    procedure GetAccuOffset(X,Y: double; var dx,dy: single);
    function GetPixel(X,Y: Single): TColor;
    procedure SetPixel(X,Y: Single; Clr: TColor);
    procedure ApplyStroke;
    procedure ApplyFill;
    property OnBeginScene: TNotifyEvent read FOnBeginScene write FOnBeginScene;
    property OnEndScene: TNotifyEvent read FOnEndScene write FOnEndScene;
  public
    constructor Create(AControl: TJSHTMLCanvasElement); overload; virtual;
    constructor Create; overload; reintroduce;
    destructor Destroy; override;
    procedure SetTransform(m11, m12, m21, m22, dx, dy: Double);
    procedure Transform(m11, m12, m21, m22, dx, dy: Double);
    procedure Rotate(Angle: Double);
    procedure Translate(X, Y: Double);
    procedure AngleArc(X, Y: Integer; Radius: Cardinal; StartAngle, SweepAngle: Double); overload;
    procedure AngleArc(X, Y: Double; Radius: Double; StartAngle, SweepAngle: Double); overload;
    procedure MoveTo(X, Y: Integer); overload;
    procedure MoveTo(X, Y: Double); overload;
    procedure LineTo(X, Y: Integer); overload;
    procedure LineTo(X, Y: Double); overload;
    procedure Rectangle(X1, Y1, X2, Y2: Double); overload;
    procedure Rectangle(X1, Y1, X2, Y2: Integer); overload;
    procedure Rectangle(const Rect: TRect); overload;
    procedure Rectangle(const Rect: TCanvasRectF); overload;
    procedure RoundRect(X1, Y1, X2, Y2, X3, Y3: Integer); overload;
    procedure RoundRect(X1, Y1, X2, Y2, X3, Y3: Double); overload;
    procedure RoundRect(const Rect: TRect; CX, CY: Integer); overload;
    procedure RoundRect(const Rect: TCanvasRectF; CX, CY: Double); overload;
    procedure FillRect(const Rect: TRect); overload;
    procedure FillRect(const Rect: TCanvasRectF); overload;
    procedure Ellipse(X1, Y1, X2, Y2: Integer); overload;
    procedure Ellipse(X1, Y1, X2, Y2: Double); overload;
    procedure Ellipse(const Rect: TRect); overload;
    procedure Ellipse(const Rect: TCanvasRectF); overload;
    procedure Polyline(const Points: array of TPoint); overload;
    procedure Polyline(const Points: array of TCanvasPointF); overload;
    procedure Polygon(const Points: array of TPoint); overload;
    procedure Polygon(const Points: array of TCanvasPointF); overload;
    procedure TextOut(X, Y: Integer; const Text: string); overload;
    procedure TextOut(X, Y: Double; const Text: string); overload;
    procedure Draw(X, Y: Integer; Graphic: TGraphic); overload;
    procedure Draw(X, Y: Double; Graphic: TGraphic); overload;
    procedure StretchDraw(Rect: TRect; Graphic: TGraphic); overload;
    procedure StretchDraw(Rect: TCanvasRectF; Graphic: TGraphic); overload;
    procedure DrawFocusRect(const Rect: TRect); overload;
    procedure DrawFocusRect(const Rect: TCanvasRectF); overload;
    procedure BeginScene;
    procedure EndScene;
    procedure Save;
    procedure Clip;
    procedure Restore;
    procedure Refresh;
    procedure Clear; overload;
    procedure Clear(AColor: TColor); overload;
    function TextExtent(const Text: string): TCanvasSizeF;
    function TextRect(ARect: TRect; const Text: String; const WordWrap: Boolean = False; const Calculate: Boolean = False; const AHorizontalAlignment: TAlignment = taLeftJustify; const AVerticalAlignment: TVerticalAlignment = taAlignTop): TRect; overload;
    function TextRect(ARect: TCanvasRectF; const Text: String; const WordWrap: Boolean = False; const Calculate: Boolean = False; const AHorizontalAlignment: TAlignment = taLeftJustify; const AVerticalAlignment: TVerticalAlignment = taAlignTop): TCanvasRectF; overload;
    function TextWidth(const Text: string): Double; overload;
    function TextHeight(const Text: string): Double; overload;
    function GetBase64Image: string;
    property Element: TJSHTMLCanvasElement read FElementCanvas;
    property Pen: TPen read FPen;
    property Brush: TBrush read FBrush;
    property Font: TFont read FFont;
    property Pixels[X, Y: Single]: TColor read GetPixel write SetPixel;
    property ClipRect: TCanvasRectF read FClipRect write SetClipRect;
    property Context: TJSCanvasRenderingContext2D read FContext;
  end;

  TJSCSSStyleDeclarationEx = class(TJSCSSStyleDeclaration)
  public
    filter: string;
  end;

  TJSHTMLElementEx = class(TJSHTMLElement)
  private
    FStyle: TJSCSSStyleDeclarationEx; external name 'style';
  public
  property style: TJSCSSStyleDeclarationEx read FStyle write FStyle;
  end;

  TJSCanvasRenderingContext2DEx = class(TJSCanvasRenderingContext2D)
  private
    FFilter: string; external name 'filter';
  public
    property filter: string read FFilter write FFilter;
  end;

function ColorToRGB(Color: TColor): Longint;
function ColorToHex(c: TColor): string;
function ColorToHTML(c: TColor): string;
function FontSizeToHTML(sz: Double): string;
function HTMLChar(h: string): integer;
function HexToColor(h: string): TColor;
function FontSizeToPx(sz: Double): Double;
function GetRValue(rgb: NativeInt): Byte;
function GetGValue(rgb: NativeInt): Byte;
function GetBValue(rgb: NativeInt): Byte;
function CreateCanvasRectF(Left, Top, Right, Bottom: Double): TCanvasRectF;
function CreateCanvasPointF(X, Y: Double): TCanvasPointF;
function CreateCanvasSizeF(cx, cy: Double): TCanvasSizeF;
function RGB(r, g, b: Byte): TColor;
function CSSFont(Font: TFont): string;

implementation

uses
  WEBLib.Forms, Math, SysUtils, Contnrs;

type
  TGraphicCache = class
  private
    FImage: TJSObject;
    FID: string;
  public
    constructor Create(AImage: TJSObject; AID: string); reintroduce;
    property Image: TJSObject read FImage;
    property ID: string read FID;
  end;

  TGraphicCacheList = class(TObjectList)
  public
    function Find(AID: string; var FImage: TJSObject): Boolean; virtual;
    function Exists(AID: string): Boolean; virtual;
  end;

var
  FCache: TGraphicCacheList;
  FQueue: TStringList;
  FCacheCount: Integer = 0;

function RGB(r, g, b: Byte): TColor;
begin
  Result := (r or (g shl 8) or (b shl 16));
end;

function CSSFont(Font: TFont): string;
var
  res,fs: string;
begin
  res := 'font-family:'+ Font.Name+';';
  res := res + 'font-style: normal;';

  if fsBold in Font.Style then
    res := res + 'font-weight: bold;';

  if fsItalic in Font.Style then
    res := res + 'font-style: italic;';

  fs := '';

  if fsUnderline in Font.Style then
    fs := fs + ' underline';

  if fsStrikeOut in Font.Style then
     fs:= fs + ' line-through';

  if (fs <> '') then
    res := res + 'text-decoration:' + fs+';';

  res := res + 'font-size:'+ IntToStr(Font.Size) + 'pt;';

  Result := res;
end;


function CreateCanvasRectF(Left, Top, Right, Bottom: Double): TCanvasRectF;
begin
  Result.Left := Left;
  Result.Top := Top;
  Result.Right := Right;
  Result.Bottom := Bottom;
end;

function CreateCanvasPointF(X, Y: Double): TCanvasPointF;
begin
  Result.X := X;
  Result.Y := Y;
end;

function CreateCanvasSizeF(cx, cy: Double): TCanvasSizeF;
begin
  Result.cx := cx;
  Result.cy := cy;
end;

function GetRValue(rgb: NativeInt): Byte;
begin
  Result := Byte(rgb and $FF);
end;

function GetGValue(rgb: NativeInt): Byte;
begin
  Result := Byte((rgb shr 8) and $FF);
end;

function GetBValue(rgb: NativeInt): Byte;
begin
  Result := Byte((rgb shr 16) and $FF);
end;

function ColorToRGB(Color: TColor): Longint;
begin
  Result := Color;
end;

function ColorToHex(c: TColor): string;
var
  s: string;
begin
  asm
    s = c.toString(16);

    while (s.length < 6)
    {
      s = "0" + s;
    }
  end;

  Result := Copy(s,5,2) + Copy(s,3,2) + Copy(s,1,2);
end;

function ColorToHTML(c: TColor): string;
begin
  Result := '#' + ColorToHex(c);
end;

function HexToColor(h: string): TColor;
var
  s: string;
begin
  h := StringReplace(h, '#', '', []);
  h := StringReplace(h, '$', '', []);
  s := '$' + Copy(h,5,2) + Copy(h,3,2) + Copy(h,1,2);
  Result := StrToInt64(s);
end;

function HTMLChar(h: string): integer;
begin
  // fmt &#xABCD;
  Delete(h,1,3);
  Delete(h,Length(h) - 1, 1);
  Result := StrToInt('0x' + h);
end;

function FontSizeToHTML(sz: Double): string;
begin
  Result := FloatToStr(sz)+'px';
  Result := StringReplace(Result, ',', '.', [rfReplaceAll]);
end;

{ TPen }

procedure TPen.Assign(Source: TPersistent);
begin
  if (Source is TPen) then
  begin
    FColor := (Source as TPen).Color;
    FStyle := (Source as TPen).Style;
    FWidth := (Source as TPen).Width;
  end;
end;

constructor TPen.Create;
begin
  FColor := clBlack;
  FWidth := 1;
  FStyle := psSolid;
end;

procedure TPen.SetColor(const Value: TColor);
begin
  FColor := Value;
end;

{ TBrush }

procedure TBrush.Assign(Source: TPersistent);
begin
  if (Source is TBrush) then
  begin
    FColor := (Source as TBrush).Color;
    FStyle := (Source as TBrush).Style;
  end;
end;

function FontSizeToPx(sz: Double): Double;
begin
  Result := sz * 96 / 72;
end;

constructor TBrush.Create;
begin
  FColor := clWhite;
  FStyle := bsSolid;
end;

{ TFont }

procedure TFont.Assign(Source: TPersistent); 
begin
  if (Source is TFont) then
  begin
    FName := (Source as TFont).Name;
    FColor := (Source as TFont).Color;
    FSize := (Source as TFont).Size;
    FStyle := (Source as TFont).Style;
  end;
end;

function TFont.ToString: string;
var
  s: string;
begin
  s := '';
  if (TFontStyle.fsBold in Style) and (TFontStyle.fsItalic in Style) then
    s := s + 'bold italic'
  else if TFontStyle.fsBold in Style then
    s := s + 'bold'
  else if TFontStyle.fsItalic in Style then
    s := s + 'italic';

  Result := s + ' ' + FontSizeToHTML(FontSizeToPx(Size)) + ' ' + Name;
end;

procedure TFont.SetName(const AName: string);
begin
  if (FName <> AName) then
  begin
    FName := AName;
    DoChange;
  end;
end;

procedure TFont.SetSize(const ASize: Integer);
begin
  if (FSize <> ASize) then
  begin
    FSize := ASize;
    DoChange;
  end;
end;

procedure TFont.SetColor(const AColor: TColor);
begin
  if (FColor <> AColor) then
  begin
    FColor := AColor;
    DoChange;
  end;
end;

procedure TFont.SetHeight(const Value: Integer);
var
  d: double;
begin
  FHeight := Value;
  d := -FHeight * 72/96;
  FSize := Round(d);
  DoChange;
end;

procedure TFont.SetStyle(const AStyle: TFontStyles);
begin
  FStyle := AStyle;
  DoChange;
end;

constructor TFont.Create;
begin
  inherited;
  FName := 'Tahoma';
  FSize := 8;
  FStyle := [];
  FColor := clBlack;
end;

procedure TFont.DoChange;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

{ TCanvas }

procedure TCanvas.AngleArc(X, Y, Radius: Double; StartAngle,
  SweepAngle: Double);
begin
  if Assigned(FContext) then
  begin
    FContext.beginPath();
    ApplyStroke;
    FContext.arc(X, Y, Radius, StartAngle, StartAngle + SweepAngle);
    FContext.stroke();
  end;
end;

procedure TCanvas.ApplyFill;
begin
  if Assigned(FContext) then
    FContext.fillStyle := ColorToHtml(Brush.Color);
end;

procedure TCanvas.ApplyStroke;
begin
  if Assigned(FContext) then
  begin
    FContext.lineWidth := Pen.Width;
    FContext.strokeStyleAsColor := ColorToHtml(Pen.Color);
    case Pen.Style of
      psSolid: FContext.setlinedash([]);
      psDot: FContext.setlinedash([1,2]);
      psDash: FContext.setlinedash([8,2]);
      psDashDot: FContext.setlinedash([6,2,2,2]);
      psDashDotDot: FContext.setlinedash([6,2,2,2,2,2]);
      psClear: FContext.setlinedash([0, $FFFF]);
    end;
  end;
end;

procedure TCanvas.BeginScene;
begin
  if Assigned(OnBeginScene) then
    OnBeginScene(Self);
end;

procedure TCanvas.Clear;
begin
  if Assigned(FContext) and Assigned(FElementCanvas) then
    FContext.clearRect(0, 0, FElementCanvas.width, FElementCanvas.height);
end;

procedure TCanvas.Clear(AColor: TColor);
var
  c: TColor;
  s: TBrushStyle;
begin
  Clear;
  if Assigned(FElementCanvas) then
  begin
    c := Brush.Color;
    s := Brush.Style;
    Brush.Color := AColor;
    Brush.Style := bsSolid;
    FillRect(Rect(0, 0, FElementCanvas.width, FElementCanvas.height));
    Brush.Color := c;
    Brush.Style := s;
  end;
end;

procedure TCanvas.Clip;
begin
  if Assigned(FContext) then
    FContext.clip;
end;

constructor TCanvas.Create;
begin
  FElementCanvas := TJSHTMLCanvasElement(document.createElement('CANVAS'));
  Create(FElementCanvas);
end;

constructor TCanvas.Create(AControl: TJSHTMLCanvasElement);
begin
  FElementCanvas := AControl;
  FContext := AControl.getContextAs2DContext('2d');
  FPen := TPen.Create;
  FBrush := TBrush.Create;
  FPathOpen := false;
  FFont := TFont.Create;
end;

procedure TCanvas.AngleArc(X, Y: Integer; Radius: Cardinal; StartAngle, SweepAngle: Double);
begin
  AngleArc(Double(X), Double(Y), Double(Radius), Double(StartAngle), Double(SweepAngle));
end;

procedure TCanvas.MoveTo(X,Y: Integer);
begin
  MoveTo(Double(X), Double(Y));
end;

procedure TCanvas.LineTo(X,Y: Integer);
begin
  LineTo(Double(X), Double(Y));
end;

procedure TCanvas.Rectangle(const Rect: TRect);
begin
  Rectangle(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom);
end;

procedure TCanvas.Rectangle(X1, Y1, X2, Y2: Double);
var
  dx,dy: single;
begin
  if Assigned(FContext) then
  begin
    FContext.beginPath();
    ApplyStroke;
    ApplyFill;

    GetAccuOffset(X1,Y1,dx,dy);

    FContext.rect(X1+dx, Y1+dy, X2 - X1, Y2 - Y1);
    if Brush.Style <> bsClear then
      FContext.fill();
    if Pen.Style <> psClear then
      FContext.stroke();
  end;
end;

procedure TCanvas.Rectangle(const Rect: TCanvasRectF);
begin
  Rectangle(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom);
end;

procedure TCanvas.Refresh;
begin
end;

procedure TCanvas.Restore;
begin
  if Assigned(FContext) then
    FContext.restore();
end;

procedure TCanvas.Rectangle(X1, Y1, X2, Y2: Integer);
begin
  Rectangle(Double(X1), Double(Y1), Double(X2), Double(Y2));
end;

procedure TCanvas.FillRect(const Rect: TRect);
begin
  Rectangle(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom);
end;

procedure TCanvas.RoundRect(X1,Y1,X2,Y2,X3,Y3: Integer);
begin
  RoundRect(Double(X1), Double(Y1), Double(X2), Double(Y2), Double(X3), Double(Y3));
end;

procedure TCanvas.RoundRect(const Rect: TRect; CX, CY: Integer);
begin
  RoundRect(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom, CX, CY);
end;

procedure TCanvas.RoundRect(X1, Y1, X2, Y2, X3, Y3: Double);
var
  dx,dy: single;
begin
  if Assigned(FContext) then
  begin
    FContext.beginPath();
    ApplyStroke;
    ApplyFill;

    GetAccuOffset(X1, Y1, dx, dy);

    FContext.moveTo(X1 + X3/2, Y1+dy);
    FContext.lineTo(X2 - X3/2, Y1+dy);
    FContext.quadraticCurveTo(X2+dx,Y1+dy, X2+dx, Y1 + Y3/2);
    FContext.lineTo(X2 +dx, Y2 - Y3/2);
    FContext.quadraticCurveTo(X2+dx, Y2+dy, X2 - X3/2, Y2+dy);
    FContext.lineTo(X1 + X3/2, Y2+dy);
    FContext.quadraticCurveTo(X1+dx, Y2+dy, X1+dx, Y2 - Y3/2);
    FContext.lineTo(X1+dx, Y1 + Y3/2);
    FContext.quadraticCurveTo(X1+dx, Y1+dy, X1 + X3/2, Y1+dy);

    if Brush.Style <> bsClear then
      FContext.fill();

    if Pen.Style <> psClear then
      FContext.stroke();
  end;
end;

procedure TCanvas.Ellipse(X1, Y1, X2, Y2: Integer);
begin
  Ellipse(Double(X1), Double(Y1), Double(X2), Double(Y2));
end;

procedure TCanvas.Ellipse(const Rect: TRect); 
begin
  Ellipse(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom);
end;

procedure TCanvas.Polyline(const Points: array of TPoint);
var
  l, i: Integer;
begin
  if Assigned(FContext) then
  begin
    l := Length(Points);

    FContext.beginPath();
    ApplyStroke;
    i := 0;
    FContext.moveTo(Points[i].X, Points[i].Y);

    while (i < l - 1) do
    begin
      inc(i);
      FContext.lineTo(Points[i].X, Points[i].Y);
    end;

    if Pen.Style <> psClear then
      FContext.stroke();
  end;
end;

procedure TCanvas.Polygon(const Points: array of TPoint);
var
  l, i: Integer;
begin
  if Assigned(FContext) then
  begin
    l := Length(Points);

    FContext.beginPath();
    ApplyStroke;
    ApplyFill;
    i := 0;
    FContext.moveTo(Points[i].X, Points[i].Y);

    while (i < l - 1) do
    begin
      inc(i);
      FContext.lineTo(Points[i].X, Points[i].Y);
    end;

    FContext.closePath();

    if Brush.Style <> bsClear then
      FContext.fill();

    if Pen.Style <> psClear then
      FContext.stroke();
  end;
end;

function TCanvas.TextExtent(const Text: string): TCanvasSizeF;
begin
  Result.cx := TextWidth(Text);
  Result.cy := TextHeight(Text);
end;

function TCanvas.TextHeight(const Text: string): Double;
begin
  Result := FontSizeToPx(Font.Size);
end;

procedure TCanvas.MoveTo(X, Y: Double);
var
  dx,dy: single;
begin
  if Assigned(FContext) then
  begin
    FPathOpen := true;
    FContext.beginPath();
    ApplyStroke;

    dx := 0;
    dy := 0;

    if (Pen.Width = 1) and (Pen.Style <> psClear) and (Frac(X) = 0) then
      dx := 0.5;

    if (Pen.Width = 1) and (Pen.Style <> psClear) and (Frac(Y) = 0) then
      dy := 0.5;

    FContext.moveTo(X + dx,Y + dy);
  end;
end;

procedure TCanvas.TextOut(X, Y: Double; const Text: string);
var
  tm: TJSTextMetrics;
begin
  if Assigned(FContext) then
  begin
    FContext.fillStyle := ColorToHtml(Font.Color);
    FContext.font := Font.ToString;
    FContext.textBaseline := 'hanging';
    FContext.fillText(Text, X, Y + 0.5);

    if fsUnderline in Font.Style then
    begin
      tm := FContext.measureText(Text);
      FContext.fillRect(X, Y + Font.Size *1.4, tm.width, 1);
    end;

    if fsStrikeOut in Font.Style then
    begin
      tm := FContext.measureText(Text);
      FContext.fillRect(X, Y + Font.Size *0.7, tm.width, 1);
    end;

  end;
end;

function TCanvas.TextRect(ARect: TCanvasRectF; const Text: String; const WordWrap: Boolean = False; const Calculate: Boolean = False; const AHorizontalAlignment: TAlignment = taLeftJustify; const AVerticalAlignment: TVerticalAlignment = taAlignTop): TCanvasRectF;
var
  i: Integer;
  s, sn, st: string;
  l: Integer;
  w, mw: Double;
  f: Boolean;
  p: Integer;
  tw: Double;
  th: Double;
  lcnt: Integer;
  rs: TCanvasRectF;
  fws: Double;
  ths: Double;
  ww, wwx, fx: Boolean;

  procedure DrawText(AText: string; AWidth, AHeight: Single);
  begin
    if ww then
    begin
      case AHorizontalAlignment of
        taCenter: TextOut(ARect.Left + (ARect.Right - ARect.Left - AWidth) / 2, ARect.Top, AText);
        taLeftJustify: TextOut(ARect.Left, ARect.Top, AText);
        taRightJustify: TextOut(ARect.Right - AWidth, ARect.Top, AText);
      end;
    end
    else
    begin
      case AHorizontalAlignment of
        taCenter:
        begin
          case AVerticalAlignment of
            taAlignTop: TextOut(ARect.Left + (ARect.Right - ARect.Left - AWidth) / 2, ARect.Top, AText);
            taVerticalCenter: TextOut(ARect.Left + (ARect.Right - ARect.Left - AWidth) / 2, ARect.Top + (ARect.Bottom - ARect.Top - AHeight) / 2, AText);
            taAlignBottom: TextOut(ARect.Left + (ARect.Right - ARect.Left - AWidth) / 2, ARect.Bottom - AHeight, AText);
          end;
        end;
        taLeftJustify:
        begin
          case AVerticalAlignment of
            taAlignTop: TextOut(ARect.Left, ARect.Top, AText);
            taVerticalCenter: TextOut(ARect.Left, ARect.Top + (ARect.Bottom - ARect.Top - AHeight) / 2, AText);
            taAlignBottom: TextOut(ARect.Left, ARect.Bottom - AHeight, AText);
          end;
        end;
        taRightJustify:
        begin
          case AVerticalAlignment of
            taAlignTop: TextOut(ARect.Right - AWidth, ARect.Top, AText);
            taVerticalCenter: TextOut(ARect.Right - AWidth, ARect.Top + (ARect.Bottom - ARect.Top - AHeight) / 2, AText);
            taAlignBottom: TextOut(ARect.Right - AWidth, ARect.Bottom - AHeight, AText);
          end;
        end;
      end;
    end;
  end;

  function FindNextWord(Text: String; var APos: Integer): String;
  var
    l: Integer;
    i: Integer;
  begin
    Result := '';

    l := Length(Text);
    if APos > l then
      Exit;

    i := APos;
    while True do
    begin
      if ((Text[i] = #10) and (Text[i - 1] = #13)) or ((Text[i] = #13) and (Text[i - 1] = #10)) or (Text[i] = ' ') then
      begin
        if Text[i] = ' ' then
          Result := Copy(Text, APos, i - (APos - 1))
        else
          Result := Copy(Text, APos, i - APos);

        Break;
      end
      else if (Text[i] = #10) or (Text[i] = #13) or (Text[i] = ' ') then
      begin
        result := Copy(Text, APos, i - (APos - 1));
        Break;
      end
      else if i >= l then
      begin
        result := Copy(Text, APos, i - (APos - 1));
        Break;
      end
      else
        inc(i);
    end;

    APos := i + 1;
  end;
begin
  ww := WordWrap or (Pos(#13, Text) > 0) or (Pos(#10, Text) > 0);
  wwx := not WordWrap and ((Pos(#13, Text) > 0) or (Pos(#10, Text) > 0));
    
  if not ww then
  begin
    w := TextWidth(Text);
    th := TextHeight(Text);
    if not Calculate then
      DrawText(Text, w, th);

    Result := CreateCanvasRectF(ARect.Left, ARect.Top, ARect.Left + w, ARect.Top + th);
  end
  else
  begin
    rs := ARect;

    mw := 0;
    i := 1;
    ths := FFont.Size * 0.5;
    lcnt := 0;
    fws := 0;
    tw := 0;
    s := FindNextWord(Text, i);
    w := TextWidth(s);
    th := TextHeight(s) + ths;

    mw := mw + w;
    if (Length(s) > 0) and (s[Length(s)] = ' ') then
      mw := mw + fws;

    fx := False;
    while i <= Length(Text) do
    begin
      l := Length(s);
      if (l >= 2) and (((s[l] = #10) and (s[l - 1] = #13)) or ((s[l] = #13) and (s[l - 1] = #10))) then
      begin
        s := Copy(s, 1, l - 2);
        f := True;
      end
      else if (l >= 1) and ((s[l] = #10) or (s[l] = #13)) then
      begin
        s := Copy(s, 1, l - 1);
        f := True;
      end
      else
        f := False;

      sn := FindNextWord(Text, i);
      w := TextWidth(sn);
      th := Max(th, TextHeight(sn) + ths);

      if (ARect.Left + mw + w > ARect.Right) or f then
      begin
        if (s <> '') and not fx then
        begin
          p := Length(s);

          st := Copy(s, 1, p);

          Inc(lcnt);
          if mw > tw then
            tw := mw;

          if not Calculate then
            DrawText(st, mw, th);

          mw := 0;
        end;

        s := '';

        fx := False;
        if (wwx and f) or not wwx then
          ARect.Top := ARect.Top + th
        else if (wwx and not f) then
          fx := True;

        if (Trunc(ARect.Top) > Trunc(ARect.Bottom - th)) and not Calculate then
          Break;
      end;

      mw := mw + w;
      if (Length(sn) > 0) and (sn[Length(sn)] = ' ') then
        mw := mw + fws;
      s := s + sn;
    end;

    if s <> '' then
    begin
      p := Length(s);
      st := Copy(s, 1, p);
      Inc(lcnt);
      if mw > tw then
        tw := mw;

      if not Calculate then
        DrawText(st, mw, th);
    end;

    Result := CreateCanvasRectF(rs.Left, rs.Top, rs.Left + tw, rs.Top + lcnt * th);
  end;
end;

procedure TCanvas.TextOut(X,Y: Integer; const Text: string);
begin
  TextOut(Double(X), Double(Y), Text);
end;

function TCanvas.TextRect(ARect: TRect; const Text: String; const WordWrap: Boolean = False; const Calculate: Boolean = False; const AHorizontalAlignment: TAlignment = taLeftJustify; const AVerticalAlignment: TVerticalAlignment = taAlignTop): TRect;
var
  r: TCanvasRectF;
begin
  r := TextRect(CreateCanvasRectF(ARect.Left, ARect.Top, ARect.Right, ARect.Bottom), Text, WordWrap, Calculate, AHorizontalAlignment, AVerticalAlignment);
  Result := Rect(Round(r.Left), Round(r.Top), Round(r.Right), Round(r.Bottom));
end;

function TCanvas.TextWidth(const Text: string): Double;
var
  f: string;
  tm: TJSTextMetrics;
begin
  if Assigned(FContext) then
  begin
    f := Font.ToString;
    FContext.font := f;
    tm := FContext.measureText(Text);
    Result := tm.width;
  end;
end;

procedure TCanvas.Transform(m11, m12, m21, m22, dx, dy: Double);
begin
  if Assigned(FContext) then
    FContext.transform(m11, m12, m21, m22, dx, dy);
end;

procedure TCanvas.Translate(X, Y: Double);
begin
  if Assigned(FContext) then
    FContext.translate(X, Y);
end;

procedure TCanvas.Draw(X, Y: Integer; Graphic: TGraphic);
begin
  Draw(Double(X), Double(Y), Graphic);
end;

destructor TCanvas.Destroy;
begin
  inherited;
end;

procedure TCanvas.Draw(X, Y: Double; Graphic: TGraphic);
var
  img: TJSObject;
begin
  if Assigned(FContext) then
  begin
    img := Graphic.Image;
    FContext.drawImage(img, X, Y);
  end;
end;

procedure TCanvas.DrawFocusRect(const Rect: TCanvasRectF);
var
  ps: TPenStyle;
begin
  ps := Pen.Style;
  Pen.Style := psDot;
  Pen.Width := 1;
  Pen.Color := clBlack;

  MoveTo(Rect.Left, Rect.Top);
  LineTo(Rect.Right, Rect.Top);
  LineTo(Rect.Right, Rect.Bottom);
  LineTo(Rect.Left, Rect.Bottom);
  LineTo(Rect.Left, Rect.Top);

  Pen.Style := ps;
end;

procedure TCanvas.DrawFocusRect(const Rect: TRect);
begin
  DrawFocusRect(CreateCanvasRectF(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom));
end;

procedure TCanvas.Ellipse(X1, Y1, X2, Y2: Double);
var
  w, h: Double;
  kappa: Double;
  ox, oy, xe, ye, xm, ym: Double;
begin
  if Assigned(FContext) then
  begin
    w := x2 - x1;
    h := y2 - y1;

    kappa := 0.5522848;

    ox := (w / 2) * kappa;
    oy := (h / 2) * kappa;
    xe := x1 + w;
    ye := y1 + h;
    xm := x1 + w / 2;
    ym := y1 + h / 2;

    FContext.beginPath();
    ApplyStroke;
    ApplyFill;
    FContext.moveTo(x1, ym);
    FContext.bezierCurveTo(x1, ym - oy, xm - ox, y1, xm, y1);
    FContext.bezierCurveTo(xm + ox, y1, xe, ym - oy, xe, ym);
    FContext.bezierCurveTo(xe, ym + oy, xm + ox, ye, xm, ye);
    FContext.bezierCurveTo(xm - ox, ye, x1, ym + oy, x1, ym);
    FContext.closePath();

    if Brush.Style <> bsClear then
      FContext.fill();

    if Pen.Style <> psClear then
      FContext.stroke();
  end;
end;

procedure TCanvas.GetAccuOffset(X,Y: double; var dx, dy: single);
begin
  dx := 0;
  dy := 0;

  if (Pen.Width = 1) and (Pen.Style <> psClear) and (Frac(X) = 0) then
    dx := 0.5;

  if (Pen.Width = 1) and (Pen.Style <> psClear) and (Frac(Y) = 0) then
    dy := 0.5;
end;

function TCanvas.GetBase64Image: string;
begin
  Result := '';
  if Assigned(Element) then
    Result := Element.toDataURL();
end;

function TCanvas.GetPixel(X,Y: Single): TColor;
var
  imgd: TJSImageData;
begin
  Result := clNone;
  if Assigned(FContext) then
  begin
    imgd := FContext.getImageData(x, y, 1, 1);
    Result := RGB(imgd.data[0], imgd.data[1], imgd.data[2]);
  end;
end;

procedure TCanvas.LineTo(X, Y: Double);
var
  dx,dy: single;
begin
  if Assigned(FContext) then
  begin
    GetAccuOffset(X,Y,dx,dy);

    if not FPathOpen then
    begin
      FContext.beginPath();
      ApplyStroke;
      FContext.moveTo(FPathX + dx, FPathY + dy);
    end;

    FContext.lineTo(X + dx, Y + dy);
    FContext.stroke();
    FPathX := X;
    FPathY := Y;

    FPathOpen := false;
  end;
end;

procedure TCanvas.Save;
begin
  if Assigned(FContext) then
    FContext.save();
end;

procedure TCanvas.SetClipRect(const Value: TCanvasRectF);
begin
  FClipRect := Value;
  if Assigned(FContext) then
  begin
    FContext.beginPath;
    FContext.rect(FClipRect.Left, FClipRect.Top, FClipRect.Right - FClipRect.Left, FClipRect.Bottom - FClipRect.Top);
    FContext.clip;
  end;
end;

procedure TCanvas.SetPixel(X,Y: Single; Clr: TColor);
begin
  if Assigned(FContext) then
  begin
    FContext.fillStyle := ColorToHtml(Clr);
    FContext.fillRect(x,y, 1, 1);
  end;
end;

procedure TCanvas.SetTransform(m11, m12, m21, m22, dx, dy: Double);
begin
  if Assigned(FContext) then
    FContext.setTransform(m11, m12, m21, m22, dx, dy);
end;

procedure TCanvas.StretchDraw(Rect: TCanvasRectF; Graphic: TGraphic);
var
  img: TJSObject;
begin
  if Assigned(FContext) then
  begin
    img := Graphic.Image;
    FContext.drawImage(img, 0, 0, Graphic.width, Graphic.height, Rect.Left, Rect.Top, Rect.Right - Rect.Left, Rect.Bottom - Rect.Top);
  end;
end;

procedure TCanvas.StretchDraw(Rect: TRect; Graphic: TGraphic);
begin
  StretchDraw(CreateCanvasRectF(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom), Graphic);
end;

procedure TCanvas.Polygon(const Points: array of TCanvasPointF);
var
  l, i: Integer;
begin
  if Assigned(FContext) then
  begin
    l := Length(Points);

    FContext.beginPath();
    ApplyStroke;
    ApplyFill;
    i := 0;
    FContext.moveTo(Points[i].X, Points[i].Y);

    while (i < l - 1) do
    begin
      inc(i);
      FContext.lineTo(Points[i].X, Points[i].Y);
    end;

    FContext.closePath();

    if Brush.Style <> bsClear then
      FContext.fill();

    if Pen.Style <> psClear then
      FContext.stroke();
  end;
end;

procedure TCanvas.Polyline(const Points: array of TCanvasPointF);
var
  l, i: Integer;
begin
  if Assigned(FContext) then
  begin
    l := Length(Points);

    FContext.beginPath();
    ApplyStroke;
    i := 0;
    FContext.moveTo(Points[i].X, Points[i].Y);

    while (i < l - 1) do
    begin
      inc(i);
      FContext.lineTo(Points[i].X, Points[i].Y);
    end;

    if Pen.Style <> psClear then
      FContext.stroke();
  end;
end;

procedure TCanvas.Ellipse(const Rect: TCanvasRectF);
begin
  Ellipse(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom);
end;

procedure TCanvas.EndScene;
begin
  if Assigned(OnEndScene) then
    OnEndScene(Self);
end;

procedure TCanvas.FillRect(const Rect: TCanvasRectF);
begin
  Rectangle(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom);
end;

procedure TCanvas.Rotate(Angle: Double);
begin
  if Assigned(FContext) then
    FContext.rotate(Angle);
end;

procedure TCanvas.RoundRect(const Rect: TCanvasRectF; CX, CY: Double);
begin
  RoundRect(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom, CX, CY);
end;

{ TGraphic }

constructor TGraphic.Create(Img: TJSObject);
begin
  FAddToQueue := True;
  FEmpty := True;
  FData := '';
  FURL := '';
  FImage := Img;
end;

procedure TGraphic.AssignEvents;
begin
  asm
    var me = this;
    this.FImage.onload = function() {
       me.DoChange();
      };
  end;
end;

procedure TGraphic.CaptureCanvas;
begin
  if Assigned(FCanvas) and Assigned(FCanvas.Element) then
    LoadFromResource(FCanvas.Element.toDataURL());
end;

constructor TGraphic.Create;
begin
  FAddToQueue := True;
  FEmpty := True;
  FData := '';
  CreateImage;
  AssignEvents;
end;

constructor TGraphic.Create(URL: string);
begin
  FAddToQueue := True;
  FEmpty := True;
  FData := '';
  Create;
  LoadFromURL(URL);
end;

procedure TGraphic.Assign(Source: TGraphic);
var
  s: string;
begin
  if Assigned(Source) and Assigned(Source.FCanvas) then
    LoadFromCanvas(Source.FCanvas)
  else if Assigned(Source) and Assigned(Source.FImage) and not Source.Empty then
  begin
    asm
      s = Source.FImage.src;
    end;

    LoadFromURL(s);
  end
  else if not Assigned(Source) then
  begin
    asm
      this.FImage.src = "";
    end;
    DoChange;
  end;
end;

function TGraphic.Image: TJSObject;
begin
  Result := FImage;
end;

procedure TGraphic.LoadFromCache(AData: String);
var
  dt: string;
  o: TJSObject;
  b: Boolean;
  s: string;
  l: Boolean;
begin
  FData := AData;
  dt := AData;
  if dt = '' then
    Exit;
    
  if not FCache.Find(dt, o) then
  begin
    b := False;
    s := '';
    asm
      s = this.FImage.src;
      b = (s != '');
    end;

    l := b and (dt <> s);
    if l then
    begin
      CreateImage;
      AssignEvents;
    end;

    if (not l and (FQueue.IndexOf(dt) = -1)) or l then
    begin
      asm
        this.FImage.src = dt;
        dt = this.FImage.src;
      end;

      FData := dt;

      Inc(FCacheCount);
      if FAddToQueue then
        FQueue.Add(dt);
    end
    else if (not l and (FQueue.IndexOf(dt) <> -1)) then
    begin
      asm
        this.FImage.src = dt;
      end;
    end;
  end
  else
  begin
    FImage := o;
    DoChange;
  end;
end;

procedure TGraphic.LoadFromCanvas(ACanvas: TCanvas);
begin
  if Assigned(ACanvas) and Assigned(ACanvas.Element) then
    LoadFromResource(ACanvas.Element.toDataURL());
end;

procedure TGraphic.LoadFromFile(AFileName: string);
begin
  LoadFromURL(AFileName);
end;

function TGraphic.GetBase64Image: string;
begin
  Result := '';
  if Assigned(FCanvas) then
    Result := FCanvas.GetBase64Image;
end;

procedure TGraphic.LoadFromResource(AResource: string; AHInstance: Integer);
begin
  FEmpty := True;
  LoadFromCache(AResource);
end;

procedure TGraphic.LoadFromResource(AResource: string);
begin
  LoadFromResource(AResource, 0);
end;

procedure TGraphic.LoadFromStream(AStream: TStream);
begin
  DoChange;
end;

procedure TGraphic.LoadFromURL(AURL: string; AHInstance: Integer);
begin
  FEmpty := True;
  LoadFromCache(AURL);
end;

procedure TGraphic.LoadFromURL(AURL: string);
begin
  LoadFromURL(AURL, 0);
end;

procedure TGraphic.RecreateCanvas;
begin
  if not Assigned(FCanvasElement) then
    FCanvasElement := TJSHTMLCanvasElement(document.createElement('CANVAS'));

  if Assigned(FCanvasElement) then
  begin
    FCanvasElement.height := Height;
    FCanvasElement.width := Width;
    if not Assigned(FCanvas) then
    begin
      FCanvas := TCanvas.Create(FCanvasElement);
      FCanvas.OnBeginScene := DoBeginScene;
      FCanvas.OnEndScene := DoEndScene;
    end;
  end;
end;

class function TGraphic.CreateFromResource(AResource: String;
  AInstance: NativeUInt): TGraphic;
begin
  Result := TGraphic.Create;
  Result.LoadFromResource(AResource);
end;

class function TGraphic.CreateFromURL(AURL: String): TGraphic;
begin
  Result := CreateFromURL(AURL, 0);
end;

class function TGraphic.CreateFromURL(AURL: String;
  AInstance: NativeUInt): TGraphic;
begin
  Result := TGraphic.Create;
  Result.LoadFromURL(AURL);
end;

class function TGraphic.CreateFromResource(AResource: string): TGraphic;
begin
  Result := CreateFromResource(AResource, 0);
end;

procedure TGraphic.CreateImage;
begin
  asm
    this.FImage = new Image();
  end;
end;

procedure TGraphic.DoBeginScene(Sender: TObject);
begin
  FCanvas.Clear;
end;

procedure TGraphic.DoChange;
var
  i: Integer;
begin
  FEmpty := (Width = 0) and (Height = 0);
  if not FEmpty and (FData <> '') and not FCache.Exists(FData) then
  begin
    FCache.Add(TGraphicCache.Create(FImage, FData));
    i := FQueue.IndexOf(FData);
    if (i >= 0) and (i <= FQueue.Count - 1) then
      FQueue.Delete(i);

    Dec(FCacheCount);
    if FCacheCount = 0 then
    begin
      if Assigned(Application.OnImageCacheReady) then
        Application.OnImageCacheReady(Application);
    end;
  end;

  if Assigned(FOnChange) then
    FOnChange(Self);
end;

procedure TGraphic.DoEndScene(Sender: TObject);
begin
  FAddToQueue := False;
  CaptureCanvas;
  FAddToQueue := True;
end;

function TGraphic.Empty: Boolean;
begin
  Result := FEmpty;
end;

procedure TGraphic.SetHeight(const Value: Integer);
begin
  asm
    this.FImage.height = Value;
  end;
  RecreateCanvas;
  DoChange;
end;

procedure TGraphic.SetSize(AWidth, AHeight: Integer);
begin
  Width := AWidth;
  Height := AHeight;
end;

procedure TGraphic.SetURL(const URL: string);
begin
  LoadFromURL(URL);
end;

procedure TGraphic.SetWidth(const Value: Integer);
begin
  asm
    this.FImage.width = Value;
  end;
  RecreateCanvas;
  DoChange;
end;

function TGraphic.GetWidth: Integer;
var
  w: integer;
begin
  w := 0;
  if Assigned(FImage) then
  begin
    asm
      w = this.FImage.width;
    end;
  end;
  Result := w;
end;

function TGraphic.GetCanvas: TCanvas;
begin
  if not Assigned(FCanvas) then
    RecreateCanvas;

  Result := FCanvas;
end;

function TGraphic.GetHeight: Integer;
var
  h: integer;
begin
  h := 0;
  if Assigned(FImage) then
  begin
    asm
      h = this.FImage.height;
    end;
  end;
  Result := h;
end;

{ TGraphicCache }

constructor TGraphicCache.Create(AImage: TJSObject; AID: string);
begin
  FImage := AImage;
  FID := AID;
end;

{ TGraphicCacheList }

function TGraphicCacheList.Exists(AID: string): Boolean;
var
  I: Integer;
  it: TGraphicCache;
begin
  Result := False;
  for I := 0 to Count - 1 do
  begin
    it := Items[I] as TGraphicCache;
    if (it.ID = AID) and Assigned(it.Image) then
    begin
      Result := True;
      Break;
    end;
  end;
end;

function TGraphicCacheList.Find(AID: string; var FImage: TJSObject): Boolean;
var
  I: Integer;
  it: TGraphicCache;
begin
  Result := False;
  for I := 0 to Count - 1 do
  begin
    it := Items[I] as TGraphicCache;
    if (it.ID = AID) and Assigned(it.Image) then
    begin
      FImage := it.Image;
      Result := True;
      Break;
    end;
  end;
end;

initialization
begin
  FCache := TGraphicCacheList.Create;
  FQueue := TStringList.Create;
end;

end.